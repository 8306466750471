import styles from './styles.module.css'

// import { useAuth } from '../../hooks/useAuth'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Login } from '../../services/index'
import { notifyErrorCustom } from '../../utils/notify'
import { useAuth } from '../../hooks/useAuth'

import { ArrowRigth, User } from '../../../src/assets/svgs/icons'
function AccountSelectionPage({ usersTologin, bodyLoginUser }) {

  const { login } = useAuth()
  const [users, setUsers] = useState([])

  useEffect(() => {
    initUsers(usersTologin)
  }, [])

  const initUsers = async (availableUsers) => {
    let retUsers = []

    if (availableUsers.data.user?.uuid) {
      retUsers[0] = {
        uuid: availableUsers.data.user?.uuid,
        name: availableUsers.data.user?.name + ' ' + availableUsers.data.user?.surname,
        email: availableUsers.data.user?.email,
        typeUser: 'Usuario Individual',
        typeUserId: 1,
        image: availableUsers.data.user.UserAccount[0].photoUrl? availableUsers.data.user.UserAccount[0].photoUrl : 'https://www.w3schools.com/w3images/avatar2.png',
      }
    }

    if (availableUsers.data.userBusiness.length > 0) {
      await availableUsers.data.userBusiness.map((item, index) => {
        retUsers[index +1] = {
          uuid: item.uuid,
          name: item.UserAccount.user.name + ' ' +  item.UserAccount.user.surname,
          email: item.UserAccount.user.email,
          typeUser: 'Convidado',
          typeUserId: 2,
          image: item.UserAccount.photoUrl ? item.UserAccount.photoUrl : 'https://www.w3schools.com/w3images/avatar2.png',
        }
        
      })
    }
    setUsers(retUsers)


  }
  const redirectToUser = async (data) => {

    
    if (data.typeUserId == 1) {

      let loginData = await Login.postAuth(bodyLoginUser)

      if (loginData?.responseStatus === 200) {
        const loginDataRet = {...loginData.data}
        login(loginDataRet)
      }
  
      if (loginData?.response?.status === 401) {
        notifyErrorCustom('Usuário ou senha incorretos')
      }
  
    } else if(data.typeUserId == 2) {

      const uuid = data.uuid
      const bodyToLogin = {...bodyLoginUser, uuid}
      let loginGuestData = await Login.postGuestComparePassword(bodyToLogin)
      if (loginGuestData?.responseStatus === 200) {
        const guestUserId = uuid
        const loginDataRet = {...loginGuestData.data,guestUserId}
        login(loginDataRet)
      }
    }
  }
  
  return (
    <div className={styles.page}>
      <div className={styles.caontainer}>
        <div className={styles.logo}>
          <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
          <img
            src={posteiLogoText}
            alt="posteiLogoText"
            style={{ height: 34 }}
          />
        </div>
        <div className={styles.caontainer_header}>
          <h1>Login</h1>
          <h2>
            Olá, identificamos que você possui mais de um login em nosso sistema
          </h2>
          <p>Selecione por qual login deseja acessar o sistema:</p>
        </div>
        <div className={styles.caontainer_body}>
          {users.map((item) => (
            <div className={styles.user_box} key={item.uuid}>
              <div className={styles.user_info_box}>
                <div className={styles.user_image_box}>
                  <img src={item.image} alt="img" style={{ width: '80px' }} />
                </div>
                <div className={styles.user_content_info_box}>
                  <h2>{item.name}</h2>
                  <p>{item.email}</p>
                </div>
              </div>
              <div className={styles.user_content_type_box}>
                <div className={styles.user_type_box}>
                  <User color={'#3cbc00'} />
                  <p>{item.typeUser}</p>
                </div>
                <div className={styles.user_arrow_box} onClick={() => redirectToUser(item)}>
                  
                  <ArrowRigth color={'#3cbc00'} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AccountSelectionPage
AccountSelectionPage.propTypes = {
  usersTologin: PropTypes.any,
  bodyLoginUser: PropTypes.any
}