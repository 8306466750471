// import React from 'react'
import styles from './styles.module.css'

export const TextsStep = ({ modalData }) => {
  return (
    <div className={styles.step_container}>
      <div>
        <div>
          <p className={styles.textTitle}>Headline</p>
          <p className={styles.textDescription}>{modalData?.headline}</p>
        </div>
        <div>
          <p className={styles.textTitle}>Copy</p>
          <p className={styles.textDescription}>{modalData?.legend}</p>
        </div>
      </div>
    </div>
  )
}
