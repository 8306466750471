/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { InterrogationCircleIcon } from '../../../../assets/svgs/icons'
import PropTypes from 'prop-types'
import { getAllBusinessNiche } from '../../../../services/businessNicheService'
import {
  getBusinessByLogin,
  getBusinessByCampaign,
  putBusiness,
  postBusiness,
} from '../../../../services/business'
import { isObjEqual } from '../../../../utils/verifyObject'
import * as yup from 'yup'
import { notifyErrorCustom } from '../../../../utils/notify'
// import { ButtonNavigateStep } from '../../../../components/ButtonNavigateStep'
import { ButtonNavigateStep } from '../../ButtonNavigateStep'
import { CustomSelect } from '../../../../components/Select/CustomSelect'

const STEPS = {
  STEP_NEW_CAMPAIGN: 'NewCampaign',
  STEP_NEW_CAMPAIGN_SUCCESS: 'StepNewCampaignSuccess',
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepBusinessInformation({
  setStep,
  campaignItem,
  clientData,
  // registrationData,
  // setCampaignItem,
}) {
  const [businessNichesOptions, setBusinessNichesOptions] = useState([])
  const [businessInformationData, setBusinessInformationData] = useState({})
  const [
    businessInformationDataToCompare,
    setBusinessInformationDataToCompare,
  ] = useState({})

  const businessInformationDataScheema = yup.object().shape({
    businessNicheId: yup.string().required('selecione um nicho'),
    subNiche: yup.string().required('preencha o campo sub-nicho'),
    description: yup.string().required('preencha o campo descrição'),
  })

  // const validateFields = async () => {
  //   const isValid = await businessInformationDataScheema
  //     .validate(businessInformationData)
  //     .then(() => {
  //       return true
  //     })
  //     .catch((error) => {
  //       notifyErrorCustom(error.message)
  //       return false
  //     })

  //   return isValid
  // }

  useEffect(() => {
    loadBusinessNiches()
    loadExistentBusiness()
  }, [])

  const loadBusinessNiches = async () => {
    const { data, responseStatus } = await getAllBusinessNiche()
    if (responseStatus === 200) {
      setBusinessNichesOptions(
        data.map((e) => ({ label: e.name, value: e.uuid }))
      )
    }
  }

  const loadExistentBusiness = async () => {
    // console.log('campaignItem', campaignItem)
    // console.log('clientData', clientData)

    const { data, responseStatus } = await getBusinessByCampaign(
      campaignItem?.uuid
    )

    if (responseStatus === 200 && data) {
      if (data.length > 0) {
        setBusinessInformationData(data[0])
        setBusinessInformationDataToCompare(data[0])
      }
    }
  }

  const handleChangeBusinessInformation = (id, value) => {
    setBusinessInformationData((prev) => ({ ...prev, [id]: value }))
  }

  // const createCampaign = async () => {
  //   const newRegistrationData = JSON.parse(JSON.stringify(registrationData))
  //   delete newRegistrationData.uuid
  //   delete newRegistrationData.createdAt
  //   delete newRegistrationData.updatedAt
  //   delete newRegistrationData.deletedAt
  //   delete newRegistrationData.enable
  //   const { data, responseStatus } = await CampaignService.postCampaign({
  //     ...newRegistrationData,
  //   })

  //   console.log('new Campaign response', data)

  //   setCampaignItem(data)

  //   if (responseStatus === 200 && data) {
  //     notifySuccessCustom('Campanha criada com sucesso')
  //     setStep(STEPS.STEP_NEW_CAMPAIGN_SUCCESS)
  //   }

  //   return data
  // }

  const onSubmitBusiness = async () => {
    // if (!(await validateFields())) return

    if (
      businessInformationData.uuid &&
      !isObjEqual(businessInformationData, businessInformationDataToCompare)
    ) {
      const { uuid, ...rest } = businessInformationData

      delete rest.userAccountId
      delete rest.createdAt
      delete rest.updatedAt
      delete rest.deletedAt
      delete rest.BusinessNiche
      delete rest.default

      delete rest.businessClientId

      await putBusiness(uuid, rest)
    } else {
      if (!businessInformationData?.uuid) {
        let newBody = { ...businessInformationData }

        if (clientData?.uuid) {
          newBody = {
            ...newBody,
            businessClientId: clientData?.uuid,
            // campaignId: campaignItem?.uuid,
          }
        }

        newBody = {
          ...newBody,

          campaignId: campaignItem?.uuid,
          guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId
        }

        await postBusiness({ ...newBody })
      }
    }

    setStep(STEPS.STEP_BRANDING)
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Informações sobre o seu negócio</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <form className={styles.form_business_information}>
        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          Selecione o seu nicho
        </p>

        <CustomSelect
          placeholder={'Selecione um tipo'}
          id="businessNicheId"
          arrowDirection="down"
          options={businessNichesOptions}
          defaultSelected={businessNichesOptions.find(
            (e) => e.value === businessInformationData.businessNicheId
          )}
          fontSize={12}
          onClickItem={({ value }) =>
            handleChangeBusinessInformation('businessNicheId', value)
          }
        />

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          Digite seu sub-nicho
        </p>
        <input
          id="subNiche"
          onChange={({ target: { id, value } }) =>
            handleChangeBusinessInformation(id, value)
          }
          value={businessInformationData.subNiche}
          className={styles.input}
          placeholder="Digite"
        ></input>

        <p
          className={styles.subtitle_text}
          style={{ marginTop: 20, marginBottom: 8 }}
        >
          Descrição do seu negócio
        </p>
        <textarea
          style={{ height: 200 }}
          className={styles.input}
          placeholder="Digite"
          id="description"
          value={businessInformationData.description}
          onChange={({ target: { id, value } }) =>
            handleChangeBusinessInformation(id, value)
          }
        ></textarea>
      </form>

      <div className={styles.buttons_field}>
        {/* <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_NEW_CAMPAIGN_SUCCESS)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep> */}

        <div></div>

        <ButtonNavigateStep onClick={() => onSubmitBusiness()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepBusinessInformation

StepBusinessInformation.propTypes = {
  setStep: PropTypes.func,
}
