import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import { CheckBadge, LogOut } from '../../../../assets/svgs/icons'
import { useNavigate } from 'react-router-dom'

import {
  BusinessService,
  NotificationService,
  UserAccountsService,
} from '../../../../services'
import { useAuth } from '../../../../hooks/useAuth'
import { Switch } from 'antd'
import { useClient } from '../../../../hooks/useClient'
import { getBusinessByClientId } from '../../../../services/business'

export const NotificationsStep = ({ setStep }) => {
  const { logout } = useAuth()
  const [settingsCompleted, setSettingsCompleted] = useState({
    parameterizationCompleted: false,
  })

  const { clientData } = useClient()

  const [notificationData, setNotificationData] = useState({})

  const [notificationAgendamento, setNotificationAgendamento] = useState()
  const [notificationPassword, setNotificationPassword] = useState(false)
  const [notificationUpdate, setNotificationUpdate] = useState(false)
  const [
    notificationAgendamentoPublicado,
    setNotificationAgendamentoPublicado,
  ] = useState(false)
  const [notificationSugestionUpdate, setNotificationSugestionUpdate] =
    useState(false)

  useEffect(() => {
    loadNotification()
  }, [
    notificationPassword,
    notificationPassword,
    notificationUpdate,
    notificationAgendamentoPublicado,
    notificationSugestionUpdate,
  ])

  const loadNotification = async () => {
    const { data } = await NotificationService.getNotificationAllData()

    setNotificationData(data[0])

    setNotificationAgendamento(data[0].appointmentNotification)
    setNotificationPassword(data[0].passwordReset)
    setNotificationUpdate(data[0].notifyUpdate)
    setNotificationAgendamentoPublicado(
      data[0].PublishedAppointmentNotifications
    )
    setNotificationSugestionUpdate(data[0].NewsSuggestionsUpdates)
  }

  const isNotification = async (field) => {
    let body = {}

    if (field === 'appointmentNotification') {
      if (notificationAgendamento) {
        await setNotificationAgendamento(false)
      } else {
        await setNotificationAgendamento(true)
      }

      body = {
        appointmentNotification: !notificationAgendamento,
        passwordReset: notificationPassword,
        notifyUpdate: notificationUpdate,
        PublishedAppointmentNotifications: notificationAgendamentoPublicado,
        NewsSuggestionsUpdates: notificationSugestionUpdate,
      }
    } else if (field === 'passwordReset') {
      if (notificationPassword) {
        setNotificationPassword(false)
      } else {
        setNotificationPassword(true)
      }

      body = {
        appointmentNotification: notificationAgendamento,
        passwordReset: !notificationPassword,
        notifyUpdate: notificationUpdate,
        PublishedAppointmentNotifications: notificationAgendamentoPublicado,
        NewsSuggestionsUpdates: notificationSugestionUpdate,
      }
    } else if (field === 'notifyUpdate') {
      if (notificationUpdate) {
        setNotificationUpdate(false)
      } else {
        setNotificationUpdate(true)
      }

      body = {
        appointmentNotification: notificationAgendamento,
        passwordReset: notificationPassword,
        notifyUpdate: !notificationUpdate,
        PublishedAppointmentNotifications: notificationAgendamentoPublicado,
        NewsSuggestionsUpdates: notificationSugestionUpdate,
      }
    } else if (field === 'PublishedAppointmentNotifications') {
      if (notificationAgendamentoPublicado) {
        setNotificationAgendamentoPublicado(false)
      } else {
        setNotificationAgendamentoPublicado(true)
      }

      body = {
        appointmentNotification: notificationAgendamento,
        passwordReset: notificationPassword,
        notifyUpdate: notificationUpdate,
        PublishedAppointmentNotifications: !notificationAgendamentoPublicado,
        NewsSuggestionsUpdates: notificationSugestionUpdate,
      }
    } else if (field === 'NewsSuggestionsUpdates') {
      if (notificationSugestionUpdate) {
        setNotificationSugestionUpdate(false)
      } else {
        setNotificationSugestionUpdate(true)
      }

      body = {
        appointmentNotification: notificationAgendamento,
        passwordReset: notificationPassword,
        notifyUpdate: notificationUpdate,
        PublishedAppointmentNotifications: notificationAgendamentoPublicado,
        NewsSuggestionsUpdates: !notificationSugestionUpdate,
      }
    }

    //console.log(body)
    await NotificationService.putNotification(notificationData.uuid, body)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      <div className={styles.container_notification}>
        <div className={styles.container_input_form}>
          <div className={styles.notification_module}>
            <div className={styles.module}>
              <h3 className={styles.module_title}>Notificações</h3>
            </div>
            <div className={styles.container_option_form}>
              <p className={styles.container_option_form_title}>
                Notificações por email
              </p>

              <div className={styles.box_option_notification}>
                <p className={styles.option_notification_label}>
                  Notificações de agendamento
                </p>

                <Switch
                  size="small"
                  style={{
                    backgroundColor: notificationAgendamento
                      ? '#FF8B00'
                      : '#000',
                  }}
                  checked={notificationAgendamento}
                  onClick={() => isNotification('appointmentNotification')}
                />
              </div>
              <div className={styles.box_option_notification}>
                <p className={styles.option_notification_label}>
                  Notificações de redefinições de senha{' '}
                </p>

                <Switch
                  size="small"
                  style={{
                    backgroundColor: notificationPassword ? '#FF8B00' : '#000',
                  }}
                  checked={notificationPassword}
                  onClick={() => isNotification('passwordReset')}
                />
              </div>
              <div className={styles.box_option_notification}>
                <p className={styles.option_notification_label}>
                  Notificações e atualizações
                </p>

                <Switch
                  size="small"
                  style={{
                    backgroundColor: notificationUpdate ? '#FF8B00' : '#000',
                  }}
                  checked={notificationUpdate}
                  onClick={() => isNotification('notifyUpdate')}
                />
              </div>

              <p className={styles.container_option_form_title}>
                Notificações por push
              </p>

              <div className={styles.box_option_notification}>
                <p className={styles.option_notification_label}>
                  Notificações de agendamentos publicados
                </p>

                <Switch
                  size="small"
                  style={{
                    backgroundColor: notificationAgendamentoPublicado
                      ? '#FF8B00'
                      : '#000',
                  }}
                  checked={notificationAgendamentoPublicado}
                  onClick={() =>
                    isNotification('PublishedAppointmentNotifications')
                  }
                />
              </div>

              <div className={styles.box_option_notification}>
                <p className={styles.option_notification_label}>
                  Novidades, Sugestões e Atualizações
                </p>

                <Switch
                  size="small"
                  style={{
                    backgroundColor: notificationSugestionUpdate
                      ? '#FF8B00'
                      : '#000',
                  }}
                  checked={notificationData?.NewsSuggestionsUpdates}
                  onClick={() => isNotification('NewsSuggestionsUpdates')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
