import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import { UserService } from '../../../services'

function StepEmail({ setEmail, email, setStep }) {
  const STEPS = {
    STEP_PASS_NAME: 'stepName',
    STEP_PASS_PHONE: 'stepPhone',
  }

  const isValidEmail = (email) => {
    // Expressão regular para validar e-mail
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Testa o e-mail recebido com o regex
    return emailRegex.test(email)
  }

  const verifyEmailExist = async (email) => {
    let response = false
    const checkEmailExists = await UserService.getUserCheckEmailExists(email)
    if (checkEmailExists?.data?.exists) {
      console.log('teste1', checkEmailExists)
      response = checkEmailExists?.data?.exists
    } else {
      console.log('teste2', checkEmailExists)

      response = checkEmailExists?.data?.exists
    }

    return response
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // if (email === '') {
      //   notifyErrorCustom('Preencha o campo com seu email')
      //   return
      // } else if (!isValidEmail(email)) {
      //   notifyErrorCustom('Esse não é um formato de email valido')
      //   return
      // }

      // const checkEmailExists = await UserService.getUserCheckEmailExists(email)
      // if (checkEmailExists?.data?.exists === true) {
      //   console.log('teste1', checkEmailExists)
      //   notifyErrorCustom('E-mail já cadastrado!')
      //   return
      //   // response = checkEmailExists?.data?.exists
      // }

      setStep(STEPS.STEP_PASS_PHONE)
    } else {
      setStep(STEPS.STEP_PASS_NAME)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Cadastro</p>
        <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

        <div className={styles.box_input_email}>
          <p>Agora nos informe seu e-mail</p>
          <input
            className={styles.input_email}
            type="text"
            value={email}
            placeholder="E-mail"
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepEmail
