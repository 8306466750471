import { useState } from 'react'
import { ButtonFavoriteStarsPress, PasteIcon } from '../../../assets/svgs/icons'
import styles from './styles.module.css'

function StepPublicationDownload() {
  const stars = [...new Array(5).keys()]
  const [activeIndex, setActiveIndex] = useState()

  const onClickStar = (index) => {
    setActiveIndex((oldState) => (oldState == index ? undefined : index))
  }
  return (
    <div className={styles.box_page}>
      <p style={{ margin: '0px' }}>Faça o download da sua publicação!</p>
      <h1 style={{ marginTop: '0px' }}>Dê uma nota para esta arte:</h1>

      <div className={styles.container_publication}>
        <div className={styles.box_publication}>
          <img
            className={styles.image_publication}
            src="https://i.pinimg.com/736x/71/c4/10/71c41077fec8adf8ccba69c545fb255f.jpg"
            alt="teste"
          />
        </div>

        <div className={styles.box_star_index}>
          {stars.map((index) => (
            <ButtonFavoriteStarsPress
              key={`star_${index}`}
              color={'#000'}
              isActive={index <= activeIndex}
              onClick={() => onClickStar(index)}
            />
          ))}
        </div>

        <div className={styles.box_download_button}>
          <button className={styles.download_button}>Fazer Download</button>
        </div>

        <div className={styles.box_legend_publication}>
          <div className={styles.legend_publication}>
            <div className={styles.box_icon}>
              <PasteIcon color="#3CBC00" width={30} height={30} />
            </div>

            <div className={styles.divider}></div>

            <p className={styles.legend_text}>Legenda da publicação copiada!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepPublicationDownload
