/* eslint-disable react/prop-types */

import { Input } from '../../../components'
import { ListTable } from '../../../components/ListTable'
import { Row } from '../../../components/ListTable/Row'
import { TableItem } from '../../../components/ListTable/TableItem'
import styles from './styles.module.css'
import { Switch } from 'antd'
import {
  // CircleFacebook,
  // CloundArrowUp,
  Eye,
  Gear,
  // Instagram,
  // Linkedin,
  // Pinterest,
  // TikTok,
  Trash,
  // Twitter,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { AnimatePresence, motion } from 'framer-motion'
// import { useNavigate } from 'react-router-dom'
import { TargetAudienceService } from '../../../services'
import { getAllgenderService } from '../../../services/genderService'
import {
  getAllLocationStatesService,
  getAllRegionsLocationService,
} from '../../../services/locationsService'
import {
  getAllSocialClassService,
  postSocialClassHasTargetAudiences,
  deleteSocialClassHasTargetAudiences,
  postEducationLevelHasTargetAudiences,
  deleteEducationLevelHasTargetAudiences,
} from '../../../services/socialClassService'
import { getAllEducationLevelService } from '../../../services/educationService'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import * as yup from 'yup'
import { Loading } from '../../../components/Loading'
import { MultiplySelectSquare } from '../../../components/Select/MultiplySelectSquare'

const STEPS = {
  STEP_TARGET_AUDIENCE_VIEW: 'targetAudienceView',
}

function TargetAudienceList({ setStep, setTargetAudienceItem, clientData }) {
  const headers = ['Nome', 'Classe Social', 'Estado', 'Região', 'Ações']

  // const [isPinterest, setIsPinterest] = useState(false)
  // const [isLinkedin, setIsLinkedin] = useState(false)
  // const [isFacebook, setIsFacebook] = useState(false)
  // const [isInstagram, setIsInstagram] = useState(false)
  // const [isTiktok, setIsTiktok] = useState(false)
  // const [isTwitter, setIsTwitter] = useState(false)

  const [locationsStatesOptions, setLocationsStatesOptions] = useState([
    { label: '', value: '' },
  ])
  const [genderOptions, setGenderOptions] = useState([{ label: '', value: '' }])

  const [educationLevelOptions, setEducationLevelOptions] = useState([
    { label: '', value: '' },
  ])
  const [socialClassOptions, setSocialClassOptions] = useState([
    { label: '', value: '' },
  ])
  const [regionLocationOptions, setRegionLocationOptions] = useState([
    { label: '', value: '' },
  ])

  const [tagetAudienceData, setTargetAudienceData] = useState({})

  // const navigation = useNavigate()

  const [expanded, setExpanded] = useState(false)

  const [dataList, setDataList] = useState([])

  const [loading, setLoading] = useState(false)

  const handleExpandForm = (params) => {
    if (!params) {
      setTargetAudienceData({})
    }

    setTargetAudienceData(params)
    setExpanded(!expanded)
  }

  const handleNavigate = (targetAudienceItem) => {
    setTargetAudienceItem(targetAudienceItem)
    setStep(STEPS.STEP_TARGET_AUDIENCE_VIEW)
    // navigation(route, { state: { targetAudienceItem } })
  }

  const handleChecked = async (data) => {
    let uuid = data?.uuid

    data.educationLevelId = data.educationLevelId ?? -1
    data.onlinePurchaseFrequencyId = data.onlinePurchaseFrequencyId ?? -1
    data.targetAudienceBrandsId = data.targetAudienceBrandsId ?? -1
    data.guestId = data.guestId ?? ''
    data.stateId = data.stateId ?? -1
    data.regionId = data.regionId ?? -1
    data.city = data.city ?? ''
    data.businessClientId = data.businessClientId ?? ''
    data.profession = data.profession ?? ''

    let newData = { ...data }

    delete newData.uuid
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.deletedAt
    delete newData.userAccountId
    delete newData.genderValue

    delete newData.userAccountId

    delete newData.educationLevelItems
    delete newData.socialClassItems

    delete newData.SocialClassHasTargetAudience
    delete newData.EducationLevelHasTargetAudience
    delete newData.educationLevelValue
    delete newData.socialClassValue
    delete newData.stateValue
    delete newData.educationLevelId

    if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
      newData.guestId = JSON.parse(
        window.localStorage.getItem('userData')
      ).guestUserId
    }
    if (!clientData?.uuid) {
      delete newData.businessClientId
    }
    setLoading(true)

    const { responseStatus } = await TargetAudienceService.putTargetAudience(
      uuid,
      { ...newData, enabled: !newData.enabled }
    )
    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      loadTargetAudienceData()
      notifySuccessCustom('Status alterado com sucesso!')
    } else {
      notifyErrorCustom('Não foi possível alterar o status!')
    }
  }

  const [isNameValid, setIsNameValid] = useState(true)
  const handleChange = (k, v) => {
    setTargetAudienceData((prevState) => ({ ...prevState, [k]: v }))

    if (k === 'name') {
      if (v.trim() === '') {
        setIsNameValid(false)
      } else {
        setIsNameValid(true)
      }
    }
  }

  useEffect(() => {
    loadTargetAudienceData()
    loadDropdownsData()
    setExpanded(false)
  }, [clientData])

  const loadTargetAudienceData = async () => {
    if (!clientData?.uuid) {
      setLoading(true)
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByNotClientId()
      setLoading(false)
      if (responseStatus === 200) {
        setDataList(data)
      }
    } else {
      setLoading(true)
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )
      setLoading(false)
      if (responseStatus === 200) {
        setDataList(data)
      }
    }
  }

  const loadDropdownsData = async () => {
    // faça chamada dos serviços
    const { data: stateLocationData, responseStatus: stateLocationStatus } =
      await getAllLocationStatesService()

    const { data: regionLocationData, responseStatus: regionLocationStatus } =
      await getAllRegionsLocationService()

    const { data: genderData, responseStatus: genderStatus } =
      await getAllgenderService()

    const { data: socialClassData, responseStatus: socialClassStatus } =
      await getAllSocialClassService()

    const { data: educationLevelData, responseStatus: educationLevelStatus } =
      await getAllEducationLevelService()

    //altere estados
    if (stateLocationStatus === 200)
      setLocationsStatesOptions(
        stateLocationData.map((e) => ({ label: e.name, value: e.id }))
      )

    if (regionLocationStatus === 200)
      setRegionLocationOptions(
        regionLocationData.map((e) => ({ label: e.name, value: e.id }))
      )

    if (genderStatus === 200)
      setGenderOptions(genderData.map((e) => ({ label: e.name, value: e.id })))
    if (socialClassStatus === 200)
      setSocialClassOptions(
        socialClassData.map((e) => ({ label: e.name, value: e.id }))
      )
    if (educationLevelStatus === 200)
      setEducationLevelOptions(
        educationLevelData.map((e) => ({ label: e.name, value: e.id }))
      )
  }

  const dataScheema = yup.object().shape({
    name: yup.string().required('o campo nome é obrigatorio'),
    genderId: yup.number().required('selecione um gênero'),
    // city: yup.string().required('digite a cidade'),
    // regionId: yup.number().required('selecione a região'),
    // stateId: yup.number().required('selecione o estado'),
    // profession: yup.string().required('preencha o campo profissão'),
    // socialClassId: yup.number().required('selecione a classe social'),
    // educationLevelId: yup
    //   .number()
    //   .required('selecione um nivel de escolaridade'),
  })

  //valide dados com base no scheema
  const validateFields = async (dataToValidate) => {
    return await dataScheema
      .validate(dataToValidate)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })
  }

  const handleSave = async () => {
    const isValid = await validateFields(tagetAudienceData)
    if (!isValid) {
      setLoading(false)
      return
    }

    if (tagetAudienceData?.uuid) {
      tagetAudienceData.educationLevelId =
        tagetAudienceData.educationLevelId ?? -1
      tagetAudienceData.onlinePurchaseFrequencyId =
        tagetAudienceData.onlinePurchaseFrequencyId ?? -1
      tagetAudienceData.targetAudienceBrandsId =
        tagetAudienceData.targetAudienceBrandsId ?? -1
      tagetAudienceData.guestId = tagetAudienceData.guestId ?? ''
      tagetAudienceData.stateId = tagetAudienceData.stateId ?? -1
      tagetAudienceData.regionId = tagetAudienceData.regionId ?? -1
      tagetAudienceData.city = tagetAudienceData.city ?? ''
      tagetAudienceData.businessClientId =
        tagetAudienceData.businessClientId ?? ''
      tagetAudienceData.profession = tagetAudienceData.profession ?? ''

      const { uuid, ...rest } = { ...tagetAudienceData }

      delete rest.createdAt
      delete rest.updatedAt
      delete rest.deletedAt
      delete rest.userAccountId
      delete rest.genderValue

      delete rest.SocialClassHasTargetAudience
      delete rest.genderId.EducationLevelHasTargetAudience
      delete rest.educationLevelValue
      delete rest.socialClassValue
      // delete rest.educationLevelValue
      // delete rest.socialClassValue
      // delete rest.stateValue
      // delete rest.businessClientId

      delete rest.educationLevelItems
      delete rest.socialClassItems
      delete rest.EducationLevelHasTargetAudience
      delete rest.stateValue
      delete rest.educationLevelId

      if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
        rest.guestId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId
      }

      setLoading(true)

      for (
        let i = 0;
        i < tagetAudienceData?.EducationLevelHasTargetAudience?.length;
        i++
      ) {
        await deleteEducationLevelHasTargetAudiences(
          tagetAudienceData?.EducationLevelHasTargetAudience[i]?.uuid
        )
      }

      for (
        let i = 0;
        i < tagetAudienceData?.SocialClassHasTargetAudience?.length;
        i++
      ) {
        await deleteSocialClassHasTargetAudiences(
          tagetAudienceData?.SocialClassHasTargetAudience[i]?.uuid
        )
      }

      const { responseStatus } = await TargetAudienceService.putTargetAudience(
        uuid,
        rest
      )

      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        const { educationLevelItems, socialClassItems } = tagetAudienceData

        for (let i = 0; i < educationLevelItems?.length; i++) {
          const body = {
            targetAudienceId: uuid,
            educationLevelId: educationLevelItems[i].value,
          }

          const saveEducationLevel = await postEducationLevelHasTargetAudiences(
            body
          )
        }

        for (let i = 0; i < socialClassItems?.length; i++) {
          const body = {
            targetAudienceId: uuid,
            socialClassId: socialClassItems[i].value,
          }

          const saveSocialClass = await postSocialClassHasTargetAudiences(body)
        }

        setExpanded(!expanded)
      }
    } else {
      setLoading(true)

      let newBody = { ...tagetAudienceData }

      const { educationLevelItems, socialClassItems } = { ...tagetAudienceData }

      delete newBody.educationLevelItems
      delete newBody.socialClassItems

      let guestId = JSON.parse(
        window.localStorage.getItem('userData')
      ).guestUserId

      if (clientData?.uuid && guestId) {
        newBody = {
          ...tagetAudienceData,
          businessClientId: clientData?.uuid,
          guestId,
        }
      }
      if (guestId) {
        newBody = { ...tagetAudienceData, guestId: guestId }
      }

      const { responseStatus, data } =
        await TargetAudienceService.postTargetAudience({
          ...newBody,
        })

      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        for (let i = 0; i < socialClassItems?.length; i++) {
          const body = {
            targetAudienceId: data?.uuid,
            socialClassId: socialClassItems[i].value,
          }

          const saveSocialClass = await postSocialClassHasTargetAudiences(body)

          console.log('saveSocialClass', saveSocialClass)

          console.log('body', body)
        }

        for (let i = 0; i < educationLevelItems?.length; i++) {
          const body = {
            targetAudienceId: data?.uuid,
            educationLevelId: educationLevelItems[i].value,
          }

          const saveEducationLevel = await postEducationLevelHasTargetAudiences(
            body
          )

          console.log('saveEducationLevel', saveEducationLevel)

          console.log('body', body)
        }

        setExpanded(!expanded)
      }
    }
    setTargetAudienceData({})
    loadTargetAudienceData()
  }

  const handleDeliteItemList = async (id) => {
    setLoading(true)

    const { responseStatus } = await TargetAudienceService.deleteTargetAudience(
      id
    )

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      notifySuccessCustom('Publico alvo deletado com sucesso!')
    }
    loadTargetAudienceData()
  }

  return (
    <div className={styles.container_page}>
      <Loading enable={loading} />
      <div className={styles.header_page}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Público-Alvo</p>
          <p className={styles.title_new_posts_title}>Lista de Públicos-Alvo</p>
        </div>
        <div>
          <button
            className={styles.button_new_user}
            onClick={() => handleExpandForm()}
          >
            {expanded ? 'Cancelar' : 'Novo Público-Alvo'}
          </button>
        </div>
      </div>

      <div className={styles.container_content}>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              // key={expanded}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={styles.container_form}
            >
              <p className={styles.title_new_posts_title}>Novo Públicos-Alvo</p>
              <div className={styles.container_content_form}>
                <div className={styles.box_input_form}>
                  <label className={styles.label_input_form}>
                    Nome do Público-Alvo
                  </label>
                  {!isNameValid && (
                    <p style={{ color: 'red' }}>
                      O campo Nome não pode estar vazio.
                    </p>
                  )}
                  <Input
                    id="name"
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                    defaultValue={tagetAudienceData?.name}
                  />
                </div>

                <div className={styles.box_input_form}>
                  <label className={styles.label_input_form}>Gênero</label>

                  <SelectSquare
                    htmlFor="select"
                    arrowDirection="down"
                    placeholder={'Selecione'}
                    defaultSelected={genderOptions.find(
                      (e) => e.value === tagetAudienceData?.genderId
                    )}
                    options={genderOptions}
                    onClickItem={({ value }) => handleChange('genderId', value)}
                  />
                </div>
                <div className={styles.box_input_form}>
                  <label className={styles.label_input_form}>
                    Nível de Escolaridade
                  </label>

                  <MultiplySelectSquare
                    htmlFor="select"
                    variante="grey"
                    arrowDirection="down"
                    placeholder={'Selecione...'}
                    defaultSelected={() => {
                      if (tagetAudienceData?.educationLevelValue?.length > 0) {
                        let itemReturn =
                          tagetAudienceData?.educationLevelValue.map((e) => ({
                            label: e,
                            value: educationLevelOptions.find(
                              (el) => el.label === e
                            )?.value,
                          }))

                        handleChange('educationLevelItems', itemReturn)
                        return itemReturn
                      }
                      return []
                    }}
                    options={educationLevelOptions}
                    onClickItem={(value) => {
                      handleChange('educationLevelItems', value)
                    }}
                  />
                </div>

                <div className={styles.box_input_form}>
                  <label className={styles.label_input_form}>
                    {' '}
                    Classe Social
                  </label>

                  <MultiplySelectSquare
                    htmlFor="select"
                    variante="grey"
                    arrowDirection="down"
                    placeholder={'Selecione...'}
                    defaultSelected={() => {
                      if (tagetAudienceData?.socialClassValue?.length > 0) {
                        let itemReturn =
                          tagetAudienceData?.socialClassValue.map((e) => ({
                            label: e,
                            value: socialClassOptions.find(
                              (el) => el.label === e
                            )?.value,
                          }))

                        handleChange('socialClassItems', itemReturn)

                        return itemReturn
                      }
                      return []
                    }}
                    options={socialClassOptions}
                    onClickItem={(value) => {
                      handleChange('socialClassItems', value)
                    }}
                  />
                </div>
                <div className={styles.box_input_form}>
                  <label className={styles.label_input_form}> Profissão</label>
                  <Input
                    id="profession"
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                    defaultValue={
                      tagetAudienceData ? tagetAudienceData?.profession : ''
                    }
                  />
                </div>

                <div className={styles.container_input_fom}>
                  <div className={styles.box_input_form}>
                    <label className={styles.label_input_form}> Região</label>
                    <SelectSquare
                      htmlFor="select"
                      arrowDirection="down"
                      placeholder={
                        tagetAudienceData
                          ? tagetAudienceData?.regionId
                          : 'Selecione'
                      }
                      defaultSelected={regionLocationOptions.find(
                        (e) => e.value === tagetAudienceData?.regionId
                      )}
                      options={regionLocationOptions}
                      onClickItem={({ value }) =>
                        handleChange('regionId', value)
                      }
                    />
                  </div>

                  <div className={styles.box_input_form}>
                    <label className={styles.label_input_form}> Estado</label>
                    <SelectSquare
                      htmlFor="select"
                      arrowDirection="down"
                      placeholder={
                        tagetAudienceData
                          ? tagetAudienceData?.stateId
                          : 'Selecione'
                      }
                      defaultSelected={locationsStatesOptions.find(
                        (e) => e.value === tagetAudienceData?.stateId
                      )}
                      options={locationsStatesOptions}
                      onClickItem={({ value }) =>
                        handleChange('stateId', value)
                      }
                    />
                  </div>

                  <div className={styles.box_input_form}>
                    <label className={styles.label_input_form}> Cidade</label>
                    <Input
                      id="city"
                      onChange={({ target: { id, value } }) =>
                        handleChange(id, value)
                      }
                      defaultValue={
                        tagetAudienceData ? tagetAudienceData?.city : ''
                      }
                    />
                  </div>
                </div>
                <div className={styles.box_button_save}>
                  <button
                    className={styles.button_save}
                    onClick={() => {
                      setLoading(true)
                      handleSave()
                    }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {expanded === false && (
          <motion.div
            // key={expanded}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ListTable headers={headers}>
              {dataList?.map((targetAudience, index) => (
                // eslint-disable-next-line react/jsx-key
                <Row key={index}>
                  <TableItem>
                    <div className={styles.box_name_target_audience}>
                      <div>
                        <p className={styles.name_target_audience_text}>
                          {targetAudience?.name}
                        </p>
                      </div>
                    </div>
                  </TableItem>
                  <TableItem>
                    {targetAudience?.socialClassValue.map((e) => e).join(', ')}
                  </TableItem>
                  <TableItem>
                    <span className={styles.bold}>
                      {targetAudience?.stateValue}
                    </span>
                  </TableItem>
                  <TableItem>
                    <span className={styles.bold}>
                      {targetAudience?.regionValue}
                    </span>
                  </TableItem>
                  <TableItem style={{ maxWidth: 170 }}>
                    <div className={styles.last_column}>
                      <div className={styles.icons_container}>
                        <div className={styles.icon}>
                          <div className={styles.box_action_icon}>
                            <Switch
                              size="small"
                              style={{
                                backgroundColor: targetAudience?.enabled
                                  ? 'black'
                                  : '',
                              }}
                              checked={targetAudience?.enabled}
                              onClick={() => handleChecked(targetAudience)}
                            />
                          </div>

                          <div
                            className={styles.box_action_icon}
                            onClick={() => handleExpandForm(targetAudience)}
                          >
                            <Gear color={'#000'} />
                          </div>

                          <div
                            className={styles.box_action_icon}
                            onClick={() => handleNavigate(targetAudience)}
                          >
                            <Eye color={'#000'} />
                          </div>

                          <div
                            className={styles.box_action_icon}
                            onClick={() =>
                              handleDeliteItemList(targetAudience.uuid)
                            }
                          >
                            <Trash color={'#000'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableItem>
                </Row>
              ))}
            </ListTable>
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default TargetAudienceList
