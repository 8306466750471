/* eslint-disable react/prop-types */
import styles from './styles.module.css'

import { useState, useEffect, useRef } from 'react'

import SimpleEditionModal from '../../../components/SimpleEditionModalTemplate'
import PropTypes from 'prop-types'

// import { Loading } from '../../../components/Loading'

import lazyLoadingAnimation from '../../../assets/lottie/lazy-loading-animation.json'
import Lottie from 'lottie-react'

import { CircleX, CheckBadge } from '../../../assets/svgs/icons'

import {
  BrandingService,
  BusinessService,
  EditorialLineService,
  LogosService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services/'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '')

  if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (char) {
          return char + char
        })
        .join('')
    }

    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    return `(${r},${g},${b})`
  } else {
    return `(${0},${0},${0})`
  }
}

function StepThirdPass({
  setStep,
  setIaData,
  iaData,
  qtdLoadTemplates,
  setQtdLoadTemplates,
  qtdTemplatesLimit,
  clientData,
  setImageUrl,
  imageUrl,
  imageLayers,
  setImageLayers,
  headlineText,
  setHeadlineText,
  imageGaleryArray,
  imageArray,
  imageAIArray,
  imageUploadArray,
  imageProductsArray,
  setHeadlineList,
  setLegendList,

  selectedCampaignOption,

  selectedArrayImage,
  setSelectedArrayImage,

  selectedArrayImageObject,
  setSelectedArrayImageObject,

  // setSelectedObjectItem,
  setSelectedObjectItemObject,
  // selectedObjectItem,
  selectedObjectItemObject,

  selectFunnel,
  setHeadlineGpt,
  headlineGpts,
  objectItem,
  setObjectItem,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  // const [loading, setLoading] = useState(false)

  const [headlineSubtitle, setHeadlineSubtitle] = useState('')

  // const [productTypeOptions, setProductTypeOptions] = useState([])
  // const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
  //   useState([])

  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [headlineGptLocal, setHeadlineGptLocal] = useState(null)

  // useEffect(() => {
  //   if (iaData.length === 0) {
  //     fetchData()
  //   }

  //   loadProductServiceData()
  //   loadtargetAudienceData()
  // }, [iaData])

  useEffect(() => {
    if (qtdLoadTemplates <= qtdTemplatesLimit) {
      // if (qtdLoadTemplates <= 0) {
      fetchData()
    }
  }, [qtdLoadTemplates, headlineGptLocal])

  const fetchData = async () => {
    // setLoading(true)

    const loadbusiness = await loadBusinessData()

    if (!headlineGptLocal) {
      await getIaHeadline(loadbusiness)
    } else {
      await getIaImages(loadbusiness)
    }

    // await getIaHeadline(loadbusiness)
    // await getIaImages(loadbusiness)
    // setLoading(false)
  }

  // const loadProductServiceData = async () => {
  //   const { data, responseStatus } = await ProductService.getProduct()

  //   if (responseStatus === 200)
  //     setProductTypeOptions(
  //       data.map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       }))
  //     )
  // }

  // const loadtargetAudienceData = async () => {
  //   const { data, responseStatus } =
  //     await TargetAudienceService.getTargetAudiences()

  //   //
  //   if (responseStatus === 200)
  //     setProductTargetAudienceOptions(
  //       data?.map((item) => ({
  //         label: item.profession,
  //         value: item.uuid,
  //       }))
  //     )
  // }

  const loadBusinessData = async () => {
    let obj = {}

    const businessInfos = await BusinessService.getBusinessByCampaign(
      selectedCampaignOption.value
    )

    const brandingInfos = await BrandingService.getBrandingByBusinessId(
      businessInfos?.data[0]?.uuid
    )

    const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      businessInfos?.data[0]?.uuid
    )

    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudienceById(
        selectedCampaignOption?.targetAudienceId
      )

    const productInfos =
      selectedCampaignOption?.productId !== null
        ? await ProductService.getProductById(selectedCampaignOption?.productId)
        : null

    obj = {
      brandingInfos: brandingInfos,
      voiceToneInfos: voiceToneInfos,
      businessInfos: businessInfos,
      targetAudiencesInfos: targetAudiencesInfos,
      productInfos,
    }

    return obj
  }

  const numImage = 4
  const headlineListLocal = []
  const legendListLocal = []
  const textHeadline = []

  const getIaHeadline = async (loadbusiness) => {
    let businessData = loadbusiness

    let getEditorialLine
    let editorialLine

    if (clientData?.uuid) {
      getEditorialLine = await EditorialLineService.getEditorialLineByClient(
        clientData?.uuid
      )
      editorialLine = getEditorialLine.data[0].bodyJson
    } else {
      getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
      editorialLine = getEditorialLine.data[0].bodyJson
    }

    const bodyGpt = {
      flow: 'multiples_publications',
      prompt: 'headline',
      post_description: editorialLine.dados_da_marca.descricao_negocio,
      brand: editorialLine.dados_da_marca.nicho,
      funil: selectFunnel,
      editorial_line: {
        primary: {
          titulo: editorialLine.linha_conteudo_principal.titulo,
          descricao: editorialLine.linha_conteudo_principal.descricao,
        },
        secondary: [
          {
            titulo: editorialLine.linhas_conteudo_secundarias[1].titulo,
            descricao: editorialLine.linhas_conteudo_secundarias[1].descricao,
            exemplos_conteudo:
              editorialLine.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine.linhas_conteudo_secundarias[2].titulo,
            descricao: editorialLine.linhas_conteudo_secundarias[2].descricao,
            exemplos_conteudo:
              editorialLine.linhas_conteudo_secundarias[2].exemplos_conteudo,
          },
          {
            titulo: editorialLine.linhas_conteudo_secundarias[3].titulo,
            descricao: editorialLine.linhas_conteudo_secundarias[3].descricao,
            exemplos_conteudo:
              editorialLine.linhas_conteudo_secundarias[3].exemplos_conteudo,
          },
          {
            titulo: editorialLine.linhas_conteudo_secundarias[4].titulo,
            descricao: editorialLine.linhas_conteudo_secundarias[4].descricao,
            exemplos_conteudo:
              editorialLine.linhas_conteudo_secundarias[4].exemplos_conteudo,
          },
        ],
      },
      niche: editorialLine.dados_da_marca.nicho,
      subniche: editorialLine.dados_da_marca.subnicho,
      campaign_description: editorialLine.dados_da_marca.descricao_negocio,
      target_public: {
        name: editorialLine?.persona?.nome || '',
        gender: editorialLine?.persona?.genero || '',
        social_class: 'Classes A, Classe B',
        profession: editorialLine?.persona?.cargo || '',
        region: 'Nacional, com foco em grandes centros urbanos',
      },
      product: {
        name: businessData?.productInfos?.data?.name || '',
        type: businessData?.productInfos?.data?.productTypeValue || '',
        value: businessData?.productInfos?.data?.value || '',
        promotional_value:
          businessData?.productInfos?.data?.promotionalValue || '',
        description: businessData?.productInfos?.data?.description || '',
      },
      voice_tone: editorialLine.tom_de_voz,
      //  voice_tone_description : editorialLine.tipo_post
    }
    let retEditorialLine = await PythonService.postPromptGPT(bodyGpt)

    // setTimeout(() => {
    //   // setHeadlineGpt(retEditorialLine)
    //   setHeadlineGptLocal(retEditorialLine)
    // }, 2000); // 2 segundos de delay
    setHeadlineGpt(retEditorialLine)
    setHeadlineGptLocal(retEditorialLine)

    // const totalMainImages =
    //   imageGaleryArray?.length +
    //   imageArray?.length +
    //   imageAIArray?.length +
    //   imageUploadArray?.length +
    //   imageProductsArray?.length
    // const numHeadline = numImage * totalMainImages

    // console.log(
    //   'businessData.targetAudiencesInfos',
    //   businessData.targetAudiencesInfos
    // )

    // let bodyHeadline = {
    //   publications_number: numHeadline,
    //   niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
    //   subniche: businessData.businessInfos?.data[0].subNiche,
    //   publication_purpose: 'divulgar',
    //   description_business: businessData.businessInfos?.data[0].description,
    //   voice_tone: businessData.voiceToneInfos?.data[0].tone,

    //   social_class:
    //     businessData.targetAudiencesInfos?.data[0]?.socialClassValue,

    //   gender: businessData.targetAudiencesInfos?.data[0]?.genderValue,

    //   profession: businessData.targetAudiencesInfos?.data[0]?.profession,

    //   target_audience: businessData.targetAudiencesInfos?.data[0]?.name,

    //   name_product: businessData?.productInfos?.data
    //     ? businessData?.productInfos?.data?.name
    //     : 'Nenhum',

    //   product_type: businessData?.productInfos?.data
    //     ? businessData?.productInfos?.data?.productTypeValue
    //     : 'Nenhum',

    //   product_description: businessData?.productInfos?.data
    //     ? businessData?.productInfos?.data?.description
    //     : 'Nenhum',

    //   value: businessData?.productInfos?.data?.price
    //     ? businessData?.productInfos?.data?.price
    //     : 'Nenhum',

    //   promotional_value: businessData?.productInfos?.data
    //     ? businessData?.productInfos?.data?.promotionalValue
    //     : 'Nenhum',
    // }

    // const headline = await PythonService.postHeadline(bodyHeadline)

    // // await headline?.data?.data.forEach((element) => {
    // //   textHeadline.push(element)
    // //   headlineListLocal.push(element.headline)
    // //   legendListLocal.push(element.legenda)
    // // })

    // // await setHeadlineList(headlineListLocal)
    // // await setLegendList(legendListLocal)

    // // await setHeadlineText(headline?.data?.data[0]?.headline)

    // await headline?.data?.data.forEach((element) => {
    //   textHeadline.push(element)
    //   headlineListLocal.push(element.titulo)
    //   legendListLocal.push(element.subtitulo)
    // })

    // await setHeadlineList(headlineListLocal)
    // await setLegendList(legendListLocal)

    // await setHeadlineText(headline?.data.data[0].titulo)
  }

  const getIaImages = async (loadbusiness) => {
    let businessData = loadbusiness
    const imgBase64 = []
    const logoBase64 = []

    const findFont1 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontTitleBase64?.data ||
      ''
    const findFont2 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontSubtitleBase64
        ?.data || ''
    const findFont3 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontTextBase64?.data ||
      ''

    const campaignLogo = await LogosService.getLogosByCampaignBase64(
      selectedCampaignOption.value
    )

    if (campaignLogo?.length !== 0 && campaignLogo) {
      campaignLogo.data.forEach((logo) => {
        logoBase64.push({ base64: logo.fileBase64 })
      })
    }

    // const templates = [
    //   'ID14.json',
    //   'ID62.json',
    //   'ID43.json',
    //   'ID59.json',
    //   'ID33.json',
    //   'ID65.json',
    //   'ID45.json',
    // ]

    let bodyGenerateArt = {
      // publications_number: 6, //! Padrao
      // selected_templates: ['ID14.json'],
      // selected_templates: [templates[qtdLoadTemplates]],
      selected_templates: [
        headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates]?.id,
      ],
      publications_number: 1,
      template: false,
      art_width: 1080,
      art_height: 1080,
      color_palette_1: hexToRgb(
        businessData.brandingInfos?.data[0]?.primaryColor
      ),
      color_palette_2: hexToRgb(
        businessData.brandingInfos?.data[0]?.secondaryColor
      ),
      color_palette_3: hexToRgb(
        businessData.brandingInfos?.data[0]?.tertiaryColor
      ),
      color_palette_4: '(0,0,0)', //! Padrao
      color_palette_5: '(255,255,255)', //! Padrao
      text: headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates],
      //text: textHeadline,
      // categoria: [['titulo']],
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name || '',
      subniche: businessData.businessInfos?.data[0].subNiche || '',
      escolaridade:
        businessData.targetAudiencesInfos?.data[0]?.educationLevelValue || '',
      publication_purpose: 'divulgar', //! Padrao
      description_business:
        businessData.businessInfos?.data[0].description || '',
      voice_tone: businessData.voiceToneInfos?.data[0].tone || '',
      social_class:
        businessData.targetAudiencesInfos?.data[0]?.socialClassValue || '',
      gender: businessData.targetAudiencesInfos?.data[0]?.genderValue || '',
      profession: businessData.targetAudiencesInfos?.data[0]?.profession || '',
      name_product: businessData?.productInfos?.data?.name || '',
      product_type: businessData?.productInfos?.data?.productTypeValue || '',
      product_description: businessData?.productInfos?.data?.description || '',
      value: businessData?.productInfos?.data?.price || '',
      promotional_value:
        businessData?.productInfos?.data?.promotionalValue || '',
      produto: businessData?.productInfos?.data?.name || '',

      complexidade: -3,
      masculino: 5,
      extravagante: -5,
      moderno: 5,
      distante: 0,
      serio: 0,
      industrial: 0,
      elitizado: 0,
      sofisticado: 0,
      refinado: 0,

      macro_effects: false,

      num_imgs: [1],

      has_logo: logoBase64.length > 0 ? true : 0,

      fonte_1: findFont1 || 'montserrat',
      fonte_2: findFont2 || 'roboto',
      fonte_3: findFont3 || 'protestriot',

      // fonte_1: '' ,
      // fonte_2: '',
      // fonte_3: '',

      // background_img_base64: null,

      logo_base64: logoBase64,
    }

    let sequenceId = 1

    if (imageArray?.length !== 0 && imageArray) {
      imageArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.base64,
          imageCategory: image2.categoryId,
          id: sequenceId,
        })
        sequenceId++
      })
    }

    if (imageGaleryArray?.length !== 0 && imageGaleryArray) {
      imageGaleryArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.fileDataBase64.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
        })
        sequenceId++
      })
    }

    if (imageAIArray.length !== 0 && imageAIArray) {
      imageAIArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.image.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
        })
        sequenceId++
      })
    }

    if (imageUploadArray.length !== 0 && imageUploadArray) {
      imageUploadArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.image.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
        })
        sequenceId++
      })
    }

    if (imageProductsArray.length !== 0 && imageProductsArray) {
      imageProductsArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.fileDataBase64.split(',')[1],
          imageCategory: image2.categoryId,
          productId: image2.productId,
          productName: image2.productName,
          productDescription: image2.productDescription,
          productValue: image2.productValue,
          productPromotionValue: image2.productPromotionValue,
        })
      })
    }

    bodyGenerateArt = {
      ...bodyGenerateArt,
      img_base64: imgBase64,
    }

    const generateArtReturn = await PythonService.postGenerateArtTemplate(
      bodyGenerateArt
    )

    setIaData(
      iaData.map((item, index) => {
        if (index === qtdLoadTemplates) {
          return {
            ...generateArtReturn?.data?.data[0],
          }
        }
        return item
      })
    )
    setQtdLoadTemplates(qtdLoadTemplates + 1)

    // if (generateArtReturn?.data?.status === 200) {
    //   setIaData(generateArtReturn?.data?.data)
    // }

    // setLoading(false)
  }

  const handleOpenModal = (url, imageLayers, item) => {
    setImageUrl(url)
    setImageLayers(imageLayers)
    setObjectItem(item)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleRemoveImage = (imageArray, index) => {
    setSelectedArrayImage(
      selectedArrayImage.filter((arr) => arr !== imageArray)
    )

    setSelectedArrayImageObject(
      selectedArrayImageObject.filter((arr) => arr?.mergedImage !== imageArray)
    )

    // setSelectedObjectItem((selectedObjectItem) =>
    //   selectedObjectItem.filter((_, arrIndex) => arrIndex !== index)
    // )

    setSelectedObjectItemObject(selectedObjectItemObject)(
      (arr) => arr === index
    )
  }

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
  }

  const HeaderStepRender = () => {
    return (
      <>
        <div
          className={styles.title_new_posts}
          style={{
            marginTop: 0,
            marginBottom: 10,
          }}
        >
          <p className={styles.title_new_posts_label}>Selecione uma</p>
          <p className={styles.title_new_posts_title}>Sugestão</p>
        </div>

        <div
          style={{
            marginBottom: 20,
          }}
        >
          {selectedArrayImage.length !== 0 && (
            <div>
              <p style={{ margin: '0px', marginBottom: 10, fontSize: 20 }}>
                Publicações selecionadas
              </p>
              <div
                className={styles.content_list_selected_image}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                <div
                  className={styles.caroucel_list_selected_image}
                  ref={carouselRef}
                >
                  {selectedArrayImage?.map((imageArray, index) => (
                    <div className={styles.card_lis_selected_image} key={index}>
                      <div className={styles.container_card_lis_selected_image}>
                        <div
                          className={styles.delete_card_lis_selected_image}
                          onClick={() => handleRemoveImage(imageArray, index)}
                        >
                          <div style={{ cursor: 'pointer' }}>
                            <CircleX
                              color={'#FF2F00'}
                              width={'20px'}
                              height={'20px'}
                            />
                          </div>
                        </div>
                        <img
                          className={styles.item_selected_image}
                          src={imageArray}
                        />
                      </div>
                    </div>
                  ))}

                  <div
                    onClick={() => {
                      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
                    }}
                    style={{
                      backgroundColor: '#ddf1cf',
                      border: '1px solid #3CBC00',
                      padding: '12px 18px',

                      marginBottom: 6,
                      borderRadius: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // height: 30,
                        // width: 30,
                        borderRadius: 200,
                        // backgroundColor: '#a922f5',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CheckBadge color={'#3CBC00'} size={24} />

                      <div
                        style={{
                          color: '#3CBC00',
                          fontSize: 14,
                          marginTop: 8,
                        }}
                      >
                        Finalizar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  const ContentField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          paddingBottom: '0px',
          marginRight: '22px',
          // height: 'calc(100vh - 180px)',
          height: 'calc(100vh - 220px)',
          overflowY: 'auto',
        }}
      >
        <div className={styles.container_content}>
          <div className={styles.container_examples}>
            <div className={styles.container_examples_image}>
              {/* {iaData?.map((item, index) => (
                <div
                  className={styles.box_example_image}
                  key={index}
                  onClick={() => {
                    handleOpenModal(
                      'data:image/png;base64,' + item?.srcComTratativa,
                      item
                    )
                  }}
                >
                  <img
                    src={'data:image/png;base64,' + item?.srcComTratativa}
                    alt=""
                    className={styles.example_image}
                  />
                </div>
              ))} */}

              {iaData?.map((item, index) => (
                <>
                  {item?.srcComTratativa && (
                    <div
                      className={styles.box_example_image}
                      key={index}
                      onClick={() => {
                        handleOpenModal(
                          'data:image/png;base64,' + item?.srcComTratativa,
                          item,
                          item
                        )
                      }}
                    >
                      <img
                        src={'data:image/png;base64,' + item?.srcComTratativa}
                        alt=""
                        className={styles.example_image}
                      />
                    </div>
                  )}

                  {!item?.srcComTratativa && (
                    <div className={styles.box_example_image} key={index}>
                      <Lottie
                        animationData={lazyLoadingAnimation}
                        style={{ width: 100, height: 100 }}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  )}
                </>
              ))}

              <SimpleEditionModal
                isOpen={isModalOpen}
                setNextStep={(newImageUrl, tempImageLayers) => {
                  setSelectedArrayImage((current) => [...current, newImageUrl])
                  setSelectedArrayImageObject((current) => [
                    ...current,
                    tempImageLayers,
                  ])
                }}
                // setSelectedObjectItem={setSelectedObjectItem}
                setSelectedObjectItemObject={setSelectedObjectItemObject}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                imageLayers={imageLayers}
                setImageLayers={setImageLayers}
                onClose={handleCloseModal}
                title={headlineText}
                subtitle={headlineSubtitle}
                publicTargetOptions={[]}
                productOptions={[]}
                onChangeColorPalleteSubtittle={(e) =>
                  setHeadlineSubtitle(e.target.value)
                }
                onChangeColorPalleteTittle={(e) =>
                  setHeadlineText(e.target.value)
                }
                objectItem={objectItem}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const FooterStepRender = () => {
    return (
      <div
        style={{
          marginRight: '22px',

          background: '#fff',
          paddingTop: '10px',
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setSelectedObjectItem([])
              setSelectedArrayImage([])
              setSelectedArrayImageObject([])
              setStep('stepImage')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* <Loading enable={loading} /> */}
      <div className={styles.container_header}>
        {HeaderStepRender()}
        {ContentField()}
        {FooterStepRender()}
      </div>
    </>
  )
}

export default StepThirdPass

StepThirdPass.propTypes = {
  setStep: PropTypes.func,
  imageArray: PropTypes.array,
  setIaData: PropTypes.func,
  iaData: PropTypes.object,
}
