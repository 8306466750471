import { useLocation, useNavigate } from 'react-router-dom'
import styles from './styles.module.css'

import posteiLogoColor from '../../assets/logos/postei-logo-color.png'
import posteiLogoTextColor from '../../assets/logos/postei-logo-text-color.png'
import { useEffect, useState } from 'react'
import { LinkedinService, TwitterService } from '../../services'
import { useRedirect } from '../../hooks/useRedirectPAge'
import { useClient } from '../../hooks/useClient'

import { postMetaByBusiness } from '../../services/metaService'

function SocialMediaRedirect() {
  const { redirectUrl, idParam, setRedirect } = useRedirect()

  const navigation = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryBusinessId = queryParams.get('businessId')
  const queryCode = queryParams.get('code')
  const queryMedia = queryParams.get('media')
  const queryOauthToken = queryParams.get('oauth_token')
  const queryOauthVerifier = queryParams.get('oauth_verifier')
  const { setClientData } = useClient()

  const [instagramLogin, setInstagramLogin] = useState(undefined)

  const executeMetaLogin = async () => {
    const verifyReturnState = queryParams.get('state')
    const redirectUri = window.location.origin + '/SocialMediaRedirect'

    const body = {
      businessId: verifyReturnState.split(',')[2],
      code: queryCode,
      redirectUri: redirectUri,
    }

    const metaByBusinessResponse = await postMetaByBusiness(body)

    if (metaByBusinessResponse?.dataL?.conected) {
      setInstagramLogin('conected')

      const timeoutId = setTimeout(() => {
        const navigationSelected = verifyReturnState.split(',')[1]
        console.log('navigationSelected', navigationSelected)

        if (navigationSelected === 'MyAccountPage') {
          navigation('/MyAccountPage', {
            state: {
              redirect: true,
            },
          })
        } else {
          navigation('/StepsPage', {
            state: {
              queryBusinessId: queryBusinessId,
              queryCode: queryCode,
              redirect: true,
            },
          })
        }
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    } else {
      setInstagramLogin('error')

      const timeoutId = setTimeout(() => {
        navigation('/StepsPage', {
          state: {
            queryBusinessId: queryBusinessId,
            queryCode: queryCode,
          },
        })
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    }
  }

  useEffect(() => {
    //!Verifica se é um retorno da META, Ex: Instagram, Facebook, Google

    const verifyReturnState = queryParams.get('state')

    console.log('queryCode ++++===> ', queryCode)

    if (verifyReturnState?.includes('instagram')) {
      setInstagramLogin('generating_short_token')
      executeMetaLogin()

      return
    }

    switch (queryMedia) {
      case '1':
        linkedinCallback()
        break
      case '2':
        twitterV1Callback()
        break
      case '2a':
        twitterCallback()
        break

      default:
        const timeoutId = setTimeout(() => {
          navigation('/StepsPage', {
            state: {
              // queryBusinessId: queryBusinessId,
              queryCode: queryCode,
            },
          })
        }, 5000)

        break
    }
  }, [])

  const setClient = (idParam) => {
    setClientData(idParam)

    setRedirect(null, null)
  }

  const linkedinCallback = async () => {
    const { responseStatus } = await LinkedinService.getLinkedinCallback(
      queryBusinessId,
      queryCode
    )

    if (responseStatus === 200 || responseStatus === 204) {
      const timeoutId = setTimeout(() => {
        navigation('/StepsPage', {
          state: {
            queryBusinessId: queryBusinessId,
            queryCode: queryCode,
          },
        })
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    }
  }

  const twitterV1Callback = async () => {
    const { responseStatus } = await TwitterService.getTwitterV1Callback(
      queryBusinessId,
      queryOauthToken,
      queryOauthVerifier
    )

    if (responseStatus === 200 || responseStatus === 204) {
      setClient(idParam)

      const timeoutId = setTimeout(() => {
        navigation(redirectUrl, {
          state: {
            redirect: true,
            queryBusinessId: queryBusinessId,
            queryCode: queryCode,
          },
        })
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    }
  }

  const twitterCallback = async () => {
    const { responseStatus } = await TwitterService.getTwitterCallback(
      queryBusinessId,
      queryCode
    )

    if (responseStatus === 200 || responseStatus === 204) {
      const timeoutId = setTimeout(() => {
        navigation('/StepsPage', {
          state: {
            queryBusinessId: queryBusinessId,
            queryCode: queryCode,
          },
        })
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        {/* <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
        <img src={posteiLogoText} alt="posteiLogoText" style={{ height: 34 }} /> */}
        <img src={posteiLogoColor} alt="posteiLogo" style={{ height: 34 }} />
        <img
          src={posteiLogoTextColor}
          alt="posteiLogoText"
          style={{ height: 34 }}
        />
      </div>

      <div className={styles.divider} />
      <div className={styles.container}>
        {!instagramLogin && <h1>Login efetuado com sucesso!</h1>}
        {instagramLogin === 'generating_short_token' && (
          <h1>Efetuando Login no Instagram</h1>
        )}
        {instagramLogin === 'conected' && (
          <h1>Instagram conectado com sucesso!</h1>
        )}
        {instagramLogin === 'error' && (
          <h1>Erro ao conectar com o Instagram!</h1>
        )}
      </div>
    </div>
  )
}

export default SocialMediaRedirect
