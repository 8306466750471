import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styles from './styles.module.css'

export const ImageFormatContainer = ({image, imageFormat, prevImageFormat}) => {

  return (
    <div className={styles.imageContainer}>
      <motion.img
        transition={{duration:0.5, ease:'easeInOut'}}
        animate={{...imageFormat}}
        initial={{...prevImageFormat}}
        src={image}
        alt="modal image" />
    </div>
  )
}

ImageFormatContainer.propTypes = {
  image:PropTypes.string,
  formatOptions:PropTypes.array,
  imageFormat:PropTypes.object,
  prevImageFormat:PropTypes.object,
}
