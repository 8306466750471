
import { CircleLike, Comment, HeartLike, Like, PlaneShare, Share } from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'

export const LayoutInstagramStories = ({
  image,
  userData
}) => {
  return (
    <div className={styles.box_image_render}>
      <div className={styles.body_image_render}>
        <div className={styles.box_body_image_render}>
          <div>
            <img
              src={image}
              className={styles.image_render}
            />

            
          </div>

          <div className={styles.teste}>
            <div className={styles.header_example}>
              <div className={styles.progress_bar_example}></div>
              <div className={styles.box_company_info}>
                <div className={styles.box_logo_company}>
                  <img className={styles.image_company}  src={userData?.photoUrl} alt="" />
                </div>
                <div className={styles.box_name_company}> 
                  <p className={styles.name_company}>
                    Minha conta
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.box_input_example}>
              <div className={styles.input_example}>
                <p className={styles.input_example_text}>Responder  a Minha conta </p>
              </div>
              <div className={styles.input_example_icons}>
                <HeartLike color={"#FFFFFF"}/>
                <PlaneShare color={"#FFFFFF"}/>
              </div>
            </div>
          </div>
          
        </div>
        
      </div>   
    </div>
  )
}

LayoutInstagramStories.propTypes = {
  image: object
}
