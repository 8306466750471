import React, { useEffect, useState } from 'react'
import { Accept, ArrowDown, TextBubble } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import {
  PlanService,
  SubscriptionService,
  PublicationService,
  BusinessClient,
  BusinessUser,
  SocialMediaConnectionService,
  BusinessService,
  PagarMeService,
} from '../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'
import { Empty } from 'antd'
import { useNavigate } from 'react-router-dom'
import AddCard from '../../components/AddCard'
import ModalComponent from '../../components/ModalComponent'
import { Loading } from '../../components/Loading'

const PlanCard = ({
  setPlanPay,
  firstLogin,
  userCard,
  userAcount,
  uuid,
  title,
  previusPrice,
  price,
  description,
  featureTitle,
  featureDescription,
  featureItem,
  premium,
  tier,
}) => {
  const [expandedItem, setExpandedItem] = useState(null)
  const [showCard, setShowCard] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tierPlan, setTierPlan] = useState(null)
  const [tierCard, setTierCard] = useState(null)

  useEffect(() => {
    if (!modalIsOpen && tierCard > 0) {
      selectPlan(tierPlan)
    }
  }, [modalIsOpen])

  const closeModal = () => {
    setModalIsOpen(false)
    setTierCard(0)
  }

  const handleToggleFeature = (itemId) => {
    setExpandedItem(itemId === expandedItem ? null : itemId)
  }

  const selectPlan = async (tier) => {
    if (!tier || modalIsOpen) {
      return
    }

    const userCardActive = await PagarMeService.getCardByUser()

    if (userCardActive.data.length <= 0) {
      setModalIsOpen(true)
      setTierCard(tier)
      //setShowCard(true)
      return
    }

    setLoading(true)
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (userSubscription.data.length > 0) {
      const currentTier = userSubscription.data[0].Plan.tier
      if (tier == currentTier) {
        notifyWarningCustom('Você não pode escolher alterar para o mesmo plano')
        setLoading(false)
        return
      }

      if (tier < currentTier) {
        if (
          window.confirm(
            'Ao selecionar um plano de nível menor que o atual, suas configurações atuais serão resetadas, isso inclui Clientes, Usuários, Publicações e acessos a redes sociais. Deseja continuar?'
          )
        ) {
          const publicationsUser =
            await PublicationService.deletePublicationByUser()

          //await PublicationService.deletePublication(id)

          const clientsUser = await BusinessClient.deleteBusinessClientByUser()
          //await BusinessClient.deleteBusinessClient(id)
          const businessByclientsUser =
            await BusinessService.deleteBusinessByClientByUser()

          const businessUsersUser = await BusinessUser.deleteBusinessByUser()
          //await BusinessUser.deleteBusinessUser(id)

          const socialUser =
            await SocialMediaConnectionService.deleteSocialUser()
        } else {
          setLoading(false)
          return
        }
      }
      setLoading(false)
    }

    const body = {
      planId: uuid,
      accountId: userAcount?.data.uuid,
      cardId: userCardActive.data[0].uuid,
      paymentMethod: 'credit_card',
    }
    const data = await SubscriptionService.getSubscriptionByUser()

    if (data.data.length > 0) {
      const retPost = await SubscriptionService.putSubscription(
        data?.data[0]?.uuid,
        body
      )
      if (retPost.responseStatus == 204) {
        notifySuccessCustom('Plano alterado com sucesso!')
        navigate('/Settings')
        return
      }
    } else {
      const retPost = await SubscriptionService.postSubscription(body)
      if (retPost.responseStatus == 200) {
        notifySuccessCustom('Plano criado com sucesso!')
        if (firstLogin) {
          setLoading(false)
          setPlanPay(true)
        } else {
          navigate('/Settings')
          setLoading(false)
        }

        return
      }
    }
  }
  const navigate = useNavigate()

  return (
    <div className={premium ? styles.container_premium : styles.container}>
      <Loading enable={loading} />

      <ModalComponent
        isOpen={modalIsOpen}
        onClose={() => closeModal}
        className="Modal"
        overlayClassName="Overlay"
        style={{
          flex: 1,
          margin: '0 10px',
          maxWidth: 500,
          // background: 'red',
        }}
      >
        <div>
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Cancelar
          </button>
        </div>
        <div>
          <AddCard
            setModalIsOpen={setModalIsOpen}
            selectPlan={selectPlan}
            tierCard={tierCard}
            setTierPlan={setTierPlan}
          />
        </div>
        {/* <button onClick={closeModal}>Close Modal</button> */}
      </ModalComponent>

      <div className={styles.header_card}>
        <div className={styles.header_card_tytle}>
          <h3>{title}</h3>{' '}
          {premium && (
            <div className={styles.best_plan}>
              {' '}
              <p>Melhor Plano</p>
            </div>
          )}
        </div>
        <div className={styles.header_card_price}>
          <h2>{price}</h2>
          <p>/mes</p>
        </div>
        <hr></hr>
        <div className={styles.header_card_description}>
          <p>{description}</p>
        </div>
      </div>

      <div className={styles.body_card}>
        <div className={styles.body_card_features_title}>
          <h2>{featureTitle}</h2>
          <p>{featureDescription}</p>
        </div>
        <div className={styles.body_card_features_item}>
          {featureItem.map((feature) => (
            <div className={styles.container_features_item} key={feature.uuid}>
              <div className={styles.content_features_item}>
                <div className={styles.features_item}>
                  <Accept color={'#00B828'} />
                  <p>{feature.displayName}</p>
                </div>
                <div
                  className={
                    expandedItem === feature.uuid
                      ? styles.features_arrow_up
                      : styles.features_arrow_down
                  }
                  onClick={() => handleToggleFeature(feature.uuid)}
                >
                  <ArrowDown />
                </div>
              </div>
              <div
                className={styles.features_togle}
                style={{
                  display: expandedItem === feature.uuid ? 'flex' : 'none',
                }}
              >
                <p>{feature.quantity}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footer_card}>
        <button
          className={styles.button_select}
          onClick={() => selectPlan(tier)}
        >
          Selecionar <Accept color={'#00B828'} />
        </button>
        <button className={styles.button_support}>
          Suporte <TextBubble color={premium ? '#190027' : '#E6E6E6'} />
        </button>
      </div>
    </div>
  )
}

export default PlanCard
