import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { ArrowDownCloud, CloseIcon, CloundArrowUp, CloundUp, Folder } from '../../assets/svgs/icons'
import { SideBar } from './SideBar'
import { ColorPalletStep } from './steps/ColorPaleteStep'
import { useState } from 'react'
import { ImageStep } from './steps/ImageStep'
import { CampaingStep } from './steps/CampaingStep'
import { EffectsStep } from './steps/EffectsStep'
import { ImageFormatContainer } from './ImageFormatContainer'
import { Select } from '../Select'
import { motion } from 'framer-motion'
import { Button } from '../Button'

export const ExportFileModal = ({
  isOpen,
  onClose,
  title = 'Quer vender seu carro?',
  subtitle = 'Nós compramos',
  imageUrl = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7us_m6dOANxDN3Notizh7Zk78tDVIrIH8IN_M36KWtUhySwG5j3JkaWo1xD61Z6caEBk&usqp=CAU',
  onClickDone,
}) => {

  const handleClickSideBar = (e) => {
    setStep(e)
    setPrevImageFormat(imageFormat)
  }

  const handleClickDeleteAditionalEffect = (e) => {
    const aux = aditionalEffectsArray
    aux.splice(e, 1)
    setAditionalEffectsArray([...aux])
  }

  const handleChangeSelectImage = (e) => {

  }

  const handleClickDone = () => {
    // TODO corrigir objeto com formato esperado

  }

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleChangeOptions = (selectedItem, label) => {
  
  }

  return !isOpen ? null : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.modal_overlay}
      onClick={handleClose}
    >
      <div className={styles.modal_content}>
        <div className={styles.model_header_container}>
            <span>Protótipo</span>
            <div className={styles.header_button_container}>
              <h3>Exporte ou salve como asset</h3>
              <button>
                <CloseIcon color='#000' size={22}/>
              </button>
            </div>
        </div>

        <div className={styles.modal_body}>
          <div className={styles.modal_body_container_select}>
              <Folder color={"#E6E6E6"}/>
              <div>
                <p>Asset</p>
                <Button variant={"primary"}>Selecionar</Button>
              </div>
          </div>
          <div className={styles.modal_body_container_select}>
              <ArrowDownCloud size={20} color="#E6E6E6"/>
              <div className={styles.modal_body_text}>
                <p>Download</p>
                <Button variant={"primary"}>Selecionar</Button>
              </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

ExportFileModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  onClickDone: PropTypes.func,
}

export default ExportFileModal
