import { useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'
import ColorPaletteComponent from './ColorPaletteComponent'
import { notifyErrorCustom } from '../../../utils/notify'

const STEPS = {
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  STEP_PASS_TYPOGRAPHY: 'stepTypography',
}
function StepColorPalette({ setStep, colors, setColors }) {
  const [first, setfirst] = useState('')

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // if (colors.length < 3) {
      //   notifyErrorCustom('Escolha no minimo 3 cores')
      //   return
      // }

      // const hasEmptyField = colors.some((item) => !item)

      // if (hasEmptyField) {
      //   notifyErrorCustom('Um campo ainda está em vazio')
      //   return
      // }

      setStep(STEPS.STEP_PASS_TYPOGRAPHY)
    } else {
      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    }
  }

  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Cores</p>
        <h1 style={{ marginTop: '0px' }}>Paleta de Cores</h1>
        <ColorPaletteComponent colors={colors} setColors={setColors} />
        Teste
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepColorPalette
