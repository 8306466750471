import { useState } from 'react'
import styles from './styles.module.css'
import Slider from 'rc-slider'

const STEPS = {
  STEP_PASS_LOGO: 'stepLogo',
  //STEP_PASS_BPS: 'stepBPS',
}

function StepBPS({ setStep }) {
  const [brandingArchetypeData, setBrandingArchetypeData] = useState({})

  const handleChange = (k, v) => {
    setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  }

  const SliderComponent = ({ value, onChange }) => {
    return (
      <div>
        <Slider
          value={value}
          onChange={onChange}
          min={-5}
          max={5}
          trackStyle={{ backgroundColor: '#28003F', height: 6 }}
          railStyle={{ backgroundColor: '#28003F', height: 6 }}
          handleStyle={{
            width: 15,
            height: 15,
            backgroundColor: '#B900FE',
            border: '2.5px solid #FFFF',
            boxShadow: 'none',
          }}
        />
      </div>
    )
  }

  const SliderValueComponent = ({
    leftText,
    rightText,
    sliderValue,
    setSliderValue,
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 20, width: 180 }}>
          <p
            style={{
              margin: 0,
              fontSize: 10,
              textAlign: 'end',
              color: '#FFFFFF',
            }}
          >
            {leftText}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
          }}
        >
          {SliderComponent({
            value: sliderValue,
            onChange: setSliderValue,
          })}
        </div>

        <div style={{ marginLeft: 20, width: 180 }}>
          <p style={{ margin: 0, fontSize: 10, color: '#FFFFFF' }}>
            {rightText}
          </p>
        </div>
      </div>
    )
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // setStep(STEPS.STEP_PASS_BPS)
    } else {
      setStep(STEPS.STEP_PASS_LOGO)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Estamos quase lá</p>
        <h1 style={{ marginTop: '0px' }}>
          Deslize para selecionar as características da sua marca
        </h1>

        <div className={styles.box_input_bps}>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 330,
                gap: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <p className={styles.number_parameter}>5</p>
                <p className={styles.number_parameter}>4</p>
                <p className={styles.number_parameter}>5</p>
                <p className={styles.number_parameter}>2</p>
                <p className={styles.number_parameter}>1</p>

                <p className={styles.number_parameter}>0</p>

                <p className={styles.number_parameter}>1</p>
                <p className={styles.number_parameter}>2</p>
                <p className={styles.number_parameter}>3</p>
                <p className={styles.number_parameter}>4</p>
                <p className={styles.number_parameter}>5</p>
              </div>

              {SliderValueComponent({
                leftText: 'Feminino',
                rightText: 'Masculino',
                sliderValue: brandingArchetypeData?.bpsFemaleOrMale || 0,

                setSliderValue: (value) =>
                  handleChange('bpsFemaleOrMale', value),
              })}

              {SliderValueComponent({
                leftText: 'Simples',
                rightText: 'Complexo',
                sliderValue: brandingArchetypeData?.bpsSimpleOrComplex || 0,

                setSliderValue: (value) =>
                  handleChange('bpsSimpleOrComplex', value),
              })}

              {SliderValueComponent({
                leftText: 'Sutil',
                rightText: 'Extravagante',
                sliderValue: brandingArchetypeData?.bpsSubtleOrExtravagant || 0,
                setSliderValue: (value) =>
                  handleChange('bpsSubtleOrExtravagant', value),
              })}

              {SliderValueComponent({
                leftText: 'Clássico',
                rightText: 'Moderno',
                sliderValue: brandingArchetypeData?.bpsClassicOrModern || 0,
                setSliderValue: (value) =>
                  handleChange('bpsClassicOrModern', value),
              })}

              {SliderValueComponent({
                leftText: 'Amigável',
                rightText: 'Distante',
                sliderValue: brandingArchetypeData?.bpsFriendlyOrDistant || 0,
                setSliderValue: (value) =>
                  handleChange('bpsFriendlyOrDistant', value),
              })}

              {SliderValueComponent({
                leftText: 'Brincalhão',
                rightText: 'Sério',
                sliderValue: brandingArchetypeData?.bpsPlayfulOrSerious || 0,
                setSliderValue: (value) =>
                  handleChange('bpsPlayfulOrSerious', value),
              })}

              {SliderValueComponent({
                leftText: 'Natural',
                rightText: 'Industrial',
                sliderValue: brandingArchetypeData?.bpsNaturalOrIndustrial || 0,
                setSliderValue: (value) =>
                  handleChange('bpsNaturalOrIndustrial', value),
              })}

              {SliderValueComponent({
                leftText: 'Popular',
                rightText: 'Elitizado',
                sliderValue: brandingArchetypeData?.bpsPopularOrElite || 0,
                setSliderValue: (value) =>
                  handleChange('bpsPopularOrElite', value),
              })}

              {SliderValueComponent({
                leftText: 'Despojado',
                rightText: 'Sofisticado',
                sliderValue:
                  brandingArchetypeData?.bpsStrippedOrSophisticated || 0,
                setSliderValue: (value) =>
                  handleChange('bpsStrippedOrSophisticated', value),
              })}

              {SliderValueComponent({
                leftText: 'Rústico',
                rightText: 'Refinado',
                sliderValue: brandingArchetypeData?.bpsRusticOrRefined || 0,
                setSliderValue: (value) =>
                  handleChange('bpsRusticOrRefined', value),
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepBPS
