// import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'

import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './hooks/useAuth'
import { ClientProvider } from './hooks/useClient'
import { RedirectProvider } from './hooks/useRedirectPAge.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <ClientProvider>
        <RedirectProvider>
          <App />
        </RedirectProvider>
      </ClientProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>,
)
