/* eslint-disable react/prop-types */

import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import React, { useEffect, useState } from 'react'
import { TimelineActionsService } from '../../services/'

function TimeLinePage() {
  const navigate = useNavigate()
  const [timelineActionData, setTimelineActionData] = useState([])

  const navigationPage = () => {
    navigate('/Settings')
  }
  useEffect(() => {
    loadTimeline()
  }, [])

  const loadTimeline = async () => {
    try {
      const { data, responseStatus } =
        await TimelineActionsService.getTimelineActionAllDataList()

      setTimelineActionData(
        data?.map((item) => ({
          userName: item.UserAccount.user.name,
          clientName: item.BusinessClient?.companyName,
          timelineAction: item.timelineActionsValue,
          date: formatDate(item.createdAt),
          guestName: item.UserAccountBusinessUsers ? item.UserAccountBusinessUsers.name : null,
        }))
      )
    } catch (error) {
      console.error('Erro ao carregar timeline', error)
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = addLeadingZero(date.getDate())
    const month = addLeadingZero(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = addLeadingZero(date.getHours())
    const minutes = addLeadingZero(date.getMinutes())
    const secconds = addLeadingZero(date.getSeconds())
    return `${day}/${month}/${year} ${hours}:${minutes}:${secconds}`
  }
  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value
  }
  
  const renderTimeline = () => {
    return timelineActionData.map((item, index) => (
      item.guestName ? (
        <div key={index} className={styles.data}>
          <div className={styles.box_data_label}>
            <div className={styles.circle} />
            <p className={`${styles.data_label}`}>
              O usuário convidado <span className={styles.boldText}>{item.guestName} </span>
              {item.timelineAction} {item.clientName ? 'para o cliente ' : ' '}
              <span className={styles.boldText}>{item.clientName}</span>
            </p>
          </div>
          <p className={styles.data_content}>{item.date}</p>
        </div>
      ) : (
        <div key={index} className={styles.data}>
          <div className={styles.box_data_label}>
            <div className={styles.circle} />
            <p className={`${styles.data_label}`}>
              O usuário <span className={styles.boldText}>{item.userName} </span>
              {item.timelineAction} {item.clientName ? 'para o cliente ' : ' '}
              <span className={styles.boldText}>{item.clientName}</span>
            </p>
          </div>
          <p className={styles.data_content}>{item.date}</p>
        </div>
      )
    ))
  }

  return (
    <div className={styles.page}>
      <div className={styles.title_my_data}>
        <div>
          <p className={styles.title_my_data_label}>Configurações </p>
          <p className={styles.title_my_data_title}>Timeline</p>
        </div>

        <button className={styles.button_back} onClick={() => navigationPage()}>
          Voltar
        </button>
      </div>

      <div className={styles.container_system_Settings}>
        <div className={styles.box_company_card}>
          <div className={styles.box_title_card}>
            <p className={styles.title_card}>Timeline</p>
          </div>
          {renderTimeline()}
          {}
        </div>
      </div>
    </div>
  )
}

export default TimeLinePage
