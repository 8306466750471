import { Checkbox } from 'antd'
import { ListTable } from '../../../components/ListTable'
import { Row } from '../../../components/ListTable/Row'
import { TableItem } from '../../../components/ListTable/TableItem'
import styles from './styles.module.css'
import { Switch } from 'antd'
import { Eye, Gear, Trash } from '../../../assets/svgs/icons'
import { motion } from 'framer-motion'
import { func, array } from 'prop-types'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { ProductService } from '../../../services'
import { useState } from 'react'
import Loading from '../../../components/Loading'

export const ProductsList = ({
  products,
  headers,
  onClickVisualize,
  onClickEdit,
  onClickDelete,
}) => {
  const [loading, setLoading] = useState(false)

  const handleChecked = async (data) => {
    //return
    let uuid = data?.uuid
    let newData = data

    delete newData.uuid
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.deletedAt

    delete newData.userAccountId
    delete newData.educationLevelValue
    delete newData.socialClassValue
    delete newData.genderValue
    delete newData.productTypeValue
    delete newData.businessClientId
    newData.enabled = !newData.enabled
    if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
      newData.guestId = JSON.parse(window.localStorage.getItem('userData')).guestUserId
    } else {
      delete newData.guestId
    }

    setLoading(true)
    
    const { responseStatus } = await ProductService.putProduct(uuid, {
      ...newData,
    })

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      //loadBusinessUser()
      notifySuccessCustom('Status alterado com sucesso!')
    } else {
      notifyErrorCustom('Não foi possível alterar o status!')
    }
  }
  return (
    <>
      <Loading enable={loading} />

      <motion.div
        initial={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <ListTable headers={headers}>
          {products?.map((product) => (
            <Row key={product?.uuid}>
              <TableItem>
                <div className={styles.box_name_target_audience}>
                  <div>
                    <p className={styles.name_target_audience_text}>
                      {product?.name}
                    </p>
                  </div>
                </div>
              </TableItem>
              <TableItem>{product?.productTypeValue}</TableItem>
              <TableItem>
                <span className={styles.bold}>{product?.value}</span>
              </TableItem>
              <TableItem>
                <span className={styles.bold}>{product?.promotionalValue}</span>
              </TableItem>
              <TableItem style={{ maxWidth: 170 }}>
                <div className={styles.last_column}>
                  <div className={styles.icons_container}>
                    <div className={styles.icon}>
                      <div className={styles.box_action_icon}>
                        <Switch
                          size="small"
                          style={{
                            backgroundColor: product?.enabled ? 'black' : '',
                          }}
                          checked={product?.enabled}
                          onClick={() => handleChecked(product)}
                        />
                      </div>

                      <div
                        className={styles.box_action_icon}
                        onClick={() => onClickEdit(product)}
                      >
                        <Gear color={'#000'} />
                      </div>

                      <div
                        className={styles.box_action_icon}
                        onClick={() => onClickVisualize(product)}
                      >
                        <Eye color={'#000'} />
                      </div>

                      <div
                        onClick={() => onClickDelete(product?.uuid)}
                        className={styles.box_action_icon}
                      >
                        <Trash color={'#000'} />
                      </div>
                    </div>
                  </div>
                </div>
              </TableItem>
            </Row>
          ))}
        </ListTable>
      </motion.div>
    </>
  )
}

export default ProductsList

ProductsList.propTypes = {
  onClickVisualize: func,
  onClickEdit: func,
  onClickDelete: func,
  products: array,
  headers: array,
}
