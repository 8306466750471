import styles from './styles.module.css'
import { Checkbox } from 'antd'
import { string, func, node, array } from 'prop-types'

export const ListTable = ({
  title,
  label,
  headers,
  children,
  buttonText,
  onClickButton,
  checkbox,
  disabledButton,
}) => {
  return (
    <div className={styles.container}>
      {label && title && (
        <>
          <div className={styles.header_page}>
            <div className={styles.title_new_posts}>
              <p className={styles.title_new_posts_label}>{label}</p>
              <p className={styles.title_new_posts_title}>{title}</p>
            </div>
            {!disabledButton && (
              <div>
                <button
                  className={styles.button_new_user}
                  onClick={onClickButton}
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div className={styles.table}>
        <div className={styles.header}>
          {headers.map((text, index) => {
            if (index == 0) {
              return (
                <div key={index} className={styles.header_item}>
                  <div className={styles.table_item_gap}>
                    {checkbox && <Checkbox />}
                    <p>{text}</p>
                  </div>
                </div>
              )
            } else if (index === headers.length - 1) {
              return (
                <div
                  key={index}
                  className={`${styles.header_item} ${styles.last_column}`}
                >
                  {text}
                </div>
              )
            } else {
              return (
                <div key={index} className={styles.header_item}>
                  {text}
                </div>
              )
            }
          })}
        </div>
        <div className={styles.body}>
          {/* row */}
          {children}
        </div>
      </div>
    </div>
  )
}

ListTable.propTypes = {
  headers: array,
  title: string,
  label: string,
  children: node,
  buttonText: string,
  onClickButton: func,
}
