/* eslint-disable indent */
import { useEffect, useState } from 'react'
import styles from './styles.module.css'

import {
  KnowLedgeChannelService,
  UserService,
  UserAccountsService,
  BusinessService,
  StatesService,
  Login,
  NotificationService,
  PlanService,
  PagarMeService,
  SubscriptionService,
} from '../../services'

import { useNavigate } from 'react-router-dom'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { BoxChecket, BoxNotChecket } from '../../assets/svgs/icons'

import InputMask from 'react-input-mask'

import Loading from '../../components/Loading'

import { useAuth } from '../../hooks/useAuth'

import { notifyErrorCustom } from '../../utils/notify'

function IndividualUser() {
  const { login } = useAuth()
  const navigation = useNavigate()

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastCpf, setLastCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [knowledgeChannelData, setKnowledgeChannelData] = useState([])
  const [knowledgeChannelOption, setKnowledgeChannelOption] = useState('')

  const [formCompany, setFormCompany] = useState(false)

  const [cnpj, setCnpj] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [corporateName, setCorporateName] = useState('')
  const [cep, setCep] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')

  const [stateData, setStateData] = useState([])
  const [stateOption, setStateOption] = useState('')

  const [city, setCity] = useState('')

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [])

  const loadData = async () => {
    let knowLedgeChannelReturn =
      await KnowLedgeChannelService.getKnowledgeChannels()

    let fistItemList = 'Canal de conhecimento'

    let newOrderknowLedgeChannel = knowLedgeChannelReturn.data.sort((a, b) => {
      if (a.description === fistItemList) return -1
      if (b.description === fistItemList) return 1
      return 0
    })

    let statesReturn = await StatesService.getStates()

    setKnowledgeChannelData(newOrderknowLedgeChannel)
    setKnowledgeChannelOption(newOrderknowLedgeChannel[0].id)

    setStateData(statesReturn.data)

    setLoading(false)
  }

  const handleChangeKnowledgeChannel = (event) => {
    setKnowledgeChannelOption(event.target.value)
  }

  const handleChangeState = (event) => {
    setStateOption(event.target.value)
  }

  const hadleRegisterUser = async () => {
    event.preventDefault()

    setLoading(true)
    await registerUser()
  }

  function onlyNumbers(input) {
    return input.replace(/[^0-9]/g, '')
  }

  const handleLogin = async () => {
    let body = {
      email: email,
      password: password,
    }

    let loginData = await Login.postAuth(body)

    if (loginData.responseStatus == 200) {
      setLoading(false)
      login(loginData)
    }

    setLoading(false)
  }

  function isEmailValid(email) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return regex.test(email)
  }

  async function isValidCPForCNPJ(value) {
    const numericValue = value.replace(/[^\d]+/g, '')

    if (value.length === 11) {
      // Verifica se é um CPF
      const invalidCpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ]

      if (invalidCpfs.includes(value)) {
        return false
      }

      let sum = 0
      let mod = 0

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(value.substring(i - 1, i), 10) * (11 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(value.substring(9, 10), 10)) {
        return false
      }

      sum = 0

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(value.substring(i - 1, i), 10) * (12 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(value.substring(10, 11), 10)) {
        return false
      }

      return true // É um CPF válido
    } else if (numericValue.length === 14) {
      // Verifica se é um CNPJ
      const invalidCpns = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999',
      ]

      if (invalidCpns.includes(numericValue)) {
        return false
      }

      let size = numericValue.length - 2
      let numbers = numericValue.substring(0, size)
      const digits = numericValue.substring(size)
      let sum = 0
      let pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(0), 10)) {
        return false
      }

      size = size + 1
      numbers = numericValue.substring(0, size)
      sum = 0
      pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(1), 10)) {
        return false
      }

      return true // É um CNPJ válido
    }

    return false // Não é nem CPF nem CNPJ
  }

  useEffect(() => {
    const cepModified = cep.replace(/\D/g, '')
    if (cepModified.length === 8) getAddress(cepModified)
  }, [cep])

  const getAddress = async (cep) => {
    try {
      const url = `https://viacep.com.br/ws/${cep}/json/`
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'content-type': 'application/json;charset=utf-8',
        },
      }
      const result = await fetch(url, options)
      const endereco = await result.json()

      if (!endereco.erro) {
        const end = `${endereco.logradouro}, ${endereco.bairro}`
        setLogradouro(end)
        setCity(endereco.localidade)
        stateData.map((item) => {
          if (item.sigla === endereco.uf) setStateOption(item.id)
        })
      }
    } catch (e) {
      return null
    }
  }

  async function checkFields() {
    const checkEmailExists = await UserService.getUserCheckEmailExists(email)

    // if (checkEmailExists?.responseStatus !== 200) {
    //   notifyErrorCustom('Ocorreu um erro desconhecido!')
    //   return setLoading(false)1
    // }

    if (checkEmailExists?.data?.exists) {
      notifyErrorCustom('Este e-mail já está em uso!')
      return setLoading(false)
    }
    if (name === '') {
      notifyErrorCustom('O campo de Nome não pode estar vazio')
      return false
    }

    if (lastName === '') {
      notifyErrorCustom('O campo de Sobrenome não pode estar vazio')
      return false
    }

    if (lastCpf === '') {
      notifyErrorCustom('O campo de CPF não pode estar vazio')
      return false
    } else {
      const cnpjWithoutMask = lastCpf.replace(/\D/g, '')

      const isValidCpf = await isValidCPForCNPJ(cnpjWithoutMask)

      if (isValidCpf === false) {
        notifyErrorCustom(
          'O campo de CPF não foi preenchido com um valor válido'
        )
        return false
      }
    }

    if (phone === '') {
      notifyErrorCustom('O campo de Telefone não pode estar vazio')
      return false
    }

    if (phone.includes('_')) {
      notifyErrorCustom('O campo de Telefone não foi preenchido corretamente')
      return false
    }

    if (email === '') {
      notifyErrorCustom('O campo de E-mail não pode estar vazio')
      return false
    }

    if (!isEmailValid(email)) {
      notifyErrorCustom(
        'O campo de Email não foi preenchido com um email válido'
      )
      return false
    }

    if (password === '') {
      notifyErrorCustom('O campo de Senha não pode estar vazio')
      return false
    }

    if (password.length < 8) {
      notifyErrorCustom('O campo de Senha deve possuir no mínimo 8 caracteres')
      return false
    }

    if (cep === '') {
      notifyErrorCustom('O campo de CEP não pode estar vazio')
      return false
    }

    if (logradouro === '') {
      notifyErrorCustom('O campo de Logradouro não pode estar vazio')
      return false
    }

    if (number === '') {
      notifyErrorCustom('O campo de Número não pode estar vazio')
      return false
    }

    if (city === '') {
      notifyErrorCustom('O campo de Cidade não pode estar vazio')
      return false
    }

    if (stateOption === '') {
      notifyErrorCustom('O campo de Estado não pode estar vazio')
      return false
    }

    return true
  }

  function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (cnpj === '') return false

    if (cnpj.length !== 14) return false

    // Eliminate known invalid CNPJs
    const invalids = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ]
    if (invalids.includes(cnpj)) return false

    // Validate verification digits
    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size)
    const digits = cnpj.substring(size)
    let sum = 0
    let pos = size - 7

    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--
      if (pos < 2) pos = 9
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digits.charAt(0), 10)) return false

    size = size + 1
    numbers = cnpj.substring(0, size)
    sum = 0
    pos = size - 7

    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--
      if (pos < 2) pos = 9
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result !== parseInt(digits.charAt(1), 10)) return false

    return true
  }

  async function checkFieldsCompany() {
    if (cnpj === '') {
      notifyErrorCustom('O campo de CNPJ não pode estar vazio')
      return false
    }

    if (cnpj.includes('_')) {
      notifyErrorCustom('O campo de CNPJ não foi preenchido corretamente')
      return false
    }

    if (!isValidCNPJ(cnpj)) {
      notifyErrorCustom('O campo de CNPJ não foi preenchido com um CNPJ válido')
      return false
    }

    if (fantasyName === '') {
      notifyErrorCustom('O campo de Nome Fantasia não pode estar vazio')
      return false
    }

    if (corporateName === '') {
      notifyErrorCustom('O campo de Razão Social não pode estar vazio')
      return false
    }

    if (cep === '') {
      notifyErrorCustom('O campo de CEP não pode estar vazio')
      return false
    }

    if (cep.includes('_')) {
      notifyErrorCustom('O campo de CEP não foi preenchido corretamente')
      return false
    }

    if (logradouro === '') {
      notifyErrorCustom('O campo de Logradouro não pode estar vazio')
      return false
    }

    if (stateOption === '') {
      notifyErrorCustom('O campo de Estado precisa ser selecionado')
      return false
    }

    if (stateOption === 'Estado') {
      notifyErrorCustom('O campo de Estado precisa ser selecionado')
      return false
    }

    if (city === '') {
      notifyErrorCustom('O campo de Cidade não pode estar vazio')
      return false
    }

    return true
  }

  const registerUser = async () => {
    const checkField = await checkFields()
    if (!checkField) {
      return setLoading(false)
    }

    if (formCompany) {
      const check = await checkFieldsCompany()
      if (!check) {
        setLoading(false)
        return
      }
    }

    if (formCompany) {
      const checkBusinessExists =
        await UserAccountsService.getUserAccountsCheckCNPJExists(
          onlyNumbers(cnpj)
        )

      // if (checkBusinessExists?.responseStatus !== 200) {
      //   notifyErrorCustom('Ocorreu um erro desconhecido!')
      //   return setLoading(false)
      // }

      if (checkBusinessExists?.data?.exists) {
        notifyErrorCustom('Este CNPJ já está em uso!')
        return setLoading(false)
      }
    }

    let bodyUser = {
      name: name,
      surname: lastName,
      phone: phone,
      email: email,
      password: password,
      knowledgeChannelId: +knowledgeChannelOption,
    }

    let userRegister = await UserService.postUser(bodyUser)

    if (userRegister?.responseStatus === 200) {
      let bodyUserAccounts = {
        userId: userRegister?.data?.uuid,
        userAccountTypeId: 0,
        cpf: lastCpf,
        cnpj: onlyNumbers(cnpj),
        fantasyName: fantasyName,
        corporateName: corporateName,
        cep: onlyNumbers(cep),
        logradouro: logradouro,
        number: number,
        complement: complement,
        state: formCompany ? +stateOption : 100,
        city: city,
      }

      let userAccountsRegister = await UserAccountsService.postUserAccounts(
        bodyUserAccounts
      )

      // const body = {
      //   userAccountId: userAccountsRegister?.data.uuid,
      //   cardToken: '123',
      //   cardName: `Robson Mock/${userAccountsRegister?.data?.cnpj}`,
      //   flag: 'VISA',
      //   number: '4000000000000010',
      //   expirationDate: '12/2026',
      //   type: 'CREDIT',
      // }

      // const returnCardUser = await PagarMeService.postCard(body)

      // const plans = await PlanService.getPlans()

      // const indexPost = await plans.data.findIndex((item) => item.tier === 1)

      // const bodySubscription = {
      //   planId: plans.data[indexPost].uuid,
      //   accountId: userAccountsRegister?.data.uuid,
      //   cardId: returnCardUser.data.uuid,
      //   paymentMethod: 'credit_card',
      // }

      // await SubscriptionService.postSubscription(bodySubscription)

      handleLogin()
    }
    // -----------------------------------------------

    setLoading(false)
  }

  const handleNavigate = () => {
    navigation('/')
  }

  const handleChecked = () => {
    setFormCompany(!formCompany)
  }

  const containerRegisterUser = () => {
    return (
      <div className={styles.container_user_register}>
        <input
          className={styles.input}
          placeholder="Nome"
          onChange={(e) => setName(e.target.value)}
        />

        <input
          className={styles.input}
          placeholder="Sobrenome"
          onChange={(e) => setLastName(e.target.value)}
        />

        <InputMask
          className={styles.input}
          type="text"
          mask="999.999.999-99"
          placeholder="CPF"
          onChange={(e) => setLastCpf(e.target.value)}
        />

        <InputMask
          className={styles.input}
          type="text"
          mask="(99) 99999-9999"
          placeholder="Telefone"
          // value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />

        <input
          className={styles.input}
          placeholder="E-mail"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <select onChange={handleChangeKnowledgeChannel}>
          {knowledgeChannelData.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>

        <input
          className={styles.input}
          placeholder="Senha"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <InputMask
          className={styles.input}
          type="text"
          mask="99999-999"
          placeholder="CEP"
          value={cep}
          onChange={(event) => setCep(event.target.value)}
        />

        <input
          className={styles.input}
          placeholder="Logradouro"
          value={logradouro}
          onChange={(e) => setLogradouro(e.target.value)}
        />

        <input
          className={styles.input}
          placeholder="Numero"
          type="number"
          onChange={(e) => setNumber(e.target.value)}
        />

        <input
          className={styles.input}
          placeholder="Complemento"
          onChange={(e) => setComplement(e.target.value)}
        />

        <select onChange={handleChangeState} value={stateOption}>
          <option value="">Estado</option>
          {stateData.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>

        <input
          className={styles.input}
          placeholder="Cidade"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </div>
    )
  }

  const containerRegisterBusiness = () => {
    return (
      <div className={styles.container_company_register}>
        <div
          style={{ padding: '8px 0px' }}
          className={styles.company_register_header}
        >
          <div
            style={{ cursor: 'pointer', marginTop: 4 }}
            onClick={handleChecked}
          >
            {formCompany ? <BoxChecket /> : <BoxNotChecket />}
          </div>
          <label style={{ fontSize: 12 }}>
            Perfil dedicado para minha empresa
          </label>
        </div>

        {formCompany && (
          <>
            {/* <input className={styles.input} placeholder="CNPJ" /> */}
            <InputMask
              className={styles.input}
              type="text"
              mask="99.999.999/9999-99"
              placeholder="CNPJ"
              value={cnpj}
              onChange={(event) => setCnpj(event.target.value)}
            />

            <input
              className={styles.input}
              placeholder="Nome Fantasia"
              onChange={(e) => setFantasyName(e.target.value)}
            />

            <input
              className={styles.input}
              placeholder="Razão Social"
              onChange={(e) => setCorporateName(e.target.value)}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <div className={styles.page}>
      <Loading enable={loading} />

      <div className={styles.container_field}>
        <div className={styles.container_form}>
          <form style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div className={styles.form_header}>
              <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
              <img
                src={posteiLogoText}
                alt="posteiLogoText"
                style={{ height: 34 }}
              />
            </div>
            <div className={styles.form_body}>
              <div>
                <h2 style={{ fontSize: '35px' }}>Usuário Individual</h2>
                <p style={{ fontSize: '12px' }}>
                  Preencha com seus dados e finalize seu cadastro
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className={styles.container_register}>
                  {containerRegisterUser()}
                  {containerRegisterBusiness()}
                </div>
              </div>
            </div>

            <div className={styles.form_footer}>
              <button
                style={{
                  fontSize: 12,
                  background: '#fff',
                  border: '1px solid #fff',
                  color: '#190027',
                  borderRadius: 4,
                  padding: '10px 60px',
                }}
                onClick={hadleRegisterUser}
              >
                Finalizar Cadastro
              </button>

              <button
                style={{
                  fontSize: 12,
                  background: '#190027',
                  border: '1px solid #fff',
                  borderRadius: 4,
                  padding: '10px 60px',
                }}
                onClick={handleNavigate}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default IndividualUser
