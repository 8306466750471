import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

const PAGARME_KEY = import.meta.env.PAGARME_SECRET_KEY

export const getCardId = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}cards/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getCardId', error.response.data)
      return error
    })
}

// export const postCard = async (customerId) => {
//   console.log('37', PAGARME_KEY)

//   try {
//     //const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//     const config = {
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         Authorization: `Basic ${btoa('sk_test_98Gz5oaH3aHG2OR2' + ':')}`,
//       },
//       withCredentials: true,
//     }

//     const response = await axios.post(
//       `https://api.pagar.me/core/v5/customers/${customerId}/cards`,
//       {}, // Você pode passar qualquer dado para o corpo da solicitação aqui, se necessário
//       config
//     )
//     return {
//       data: response.data,
//       responseStatus: response.status,
//     }
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       // Handle unauthorized error (e.g., refresh token or redirect to login)
//       window.localStorage.clear()
//       window.location.reload()
//     }

//     console.log('Error postCard', error.response?.data || error.message)
//     return {
//       error: true,
//       errorMessage: error.response?.data || error.message,
//     }
//   }
// }

export const postCard = async (body) => {
  const config = {
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}cards`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postCards', error.response.data)
      return error
    })
}

export const getCardByUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))
  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  // const config = {
  //   withCredentials: true,
  // }

  return await axios
    .get(`${BASE_URL}cards-by-user`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postCards', error.response.data)
      return error
    })
}

export const putCard = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}cards/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error putProductType', error.response.data)
      return error
    })
}
