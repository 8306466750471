import React, { useEffect, useState } from 'react'
import { ListClientStep } from './Steps/ListClients'
import { CreateClientStep } from './Steps/CreateClient'
import { useLocation } from 'react-router-dom'

export const ClientPage = () => {
  const location = useLocation()
  const [step, setStep] = useState(0)
  const [clientData, setClientData] = useState('')

  useEffect(() => {
    if (location.state?.header) {
      setStep(1)
    }
  }, [])

  return (
    <div>
      {step == 0 && (
        <ListClientStep
          setData={setClientData}
          data={clientData}
          setStep={setStep}
        />
      )}
      {step == 1 && (
        <CreateClientStep
          setStep={setStep}
          client={clientData}
          setClient={setClientData}
        />
      )}
    </div>
  )
}
