import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { useNavigate } from 'react-router-dom'

function Agency() {
  const navigation = useNavigate()

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }

  return (
    <div className={styles.page}>
      <div className={styles.container_form}>
        <form>
          <div className={styles.form_header}>
            <div className={styles.logo}>
              <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
              <img
                src={posteiLogoText}
                alt="posteiLogoText"
                style={{ height: 34 }}
              />
            </div>
          </div>
          <div className={styles.form_body}>
            <div className={styles.form_body_title}>
              <h2>Agência</h2>
              <p>Preencha com seus dados e finalize seu cadastro</p>
            </div>

            <div className={styles.form_body_content}>
              <input placeholder="Nome da Empresa"></input>
              <input placeholder="Razão Social"></input>
              <input placeholder="CNPJ - CPF"></input>
              <input placeholder="Cidade"></input>
              <input placeholder="Estado"></input>
              <input placeholder="Nome do Responsavel"></input>
              <input placeholder="E-mail"></input>
              <input placeholder="Telefone"></input>
            </div>
          </div>

          <div className={styles.form_footer}>
            <button
              className={styles.button_register}
              onClick={() => handleNavigate('/PlansPage')}
            >
              Finalizar Cadastro
            </button>
            <button
              className={styles.button_cancel}
              onClick={() => handleNavigate('/')}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Agency
