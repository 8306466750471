import Proptypes from 'prop-types'
import styles from './styles.module.css'

export const Button = ({children, variant, onClick, style}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={`${styles.button} ${styles[variant]}`}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children:Proptypes.node,
  variant:Proptypes.string,
  onClick:Proptypes.func,
  style:Proptypes.object
}
