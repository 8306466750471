import Proptypes from 'prop-types'
import styles from './styles.module.css'

export const ButtonNavigateStep = ({ children, variant, onClick, style }) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={
        variant === 'next' ? `${styles.button_next}` : `${styles.button_back} `
      }
    >
      {children}
    </button>
  )
}

ButtonNavigateStep.propTypes = {
  children: Proptypes.node,
  variant: Proptypes.string,
  onClick: Proptypes.func,
  style: Proptypes.object,
}
