import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './styles.module.css'
import Logo from '../../assets/logos/postei-logo-color.png'
import {
  Home,
  Picture,
  Retangle_group,
  Datepiker,
  Bookmark,
  LogOut,
  KeyRed,
  User,
  Parameters,
  Bell,
} from '../../../src/assets/svgs/icons'

import { useAuth } from '../../hooks/useAuth'

const SideMenuMyAccount = ({ setStep, step }) => {
  useEffect(() => {
    const guestUserId = window.localStorage.getItem('userData')
    if (JSON.parse(guestUserId).guestUserId) {
      setStep(1)
    }
  }, [])

  return (
    <div className={style.sidebar_expanded}>
      <div className={style.body_slidebar}>
        {JSON.parse(window.localStorage.getItem('userData')).guestUserId ? (
          <ul>
            <li className={step === 1 ? style.selected : style.unselected}>
              <div className={style.box_item_menu} onClick={() => setStep(1)}>
                <div className={style.iconContainer}>
                  <KeyRed
                    width={'25'}
                    height={'25'}
                    color={step === 1 ? '#FF8B00' : '#E6E6E6'}
                  />
                </div>
                <p
                  className={
                    step === 1
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Dados de Acesso
                </p>
              </div>
            </li>
          </ul>
        ) : (
          <ul>
            <li className={step === 0 ? style.selected : style.unselected}>
              <div className={style.box_item_menu} onClick={() => setStep(0)}>
                <div className={style.iconContainer}>
                  <User
                    width={'25'}
                    height={'25'}
                    color={step === 0 ? '#FF8B00' : '#E6E6E6'}
                  />
                </div>
                <p
                  className={
                    step === 0
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Minha Conta
                </p>
              </div>
            </li>

            <li className={step === 1 ? style.selected : style.unselected}>
              <div className={style.box_item_menu} onClick={() => setStep(1)}>
                <div className={style.iconContainer}>
                  <KeyRed
                    width={'25'}
                    height={'25'}
                    color={step === 1 ? '#FF8B00' : '#E6E6E6'}
                  />
                </div>
                <p
                  className={
                    step === 1
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Dados de Acesso
                </p>
              </div>
            </li>
            <li
              className={step === 2 ? style.selected : style.unselected}
              onClick={() => setStep(2)}
            >
              <div className={style.box_item_menu}>
                <div className={style.iconContainer}>
                  <Bell
                    color={step === 2 ? '#FF8B00' : '#E6E6E6'}
                    width={25}
                    height={25}
                  />
                </div>
                <p
                  className={
                    step === 2
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Notificações
                </p>
              </div>
            </li>
            <li
              className={step === 3 ? style.selected : style.unselected}
              onClick={() => setStep(3)}
            >
              <div className={style.box_item_menu}>
                <div className={style.iconContainer}>
                  <Parameters color={step === 3 ? '#FF8B00' : '#E6E6E6'} />
                </div>
                <p
                  className={
                    step === 3
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Parametrizações
                </p>
              </div>
            </li>
            <li
              className={step === 4 ? style.selected : style.unselected}
              onClick={() => setStep(4)}
            >
              <div className={style.box_item_menu}>
                <div className={style.iconContainer}>
                  <Bookmark color={step === 4 ? '#FF8B00' : '#E6E6E6'} />
                </div>
                <p
                  className={
                    step === 4
                      ? style.navigation_text_selected
                      : style.navigation_text
                  }
                >
                  Redes Sociais
                </p>
              </div>
            </li>
          </ul>
        )}
        {/* <ul>
          <li className={step === 0 ? style.selected : style.unselected}>
            <div className={style.box_item_menu} onClick={() => setStep(0)}>
              <div className={style.iconContainer}>
                <User
                  width={'25'}
                  height={'25'}
                  color={step === 0 ? '#FF8B00' : '#E6E6E6'}
                />
              </div>
              <p
                className={
                  step === 0
                    ? style.navigation_text_selected
                    : style.navigation_text
                }
              >
                Minha Conta
              </p>
            </div>
          </li>

          <li className={step === 1 ? style.selected : style.unselected}>
            <div className={style.box_item_menu} onClick={() => setStep(1)}>
              <div className={style.iconContainer}>
                <KeyRed
                  width={'25'}
                  height={'25'}
                  color={step === 1 ? '#FF8B00' : '#E6E6E6'}
                />
              </div>
              <p
                className={
                  step === 1
                    ? style.navigation_text_selected
                    : style.navigation_text
                }
              >
                Dados de Acesso
              </p>
            </div>
          </li>
          <li
            className={step === 2 ? style.selected : style.unselected}
            onClick={() => setStep(2)}
          >
            <div className={style.box_item_menu}>
              <div className={style.iconContainer}>
                <Bell
                  color={step === 2 ? '#FF8B00' : '#E6E6E6'}
                  width={25}
                  height={25}
                />
              </div>
              <p
                className={
                  step === 2
                    ? style.navigation_text_selected
                    : style.navigation_text
                }
              >
                Notificações
              </p>
            </div>
          </li>
          <li
            className={step === 3 ? style.selected : style.unselected}
            onClick={() => setStep(3)}
          >
            <div className={style.box_item_menu}>
              <div className={style.iconContainer}>
                <Parameters color={step === 3 ? '#FF8B00' : '#E6E6E6'} />
              </div>
              <p
                className={
                  step === 3
                    ? style.navigation_text_selected
                    : style.navigation_text
                }
              >
                Parametrizações
              </p>
            </div>
          </li>
          <li
            className={step === 4 ? style.selected : style.unselected}
            onClick={() => setStep(4)}
          >
            <div className={style.box_item_menu}>
              <div className={style.iconContainer}>
                <Bookmark color={step === 4 ? '#FF8B00' : '#E6E6E6'} />
              </div>
              <p
                className={
                  step === 4
                    ? style.navigation_text_selected
                    : style.navigation_text
                }
              >
                Redes Sociais
              </p>
            </div>
          </li>
          
        </ul> */}
      </div>
      <div className={style.footer_slidebar}>
        <button className={style.button_logout} onClick={() => setStep(5)}>
          <LogOut color={'#FF2F00'} width={'30px'} height={'30px'} /> Sair
        </button>
      </div>
    </div>
  )
}

//   ${sidebarOpen ? 'open' : ''}

export default SideMenuMyAccount
