import styles from './styles.module.css'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Switch } from 'antd'
import {
  BoxChecket,
  BoxNotChecket,
  LeftBackArrow,
} from '../../../assets/svgs/icons'
import { Input } from '../../../components'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

const STEPS = {
  STEP_FIRST_PASS_HEADLINES: 'stepHeadlines',
  STEP_NEW_CAMPAIGN_SUCCESS: 'stepNewCampaignSuccess',
}

function StepNewCampaign({ setStep }) {
  const [selectedOption, setSelectedOption] = useState('')
  const handleChange = (event) => {
    setSelectedOption(event.target.value)
  }

  // const navigation = useNavigate()

  const [expand, setexpand] = useState(false)
  const [isParam, setIsParam] = useState(false)
  const [checkSwitch, setCheckSwitch] = useState(false)

  // const handleNavigate = (route) => {
  // 	navigation(`${route}`)
  // }

  const nextButton = async (navigation) => {
    if (navigation === 'next') {
      setStep(STEPS.STEP_NEW_CAMPAIGN_SUCCESS)
    }
    if (navigation === 'back') {
      setStep(STEPS.STEP_FIRST_PASS_HEADLINES)
    }
  }

  const handleCheckt = () => {
    setexpand(!expand)
  }

  const handleChecktParamGeral = () => {
    setIsParam(!isParam)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Campanhas</p>
        <p className={styles.title_new_posts_title}>Nova Campanha</p>
      </div>

      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.container_form_first_options_header}>
              <div
                className={styles.box_button_back}
                onClick={() => {
                  nextButton('back')
                }}
              >
                <LeftBackArrow /> Voltar
              </div>
            </div>
            <div className={styles.form_container_input}>
              <div className={styles.form_container_label_input_container}>
                <label className={styles.form_container_input_label}>
                  Primeiro digite o nome da sua campanha
                </label>
                <Input
                  placeholder="Digite"
                  value={selectedOption}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.container_box_checkbox}>
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    Selecione o cliente
                  </label>
                  <Input
                    placeholder="Digite"
                    value={selectedOption}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.box_svg} onClick={handleCheckt}>
                  {expand ? (
                    <BoxChecket width={20} height={20} color={'#000'} />
                  ) : (
                    <BoxNotChecket
                      width={20}
                      height={20}
                      borderColor={'#000'}
                    />
                  )}
                  <p className={styles.box_svg_label}>
                    Esta campanha não é para um cliente
                  </p>
                </div>
              </div>
            </div>

            {expand && (
              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                animate={{ opacity: 1 }}
                className={styles.form_container_input}
              >
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    Selecione o tipo de campanha
                  </label>
                  <SelectSquare
                    backgroundColor={'rgba(224, 224, 224, 1)'}
                    arrowDirection="down"
                    placeholder={'Selecione'}
                    options={[{ label: 'campanha 1', value: 'campanha1' }]}
                  />
                </div>
                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    Selecione um de seus produtos
                  </label>
                  <SelectSquare
                    backgroundColor={'rgba(224, 224, 224, 1)'}
                    arrowDirection="down"
                    placeholder={'Selecione'}
                    options={[{ label: 'produto 1', value: 'produto1' }]}
                  />
                </div>
                <div className={styles.container_box_checkbox}>
                  <div className={styles.form_container_label_input_container}>
                    <label className={styles.form_container_input_label}>
                      Selecione o publico-alvo desta campanha
                    </label>
                    <SelectSquare
                      backgroundColor={'rgba(224, 224, 224, 1)'}
                      arrowDirection="down"
                      placeholder={'Selecione'}
                      options={[
                        { label: 'publico alvo 1', value: 'publicoAlvo1' },
                      ]}
                    />
                  </div>

                  <div
                    className={styles.box_svg}
                    onClick={handleChecktParamGeral}
                  >
                    {isParam ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                    <p className={styles.box_svg_label}>
                      Esta campanha não é para um cliente
                    </p>
                  </div>
                </div>

                <div className={styles.form_container_label_input_container}>
                  <label className={styles.form_container_input_label}>
                    Digite o objetivo desta campanha
                  </label>
                  <Input
                    placeholder="Digite"
                    value={selectedOption}
                    onChange={handleChange}
                  />
                </div>
                <label className={styles.form_container_input_label}>
                  Parametrizações
                </label>

                <div className={styles.box_parameterizations}>
                  <Switch
                    onChange={() => setCheckSwitch(!checkSwitch)}
                    defaultChecked={checkSwitch}
                    size="small"
                    handleBg={'#0000'}
                    style={{
                      outline: 'none',
                      backgroundColor: checkSwitch
                        ? 'rgba(255, 139, 0, 1)'
                        : '',
                    }}
                  />
                  <p>Manter parametrizações do cadastro inicial</p>
                </div>
              </motion.div>
            )}
          </div>
        </form>
        <div className={styles.button_next}>
          <button
            onClick={() => {
              nextButton('next')
            }}
          >
            prosseguir
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepNewCampaign

StepNewCampaign.propTypes = {
  setStep: PropTypes.string,
}
