/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import SchedulerCalendar from '../../../components/SchedulerCalendar'
import CarouselHours from '../../../components/CarouselHours'

import { useNavigate } from 'react-router-dom'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'

import { Loading } from '../../../components/Loading'

import {
  BusinessService,
  PublicationService,
  HeadlineHasPublicationService,
  VoiceToneService,
} from '../../../services'

import {
  Picture,
  Instagram,
  Linkedin,
  Pinterest,
  TikTok,
  Twitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
} from '../../../assets/svgs/icons'

import { useState, useEffect } from 'react'

import { imgBase64Mock } from '../../../assets/mocks/imgMock'

import { ModalComponent } from '../../../components/ModalComponent'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { ImageRender } from '../../../components/ImageRender'
import dayjs from 'dayjs'
import { postPromptGPT } from '../../../services/pythonService'

const MockArray = [
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
  imgBase64Mock,
]

function StepFinish({
  setStep,
  targetAudienceRef,
  productRef,
  purposeOfPublicationRef,
  legend,
  imageUrl,

  publicationIdRef,
  imageLayers,

  editRef,
  editDataRef,
  currentIndex,
  dashParam,
  setLegend,
}) {
  const { clientData } = useClient()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [oppenedModalLocale, setOppenedModalLocale] = useState(false)
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false)

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false)
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')

  const [selectedValues, setSelectedValues] = useState({})
  const selectedValuesString = Object.keys(selectedValues).toString() || ''

  const [ImageLogoSrc, setImageLogoSrc] = useState({})

  useEffect(() => {
    if (editRef.current) {
      // Criar um objeto Date
      const date = new Date(editDataRef?.current.publicationDate)

      // Obter a hora
      const hours = date.getUTCHours().toString().padStart(2, '0')
      const minutes = date.getUTCMinutes().toString().padStart(2, '0')

      if (selectedValues[parseInt(editDataRef?.current?.socialTypes)]) {
        const newValues = { ...selectedValues }
        delete newValues[parseInt(editDataRef?.current?.socialTypes)]
        setSelectedValues(newValues)
      } else {
        setSelectedValues({
          ...selectedValues,
          [parseInt(editDataRef?.current?.socialTypes)]: true,
        })
      }

      setSelectedDate(date.toDateString())
      setValueHour(hours)
      setValueMinute(minutes)
    }
  }, [])

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
      const fileContentType = matches[0]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body, selectedValuesString)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    } else {
      console.log(publicationImageBucket?.responseStatus)
    }
  }

  const handleClick = (value) => {
    // Verifica se o valor já está no array
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  const handleSavePublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      setLoading(false)
      return
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')
      setLoading(false)
      return
    }

    // const dateSelected = new Date(selectedDate)

    // dateSelected.setUTCHours(valueHour)
    // dateSelected.setUTCMinutes(valueMinute)

    const dateSelected = dayjs(selectedDate).hour(valueHour).minute(valueMinute)

    const imageUrlBuket = ImageLogoSrc?.src
      ? `data:image/png;base64,${ImageLogoSrc?.src}`
      : imageUrl

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket)

    if (publicationImageBucket) {
      let publicationBody = {
        headline: imageLayers.params
          ? imageLayers?.params.filter((item) => item?.name === 'titulo')[0]
              ?.value
          : '',
        legend: legend,
        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        classification: currentIndex,
        sketch: false,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        objective: purposeOfPublicationRef?.current,
        businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
        generateArtJson: imageLayers,
      }

      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      //return

      const publicationResponse = await PublicationService.postPublication(
        businessId,
        selectedValuesString,
        publicationBody
      )

      if (publicationResponse?.responseStatus === 200) {
        publicationIdRef.current = [
          { uuid: publicationResponse?.data?.uuid, classification: null },
        ]
      } else {
        // notifyErrorCustom('Erro ao salvar publicação!')
        // return
      }

      let headlineHasPublicationBody = {
        publicationId: publicationResponse?.data?.uuid,
        purposePublication: '',
        publicationDescription: '',
        productId: productRef?.current?.uuid,
        targetAudience: targetAudienceRef?.current?.uuid,
      }

      // const headlinePublicationRetur =
      await HeadlineHasPublicationService.postHeadlineHasPublication(
        headlineHasPublicationBody
      )

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        notifySuccessCustom('Publicação agendada com sucesso!')
        setStep('stepPublicationSuccess')
      } else {
        setLoading(false)
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!')
      setLoading(false)
      return
    }

    setLoading(false)
  }

  const handleEditPublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      setLoading(false)
      return
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')
      setLoading(false)
      return
    }

    // const dateSelected = new Date(selectedDate)

    // dateSelected.setUTCHours(valueHour)
    // dateSelected.setUTCMinutes(valueMinute)

    const dateSelected = dayjs(selectedDate).hour(valueHour).minute(valueMinute)

    //!--------------------------

    const imageUrlBuket = ImageLogoSrc?.src
      ? `data:image/png;base64,${ImageLogoSrc?.src}`
      : imageUrl

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket)

    //!--------------------------

    if (publicationImageBucket) {
      let publicationBody = {
        ...editDataRef.current,

        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        classification: editDataRef?.current?.classification,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
        generateArtJson: editDataRef?.current?.generateArtJson,
      }

      delete publicationBody.updatedAt
      delete publicationBody.deletedAt
      delete publicationBody.uuid
      delete publicationBody.createdAt
      delete publicationBody.socialTypes
      delete publicationBody.campaignId
      delete publicationBody.userAccountId
      delete publicationBody.hashtag
      delete publicationBody.link
      delete publicationBody.messagePermission
      delete publicationBody.objective
      delete publicationBody.businessClientId

      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      try {
        await PublicationService.putPublication(
          editDataRef?.current?.uuid,
          businessId,
          selectedValuesString,
          publicationBody
        )
        notifySuccessCustom('Publicação agendada com sucesso!')
        setStep('stepPublicationSuccess')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!')
      setLoading(false)
      return
    }

    setLoading(false)
  }

  const [valueItim, setValueItim] = useState(1)

  const handleSelectedIntem = (item) => {
    // campaignRef.current = item
    setValueItim(item.id)
  }

  const [gptInput, setGptInput] = useState('')

  const loadGptOutput = async () => {
    // console.log('gptInput ====> ', gptInput)

    let businesData = undefined
    let voiceToneData = ''

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
        businesData?.uuid
      )
    } else {
      const { data } = await BusinessService.getBusinessByLogin()

      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()
    }

    // console.log('voiceToneData ====> ', voiceToneData?.data[0]?.tone)

    const body = {
      flow: 'own_publication',
      prompt: 'legend',

      niche: businesData?.BusinessNiche?.name,
      subniche: businesData?.subNiche,
      input: gptInput,
      voice_tone: voiceToneData?.data[0]?.tone,
      business_description: businesData?.description,
    }

    // console.log('body ====> ', body)

    const response = await postPromptGPT(body)

    // console.log('response ====> ', response)

    if (response?.data?.status === 200) {
      setLegend(response?.data?.data?.legenda)
    } else {
      notifyErrorCustom('Erro ao gerar legenda!')
    }

    setLoading(false)
  }

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ]
    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          // overflowY: 'auto',
          //WebkitOverflowScrolling: 'touch',
          //scrollbarWidth: 'none',
          //msOverflowStyle: 'none',
          // height: 'calc(100vh - 176px)',
          // backgroundColor: 'red',
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            // height: 'calc(82vh - (-180px))',
            height: 'calc(100vh - 176px)',
            // height: '800px',
            // backgroundColor: 'red',
          }}
        >
          <div className={styles.card}>
            <div className={styles.showPath}>
              <Picture color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>{'Publicar em'}</p>
            </div>

            <div className={styles.iconsField}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(1)}
              >
                <Linkedin
                  color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(4)}
              >
                <Instagram
                  color={selectedValues[4] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
                onClick={() => handleClick(2)}
              >
                <Twitter
                  color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                  width={24}
                  height={24}
                />
              </div>

              {/* <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div> */}
            </div>
          </div>

          {dashParam && (
            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
                cursor: 'default',
              }}
              className={styles.selectedModalCard}
            >
              <div
                className={styles.form_container_input}
                style={{
                  cursor: 'default',
                  width: '100%',
                }}
              >
                <label
                  style={{
                    fontSize: 14,
                    color: '#190027',
                    fontWeight: 500,
                  }}
                >
                  Legenda
                </label>

                <textarea
                  readOnly={false}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={legend}
                  style={{
                    width: '100%',
                    height: 130,
                    borderRadius: 6,
                    border: '1px solid #ededed',
                    backgroundColor: 'rgba(224, 224, 224, 1)',
                    padding: '10px',
                    color: '#373737',
                    resize: 'none',
                    outline: 'none',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                  }}
                  onChange={(e) => setLegend(e.target.value)}
                  placeholder="Digite aqui a legenda desta publicação"
                />

                {/* <div
                className={styles.textarea}
                style={{
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                  }}
                >
                  {publicationLegend === undefined || publicationLegend === ''
                    ? 'Clique em aplicar para gerar legenda...'
                    : publicationLegend}
                </div>
              </div> */}

                <div
                  style={{
                    display: 'flex',
                    gap: 20,
                  }}
                >
                  <input
                    value={gptInput}
                    onChange={(e) => setGptInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setLoading(true)
                        //loadGptOutput()
                      }
                    }}
                    type="text"
                    placeholder="Digite sobre seu post e clique em aplicar para que a IA te de uma sugestão de legenda"
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 5,
                      border: '1px solid #ededed',
                      backgroundColor: '#ededed',
                      padding: '0 10px',
                      marginTop: 10,
                      marginBottom: 20,

                      color: '#190027',
                    }}
                  />
                  <div
                    onClick={() => {
                      setLoading(true)
                      loadGptOutput()
                    }}
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      height: 40,
                      width: 100,
                      borderRadius: 5,
                      backgroundColor: '#6A0098',

                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: 14,
                      }}
                    >
                      Aplicar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Calendar2 color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>{'Data e Horário'}</p>
            </div>

            <div className={styles.box_calendar}>
              <div
                style={{
                  flex: 1,
                }}
              >
                <SchedulerCalendar
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>

              <div className={styles.container_hour_carousel}>
                <p className={styles.container_hour_carousel_title}>Horário</p>
                <CarouselHours
                  items={hours}
                  setValueMinute={setValueMinute}
                  valueMinute={valueMinute}
                  setValueHour={setValueHour}
                  valueHour={valueHour}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>{'Configurações Adicionais'}</p>
            </div>
            <div
              onClick={() => setOppenedModalLocale(true)}
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <PinMapsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>Localização</div>
                <div className={styles.selectedModalTextSubtitle}>
                  Adicione uma localização
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppenedModalPersons(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <MorePersonsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  Marcar pessoas
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Marque pessoas ou empresas a esta publicação
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <FacebookChatIcon color={'#610f92'} size={'24'} />

              <div>
                <div className={styles.selectedModalTextTitle}>
                  Receber Mensagens
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Permitam que pessoas te enviem mensagens a partir desta
                  publicação (Apenas Facebook)
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalLinks(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <LinksIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  Adicionar Links
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Adicione links externos a sua publicação (Apenas Facebook)
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalHashtags(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <HashtagIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  Adicionar Hashtags
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Segmente sua publicação para os melhores públicos
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p className={styles.patchTitle}>
                {'Configurações de Privacidade'}
              </p>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>Público</div>
                <div className={styles.selectedModalTextSubtitle}>
                  Qualquer pessoa dentro ou fora do Facebook poderá ver sua
                  publicação.
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>Restrito</div>
                <div className={styles.selectedModalTextSubtitle}>
                  Escolha certas pessoas no Facebook que podem ver sua
                  publicação.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SchedulePhotosField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,
        }}
      >
        <Loading enable={loading} />
        <div className={styles.container_select_preview}>
          <SelectSquare
            backgroundColor={'#EDEDED'}
            border={true}
            borderColor={'#dadada'}
            placeholder={'Selecione'}
            arrowDirection="down"
            options={[
              {
                id: 1,
                label: 'Previa Feed Do Facebook',
              },
              {
                id: 2,
                label: 'Previa Feed Do Instagram',
              },
              {
                id: 3,
                label: 'Previa Stories Do Facebook',
              },
              {
                id: 4,
                label: 'Previa Stories Do Instagram',
              },
            ]}
            fontSize={12}
            //  defaultSelected={locationsStatesOptions.find(
            //    (e) => e.value === locationsStatesOptions?.value
            //  )}
            onClickItem={handleSelectedIntem}
          />
        </div>
        <div
          style={{
            backgroundColor: '#fbfbfb',
            flex: 1,
          }}
        >
          <ImageRender image={imageUrl} type={valueItim} legend={legend} />
        </div>
        {/* <div><CarrouselComponent /></div> */}
      </div>
    )
  }

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              if (location?.state?.dashboad) {
                return navigate(-1)
              }

              if (editRef) {
                return navigate(-1)
              }

              setStep('stepSubtitles')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={styles.button_next}
              onClick={() => {
                setLoading(true)

                if (editRef.current) {
                  handleEditPublication()
                } else {
                  handleSavePublication()
                }
              }}
            >
              <p className={styles.button_title}>Finalizar</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Localização</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Marcar Pessoas</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Twitter color={'#AAAAAA'} width={24} height={24} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Quem Você gostaria de Marcar?"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Link</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira um link público e válido aqui"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Hashtags</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira as hashtags e separe as mesmas com uma vírgula (,)"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  )
}

export default StepFinish

// StepFinish.propTypes = {}
