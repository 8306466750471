import { array, func } from 'prop-types'
import styles from './styles.module.css'
import { Stars, Trash } from '../../../assets/svgs/icons'

export const ImagesContainer = ({
  imagesArray,
  onClickExclude,
  onClickRemoveBackground,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
      {imagesArray.length !== 0 && (
        <div className={styles.content_list_image}>
          <div className={styles.container_box_list_image}>
            {imagesArray.map((image, index) =>
              image.status !== 'deleted' ? (
                <div key={index} className={styles.container_list_image}>
                  <div className={styles.item_list_image}>
                    <img
                      src={image.fileUrl}
                      alt="file-preview"
                      className={styles.image_list}
                    />

                    <div className={styles.box_data_info}>
                      <p className={styles.box_data_info_title}>
                        {image.fileName}
                      </p>
                      <p className={styles.box_data_info_size}>
                        Tamanho &nbsp;<b> {image.fileSize} mb</b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.item_list_image_buttons}>
                    <button
                      className={styles.button_cancel}
                      onClick={() => onClickExclude(index)}
                    >
                      <p className={styles.button_cancel_title}>Excluir</p>
                      <Trash />
                    </button>
                    {/* <button
                      className={styles.button_remove_background}
                      disabled={!image.isOriginal}
                      style={{ opacity: !image.isOriginal ? 0.5 : 1 }}
                      onClick={() => onClickRemoveBackground(index, image)}
                    >
                      <p className={styles.button_cancel_title}>
                        Remover Fundo
                      </p>
                      <Stars />
                    </button> */}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
    </div>
  )
}

ImagesContainer.propTypes = {
  imagesArray: array,
  onClickExclude: func,
  onClickRemoveBackground: func,
}
