import {
  Comment,
  CommentBubble,
  Globe,
  HeartLike,
  Like,
  PlaneShare,
  SaveFlag,
  Share,
} from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'

export const LayoutInstagram = ({
  image,
  legend,
  userData
}) => {

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <div className={styles.box_image_render}>
      <div className={styles.header_image_render}>
        <div className={styles.box_company_info}>
          <div className={styles.box_logo_company}>
            <img className={styles.image_company} src={userData?.photoUrl} alt="" />
          </div>
          <div className={styles.box_name_company}> 
            <p className={styles.name_company}>
              Minha conta
            </p>
          </div>
        </div>
      </div>

      <div className={styles.body_image_render}>
        <img
          src={image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: 0,
          }}
        />
      </div>
      <div className={styles.footer_image_render}>
        <div className={styles.box_icons}>
          <div>
            <HeartLike />
            <CommentBubble />
            <PlaneShare />
          </div>
          <div>
            <div>
              <SaveFlag />
            </div>
          </div>
        </div>
        <p className={styles.legend_publication}>
          {truncateString(legend, 60)}
        </p>
      </div>
    </div>
  )
}

LayoutInstagram.propTypes = {
  image: object,
}
