import { useState } from 'react'
import styles from './styles.module.css'
import {
  Accept,
  CloundArrowUp,
  Cross,
  Interrogation,
  Stars,
  Trash,
} from '../../assets/svgs/icons'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { PythonService } from '../../services'
import { useEffect } from 'react'
import { Loading } from '../Loading'

export const BackgroundRemoverModal = ({
  onCloseModal,
  closeModal,
  imageArray,
}) => {
  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [removeBackgroundMode, setRemoveBackgroundMode] = useState(undefined)
  const [removedImageBackground, setRemovedImageBackground] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFilesSelectedArray(imageArray)
  }, [imageArray])

  const onDragOver = (event) => {
    event.preventDefault()
  }

  const onDragLeave = () => {}

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          isBackgroundRemoved: false,
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const handleRemoveFile = (index) => {
    // var indice = filesSelectedArray.indexOf(index)
    // filesSelectedArray.splice(indice, 1)
    // setFilesSelectedArray((current) => [...current])

    setFilesSelectedArray((current) => current.filter((_, i) => i !== index))
  }

  const addImages = async () => {
    onCloseModal(filesSelectedArray)
    closeModal('galeria')
  }

  const handleOpenRemoveBackgroundMode = (index) =>
    setRemoveBackgroundMode(index)

  const handleRemoveBackground = async (index, image) => {
    setLoading(true)
    const { data } = await PythonService.postRemoveBackground({ image })
    filesSelectedArray[index].fileDataBase64 = data?.data?.images[1]?.image
    filesSelectedArray[index].isBackgroundRemoved = true
    setFilesSelectedArray([...filesSelectedArray])
    setRemovedImageBackground(data?.data?.images)
    setLoading(false)
  }

  const handleStopRemoveBackgroundMode = () => setRemoveBackgroundMode(-1)

  const handleRestoreOriginalImage = (index, image) => {
    if (image.isBackgroundRemoved) {
      filesSelectedArray[index].fileDataBase64 =
        removedImageBackground[0]?.image
    } else {
      filesSelectedArray[index].fileDataBase64 = image.fileDataBase64
    }

    filesSelectedArray[index].isBackgroundRemoved = false
    setFilesSelectedArray([...filesSelectedArray])
    handleStopRemoveBackgroundMode()
  }

  return (
    <div className={styles.modal}>
      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('galeria')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2 className={styles.modal_title_text}>
          Realize upload dos elementos <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Realize uploads arrastando os elementos para área demarcada ou
          selecione do explorador de arquivos
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <div className={styles.modal_drag}>
              <div
                className={styles.modal_drag_drop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <label htmlFor="arquivo">
                  <CloundArrowUp color={'#B900FE'} />
                  <h2
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_title_short
                        : styles.modal_drag_drop_title
                    }
                  >
                    Arraste & Solte o arquivo
                  </h2>
                  <p
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_sub_title_short
                        : styles.modal_drag_drop_sub_title
                    }
                  >
                    Ou selecione o arquivo no explorador
                  </p>
                </label>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={(e) => onFileChange(e)}
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.modal_button_save}>
            <button className={styles.button_add}>
              <label className={styles.button_add_label} htmlFor="arquivo">
                Adicionar Imagem
              </label>
            </button>
          </div>
        </div>
        {filesSelectedArray.length !== 0 && (
          <div className={styles.content_list_image}>
            <div className={styles.container_box_list_image}>
              {filesSelectedArray.map((image, index) => (
                <div
                  key={index}
                  className={
                    styles[
                      removeBackgroundMode !== index
                        ? 'container_list_image'
                        : 'container_list_image_remove_background_mode'
                    ]
                  }
                >
                  <div className={styles.item_list_image}>
                    <img
                      className={styles.image_list_image}
                      src={image?.fileDataBase64}
                      alt="file-preview"
                    />

                    {removeBackgroundMode !== index && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        style={{
                          flex: 1,
                          margin: '0 10px',
                          maxWidth: 300,
                          // background: 'red',
                        }}
                      >
                        <p className={styles.box_data_info_title}>
                          {image?.name}
                        </p>

                        <p className={styles.box_data_info_size}>
                          Tamanho &nbsp;<b> {image?.size} mb</b>
                        </p>
                      </motion.div>
                    )}
                  </div>
                  {removeBackgroundMode !== index && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.8 }}
                      className={styles.item_list_image_buttons}
                    >
                      <button
                        className={styles.button_cancel}
                        onClick={() => handleRemoveFile(index)}
                      >
                        <p className={styles.button_cancel_title}>Excluir</p>
                        <Trash />
                      </button>

                      <button
                        disabled={image?.isBackgroundRemoved}
                        onClick={() => handleOpenRemoveBackgroundMode(index)}
                        className={`${styles.button_remove_background} ${
                          image?.isBackgroundRemoved
                            ? styles.button_remove_background_disabled
                            : ''
                        }
                          `}
                      >
                        <p className={styles.button_remove_background_title}>
                          Remover Fundo
                        </p>
                        <Stars />
                      </button>
                    </motion.div>
                  )}
                  {removeBackgroundMode === index && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1 }}
                      className={styles.item_list_image_buttons}
                    >
                      <Loading enable={loading} />
                      {removedImageBackground[1]?.image !==
                        image?.fileDataBase64 &&
                        !image?.isBackgroundRemoved && (
                          <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={() =>
                              handleRemoveBackground(
                                index,
                                image?.fileDataBase64
                              )
                            }
                            className={styles.button_remove_background}
                          >
                            <p
                              className={styles.button_remove_background_title}
                            >
                              Remover Fundo
                            </p>
                            <Stars />
                          </motion.button>
                        )}
                      {removedImageBackground[1]?.image ===
                        image?.fileDataBase64 && (
                        <button
                          onClick={() => handleStopRemoveBackgroundMode()}
                          className={styles.button_remove_background_success}
                        >
                          <p className={styles.button_remove_background_title}>
                            Sucesso
                          </p>
                          <Accept />
                        </button>
                      )}
                      <button
                        className={
                          styles[
                            !removeBackgroundMode === index
                              ? 'button_cancel'
                              : 'button_cancel_remove_background'
                          ]
                        }
                        onClick={() => handleRestoreOriginalImage(index, image)}
                      >
                        <p
                          className={styles.button_cancel_title}
                          style={{
                            color:
                              removeBackgroundMode === index ? 'black' : '',
                          }}
                        >
                          Cancelar
                        </p>
                      </button>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {filesSelectedArray.length !== 0 && (
        <button className={styles.button_save} onClick={() => addImages()}>
          Adicionar Imagens
        </button>
      )}
    </div>
  )
}

export default BackgroundRemoverModal

BackgroundRemoverModal.propTypes = {
  onCloseModal: PropTypes.func,
  closeModal: PropTypes.func,
  imageArray: PropTypes.array,
}
