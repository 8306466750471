/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useState } from 'react'
import styles from './styles.module.css'

// eslint-disable-next-line react/prop-types
const CarouselCampaing = ({ items }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const RenderItems = (posts) => {
    return posts.map((post, index) => (
      <div key={index} className={styles.carousel_item}>
        <img
          draggable="false"
          onDragStart={handleDragStart}
          className={styles.carousel_img}
          src={post.url}
          alt="Efects"
        />
      </div>
    ))
  }

  const handleDragStart = (event) => {
    event.preventDefault()
  }

  return items?.map((item, index) => {
    const carouselRef = useRef(null)

    const handleMouseDown = (event) => {
      setIsDragging(true)
      setStartX(event.clientX - carouselRef.current.offsetLeft)
      setScrollLeft(carouselRef.current.scrollLeft)
      carouselRef.current.style.scrollBehavior = 'unset'
    }

    const handleMouseMove = (event) => {
      if (!isDragging) return
      const x = event.clientX - carouselRef.current.offsetLeft
      const scrollOffset = (x - startX) * 1.0
      carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    }

    const handleMouseUp = () => {
      setIsDragging(false)
      carouselRef.current.style.scrollBehavior = 'smooth'
    }
    return (
      <div draggable="false" key={index} className={styles.carousel_container}>
        <p draggable="false" className={styles.title_campaign}>
          {item?.nome}
        </p>

        <div
          draggable="false"
          className={styles.carousel_field_move}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div draggable="false" ref={carouselRef} className={styles.carousel}>
            {RenderItems(item?.posts)}
          </div>
        </div>
      </div>
    )
  })
}

export default CarouselCampaing
