import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { ColorBox } from './ColorBox'
import { useState } from 'react'
import { HexAlphaColorPicker } from 'react-colorful'
import { CloseIcon, Pen2 } from '../../assets/svgs/icons'

export const ColorPalete = ({colors, onClose}) => {
  const [showPicker, setShowPicker] = useState(false)
  const [color, setColor] = useState('#000')
  const [renderCopyText, setRenderCopyText] = useState(false)
  const [indexBoxColor, setIndexBoxColor] = useState()



  const handleClickCopy = () =>{
    setRenderCopyText(true)
    navigator.clipboard.writeText(color).then(()=>{
      setTimeout(()=>setRenderCopyText(false), 1000);
    })
  }

  const handleClose = () => {
    setShowPicker(false)
    const aux = colors;
    aux[indexBoxColor] = color
    colors = aux
    onClose(colors)
  }

  const handleOpen = (index) =>{
    setShowPicker(true)
    setIndexBoxColor(index)

  }

  return (
    <div className={styles.colorPaleteContainer}>
      <div className={styles.boxiesContainer}>
        {colors.map( (color, index) =>(
          <>
            <ColorBox
              key={index}
              color={color}
              onClickBox={()=>handleOpen(index)}/>
          </>
        ))}
      </div>
      {showPicker && (
        <>
          <div className={styles.closeIconContainer}>
            <button onClick={()=>handleClose()} >
              <CloseIcon color='#000'/>
            </button>
          </div>
          <div className='color_picker_palete'>
            <HexAlphaColorPicker color={color} onChange={(e)=>setColor(e)}/>
          </div>
          <div className={styles.inputContainer}>
            <div style={{backgroundColor:color, width:30, height:30}}/>
            <input type="text" value={color} onChange={(e)=>setColor(e.target.value)} />
            <button onClick={()=>handleClickCopy()}><Pen2 color="black"/></button>
            {renderCopyText && <div>copiado</div>}
          </div>
        </>
      )}
    </div>
  )
}

ColorPalete.propTypes = {
  colors:PropTypes.array,
  onClose:PropTypes.func
}
