/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { ArrowDown, ArrowUp } from '../../assets/svgs/icons'

const CarouselHours = ({
  items,
  valueHour,
  setValueHour,
  valueMinute,
  setValueMinute,
}) => {
  const itemsToShow = items

  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(null)
  const [scrollY, setScrollY] = useState(0)

  // const [valueHour, setValueHour] = useState('')
  // const [valueMinute, setValueMinute] = useState('')

  const nextItems = () => {
    // Determine o número de itens para rolar
    const itemsToScroll = 6 // Defina o número desejado de itens a serem rolados

    // Calcule a nova posição de rolagem
    const newScrollTop = carouselRef.current.scrollTop + itemsToScroll * 30 // Substitua ITEM_HEIGHT pelo valor da altura de cada item

    // Ajuste o valor máximo para evitar rolar além do final
    const maxScrollTop =
      carouselRef.current.scrollHeight - carouselRef.current.clientHeight
    const clampedScrollTop = Math.min(newScrollTop, maxScrollTop)

    // Atualize a posição de rolagem
    carouselRef.current.scrollTop = clampedScrollTop
  }

  const prevItems = () => {
    // Determine o número de itens para rolar para cima
    const itemsToScroll = 6 // Defina o número desejado de itens a serem rolados

    // Calcule a nova posição de rolagem
    const newScrollTop = carouselRef.current.scrollTop - itemsToScroll * 30 // Substitua ITEM_HEIGHT pelo valor da altura de cada item

    // Ajuste o valor mínimo para evitar rolar antes do início
    const clampedScrollTop = Math.max(newScrollTop, 0)

    // Atualize a posição de rolagem
    carouselRef.current.scrollTop = clampedScrollTop
  }

  // Função para atualizar a posição de rolagem vertical
  const handleScroll = (e) => {
    setScrollY(e.target.scrollTop)
  }

  // Evento de mouse para iniciar o arrasto
  const handleMouseDown = (e) => {
    setIsDragging(true)
    setStartY(e.clientY)
  }

  // Evento de mouse para calcular a diferença e atualizar a rolagem
  const handleMouseMove = (e) => {
    if (!isDragging) return
    const deltaY = e.clientY - startY
    carouselRef.current.scrollTop = scrollY - deltaY
  }

  // Evento de mouse para encerrar o arrasto
  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleChangeHour = (e) => {
    const inputValue = e.target.value

    // Verifica se o valor digitado tem no máximo 2 caracteres
    if (inputValue.length <= 2) {
      // Se o primeiro caractere for maior que 2, não atualiza o valor
      if (inputValue.length === 1 && inputValue > '2') {
        return
      }

      // Se o primeiro caractere for igual a 2, o segundo não pode ser maior que 3
      if (
        inputValue.length === 2 &&
        valueHour[0] === '2' &&
        inputValue[1] > '3'
      ) {
        return
      }

      // Atualiza o valor no estado
      setValueHour(inputValue)
    }
  }

  const handleChangeMinute = (e) => {
    const inputValue = e.target.value

    // Verifica se o valor digitado tem no máximo 2 caracteres
    if (inputValue.length <= 2) {
      // Se o primeiro caractere for maior que 2, não atualiza o valor
      if (inputValue.length === 1 && inputValue > '5') {
        return
      }

      // Atualiza o valor no estado
      setValueMinute(inputValue)
    }
  }

  return (
    <div className={styles.vertical_carousel}>
      <button className={styles.nextItems} onClick={prevItems}>
        <ArrowUp />
      </button>
      <div
        className={styles.container_carousel_items}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div
          className={styles.carousel_items}
          ref={carouselRef}
          onScroll={handleScroll}
        >
          {itemsToShow.map((item, index) => (
            <div
              key={index}
              className={styles.carousel_item}
              onClick={() => {
                setValueHour(`${item[0]}${item[1]}`)
                setValueMinute(`${item[3]}${item[4]}`)
              }}
            >
              <p className={styles.carousel_item_title}>{item}</p>
            </div>
          ))}
        </div>
      </div>
      <button className={styles.nextItems} onClick={nextItems}>
        <ArrowDown />
      </button>

      <div className={styles.display}>
        <input
          className={styles.hours}
          placeholder="00"
          value={valueHour}
          onChange={handleChangeHour}
          maxLength={2}
        />
        :
        <input
          className={styles.minutes}
          placeholder="00"
          value={valueMinute}
          onChange={handleChangeMinute}
          maxLength={2}
        />
      </div>
    </div>
  )
}

export default CarouselHours
