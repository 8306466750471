import {
  Magic,
  Picture,
  Pen2,
  CheckBadge,
  Engine,
  CalendarIcon,
  Clips,
} from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import Proptypes from 'prop-types'
import { motion } from 'framer-motion'
import { useState } from 'react'

export const SideBar = ({ onClickStep, scheduling }) => {
  const [step, setStep] = useState(1)
  const handleChangeStep = (step) => {
    setStep(step)
    onClickStep(step)
  }

  return (
    <div className={styles.sideMenu}>
      <div className={styles.topMenuButton}>
        <button className={styles.button} onClick={() => handleChangeStep(1)}>
          <Picture />
        </button>
        <button className={styles.button} onClick={() => handleChangeStep(2)}>
          <CalendarIcon />
        </button>
        <button className={styles.button} onClick={() => handleChangeStep(3)}>
          <Clips />
        </button>
        <button className={styles.button} onClick={() => handleChangeStep(4)}>
          <Pen2 />
        </button>
      </div>
      <div className={styles.bottomMenuButton}>
        <button className={styles.button} onClick={() => handleChangeStep(5)}>
          <CheckBadge />
        </button>
      </div>
    </div>
  )
}

SideBar.propTypes = {
  step: Proptypes.number,
  onClickStep: Proptypes.func,
}
