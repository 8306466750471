/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { CloseIcon } from '../../assets/svgs/icons'
import { SideBar } from './SideBar'
import { ColorPalletStep } from './steps/ColorPaleteStep'
import { ImageStep } from './steps/ImageStep'
import { CampaingStep } from './steps/CampaingStep'
import { EffectsStep } from './steps/EffectsStep'
import { ImageFormatContainer } from './ImageFormatContainer'
import { Select } from '../Select'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { useClient } from '../../hooks/useClient'

import { ProductService } from '../../services'

import { notifyErrorCustom } from '../../utils/notify'

export const SimpleEditionModal = ({
  isOpen,
  onClose,
  title = '',
  subtitle = '',
  imageUrl = '',
  setImageUrl,

  imageLayers = {},
  // setImageLayers,
  onChangeColorPalleteTittle,
  onChangeColorPalleteSubtittle,
  publicTargetOptions,
  productOptions,
  fontName,
  setNextStep,
  isCampaign,
  simplesEditModalData,
}) => {
  const { clientData } = useClient()

  const navigate = useNavigate()

  const [tempImageLayers, setTempImageLayers] = useState({})
  const [tempMergeImageLayers, setTempMergeImageLayers] = useState('')

  const colors = ['#FF5733', '#47A8E7', '#9C27B0', '#2ECC71', '#FF2933']

  const [step, setStep] = useState(1)

  const [aditionalEffectsArray, setAditionalEffectsArray] = useState([
    'Desfoque gaussiano',
    'Desfoque de movimento',
    'Desfoque de fundo',
  ])
  const [appliedEffectsArray, setAppliedEffectsArray] = useState([
    'Blur',
    'Neon',
    'Preto e Branco',
    'HDR',
    'FOG',
  ])

  const [imageFormat, setimageFormat] = useState({ with: 400, height: 400 })
  const [prevImageFormat, setPrevImageFormat] = useState({
    with: 400,
    height: 400,
  })
  const [campaingOptionSelected, setCampaingOptionSelected] = useState({
    labe: '',
    value: '',
  })
  const [productOptionSelected, setProductOptionSelected] = useState({
    labe: '',
    value: '',
  })
  const [targetPublicOptionSelected, setTargetPublicOptionSelected] = useState({
    labe: '',
    value: '',
  })

  const formatOptions = [
    { label: 'Selecione o Formato', value: { width: 400, height: 400 } },
    { label: 'formato 1', value: { width: 400, height: 200 } },
    { label: 'formato 2', value: { width: 200, height: 400 } },
    { label: 'formato 3', value: { width: 400, height: 400 } },
  ]

  const [selectedFormat, setSelectedFormat] = useState({
    labe: 'Selecione um Formato',
    value: '',
  })

  useEffect(() => {
    if (!isOpen) return

    if (!imageLayers?.camadas) return

    setTempImageLayers(imageLayers)

    setStep(1)
  }, [imageLayers, isOpen])

  useEffect(() => {
    if (!isOpen) return

    //if (!imageLayers?.layer) return
    if (!imageLayers?.camadas) return

    async function newHandleMergedLayers() {
      if (!tempImageLayers?.camadas) return

      setTempMergeImageLayers(await handleMergedLayers(tempImageLayers))
    }

    newHandleMergedLayers()
  }, [tempImageLayers])

  const handleClickSideBar = (e) => {
    setStep(e)
    setPrevImageFormat(imageFormat)
  }

  const handleClickDeleteAppliedEffect = (e) => {
    const aux = appliedEffectsArray
    aditionalEffectsArray.push(aux[e])
    aux.splice(e, 1)
    setAditionalEffectsArray(aditionalEffectsArray)
    setAppliedEffectsArray([...aux])
  }

  const handleClickAddEffect = (e) => {
    const aux = aditionalEffectsArray
    appliedEffectsArray.push(aux[e])
    setAppliedEffectsArray(appliedEffectsArray)
    aux.splice(e, 1)
    setAditionalEffectsArray([...aux])
  }

  const handleChangeSelectImage = (e) => {
    setSelectedFormat({ ...e })
    setimageFormat((prev) => {
      setPrevImageFormat(prev)
      return { ...e.value }
    })
  }

  const handleClickDone = () => {
    setStep(1)
    setNextStep(
      imageLayers?.camadas
        ? 'data:image/png;base64,' + tempMergeImageLayers
        : imageUrl,
      {
        tempImageLayers,
        mergedImage: 'data:image/png;base64,' + tempMergeImageLayers,
      }
    )
    onClose()

    if (!tempImageLayers?.camadas) return

    setImageUrl('data:image/png;base64,' + tempMergeImageLayers)
  }

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      setStep(1)
      onClose()
    }
  }

  const handleChangeOptions = async (selectedItem, label) => {
    if (label === 'campaing') {
      setCampaingOptionSelected(selectedItem)
      return
    }
    if (label === 'product') {
      setProductOptionSelected(selectedItem)

      const productImageSelected =
        await ProductService.getProductImageByProductId(selectedItem?.value)

      if (productImageSelected?.data !== null) {
        const newImagesLayers = tempImageLayers?.camadas?.map((item) => {
          if (item.tipo === 'imagem_principal') {
            return { ...item, srcSemTratativa: productImageSelected?.data }
          }

          return {
            ...item,
          }
        })

        setTempImageLayers({ tempImageLayers, camadas: newImagesLayers })
      } else {
        notifyErrorCustom('Este produto não possui imagem')
      }
      return
    }
    if (label === 'targetPublic') {
      setTargetPublicOptionSelected(selectedItem)
      return
    }
  }

  const handleMergedLayers = async (objCamadas) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    // Carrega a primeira imagem como base
    const baseImage = new Image()
    baseImage.src = `data:image/png;base64,${objCamadas?.camadas[0]?.srcSemTratativa}`

    // Aguarda o carregamento da imagem base
    await new Promise((resolve) => {
      baseImage.onload = resolve
    })

    // Determine o tamanho máximo entre todas as imagens
    let maxWidth = baseImage.width
    let maxHeight = baseImage.height

    for (let i = 1; i < objCamadas?.camadas?.length; i++) {
      const currentImage = new Image()
      currentImage.src = `data:image/png;base64,${objCamadas?.camadas[i]?.srcSemTratativa}`

      // Aguarda o carregamento da imagem atual
      await new Promise((resolve) => {
        currentImage.onload = resolve
      })

      // Atualiza as dimensões máximas
      maxWidth = Math.max(maxWidth, currentImage.width)
      maxHeight = Math.max(maxHeight, currentImage.height)
    }

    // Define o tamanho do canvas conforme as dimensões máximas
    canvas.width = maxWidth
    canvas.height = maxHeight

    // Desenha a imagem base no canvas (centralizada)
    const baseX = (maxWidth - baseImage.width) / 2
    const baseY = (maxHeight - baseImage.height) / 2
    context.drawImage(baseImage, baseX, baseY)

    // Mescla as imagens restantes (redimensionadas e centralizadas)
    for (let i = 1; i < objCamadas?.camadas?.length; i++) {
      const currentImage = new Image()
      currentImage.src = `data:image/png;base64,${objCamadas?.camadas[i]?.srcSemTratativa}`

      // Aguarda o carregamento da imagem atual
      await new Promise((resolve) => {
        currentImage.onload = resolve
      })

      // Redimensiona a imagem para as dimensões máximas
      const scaleX = maxWidth / currentImage.width
      const scaleY = maxHeight / currentImage.height
      const scale = Math.min(scaleX, scaleY)
      const width = currentImage.width * scale
      const height = currentImage.height * scale

      // Calcula as coordenadas para centralizar a imagem redimensionada
      const x = (maxWidth - width) / 2
      const y = (maxHeight - height) / 2

      // Desenha a imagem atual no canvas
      context.drawImage(currentImage, x, y, width, height)
    }

    // Obtém o resultado em base64 a partir do canvas
    const base64Result = canvas.toDataURL('image/png').split(',')[1]

    return base64Result
  }

  return !isOpen ? null : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.modal_overlay}
      onClick={(e) => {
        handleClose(e)
      }}
    >
      <div className={styles.modal_content}>
        <div className={styles.sidebar_container}>
          <SideBar
            onClickDone={handleClickDone}
            onClickStep={handleClickSideBar}
          />
        </div>
        <div className={styles.modalContainer}>
          <button
            onClick={(e) => {
              onClose(e)
            }}
            className={styles.closeButton}
          >
            <CloseIcon size="30" />
          </button>
          <div className={styles.contentContainer}>
            <div className={styles.leftContainer}>
              <ImageFormatContainer
                fontName={fontName}
                title={title}
                imageLayers={
                  imageLayers?.camadas
                    ? 'data:image/png;base64,' + tempMergeImageLayers
                    : imageUrl
                }
                prevImageFormat={prevImageFormat}
                imageFormat={imageFormat}
              />
              <Select
                htmlFor="select"
                label="Formato"
                placeholder={'Selecione um Formato'}
                selected={selectedFormat}
                onSelectItem={handleChangeSelectImage}
                options={formatOptions}
              />
            </div>
            <div className={styles.stepsContainer}>
              {step === 1 && (
                <ColorPalletStep
                  colors={colors}
                  title={title}
                  subtitle={subtitle}
                  onChangeSubtitle={onChangeColorPalleteSubtittle}
                  onChangeTittle={onChangeColorPalleteTittle}
                  onClickButton={() => navigate('/NewPostFormPage')}
                />
              )}
              {step === 2 && (
                <ImageStep
                  imageLayers={tempImageLayers}
                  // setImageLayers={setImageLayers}
                  setImageLayers={setTempImageLayers}
                />
              )}
              {step === 3 && (
                <EffectsStep
                  onClickDeleteTag={handleClickDeleteAppliedEffect}
                  effects={appliedEffectsArray}
                  aditionalEffects={aditionalEffectsArray}
                  onClickPlus={handleClickAddEffect}
                />
              )}
              {step === 4 && (
                <CampaingStep
                  onChangeItems={handleChangeOptions}
                  campaingOptionSelected={campaingOptionSelected}
                  productOptionSelected={productOptionSelected}
                  targetPublicOptionSelected={targetPublicOptionSelected}
                  targetPublicOptions={publicTargetOptions}
                  productOptions={productOptions}
                  clientData={clientData}
                  isCampaign={isCampaign}
                  imageLayers={tempImageLayers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

SimpleEditionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  onClickDone: PropTypes.func,
  onChangeColorPalleteTittle: PropTypes.func,
  onChangeColorPalleteSubtittle: PropTypes.func,
  publicTargetOptions: PropTypes.array,
  productOptions: PropTypes.array,
  headlineText: PropTypes.string,
  fontName: PropTypes.string,
}

export default SimpleEditionModal
