import { Comment, Like, Share, Stars } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { object, node, func } from 'prop-types'
import {LayoutFacebook} from './layouts/layoutFacebook'
import { LayoutInstagram } from './layouts/layoutInstagram'
import { LayoutFacebookStories } from './layouts/layoutFacebookStories'
import { LayoutInstagramStories } from './layouts/layoutInstagramStories'
import { UserAccountsService } from '../../services'
import { useEffect, useState } from 'react'

export const ImageRender = ({
  image,
  type,
  legend = ''
}) => {


  const [imagesToRender, setImagesToRender] = useState({})
  
  useEffect(() => {
    hadleLoadUserData()
    
  },[])

  const hadleLoadUserData = async () =>{
    const { data: dataAccount, responseStatus: responseStatusAccount } =
      await UserAccountsService.getUserAccountsByLogin()
      
    setImagesToRender(dataAccount)
  }
    
  const renderImageSocialMedia = () => {

    switch (type) {
    case 1:
      return ( <LayoutFacebook image={image} legend={legend} userData={imagesToRender}/>)
      break;
    
    case 2:
      return ( <LayoutInstagram image={image} legend={legend} userData={imagesToRender}/>)
      break;

    case 3:
      return ( <LayoutFacebookStories image={image} legend={legend} userData={imagesToRender}/>)
      break;

    case 4:
      return ( <LayoutInstagramStories image={image} legend={legend} userData={imagesToRender}/>)
      break;
  
  
    default:
      break;
    }
  }

  return (
    renderImageSocialMedia()
   
  )
}

ImageRender.propTypes = {
  image: object
}
