/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'
import InputIA from '../../../../components/InputIA'

import {
  PythonNodeService,
  PythonService,
  ProductService,
  BrandingService,
  VoiceToneService,
  BusinessService,
  TargetAudienceService,
} from '../../../../services'

import { Loading } from '../../../../components/Loading'

import {
  notifyErrorCustom,
  // notifySuccessCustom,
} from '../../../../utils/notify'

const ModalArtiificialInteligency = ({
  // setImageAIArray,
  closeModal,
  updateImage,
  // simpleEditModal,
  // setImageLayers,
  // imageLayers,
  // updateImage,
}) => {
  const [galeyimages, setGaleyimages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const [inputText, setInputText] = useState('')

  const [loading, setLoading] = useState(false)

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleSearchimage = async () => {
    if (inputText === '') {
      notifyErrorCustom('Nescessário que digite algo para pesquisar')
      setLoading(false)
      return
    }

    setGaleyimages(true)

    const body = {
      prompt: inputText,
      num_imgs: 4,
    }

    const response = await PythonNodeService.postIaPromptImages(body)

    if (response.responseStatus === 200) {
      const images = response.data

      setGaleyimages(images)
    } else {
      notifyErrorCustom('Houve um erro gerar imagens')
    }

    setLoading(false)
  }

  const handleImageClick = (imageUrl) => {
    const imageArray = galeyimages.find(
      (image) => image.image === imageUrl.image
    )

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.url])
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.url])
    }
  }

  // const addImages = async (selectedArrayImage) => {
  //   if (simpleEditModal) {
  //     var imageLayersTemp = { ...imageLayers }

  //     const newSelectedArrayImage = selectedArrayImage.map((image) => {
  //       const lewLayer = {
  //         imageId: image.image,
  //         isOriginal: true,
  //         order: 1,
  //         position: [],
  //         sequenceEffects: [],
  //         type: 'image-upload',
  //       }

  //       imageLayersTemp.layer[0] = lewLayer
  //     })

  //     // setImageLayers(imageLayersTemp)
  //   } else {
  //     const newSelectedArrayImage = selectedArrayImage.map((image) => {
  //       return {
  //         id: image.id,
  //         image: 'data:image/png;base64,' + image.image,
  //       }
  //     })

  //     setImageAIArray(newSelectedArrayImage)
  //   }
  //   closeModal('inteligenciaArtificial')
  // }

  const getHeadlineText = async () => {
    const productInfos = await ProductService.getProduct()
    const brandingInfos = await BrandingService.getAllBranding()
    const voiceToneInfos = await VoiceToneService.getAllVoiceTone()
    const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudience()

    const obj = {
      productInfos: productInfos,
      brandingInfos: brandingInfos,
      voiceToneInfos: voiceToneInfos,
      businessInfos: businessInfos,
      targetAudiencesInfos: targetAudiencesInfos,
    }

    getIaHeadline(obj)
  }

  const getIaHeadline = async (loadbusiness) => {
    let businessData = loadbusiness

    let bodyHeadline = {
      publications_number: 4,
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
      subniche: businessData.businessInfos?.data[0].subNiche,
      publication_purpose: 'divulgar',
      description_business: businessData.businessInfos?.data[0].description,
      voice_tone: businessData.voiceToneInfos?.data[0].tone,
      social_class: 'Classe A',
      gender: businessData.targetAudiencesInfos?.data[0]?.genderValue,
      profession: businessData.targetAudiencesInfos?.data[0].profession,
      name_product: businessData.productInfos?.data[0].name,
      product_type: businessData.productInfos?.data[0].productTypeValue,
      product_description: businessData.productInfos?.data[0].description,
      value: businessData.productInfos?.data[0].value,
      promotional_value: businessData.productInfos?.data[0].promotionalValue,
    }

    const headline = await PythonService.postHeadline(bodyHeadline)

    if (headline?.data.status === 201) {
      setInputText(headline?.data?.data[0]?.headline)
      setLoading(false)
    } else {
      setLoading(false)
      notifyErrorCustom('Houve um erro gerar o headline')
      return
    }
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />

      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('inteligenciaArtificial')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>
          Inteligência Artificial <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Crie elementos e imagens com Inteligência Artificial
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <InputIA
              placeholder={'Digite aqui o que deseja'}
              variant={'light'}
              text={inputText}
              setText={setInputText}
              setLoading={() => {}}
              clickFunction={() => {
                setLoading(true)
                getHeadlineText()
              }}
            />

            <button
              className={styles.button_send}
              onClick={() => {
                setLoading(true)
                handleSearchimage()
              }}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>

      <div className={styles.container_galeyimages}>
        {galeyimages.length > 0 && (
          <Masonry columnsCount={3} gutter="10px">
            {galeyimages.map((imageUrl, i) => (
              <img
                key={i}
                src={'data:image/png;base64,' + imageUrl?.image}
                onClick={() => handleImageClick(imageUrl)}
                className={
                  selectedImages.includes(imageUrl?.image)
                    ? styles.selectedImage
                    : styles.image
                }
                // alt="file-preview"
              />
            ))}
          </Masonry>
        )}
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.contaier_caroucel_list_selected_image}>
          <p>Imagens Adicionadas</p>
          <div className={styles.caroucel_list_selected_image}>
            {selectedArrayImage.map((imageArray) => (
              <div
                className={styles.card_lis_selected_image}
                key={imageArray.id}
              >
                <div className={styles.container_card_lis_selected_image}>
                  <div
                    className={styles.delete_card_lis_selected_image}
                    onClick={() => handleRemoveImage(imageArray)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CircleX
                        color={'#FF2F00'}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  </div>

                  <img
                    className={styles.item_selected_image}
                    src={'data:image/png;base64,' + imageArray.image}
                    // alt={imageArray.title}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            // addImages(selectedArrayImage)
            updateImage(selectedArrayImage)
          }}
        >
          Adicionar Imagens
        </button>
      )}
    </div>
  )
}

export default ModalArtiificialInteligency
