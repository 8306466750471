import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const postUserAccounts = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  return await axios
    .post(`${BASE_URL}user-accounts`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postUserAccounts', error.response.data)
      return error
    })
}

export const getUserAccountsCheckCNPJExists = async (cnpj) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  return await axios
    .get(`${BASE_URL}user-account-check-cnpj-exists/${cnpj}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getUserAccountsCheckCNPJExists', error.response.data)
      return error
    })
}

export const getUserAccountsByLogin = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  console.log('STORAGE_TOKEN', STORAGE_TOKEN)

  return await axios
    .get(`${BASE_URL}user-account-by-login`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error getUserAccountsByLogin', error.response.data)
      return error
    })
}

export const deleteUserAccountsImage = async (imageName) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}user-accounts-image/${imageName}`, config)
    .then((response) => {
      return {
        dataImageDelete: response.data,
        responseStatusImage: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error deleteUserAccountsByLogin', error.response.data)
      return error
    })
}

export const postAccountsImage = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}user-accounts-image`, body, config)
    .then((response) => {
      return {
        dataImage: response.data,
        responseImageStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postAccountsImage', error.response.data)
      return error
    })
}

export const getUserAccountsParameterizationCompleted = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}user-account-by-login-steps-parameterization`, config)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log(
        'error getUserAccountsParameterizationCompleted',
        error.response.data
      )
      return error
    })
}

export const getParameterizationCompletedByUserAccount = async (
  userAccountId
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}steps-parameterization-by-user-account/${userAccountId}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log(
        'error getUserAccountsParameterizationCompleted',
        error.response.data
      )
      return error
    })
}

export const putUserAccountsByLogin = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  let config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  return await axios
    .put(`${BASE_URL}user-accounts-by-login`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postUserAccounts', error.response.data)
      return error
    })
}

export const getUserAccountByUser = async (user) => {
  return await axios
    .get(`${BASE_URL}user-account-by-user/${user}`)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getUserAccountByUser', error.response.data)
      return error
    })
}
