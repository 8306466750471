import styles from './styles.module.css'
import { InterrogationCircleIcon } from '../../assets/svgs/icons'
import { useState, useEffect } from 'react'
import { ProductService, PythonService } from '../../services'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'
import { CustomSelect } from '../../components/Select/CustomSelect'
import { formatPrice } from '../../utils/format'
import { func } from 'prop-types'
import { DragUploadRemoveBackgroundContainer } from '../../components/DragUploadRemoveBackgroundContainer/index.jsx'
import { notifyErrorCustom } from '../../utils/notify'
import * as yup from 'yup'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepProduct({ setStep, clientData }) {
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productData, setProductData] = useState({})

  const [selectedProductId, setSelectedProductId] = useState(undefined)
  const [imagesArray, setImagesArray] = useState([])

  // const [dataToCompare, setDataToCompare] = useState({})

  // const dataScheema = yup.object().shape({
  //   name: yup.string().required('preencha o campo nome'),
  //   description: yup.string().required('preencha o campo descrição'),
  //   // value:yup.number().required('preencha o preço do produto'),
  //   productTypeId: yup.number().required('selecione o tipo de produto'),
  //   // promotionalValue:yup.number().required('preencha o campo de preço promocional')
  // })

  // const validateFields = async () => {
  //   return await dataScheema
  //     .validate(productData)
  //     .then(() => {
  //       return true
  //     })
  //     .catch((error) => {
  //       notifyErrorCustom(error.message)
  //       return false
  //     })
  // }

  useEffect(() => {
    loadProductServiceData()
    loadProductData()
    loadProductImages()
  }, [])

  useEffect(() => {
    loadProductImages(selectedProductId)
  }, [selectedProductId])

  const loadProductImages = async (productId) => {
    const { data, responseStatus } = await ProductService.getProductImage64(
      productId
    )

    if (responseStatus === 200 && data.length > 0) {
      setImagesArray(
        data.map((file) => ({
          uuid: file.uuid,
          fileName: file.fileName,
          fileFormat: file.fileFormat,
          fileContentType: file.fileContentType,
          fileBase64: file.fileBase64,
          fileUrl: file.fileUrl,
          status: 'default',
          isOriginal: true,
        }))
      )
    }
  }
  const loadProductData = async () => {
    if (clientData?.uuid) {
      const { responseStatus, data } =
        await ProductService.getProductByBusinessClientId(clientData?.uuid)

      if (responseStatus === 200) {
        if (data.length === 0) return

        setProductData(data[0])
        // setDataToCompare(data[0])

        if (data[0]?.uuid) {
          setSelectedProductId(data[0]?.uuid)
        }
      }
    } else {
      const { responseStatus, data } = await ProductService.getProduct()

      if (responseStatus === 200) {
        if (data.length === 0) return

        setProductData(data[0])
        // setDataToCompare(data[0])

        if (data[0]?.uuid) {
          setSelectedProductId(data[0]?.uuid)
        }
      }
    }
  }

  const loadProductServiceData = async () => {
    const { data, responseStatus } = await ProductService.getAllProductType()
    if (responseStatus === 200)
      setProductTypeOptions(
        data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
  }

  const handleChange = (id, value) => {
    if (id === 'value') {
      value = formatPrice(value)
      setProductData((prev) => ({ ...prev, [id]: value }))
    }

    if (id === 'promotionalValue') {
      value = formatPrice(value)
      setProductData((prev) => ({ ...prev, [id]: value }))
    }

    setProductData((prev) => ({ ...prev, [id]: value }))
  }

  const dataScheema = yup.object().shape({
    name: yup.string().required('O campo name do produto é obrigatorio'),
    description: yup
      .string()
      .required('O campo descrição do produto é obrigatorio'),
    productTypeId: yup.number().required('selecione o tipo do produto'),
    value: yup.string().required('O campo valor do produto é obrigatorio'),
  })

  //valide dados com base no scheema
  const validateFields = async (dataToValidate) => {
    return await dataScheema
      .validate(dataToValidate)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })
  }

  const handleSubmit = async () => {
    const isValid = await validateFields(productData)
    if (!isValid) return

    if (!selectedProductId) {
      let body = productData
      let guestId = JSON.parse(
        window.localStorage.getItem('userData')
      ).guestUserId

      if (clientData?.uuid) {
        body = {
          ...productData,
          businessClientId: clientData?.uuid,
        }
      }

      if (guestId) {
        body = {
          ...productData,
          businessClientId: clientData?.uuid,
          guestId: guestId,
        }
      }

      const { data, responseStatus } = await ProductService.postProduct({
        ...body,
        productTypeId: +productData.productTypeId,
      })

      if (responseStatus === 200 && data) {
        imagesArray.map(async (image) => {
          await ProductService.postUploadImageProduct({
            productId: data?.uuid,
            fileName: image.fileName,
            fileFormat: image.fileFormat,
            fileContentType: image.fileContentType,
            fileBase64: image.fileUrl.split('base64,')[1],
          })
        })
      }
    } else {
      let newData = productData

      delete newData.uuid
      delete newData.userAccountId
      delete newData.createdAt
      delete newData.updatedAt
      delete newData.deletedAt
      delete newData.productTypeValue
      delete newData.businessClientId
      if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
        newData.guestId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId
      } else {
        delete newData.guestId
      }

      await ProductService.putProduct(selectedProductId, {
        ...newData,
        productTypeId: +productData.productTypeId,
      })

      imagesArray.map(async (image) => {
        if (image.status === 'deleted' && image.uuid) {
          await ProductService.deleteUploadImageProduct(image.uuid)
        }

        if (image.status !== 'deleted' && !image.uuid) {
          await ProductService.postUploadImageProduct({
            productId: selectedProductId,
            fileName: image.fileName,
            fileFormat: image.fileFormat,
            fileContentType: image.fileContentType,
            fileBase64: image.fileUrl.split('base64,')[1],
          })
        }
      })
    }

    // if (!isObjEqual(data, dataToCompare)) {
    //   await ProductService.putProduct({
    //     ...data,
    //     productTypeId: +data.productTypeId,
    //   })
    // }
    // const isValid = await validateFields()

    // if (!isValid) return
    // if (!dataToCompare && data) {
    // await ProductService.postProductType({
    //   ...data,
    //   productTypeId: +data.productTypeId,
    // })
    // }

    setStep(STEPS.STEP_BRAND_ARCHETYPE)
  }

  // const [selectedTypeProduct, setSelectedTypeProduct] = useState({})

  // const handleChangeSelect = (e) => {
  //   setSelectedTypeProduct(e)
  // }
  const handleRemoveBackground = async (index, image) => {
    let parts

    parts = image?.fileBase64.split(',')

    const { data } = await PythonService.postRemoveBackground({
      image: 'data:image/' + image.fileFormat + ';base64,' + image.fileBase64,
    })

    console.log('253', parts[1])
    const noBgImage = data.data.images

    imagesArray[index].fileUrl = noBgImage[1]?.image
    imagesArray[index].isOriginal = noBgImage[1]?.isOriginal

    setImagesArray([...imagesArray])
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Adicione o seu primeiro produto</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        A importância de um produto no marketing reside em atender às
        necessidades e desejos do público-alvo, agregando valor e proporcionando
        uma vantagem competitiva.
      </p>

      <div className={styles.step_data_container}>
        <div
          className={styles.input_field}
          // onSubmit={handleSubmit}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              Nome do Produto
            </p>
            <input
              className={styles.input}
              type="text"
              id="name"
              placeholder="Digite aqui o nome deste produto"
              value={productData.name}
              onChange={({ target: { id, value } }) => handleChange(id, value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              Tipo de produto
            </p>

            <CustomSelect
              placeholder={'Selecione'}
              arrowDirection="down"
              options={productTypeOptions}
              defaultSelected={productTypeOptions.find(
                (e) => e.value === productData.productTypeId
              )}
              fontSize={12}
              onClickItem={({ value }) => handleChange('productTypeId', value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              Descrição do produto
            </p>
            <textarea
              id="description"
              style={{ height: 150 }}
              className={styles.input}
              placeholder="Digite"
              value={productData.description}
              onChange={({ target: { id, value } }) => handleChange(id, value)}
            ></textarea>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <p className={styles.title_text}>Valores</p>
          </div>

          <div>
            <div style={{ display: 'flex', gap: 30 }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
                  Preço do produto
                </p>
                <input
                  id="value"
                  className={styles.input}
                  type="text"
                  placeholder="R$0,00"
                  defaultValue={productData.value}
                  value={productData.value}
                  onChange={({ target: { id, value } }) =>
                    handleChange(id, value)
                  }
                />
              </div>

              <div
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
                  Preço promocional
                </p>
                <input
                  className={styles.input}
                  type="text"
                  id="promotionalValue"
                  placeholder="R$0,00"
                  defaultValue={productData.promotionalValue}
                  value={productData.promotionalValue}
                  onChange={({ target: { id, value } }) =>
                    handleChange(id, value)
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.container_image_products}>
            <div className={styles.box_title_upload}>
              <p className={styles.title_text}>Adicione as fotos do produto</p>
              <InterrogationCircleIcon color={'#e6e6e6'} />
            </div>
            <DragUploadRemoveBackgroundContainer
              imagesArray={imagesArray}
              setImagesArray={setImagesArray}
              onClickRemoveBackground={handleRemoveBackground}
              onClickButton={(images) => setImagesArray(images)}
              textButton={'Adicionar imagem ao produto'}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_TARGET_AUDIENCE)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleSubmit()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepProduct

StepProduct.propTypes = {
  setStep: func,
}
