// import Carousel from '../../components/Carousel'
import styles from './styles.module.css'

import Efect01 from '../../../../assets/imgs/Effect01.png'
import Efect02 from '../../../../assets/imgs/Effect02.png'
import Efect03 from '../../../../assets/imgs/Effect03.png'
// import Efect04 from '../../../../assets/imgs/Effect04.png'

import { InterrogationCircleIcon } from '../../../../assets/svgs/icons'
import { ButtonNavigateStep } from '../../ButtonNavigateStep'
// import { useEffect } from 'react'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

const CAROUSEL_PROPS = {
  CLICK: 'click',
  SCROLL: 'scroll',
}

// eslint-disable-next-line react/prop-types
function StepEffects({ setStep }) {
  const items = [
    { id: 1, content: Efect01 },
    { id: 2, content: Efect02 },
    { id: 3, content: Efect03 },
    { id: 4, content: Efect03 },
    { id: 5, content: Efect01 },
    // Adicione mais itens, se necessário
  ]

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Efeitos</p>
        <InterrogationCircleIcon width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        Esta é uma etapa opcional onde você pode escolher efeitos de sua
        preferência
      </p>

      <h3
        style={{
          marginBottom: 20,
          marginTop: 40,
          color: '#0f0f0f',
          fontSize: 16,
        }}
      >
        Efeitos de desfoque
      </h3>
      <div className={styles.carousel}>
        {/* <Carousel items={items} type={CAROUSEL_PROPS.SCROLL} /> */}

        <h3
          style={{
            marginBottom: 20,
            marginTop: 40,
            color: '#0f0f0f',
            fontSize: 16,
          }}
        >
          Efeitos de Iluminação
        </h3>
        {/* <Carousel items={items} type={CAROUSEL_PROPS.SCROLL} /> */}

        <h3
          style={{
            marginBottom: 20,
            marginTop: 40,
            color: '#0f0f0f',
            fontSize: 16,
          }}
        >
          Efeitos epeciais
        </h3>
        {/* <Carousel items={items} type={CAROUSEL_PROPS.SCROLL} /> */}

        <h3
          style={{
            marginBottom: 20,
            marginTop: 40,
            color: '#0f0f0f',
            fontSize: 16,
          }}
        >
          Texturas
        </h3>
        {/* <Carousel items={items} type={CAROUSEL_PROPS.SCROLL} /> */}
      </div>
      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_VOICE_TONE)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_BRAND_ARCHETYPE)}
          variant={'next'}
        >
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepEffects
