import { HexColorPicker } from 'react-colorful'
import {
  Cross,
  More,
  NavigateArrowLeft,
  NavigateArrowRigth2,
  PasteIcon,
  Pen2,
  Plus,
  Trash,
} from '../../../../assets/svgs/icons'
import { useRef, useState } from 'react'
import styles from './styles.module.css'
import ModalComponent from '../../../../components/ModalComponent'
import { notifyErrorCustom } from '../../../../utils/notify'

// function ColorPaletteComponent({}) {

const ColorPaletteComponent = ({
  fieldName,
  fieldDescription,
  color,
  setColor,
  left,
  right,
  order,
  colors,
  setColors,
}) => {
  const [primaryColor, setPrimaryColor] = useState(undefined)
  const [secondaryColor, setSecondaryColor] = useState(undefined)
  const [tertiaryColor, setTertiaryColor] = useState(undefined)
  const [complementaryColor1, setComplementaryColor1] = useState(undefined)
  const [complementaryColor2, setComplementaryColor2] = useState(undefined)
  const [colorOrderEnabled, setColorOrderEnabled] = useState(undefined)

  const [isModalOpen, setModalOpen] = useState(false)

  const temColorRef = useRef(null)

  const [selectedColors, setSelectedColors] = useState({
    primary: '1',
    secundary: '2',
  })

  const [indexToRemove, setIndexToRemove] = useState(null)
  const [colorIndex, setColorIndex] = useState('')

  const handleColor = () => {
    console.log('temColorRef', temColorRef)
    if (temColorRef?.current?.order === 1)
      setPrimaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 2)
      setSecondaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 3)
      setTertiaryColor(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 4)
      setComplementaryColor1(temColorRef?.current?.color)
    if (temColorRef?.current?.order === 5)
      setComplementaryColor2(temColorRef?.current?.color)
  }

  //const hadleSelectColor = () => {}

  const hadleSelectColor = () => {
    setColors((prevColors) => {
      console.log('entrou aqui 1', prevColors)

      // Verifica se o array já tem 5 cores
      if (prevColors.length >= 5) {
        console.log('entrou aqui 2')

        return prevColors // Retorna o array sem modificá-lo
      }

      // Verifica se a última posição do array está vazia
      if (!prevColors[prevColors.length - 1] && prevColors.length != 0) {
        console.log('entrou aqui 3')
        return prevColors // Retorna o array sem modificá-lo
      }

      // Adiciona a nova cor ao final do array se ainda não tiver 5 cores
      return [...prevColors, '']
    })
  }
  console.log('colors', colors)

  const hadleChangeColor = () => {
    // console.log('type', indexToRemove)
    // if (indexToRemove != null) {
    console.log('90', indexToRemove)
    //   notifyErrorCustom('O campo de CPF não foi preenchido com um valor válido')
    //   return
    // }
    for (let index = 0; index < colors.length; index++) {
      //const element = array[index];

      if (colors[index] === '') {
        notifyErrorCustom('Um campo ainda está em branco')
        return
      }
    }
    setColors((prevColors) => {
      // Verifica se o indexToRemove é válido
      if (indexToRemove < 0 || indexToRemove >= prevColors.length) {
        return prevColors // Retorna o array sem modificá-lo se o índice for inválido
      }

      // Cria um novo array substituindo o valor na posição indexToRemove
      return prevColors.map((color, index) =>
        index === indexToRemove ? '' : color
      )
    })
    setModalOpen(false)
  }

  const addColor = (type, tempColor) => {
    if (indexToRemove != null) {
      console.log('indexToRemove 105', indexToRemove)
      setColors((prevColors) => {
        return prevColors.map((color, index) =>
          index === indexToRemove ? tempColor : color
        )
      })
      setIndexToRemove(null)
    } else {
      console.log('indexToRemove 113', indexToRemove)

      setColors((prevColors) => {
        // Verifica se a última posição do array está vazia
        if (!prevColors[prevColors.length - 1]) {
          // Cria um novo array com a última posição substituída por `tempColor`
          return [...prevColors.slice(0, prevColors.length - 1), tempColor]
        }

        // Se a última posição não estiver vazia, não faz nada
        return prevColors
      })
    }
  }

  let verifyEnablePickColor = false
  if (order === colorOrderEnabled) verifyEnablePickColor = true
  const [enablePickColor, setEnablePickColor] = useState(verifyEnablePickColor)
  const [tempColor, setTempColor] = useState(color)

  const [isHovered, setIsHovered] = useState(false)

  const [visibleTextCopy, setVisibleTextCopy] = useState(false)

  let borderRadius = undefined
  if (left) borderRadius = '10px 0 0 10px'
  if (right) borderRadius = '0 10px 10px 0'

  function isValidHexColor(hex) {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)
  }

  function hexToRgb(hex) {
    let bigint = parseInt(hex.substring(1), 16)
    let r = (bigint >> 16) & 255
    let g = (bigint >> 8) & 255
    let b = bigint & 255

    return [r, g, b]
  }

  function getLuminance(r, g, b) {
    return (0.299 * r + 0.587 * g + 0.114 * b) / 255
  }

  function getTextColor(hexColor) {
    const [r, g, b] = hexToRgb(hexColor)
    return getLuminance(r, g, b) > 0.5 ? 'black' : 'white'
  }

  const MoveColorRight = (selectedItem) => {
    if (selectedItem === 1) {
      setPrimaryColor(secondaryColor)
      setSecondaryColor(primaryColor)
    }
    if (selectedItem === 2) {
      setSecondaryColor(tertiaryColor)
      setTertiaryColor(secondaryColor)
    }
    if (selectedItem === 3) {
      setTertiaryColor(complementaryColor1)
      setComplementaryColor1(tertiaryColor)
    }
    if (selectedItem === 4) {
      setComplementaryColor1(complementaryColor2)
      setComplementaryColor2(complementaryColor1)
    }
  }

  const MoveColorLeft = (selectedItem) => {
    if (selectedItem === 2) {
      setPrimaryColor(secondaryColor)
      setSecondaryColor(primaryColor)
    }
    if (selectedItem === 3) {
      setSecondaryColor(tertiaryColor)
      setTertiaryColor(secondaryColor)
    }
    if (selectedItem === 4) {
      setTertiaryColor(complementaryColor1)
      setComplementaryColor1(tertiaryColor)
    }
    if (selectedItem === 5) {
      setComplementaryColor1(complementaryColor2)
      setComplementaryColor2(complementaryColor1)
    }
  }

  const textCopy = (tempColor) => {
    navigator.clipboard
      .writeText(tempColor)
      .then(() => {
        // Mostrar mensagem
        setVisibleTextCopy(true)

        // Esconder mensagem após 2 segundos
        setTimeout(() => {
          setVisibleTextCopy(false)
        }, 1000)
      })
      .catch((err) => {
        console.error('Erro ao copiar texto: ', err)
      })
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    //setIndexColor('')
  }

  const handleOpenModal = (color, index) => {
    setModalOpen(true)
    if (indexToRemove === null) {
      setIndexToRemove(index)
    }
    setColorIndex(color)
  }
  const handleDeleteColor = () => {
    setColors((prevColors) => {
      // Verifica se o índice está dentro do intervalo do array
      if (indexToRemove < 0 || indexToRemove >= prevColors.length) {
        return prevColors // Retorna o array sem modificá-lo se o índice for inválido
      }

      // Cria um novo array sem o item na posição indicada
      return [
        ...prevColors.slice(0, indexToRemove), // Elementos antes do índice
        ...prevColors.slice(indexToRemove + 1), // Elementos após o índice
      ]
    })
    setIndexToRemove(null)
    setModalOpen(false)
  }

  if (!enablePickColor && color)
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          //alignItems: 'center',
          justifyContent: 'space-between',
          background: tempColor,
          borderRadius: borderRadius,
        }}
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              color: getTextColor(tempColor ? tempColor : '#fff'),
              fontSize: 10,
              marginTop: 10,
              fontWeight: isHovered ? 700 : 300,
            }}
          >
            {fieldName}
          </p>
          {isHovered && (
            <p
              style={{
                color: getTextColor(tempColor ? tempColor : '#fff'),
                fontSize: 10,
                textAlign: 'center',
              }}
            >
              {fieldDescription}
            </p>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isHovered && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                marginBottom: 8,
              }}
              onClick={() => {
                setColor(undefined)
                setColorOrderEnabled(undefined)
              }}
            >
              <Trash
                color={getTextColor(tempColor ? tempColor : '#fff')}
                width={18}
                height={18}
              />
            </div>
          )}

          <div style={{ display: 'flex', gap: 14 }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                MoveColorLeft(order)
              }}
            >
              <NavigateArrowLeft
                color={getTextColor(tempColor ? tempColor : '#fff')}
                width={12}
                height={12}
              />
            </div>

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                MoveColorRight(order)
              }}
            >
              <NavigateArrowRigth2
                color={getTextColor(tempColor ? tempColor : '#fff')}
                width={12}
                height={12}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <p
              style={{
                color: getTextColor(tempColor ? tempColor : '#fff'),
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {color}
            </p>

            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleColor()
                setColorOrderEnabled(order)
              }}
            >
              <Pen2
                width={12}
                height={12}
                color={getTextColor(tempColor ? tempColor : '#fff')}
              />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className={styles.container_box_color_palette_component}>
      <div className={styles.box_color_palette_component}>
        <div
          style={{
            height: '100%',
            borderRadius: borderRadius,
            //background: tempColor,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  color: getTextColor(tempColor ? tempColor : '#fff'),
                  fontSize: 10,
                  marginTop: 10,
                }}
              >
                {fieldName}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              {enablePickColor && (
                <>
                  <div
                    style={{
                      padding: '0px 14px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 8,
                        color: getTextColor(tempColor ? tempColor : '#fff'),
                        fontWeight: 700,
                      }}
                    >
                      Selecione sua cor
                    </p>

                    <div
                      style={{
                        cursor: 'pointer',
                        paddingBottom: 4,
                      }}
                      onClick={() => {
                        if (isValidHexColor(tempColor)) {
                          setColor(tempColor)
                          setEnablePickColor((current) => !current)
                          setColorOrderEnabled(undefined)
                        }

                        setEnablePickColor((current) => !current)
                        setColorOrderEnabled(undefined)
                      }}
                    >
                      <Cross
                        color={getTextColor(tempColor ? tempColor : '#fff')}
                        width={6}
                        height={6}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', flex: 1 }}>
                    <div className="color_picker">
                      <HexColorPicker
                        className={styles.box_color_picker}
                        color={tempColor ? tempColor : '#FFF'}
                        onChange={(event) => {
                          setTempColor(event)
                          temColorRef.current = { color: event, order }
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      padding: '0px 14px',
                      marginTop: 4,
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 8,
                        color: getTextColor(tempColor ? tempColor : '#fff'),
                        fontWeight: 700,
                      }}
                    >
                      Hex
                    </p>

                    <div
                      style={{
                        height: 10,
                        width: 10,
                        background: tempColor ? tempColor : '#FFF',
                        borderRadius: 1,
                      }}
                    />

                    <p
                      style={{
                        margin: 0,
                        fontSize: 8,
                        color: getTextColor(tempColor ? tempColor : '#fff'),
                      }}
                    >
                      {tempColor ? tempColor : '#FFF'}
                    </p>

                    <div
                      style={{
                        border: `1px solid ${getTextColor(
                          tempColor ? tempColor : '#fff'
                        )}`,
                        height: 14,
                        width: 14,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        textCopy(tempColor)
                      }}
                    >
                      <PasteIcon
                        color={getTextColor(tempColor ? tempColor : '#fff')}
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                </>
              )}

              {visibleTextCopy && (
                <div style={{ height: 0 }}>
                  <p
                    style={{
                      fontSize: 12,
                      color: getTextColor(tempColor ? tempColor : '#fff'),
                      margin: 0,
                      textAlign: 'center',
                    }}
                  >
                    Copiado!
                  </p>
                </div>
              )}

              {enablePickColor && (
                <div
                  style={{
                    display: 'flex',
                    marginTop: 24,
                    marginBottom: 20,
                    justifyContent: 'center',
                  }}
                >
                  <input
                    style={{
                      background: tempColor ? tempColor : '#190027',
                      color: getTextColor(tempColor ? tempColor : '#fff'),
                      border: `1px solid ${getTextColor(
                        tempColor ? tempColor : '#fff'
                      )}`,
                      borderRadius: 2,
                      width: 80,
                      fontSize: 14,
                      fontWeight: 600,
                      textAlign: 'center',
                    }}
                    value={tempColor ? tempColor : '#'}
                    onChange={(event) => {
                      if (event.target.value === '') return
                      return setTempColor(event.target.value)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        if (isValidHexColor(tempColor)) {
                          setColor(tempColor)
                          setEnablePickColor((current) => !current)
                          setColorOrderEnabled(undefined)
                        }

                        setEnablePickColor((current) => !current)
                        setColorOrderEnabled(undefined)
                      }
                    }}
                  />

                  <div
                    style={{
                      border: `1px solid ${getTextColor(
                        tempColor ? tempColor : '#fff'
                      )}`,
                      height: 50,
                      width: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 2,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      textCopy(tempColor)
                    }}
                  >
                    <PasteIcon
                      color={getTextColor(tempColor ? tempColor : '#fff')}
                      width={48}
                      height={48}
                    />
                  </div>

                  <div>
                    <button onClick={() => addColor('post', tempColor)}>
                      Aplicar
                    </button>
                  </div>
                </div>
              )}

              {/* {!enablePickColor && !color && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                > */}
              {/* <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 44,
                      height: 44,
                      borderRadius: 8,
                      background: '#470063',
                      cursor: 'pointer',
                      marginBottom: 30,
                    }}
                    onClick={() => {
                      temColorRef.current = undefined
                      handleColor()
                      setColorOrderEnabled(order)
                    }}
                  >
                    <More />
                  </div> */}
              {/* </div> */}
              {/* )} */}
            </div>
          </div>
        </div>
      </div>

      {
        //console.log('selectedColors', selectedColors)
        colors?.map((color, index) => (
          <div
            key={index}
            className={styles.circle_plus}
            style={{ background: color }}
            onClick={() => handleOpenModal(color, index)}
          ></div>
        ))
      }

      <ModalComponent
        isOpen={isModalOpen}
        onClose={() => handleCloseModal('uploads')}
        className={styles.teste}
      >
        <div>
          <p>Selecione uma das opções abaixo</p>

          <div onClick={() => hadleChangeColor()}>editar cor selecionada</div>

          <div onClick={() => handleDeleteColor()}>excluir cor selecionada</div>
        </div>
      </ModalComponent>

      {colors.length < 5 && (
        <div
          className={styles.circle_plus}
          onClick={() => {
            temColorRef.current = undefined
            handleColor()
            hadleSelectColor()
            setColorOrderEnabled(order)
          }}
        >
          <Plus />
        </div>
      )}
    </div>
  )
}
// }

export default ColorPaletteComponent
