/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import {
  CircleX,
  Cross,
  Interrogation,
  // ArrowDown,
} from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import {
  BusinessService,
  MyUploadsService,
  ProductService,
} from '../../../../services'
import { Loading } from '../../../../components/Loading'

import {
  notifyErrorCustom,
  // notifySuccessCustom,
} from '../../../../utils/notify'

import { useClient } from '../../../../hooks/useClient'
import { getBusinessByClientId } from '../../../../services/business'
import { Select } from 'antd'
import { SelectSquare } from '../../../../components/Select/SelectSquare/SelectSquare'

const ModalProducts = ({
  setImageProductsArray,
  closeModal,
  simpleEditModal,
  setImageLayers,
  imageLayers,
}) => {
  const { clientData } = useClient()

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const [images, setImages] = useState([])
  const [products, setProducts] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    //let businessId

    if (clientData?.uuid) {
      // const { data } = await getBusinessByClientId(clientData?.uuid)

      // businessId = data[0]?.uuid

      const response = await ProductService.getProductByBusinessClientId(
        clientData?.uuid
      )

      if (response.responseStatus === 200) {
        const arrayProducts = response.data

        setProducts(arrayProducts)
      } else {
        notifyErrorCustom('Ocorreu um erro ao carregar as imagens')
      }
    } else {
      const response = await ProductService.getProductByNotBusinessClient()
      if (response.responseStatus === 200) {
        const arrayProducts = response.data

        setProducts(
          // { description: 'Escolha uma opção', value: null },
          arrayProducts
        )
      } else {
        notifyErrorCustom('Ocorreu um erro ao carregar as imagens')
      }
    }
    setLoading(false)
  }

  const handleChange = async (value) => {
    const response = await ProductService.getProductImage64ByProductId(value)

    setImages(response.data)
  }

  const handleImageClick = (imageUrl) => {
    const imageArray = images.find(
      (image) => image.fileBase64 === imageUrl.fileBase64
    )

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.fileBase64)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.fileBase64)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.fileBase64])
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.fileBase64)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.fileBase64)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.fileBase64])
    }
  }

  const addImages = async () => {
    if (simpleEditModal) {
      var imageLayersTemp = { ...imageLayers }
      const newSelectedArrayImage = selectedArrayImage.map((image) => {
        const lewLayer = {
          imageId: image.fileBase64,
          isOriginal: true,
          order: 1,
          position: [],
          sequenceEffects: [],
          type: 'image-upload',
        }

        imageLayersTemp.layer[0] = lewLayer
      })

      // return
      setImageLayers(imageLayersTemp)
    } else {
      let newSelectedArrayImage = selectedArrayImage.map((image) => {
        return {
          id: image.productId,
          image: image.fileUrl,
          isOriginal: true,
          fileDataBase64: `data:image/png;base64,${image.fileBase64}`,
          name: image.fileName,
          type: image.fileContentType,
          isBackgroundRemoved: false,
        }
      })

      setImageProductsArray(newSelectedArrayImage)
    }

    closeModal('produtos')
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />
      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('produtos')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>
          Selecione um de seus produtos <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Escolha as imagens para gerar sua publicação ou carrossel!
        </p>
      </div>
      <select
        className={styles.modal_select}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option className={styles.modal_option} value={null}>
          {'Selecione um produto'}
        </option>
        {products.map((product) => (
          <option
            key={product.uuid}
            className={styles.modal_option}
            value={product.uuid}
          >
            {product.name}
          </option>
        ))}
      </select>

      <div className={styles.container_galeyimages}>
        <Masonry columnsCount={3} gutter="10px">
          {images.map((imageUrl, i) => (
            <img
              key={i}
              //  src={'data:image/png;base64,' + imageUrl?.fileBase64}
              src={imageUrl.fileUrl}
              onClick={() => handleImageClick(imageUrl)}
              className={
                selectedImages.includes(imageUrl.fileBase64)
                  ? styles.selectedImage
                  : styles.image
              }
            />
          ))}
        </Masonry>
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.contaier_caroucel_list_selected_image}>
          <p>Imagens Adicionadas</p>
          <div className={styles.caroucel_list_selected_image}>
            {selectedArrayImage.map((imageArray) => (
              <div
                className={styles.card_lis_selected_image}
                key={imageArray.id}
              >
                <div className={styles.container_card_lis_selected_image}>
                  <div
                    className={styles.delete_card_lis_selected_image}
                    onClick={() => handleRemoveImage(imageArray)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CircleX
                        color={'#FF2F00'}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  </div>
                  <img
                    className={styles.item_selected_image}
                    src={imageArray.fileUrl}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            addImages()
          }}
        >
          Adicionar Imagens
        </button>
      )}
    </div>
  )
}

export default ModalProducts
