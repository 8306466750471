import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import { CheckBadge, LogOut } from '../../../../assets/svgs/icons'
import { useNavigate } from 'react-router-dom'

import { UserAccountsService } from '../../../../services'
import { useAuth } from '../../../../hooks/useAuth'

export const LogOutStep = ({ setStep }) => {
  const { logout } = useAuth()
  const [settingsCompleted, setSettingsCompleted] = useState({
    parameterizationCompleted: false,
  })

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const { data, status } =
      await UserAccountsService.getUserAccountsParameterizationCompleted()

    if (status === 200) {
      setSettingsCompleted(data)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      <div className={styles.container_log_out}>
        <div className={styles.box_parameterization_content}>
          <div className={styles.log_out_module}>
            <div className={styles.module}>
              <h3 className={styles.module_title}>Sair</h3>
            </div>
          </div>
          <div className={styles.container_log_out_content}>
            <LogOut color={'#ff2f00'} width="64" height="64" />
            <p className={styles.title_logout_page}>Deseja realmente sair?</p>
            <div className={styles.description_text}>
              <p className={styles.content_text}>
                Para retornar você deverá efetuar o login novamente!
              </p>
            </div>

            <div className={styles.box_buttons}>
              <button
                className={styles.button_log_out}
                onClick={() => logout()}
              >
                <LogOut width="30" height="30" color={'#ff2f00'} /> Sair
              </button>
              <button
                className={styles.button_cancel}
                onClick={() => setStep(0)}
              >
                Cancelar e voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
