/* eslint-disable react/prop-types */
import { CheckBadgeCircle } from '../../assets/svgs/icons'
import style from './styles.module.css'

const Stepper = ({
  size,
  completedStepParametrization,
  completedStepAddClients,
  completedStepAddTeamMembers,
  completedStepCreateCampaign,
  completedStepAddNewPost,
}) => {
  return (
    <div style={{}}>
      <div className={style.container_stepper}>
        <div className={style.box_stepper}>
          <div className={style.box_checked}>
            <div className={style.fixing_bar}></div>
            {completedStepParametrization ? (
              <div className={style.validator_stepper}>
                <CheckBadgeCircle size={size} />
              </div>
            ) : (
              <div className={style.validator_stepper_off}>
                <p className={style.number_of_steppe}>{'1'}</p>
              </div>
            )}
          </div>
          <div className={style.box_title_steppe}>
            <p className={style.title_steppe}>{'Parametrizações'}</p>
            <p className={style.label_steppe}>
              {'Conclua a configurações de parametrização'}
            </p>
          </div>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddClients ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'2'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>{'Adicione Clientes'}</p>
          <p className={style.label_steppe}>
            {'Adicione clientes à sua conta'}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddTeamMembers ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'3'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>{'Membros da Equipe'}</p>
          <p className={style.label_steppe}>
            {'Adicione os membros da sua conta'}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepCreateCampaign ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'4'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>{'Crie uma Campanha'}</p>
          <p className={style.label_steppe}>
            {'Conclua o cadastro de campanhas'}
          </p>
        </div>
      </div>

      <div className={style.box_stepper} style={{ marginTop: 16 }}>
        <div className={style.box_checked}>
          <div className={style.fixing_bar}></div>
          {completedStepAddNewPost ? (
            <div className={style.validator_stepper}>
              <CheckBadgeCircle size={size} />
            </div>
          ) : (
            <div className={style.validator_stepper_off}>
              <p className={style.number_of_steppe}>{'5'}</p>
            </div>
          )}
        </div>
        <div className={style.box_title_steppe}>
          <p className={style.title_steppe}>{'Adicione uma nova publicação'}</p>
          <p className={style.label_steppe}>
            {'Adicione uma nova publicação à conta do seu cliente'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Stepper
