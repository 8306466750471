import {
  CircleLike,
  Comment,
  Like,
  Pause,
  Share,
} from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { object } from 'prop-types'

export const LayoutFacebookStories = ({ image,userData }) => {
  return (
    <div className={styles.box_image_render}>
      <div className={styles.body_image_render}>
        <div>
          <img src={image} className={styles.image_render} />
        </div>
        <div className={styles.teste}>
          <div className={styles.header_example}>
            <div className={styles.progress_bar_example}></div>
            <div className={styles.box_company_info}>
              <div className={styles.container_header}>
                <div className={styles.box_logo_company}>
                  <div>
                    <img
                      className={styles.image_company}
                      src={userData?.photoUrl}
                      alt=""
                    />
                  </div>
                  <div className={styles.box_name_company}>
                    <p className={styles.name_company}>Empresa teste</p>
                  </div>
                </div>
                <div className={styles.box_time_publication_example}>
                  <p className={styles.minute_example}>1min</p>

                  <Pause />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer_image_render}>
        <div className={styles.input_example}>
          <p>Responder a Empresa Teste </p>
        </div>
        <CircleLike />
      </div>
    </div>
  )
}

LayoutFacebookStories.propTypes = {
  image: object,
}
