import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
// import { ArrowDown, CloundUp, NumberOne } from '../../assets/svgs/icons'
import { useState, useEffect } from 'react'

import TargetAudienceList from './TargetAudienceList'
import TargetAudienceView from './TargetAudienceViewStep'

import { useClient } from '../../hooks/useClient'

const STEPS = {
  STEP_TARGET_AUDIENCE_LIST: 'targetAudienceList',
  STEP_TARGET_AUDIENCE_VIEW: 'targetAudienceView',
}

function TargetAudience() {
  const { clientData } = useClient()

  const [step, setStep] = useState(STEPS.STEP_TARGET_AUDIENCE_LIST)

  const [targetAudienceItem, setTargetAudienceItem] = useState({})

  useEffect(() => {
    setStep(STEPS.STEP_TARGET_AUDIENCE_LIST)
    setTargetAudienceItem({})
  }, [clientData])

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_TARGET_AUDIENCE_LIST && (
        <TargetAudienceList
          setStep={setStep}
          setTargetAudienceItem={setTargetAudienceItem}
          clientData={clientData}
        />
      )}

      {step === STEPS.STEP_TARGET_AUDIENCE_VIEW && (
        <TargetAudienceView
          setStep={setStep}
          state={targetAudienceItem}
          setTargetAudienceItem={setTargetAudienceItem}
        />
      )}
    </div>
  )
}

export default TargetAudience
