import { Magic, Picture, Pen2, CheckBadge, Engine } from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import Proptypes from 'prop-types'
import {motion} from 'framer-motion'
import { useState } from 'react'

export const SideBar = ({onClickStep}) => {
  const [step, setStep] = useState(1)
  const handleChangeStep = (step) =>{
    setStep(step)
    onClickStep(step)
  }

  return (
    <div className={styles.sideMenu}>
      <div className={styles.topMenuButton}>
        <motion.button transition={{duration:0.3, ease:'easeInOut'}} animate={{backgroundColor:step === 1 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)'}} className={` ${styles.button} ${step == 1 ? styles.selected: styles.default}`} onClick={()=>handleChangeStep(1)}><Pen2/></motion.button>
        <motion.button transition={{duration:0.3, ease:'easeInOut'}} animate={{backgroundColor:step === 2 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)'}} className={` ${styles.button} ${step == 2 ? styles.selected: styles.default}`} onClick={()=>handleChangeStep(2)}><Picture/></motion.button>
        <motion.button transition={{duration:0.3, ease:'easeInOut'}} animate={{backgroundColor:step === 3 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)'}} className={` ${styles.button} ${step == 3 ? styles.selected: styles.default}`} onClick={()=>handleChangeStep(3)}><Magic/></motion.button>
        <motion.button transition={{duration:0.3, ease:'easeInOut'}} animate={{backgroundColor:step === 4 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)'}} className={` ${styles.button} ${step == 4 ? styles.selected: styles.default}`} onClick={()=>handleChangeStep(4)}><Engine/></motion.button>
      </div>
      <div className={styles.bottomMenuButton}>
        <button className={styles.button}><CheckBadge/></button>
      </div>
    </div>
  )
}

SideBar.propTypes = {
  step:Proptypes.number,
  onClickStep:Proptypes.func
}
