import { Input } from '../../../components'
import styles from './styles.module.css'
import { CloundArrowUp } from '../../../assets/svgs/icons'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { motion } from 'framer-motion'
import { ImageCard } from '../../../components/ImageCard'
import { Trash } from '../../../assets/svgs/icons'
import { object, func, array } from 'prop-types'
import { ErrorMessage } from '../../../components/ErrorMessage'

export const ProductForm = ({
  product,
  imagesArray,
  productOptions,
  onSubmit,
  onExcludeImage,
  onRemoveBackground,
  onChangeImage,
  onChangeProductData,
  error,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container_form}
    >
      <p className={styles.title_new_posts_title}>Novo Produto</p>
      <div className={styles.container_content_form}>
        <div className={styles.box_input_form}>
          <label className={styles.label_input_form}>Nome do Produto</label>
          <Input
            id="name"
            value={product?.name}
            onChange={({ target: { id, value } }) =>
              onChangeProductData(id, value)
            }
          />
          <ErrorMessage message={error.find((e) => e.id === 'name')?.message} />
        </div>

        <div className={styles.box_input_form}>
          <label className={styles.label_input_form}>Tipo de Produto</label>
          <SelectSquare
            placeholder={'Selecione'}
            onClickItem={({ value }) =>
              onChangeProductData('productTypeId', value)
            }
            defaultSelected={productOptions?.find(
              (e) => e.value === product?.productTypeId
            )}
            options={productOptions}
          />
          <ErrorMessage
            message={error.find((e) => e.id === 'productTypeId')?.message}
          />
        </div>
        <div className={styles.box_input_form}>
          <label className={styles.label_input_form}>
            Descrição do produto
          </label>
          <textarea
            id="description"
            value={product?.description}
            onChange={({ target: { id, value } }) =>
              onChangeProductData(id, value)
            }
            className={styles.textarea_input_form}
            placeholder="Digite aqui a descrição do produto"
          />
          <ErrorMessage
            message={error.find((e) => e.id === 'description')?.message}
          />
        </div>

        <p className={styles.title_new_posts_title}>Valores</p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '30px',
          }}
        >
          <div className={styles.box_input_form} style={{ width: '100%' }}>
            <label className={styles.label_input_form}>Preço do Produto</label>
            <Input
              id="value"
              value={product?.value}
              onChange={({ target: { id, value } }) =>
                onChangeProductData(id, value)
              }
            />
            <ErrorMessage
              message={error.find((e) => e.id === 'value')?.message}
            />
          </div>
          <div className={styles.box_input_form} style={{ width: '100%' }}>
            <label className={styles.label_input_form}>Preço Promocional</label>
            <Input
              id="promotionalValue"
              value={product?.promotionalValue}
              onChange={({ target: { id, value } }) =>
                onChangeProductData(id, value)
              }
            />
            <ErrorMessage
              message={error.find((e) => e.id === 'promotionalValue')?.message}
            />
          </div>
        </div>

        <p className={styles.title_new_posts_title}>Imagens</p>
        <div className={styles.modal_drag}>
          <div className={styles.modal_drag_drop}>
            <label className={styles.modal_drag_drop_label} htmlFor="arquivo">
              <CloundArrowUp color={'#B900FE'} />
              <h2 className={styles.modal_drag_drop_title}>
                Arraste & Solte o arquivo
              </h2>
              <p className={styles.modal_drag_drop_sub_title}>
                Ou selecione o arquivo no explorador
              </p>
            </label>
            <input
              type="file"
              name="arquivo"
              id="arquivo"
              onChange={onChangeImage}
            ></input>
          </div>
        </div>
        <div className={styles.images_container}>
          {console.log('136 ===> imagesArray', imagesArray)}
          {imagesArray.map(
            (e, index) =>
              e.status !== 'deleted' && (
                <ImageCard
                  key={index}
                  image={e}
                  onClickButton={() => onExcludeImage(index)}
                  onRemoveBackground={() =>
                    onRemoveBackground(
                      index,
                      e.fileBase64
                        ? 'data:' +
                            e.fileContentType +
                            ';base64,' +
                            e.fileBase64
                        : e.fileUrl
                    )
                  }
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '13px',
                      }}
                    >
                      <p
                        style={{
                          color: '#190027',
                          fontSize: '12px',
                          margin: 0,
                        }}
                      >
                        Excluir
                      </p>
                      <Trash color={'#000000'} />
                    </div>
                  </div>
                </ImageCard>
              )
          )}
        </div>
        <div className={styles.divider_topic}></div>

        <div className={styles.box_button_save}>
          <button onClick={onSubmit} className={styles.button_save}>
            Salvar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default ProductForm

ProductForm.propTypes = {
  product: object,
  imagesArray: array,
  productOptions: array,
  error: array,
  onSubmit: func,
  onExcludeImage: func,
  onChangeImage: func,
  onChangeProductData: func,
}
