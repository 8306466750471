// import React from 'react';
import RoutesAll from './routes'
import './App.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <ToastContainer icon={false} autoClose={2000} hideProgressBar />
      <RoutesAll />
    </>
  )
}

export default App
