import styles from './styles.module.css'
import { useState, useRef, useEffect } from 'react'

import NewPostFormSecond from './StepSecondPass'
import StepFirstPass from './StepfirstPass'
import StepImageBank from './StepSecondPass/StepImageBank'
import StepThirdPass from './StepThirdPass'
import StepFourthPass from './StepFourthPass'
import StepFinish from './StepFinish'

import { useLocation } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'

const STEPS = {
  STEP_FIRST_PASS_HEADLINES: 'stepHeadlines',
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_FINISH: 'stepFinish',
}

function NewPostForm() {
  const { clientData } = useClient()

  const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS_HEADLINES)

  const [imageGaleryArray, setImageGaleryArray] = useState([])

  const [imageArray, setImageArray] = useState([])
  const [imageAIArray, setImageAIArray] = useState([])
  const [imageUploadArray, setImageUploadArray] = useState([])
  const [imageProductsArray, setImageProductsArray] = useState([])

  const [imageUrl, setImageUrl] = useState('')

  const [imageLayers, setImageLayers] = useState([])

  const [headlineText, setHeadlineText] = useState('')
  const [legend, setLegend] = useState(false)

  const purposeOfPublicationRef = useRef(undefined)
  const publicationFormatRef = useRef(undefined)
  const productRef = useRef(undefined)
  const targetAudienceRef = useRef(undefined)
  const iWantPublicationRef = useRef(undefined)

  const [iaData, setIaData] = useState([])

  const [publicationDraft, setPublicationDraft] = useState({})
  const [draft, setDraft] = useState(false)
  const [repost, setRepost] = useState(false)
  const [schedule, setSchedule] = useState(false)

  const [headlineList, setHeadlineList] = useState([])

  const [legendList, setLegendList] = useState([])

  console.log('62', publicationDraft)

  useEffect(() => {
    const dataPublication = location.state?.dataPublication
    const dataSchedulePublication = location.state?.dataSchedulePublication

    const draftValue = location.state?.draft ? location.state?.draft : false
    const repostValue = location.state?.repost ? location.state?.repost : false
    const scheduleValue = location.state?.schedule
      ? location.state?.schedule
      : false

    setPublicationDraft(dataPublication)
    setDraft(draftValue)
    setRepost(repostValue)
    setSchedule(scheduleValue)
    // setSketchData()

    if (dataPublication && !dataPublication.images) {
      setHeadlineText(dataPublication?.headline)
      setLegend(dataPublication?.legend)
      setImageUrl(dataPublication?.publicationImageUrl)
      console.log('82', dataPublication)
    } else {
      setHeadlineText(dataPublication?.description)
      setLegend(dataPublication?.copy)
      setImageUrl(dataPublication?.images[0]?.url)
      console.log('87', dataPublication)
    }

    if (draftValue || repostValue || scheduleValue) {
      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    }
  }, [])

  useEffect(() => {
    const dataPublication = location.state?.dataPublication
    if (dataPublication) return

    setStep(STEPS.STEP_FIRST_PASS_HEADLINES)
    // setStep(STEPS.STEP_FINISH)
  }, [clientData])

  return (
    <div className={styles.page}>
      {step === STEPS.STEP_FIRST_PASS_HEADLINES && (
        <StepFirstPass
          setStep={setStep}
          purposeOfPublicationRef={purposeOfPublicationRef}
          publicationFormatRef={publicationFormatRef}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          iWantPublicationRef={iWantPublicationRef}
          clientData={clientData}
        />
      )}

      {step === STEPS.STEP_SECOND_PASS_IMAGE && (
        <NewPostFormSecond
          setStep={setStep}
          imageArray={imageArray}
          setImageArray={setImageArray}
          imageGaleryArray={imageGaleryArray}
          setImageGaleryArray={setImageGaleryArray}
          imageAIArray={imageAIArray}
          setImageAIArray={setImageAIArray}
          imageUploadArray={imageUploadArray}
          setImageUploadArray={setImageUploadArray}
          imageProductsArray={imageProductsArray}
          setImageProductsArray={setImageProductsArray}
          purposeOfPublicationRef={purposeOfPublicationRef}
          publicationFormatRef={publicationFormatRef}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          iWantPublicationRef={iWantPublicationRef}
        />
      )}

      {step === STEPS.STEP_IMAGE_BANK && (
        <StepImageBank setStep={setStep} setImageArray={setImageArray} />
      )}

      {step === STEPS.STEP_THIRD_PASS_PUBLICATION && (
        <StepThirdPass
          setStep={setStep}
          setIaData={setIaData}
          iaData={iaData}
          purposeOfPublicationRef={purposeOfPublicationRef}
          publicationFormatRef={publicationFormatRef}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          iWantPublicationRef={iWantPublicationRef}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          imageLayers={imageLayers}
          setImageLayers={setImageLayers}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          imageGaleryArray={imageGaleryArray}
          imageAIArray={imageAIArray}
          imageUploadArray={imageUploadArray}
          imageProductsArray={imageProductsArray}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
        />
      )}

      {step === STEPS.STEP_FOURTH_PASS_SUBTITLE && (
        <StepFourthPass
          setStep={setStep}
          purposeOfPublicationRef={purposeOfPublicationRef}
          publicationFormatRef={publicationFormatRef}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          iWantPublicationRef={iWantPublicationRef}
          imageUrl={imageUrl}
          imageLayers={imageLayers}
          setImageUrl={setImageUrl}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          legend={legend}
          setLegend={setLegend}
          // dados da tela anterior
          draftData={publicationDraft}
          publicationRet={location.state?.dataPublication}
          draft={draft}
          repost={repost}
          schedule={schedule}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
        />
      )}

      {step === STEPS.STEP_FINISH && (
        <StepFinish
          setStep={setStep}
          //-------
          purposeOfPublicationRef={purposeOfPublicationRef}
          publicationFormatRef={publicationFormatRef}
          productRef={productRef}
          targetAudienceRef={targetAudienceRef}
          iWantPublicationRef={iWantPublicationRef}
          imageUrl={imageUrl}
          imageLayers={imageLayers}
          setImageUrl={setImageUrl}
          headlineText={headlineText}
          setHeadlineText={setHeadlineText}
          legend={legend}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legendList={legendList}
          //dados da tela anterior
          draftModalData={publicationDraft}
        />
      )}
    </div>
  )
}

export default NewPostForm
