/* eslint-disable no-unused-vars */
import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getMyUploadsByPublicationIdBase64 = async (client) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}my-uploads-by-publication-id-base64/${client}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const postMyUploads = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}my-uploads`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

// export const getPublication = async (id) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publications/${id}`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         // window.localStorage.clear()
//         // window.location.reload()
//       }
//       console.log('error getProduct', error.response.data)
//       return error
//     })
// }

// export const postPublication = async (id, body) => {

//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .post(`${BASE_URL}publications/${id}`, body, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         // window.localStorage.clear()
//         // window.location.reload()
//       }
//       console.log('error postProduct', error.response.data)
//       return error
//     })
// }

// export const putPublication = async (id, business, body) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .put(`${BASE_URL}publications/${id}/${business}`, body, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         // window.localStorage.clear()
//         // window.location.reload()
//       }
//       console.log('error postProduct', error.response.data)
//       return error
//     })
// }

// export const deletePublication = async (id, body) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .delete(`${BASE_URL}publications/${id}`, body, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error postProduct', error.response.data)
//       return error
//     })
// }

// export const postPublicationImage = async (body) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .post(`${BASE_URL}publications-image`, body, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error postProduct', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLogin = async () => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-user`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByUsersByDate = async (day, month, year) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(
//       `${BASE_URL}publication-by-users-by-date/${day}/${month}/${year}`,
//       config
//     )
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByUsersByDate', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginByBusinessClientId = async (id) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-business-client/${id}`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log(
//         'error getAllPublicationByLoginByBusinessClientId',
//         error.response.data
//       )
//       return error
//     })
// }

// export const getAllPublicationByLoginByNotBusinessClient = async () => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-not-business-client`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginSketch = async () => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-user-sketch`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginSketchByBusinessClientId = async (id) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(
//       `${BASE_URL}publication-by-user-sketch-by-business-client/${id}`,
//       config
//     )
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginSketchNotBusinessClient = async () => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-user-sketch-by-not-business-client`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginByDate = async (month, year) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(`${BASE_URL}publication-by-user-by-date/${month}/${year}`, config)
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginByDateByBusinessClientId = async (
//   id,
//   month,
//   year
// ) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(
//       `${BASE_URL}publication-by-user-by-dateby-business-client/${id}/${month}/${year}`,
//       config
//     )
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }

// export const getAllPublicationByLoginByDateNotBusinessClient = async (
//   month,
//   year
// ) => {
//   const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

//   const config = {
//     headers: {
//       Authorization: 'Bearer ' + STORAGE_TOKEN,
//     },
//     withCredentials: true,
//   }

//   return await axios
//     .get(
//       `${BASE_URL}publication-by-user-by-date-by-not-business-client/${month}/${year}`,
//       config
//     )
//     .then((response) => {
//       return {
//         data: response.data,
//         responseStatus: response.status,
//       }
//     })
//     .catch(function (error) {
//       if (error.response.status === 401) {
//         window.localStorage.clear()
//         window.location.reload()
//       }
//       console.log('error getAllPublicationByLogin', error.response.data)
//       return error
//     })
// }
