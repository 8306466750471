import React, { useState } from 'react'
import styles from './styles.module.css'
import { ArrowUp, CloundArrowUp, Pen2 } from '../../../assets/svgs/icons'
import InputSearch from '../../../components/InputSearch'
import { Button } from '../../../components'
import { motion } from 'framer-motion'

export const SelectPrototypeStep = ({ setStep }) => {
  const [hovered, setHovered] = useState(false)
  const [expandProducts, setexpandProducts] = useState(false)

  const handleClickEdit = () => setStep(3)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      <div className={styles.page_header}>
        <span>Protótipos</span>
        <h2>Lista de Protótipos</h2>
      </div>
      <motion.div
        transition={{ ease: 'linear', duration: 2 }}
        className={styles.container}
      >
        <div className={styles.container_top}>
          <div className={styles.title}>
            <h3>Escolha o Protótipo</h3>
            <h3>
              <span>perfeito</span> para o seu produto
            </h3>
          </div>
          <div className={styles.input_button_container}>
            <InputSearch
              style={{
                backgroundColor: '#D3D3D3',
                height: '45px',
                paddingLeft: '18px',
                paddingRight: '18px',
                boxSizing: 'border-box',
                fontSize: 16,
                borderRadius: '50px',
              }}
              placeholder={'Digite o nome de um produto ou categoria'}
            />
            <Button
              style={{ borderRadius: '50px', padding: '21px', height: '60px' }}
              variant={'primary'}
            >
              Buscar
            </Button>
          </div>
        </div>
        <>
          {!expandProducts && (
            <motion.div
              initial={{ height: '0px', flex: 0 }}
              animate={{ height: '100%', flex: 1 }}
              transition={{ type: 'spring', duration: 0.8, bounce: 0.8 }}
              className={styles.container_bottom}
            >
              <div className={styles.container_bottom_text}>
                <span>Sugestões</span>
                <p>Protótipos de produtos com base no seu negócio</p>
              </div>
              <div className={styles.sugestions_container}>
                <div
                  className={styles.image_icon_component}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <div className={styles.image_container}>
                    <img
                      width={214}
                      height={200}
                      src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
                      alt=""
                    />
                  </div>
                  <div
                    className={`${styles.product_actions} ${
                      hovered ? styles.product_actions_hovered : ''
                    } `}
                  >
                    <button>
                      <CloundArrowUp
                        width={35}
                        height={35}
                        color={'#37373780'}
                      />
                    </button>

                    <button onClick={() => setStep(3)}>
                      <Pen2 width={35} height={35} color={'#37373780'} />
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </>
      </motion.div>
      <div
        onClick={() => setexpandProducts((prevState) => !prevState)}
        className={styles.visualize_mockups}
      >
        <div
          style={{ rotate: expandProducts ? '180deg' : '0deg' }}
          className={`${styles.visualize_mockups_button} ${
            expandProducts ? styles.product_expanded : ''
          }`}
        >
          <ArrowUp />
        </div>
        <div className={styles.visualize_mockups_text}>
          <p>Visualizar meus Protótipos</p>
        </div>
      </div>
      {expandProducts && (
        <motion.div
          initial={{ height: '0px', flex: 0 }}
          animate={{ height: '100%', flex: 1 }}
          transition={{ type: 'spring', duration: 0.8, bounce: 0.8 }}
          className={styles.product_expanded}
        >
          <div className={styles.mockups_container}>
            <div className={styles.sugestions_container}>
              <div
                className={styles.image_icon_component}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <div className={styles.image_container}>
                  <img
                    width={214}
                    height={200}
                    src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
                    alt=""
                  />
                </div>
                <div
                  className={`${styles.product_actions} ${
                    hovered ? styles.product_actions_hovered : ''
                  } `}
                >
                  <button>
                    <CloundArrowUp width={35} height={35} color={'#37373780'} />
                  </button>
                  <button onClick={handleClickEdit}>
                    <Pen2 width={35} height={35} color={'#37373780'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  )
}
