import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const postBusinessUser = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}user-account-business-users`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBusinessUser', error.response.data)
      return error
    })
}

export const postBusinessUserImage = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}user-user-image`, body, config)
    .then((response) => {
      return {
        dataImage: response.data,
        responseStatusImage: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBusinessUser', error.response.data)
      return error
    })
}

export const putBusinessUser = async (uuid, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}user-account-business-users/${uuid}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error businessUser', error.response.data)
      return error
    })
}

export const getBusinessUser = async (uuid) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-user/${uuid}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getBusinessUser', error.response.data)
      return error
    })
}

export const getAllBusinessUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}/user-account-business-users-all-data`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllBusinessUser', error.response.data)
      return error
    })
}

export const deleteBusinessUser = async (uuid) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}user-account-business-users/${uuid}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error DeleteBusinessUser', error.response.data)
      return error
    })
}

export const deleteBusinessImageUser = async (name) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}user-user-image/${name}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseImageStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error DeleteBusinessUser', error.response.data)
      return error
    })
}

export const getUserCheckEmaiUser = async (email) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}userCheckEmailUser/${email}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getUserCheckEmaiUser', error.response.data)
      return error
    })
}

export const getAllBusinessUserCount = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-user-by-user/count`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllBusinessUserCount', error.response.data)
      return error
    })
}

export const deleteBusinessByUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}user-business-user-by-user`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error deleteBusinessByUser', error.response.data)
      return error
    })
}
