/* eslint-disable react/prop-types */
import styles from './styles.module.css'

const MODAL_TYPES = {
  DEFAULT: 'default',
}

export const ModalComponent = ({
  isOpen,
  onClose,
  type,
  children,
  style = {},
}) => {
  if (!isOpen) return null

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const Modal_Default = () => {
    return (
      <div className={styles.modal_overlay} onClick={handleClose}>
        <div className={styles.modal_content} style={style}>
          {children}
        </div>
      </div>
    )
  }

  if (!type || type === MODAL_TYPES.DEFAULT) return <Modal_Default />
}

export default ModalComponent
