import {string} from 'prop-types'
import styles from './styles.module.css'

export const ErrorMessage = ({message}) => {
  return (
    <p className={styles.error}>{message}</p>
  )
}

ErrorMessage.propTypes = {
  message:string
}
