import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getStates = async () => {
  return await axios
    .get(`${BASE_URL}states`)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getCountries', error.response.data)
      return error
    })
}
