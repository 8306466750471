import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import {
  CloundArrowUp,
  Cross,
  Interrogation,
  Stars,
  Trash,
} from '../../../../assets/svgs/icons'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { PythonService } from '../../../../services'
const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
}

const ModalGalery = ({ closeModal, updateImage }) => {
  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [removeBackgroundMode, setRemoveBackgroundMode] = useState([])
  const [removedImageBackground, setRemovedImageBackground] = useState({})
  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const handleRemoveFile = (index) => {
    var indice = filesSelectedArray.indexOf(index)
    filesSelectedArray.splice(indice, 1)

    setFilesSelectedArray((current) => [...current])
  }

  // const nextButton = async () => {
  //   setStep(STEPS.STEP_SECOND_PASS_IMAGE)
  // }

  // const addImages = async () => {
  //   setImageGaleryArray(filesSelectedArray)

  //   // nextButton()
  //   closeModal('galeria')
  // }

  const handleOpenRemoveBackgroundMode = (index) => {
    if (removeBackgroundMode.some((e) => e === index)) return
    removeBackgroundMode.push(index)
    setRemoveBackgroundMode([...removeBackgroundMode])
  }

  const handleRemoveBackground = async (index, image) => {
    const { data } = await PythonService.postRemoveBackground({ image })
    filesSelectedArray[index].fileDataBase64 = data?.data?.images[1].image
    setFilesSelectedArray([...filesSelectedArray])
    setRemovedImageBackground(data?.data?.images)
  }

  const handleStopRemoveBackgroundMode = (index) => {
    const aux = removeBackgroundMode.filter((e) => e !== index)
    setRemoveBackgroundMode([...aux])
  }

  function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  return (
    // <div className={styles.modal_overlay} style={{ background: '#0006' }}>
    <div className={styles.modal}>
      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('galeria')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2 className={styles.modal_title_text}>
          Realize upload dos elementos <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Realize uploads arrastando os elementos para área demarcada ou
          selecione do explorador de arquivos
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <div className={styles.modal_drag}>
              {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
              </label> */}

              <div
                className={styles.modal_drag_drop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                // onClick={handleShowListImages}

                // style={{
                //   height: '100px',
                //   width: '100%',
                //   border: '2px dashed black',
                //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                // }}
                // className={styles.modal_drag}
              >
                <label htmlFor="arquivo">
                  <CloundArrowUp color={'#B900FE'} />
                  <h2
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_title_short
                        : styles.modal_drag_drop_title
                    }
                  >
                    Arraste & Solte o arquivo
                  </h2>
                  <p
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_sub_title_short
                        : styles.modal_drag_drop_sub_title
                    }
                  >
                    Ou selecione o arquivo no explorador
                  </p>
                </label>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={(e) => onFileChange(e)}
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.modal_button_save}>
            <button className={styles.button_add}>
              <label className={styles.button_add_label} htmlFor="arquivo">
                Adicionar Imagem
              </label>
            </button>
          </div>
        </div>
        {filesSelectedArray.length !== 0 && (
          <div className={styles.content_list_image}>
            <div className={styles.container_box_list_image}>
              {filesSelectedArray.map((image, index) => (
                <div
                  key={index}
                  className={
                    styles[
                      !removeBackgroundMode.some((e) => e === index)
                        ? 'container_list_image'
                        : 'container_list_image_remove_background_mode'
                    ]
                  }
                >
                  <div className={styles.item_list_image}>
                    <img src={image.fileDataBase64} alt="file-preview" />
                    {!removeBackgroundMode.some((e) => e === index) && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        className={styles.box_data_info}
                      >
                        <p className={styles.box_data_info_title}>
                          {image.name}
                        </p>
                        <p className={styles.box_data_info_size}>
                          Tamanho &nbsp;<b> {formatBytes(image.size)} </b>
                        </p>
                      </motion.div>
                    )}
                  </div>

                  {!removeBackgroundMode.some((e) => e === index) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.8 }}
                      className={styles.item_list_image_buttons}
                    >
                      <button
                        className={styles.button_cancel}
                        onClick={() => handleRemoveFile(index)}
                      >
                        <p className={styles.button_cancel_title}>Excluir</p>
                        <Trash />
                      </button>
                      <button
                        onClick={() => handleOpenRemoveBackgroundMode(index)}
                        className={styles.button_remove_background}
                      >
                        <p className={styles.button_remove_background_title}>
                          Remover Fundo
                        </p>
                        <Stars />
                      </button>
                    </motion.div>
                  )}
                  {removeBackgroundMode.some((e) => e === index) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1 }}
                      className={styles.item_list_image_buttons}
                    >
                      <button
                        onClick={() =>
                          handleRemoveBackground(index, image.fileDataBase64)
                        }
                        className={styles.button_remove_background}
                      >
                        <p className={styles.button_remove_background_title}>
                          Remover Fundo
                        </p>
                        <Stars />
                      </button>
                      <button
                        className={
                          styles[
                            !removeBackgroundMode.some((e) => e === index)
                              ? 'button_cancel'
                              : 'button_cancel_remove_background'
                          ]
                        }
                        onClick={() => handleStopRemoveBackgroundMode(index)}
                      >
                        <p
                          className={styles.button_cancel_title}
                          style={{
                            color: removeBackgroundMode.some((e) => e === index)
                              ? 'black'
                              : '',
                          }}
                        >
                          Cancelar
                        </p>
                      </button>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {filesSelectedArray.length !== 0 && (
        <button
          className={styles.button_save}
          onClick={() => {
            // addImages()
            updateImage(filesSelectedArray)
          }}
        >
          Adicionar Imagens
        </button>
      )}
    </div>
    // </div>
  )
}

export default ModalGalery

ModalGalery.propTypes = {
  setImageGaleryArray: PropTypes.func,
  setStep: PropTypes.func,
  closeModal: PropTypes.func,
}
