/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { createContext, useContext, useMemo, useState } from 'react'
import { useClientStorage } from './useLocalStorage'

const ClientContext = createContext()

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useClientStorage('clientData', null)
  const [allClientData, setAllClientData] = useState([])

  const value = useMemo(
    () => ({
      clientData,
      setClientData,
      allClientData,
      setAllClientData,
    }),
    [clientData]
  )
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  )
}

export const useClient = () => {
  return useContext(ClientContext)
}
