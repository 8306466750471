import { useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'

const STEPS = {
  STEP_PASS_ADDRESS: 'stepAddress',
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
}
function StepNIcheAndObjective({ setStep }) {
  const [first, setfirst] = useState('')

  const handleNavigate = async (type) => {
    if (type === 'next') {
      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    } else {
      setStep(STEPS.STEP_PASS_ADDRESS)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Informações Adicionais</p>
        <h1 style={{ marginTop: '0px' }}>Nicho e Objetivo</h1>

        <div className={styles.container_objective}>
          <p>Defina seu objetivo</p>
          <div className={styles.box_objective}>
            <div className={styles.objective_selected}>
              <div className={styles.box_icon}>
                <UserGroup />
              </div>
              <p className={styles.objective_text}>Engajamento</p>
            </div>
            <div className={styles.objective}>Vendas</div>
            <div className={styles.objective}>Vendas</div>
            <div className={styles.objective}>Vendas</div>
            <div className={styles.objective}>Vendas</div>
            <div className={styles.objective}>Vendas</div>
          </div>
        </div>

        <div className={styles.container_niche}>
          <p>Agora Escolha seu nicho</p>
          <div className={styles.box_niche}>
            <div className={styles.niche_selected}>Indústria</div>
            <div className={styles.niche}>Moda</div>
          </div>
        </div>

        <div className={styles.container_subniche}>
          <p>Digite abaixo seu subnicho</p>
          <div className={styles.box_input}>
            <input
              className={styles.input_subniche}
              type="text"
              placeholder="Seu subnicho"
            />
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepNIcheAndObjective
