import styles from './styles.module.css'

import { useNavigate } from 'react-router-dom'

import Masonry from 'react-responsive-masonry'
import { useRef, useState } from 'react'
import {
  CircleX,
  Interrogation,
  LeftBackArrow,
} from '../../../../assets/svgs/icons'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
}

function StepImageBank({ setStep, setImageArray }) {
  const navigation = useNavigate()

  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.url])
    }
  }

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const handleImageClick = (imageUrl) => {
    const imageArray = images.find((image) => image.url === imageUrl.url)

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
        setImageArray([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.url])
    }
  }

  const nextButton = async () => {
    setStep(STEPS.STEP_SECOND_PASS_IMAGE)
  }

  const addImages = async () => {
    setImageArray(selectedArrayImage)

    nextButton()
  }

  const handleMouseDown = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    // }
  }

  const handleMouseMove = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    // }
  }

  const handleMouseUp = () => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
    // }
  }

  const images = [
    // Adicione URLs de imagens conforme necessário
    {
      id: 1,
      url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
      title: 'Comida saudavel',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 2,
      url: 'https://i.pinimg.com/564x/17/39/0b/17390b7b5350ee00f99c43394d0e1bdc.jpg',
      title: 'Violão',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 3,
      url: 'https://i.pinimg.com/564x/a8/cd/72/a8cd72ee405f0fe7be15f9390f23ac4d.jpg',
      title: 'Aplicativo de storyes',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 4,
      url: 'https://i.pinimg.com/564x/88/37/ba/8837ba494600949bead1264bf32d4653.jpg',
      title: 'Promoção',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 5,
      url: 'https://i.pinimg.com/564x/c8/fc/4c/c8fc4cd20998a332b016f857feeed6ec.jpg',
      title: 'Start Up',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 6,
      url: 'https://i.pinimg.com/564x/7a/89/22/7a89227cb718b8ce49dc6904e18013db.jpg',
      title: 'Milke Shake',
      size: '32mb',
      origin: 'imgBank',
    },
    {
      id: 7,
      url: 'https://i.pinimg.com/564x/16/e7/9d/16e79d15cfd25e7ef5c7a69935bd4b26.jpg',
      title: 'Escola de ensino',
      size: '32mb',
      origin: 'imgBank',
    },

    {
      id: 8,
      url: 'https://i.pinimg.com/564x/23/17/7e/23177ed9ca9388c3b0b51181a0f5b61f.jpg',
      title: 'Resvista',
      size: '32mb',
      origin: 'imgBank',
    },
  ]

  return (
    // <div className={styles.page}>
    <div className={styles.container_new_posts}>
      <div className={styles.header}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            nextButton()
          }}
        >
          <LeftBackArrow />
        </div>
        <p>Voltar</p>
      </div>
      <div className={styles.image_bank_title}>
        <h2 className={styles.image_bank_text}>
          Banco de Imagens <Interrogation color={'#FF8B00'} />
        </h2>
        <p className={styles.image_bank_subtitle_text}>
          Escolha imagens personalizadas para o seu nicho ou produto de nossa
          coleção de imagens
        </p>
      </div>
      <div className={styles.filters}>
        <div className={styles.search_images}>
          <input className={styles.filters_images} placeholder="Selecione" />
        </div>
        <div className={styles.search_parameters}>
          <select className={styles.filters_images}>
            <option className={styles.filters_images_option}>teste 1</option>
          </select>
          <select className={styles.filters_images}>
            <option className={styles.filters_images_option}>Teste 1</option>
          </select>
          <select className={styles.filters_images}>
            <option className={styles.filters_images_option}>teste 1</option>
          </select>
        </div>
      </div>

      <div>
        <div className={styles.container_galery_of_image}>
          <Masonry columnsCount={3} gutter="10px">
            {images.map((imageUrl, i) => (
              <img
                key={i}
                src={imageUrl.url}
                onClick={() => handleImageClick(imageUrl)}
                className={
                  selectedImages.includes(imageUrl.url)
                    ? styles.selectedImage
                    : styles.image
                }
              />
            ))}
          </Masonry>
        </div>

        {selectedArrayImage.length !== 0 && (
          <div className={styles.container_list_selected_image}>
            <p>Imagens Adicionadas</p>
            <div
              className={styles.content_list_selected_image}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <div
                className={styles.caroucel_list_selected_image}
                ref={carouselRef}
              >
                {selectedArrayImage.map((imageArray) => (
                  <div
                    className={styles.card_lis_selected_image}
                    key={imageArray.id}
                  >
                    <div className={styles.container_card_lis_selected_image}>
                      <div
                        className={styles.delete_card_lis_selected_image}
                        onClick={() => handleRemoveImage(imageArray)}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          <CircleX
                            color={'#FF2F00'}
                            width={'15px'}
                            height={'15px'}
                          />
                        </div>
                      </div>
                      <img
                        className={styles.item_selected_image}
                        src={imageArray.url}
                        alt={imageArray.title}
                      />
                    </div>
                    <div className={styles.item_selected_image_description}>
                      <p
                        className={styles.item_selected_image_description_title}
                      >
                        {imageArray.title}
                      </p>
                      <p
                        className={
                          styles.item_selected_image_description_category
                        }
                      >
                        Nome da categoria
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button
              className={styles.button_add_image}
              onClick={() => {
                addImages()
              }}
            >
              Adicionar Imagens
            </button>
          </div>
        )}
      </div>
    </div>
    // </div>
  )
}

export default StepImageBank
