import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { NavigateArrowRigth } from '../../../assets/svgs/icons'
// import { ChevronDown } from '../../../assets/svgs/icons'
export const SelectRound = ({
  options,
  label,
  htmlFor,
  placeholder,
  onSelectItem,
  defaultSelected,
  variant,
  changeBackgroundColor,
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  })

  const handleSelect = (e) => {
    setContainerVisible(false)
    setSelectedItem(e)
    onSelectItem(e)
  }

  useEffect(() => {
    setSelectedItem(defaultSelected)
  }, [defaultSelected])

  return (
    <div className={styles.select_search}>
      <div className={styles.inputSelectContainer}>
        <label htmlFor={htmlFor}>{label}</label>
        <div
          style={{
            backgroundColor: changeBackgroundColor ? changeBackgroundColor : undefined
          }}
          className={styles.buttonSelect}
          onClick={() => setContainerVisible((prev) => !prev)}
          type="text"
        >
          <p className={styles.placeholder_select} style={{ opacity: changeBackgroundColor ?  0.6  : undefined,}}>
            {selectedItem?.label || placeholder}
          </p>
          {/* <p className={styles.placeholder_selet}>{placeholder}</p> */}
          <div className={styles.box_svg}>
            <div
              className={
                variant === 'right'
                  ? containerVisible
                    ? styles.rotate
                    : styles.containerVisible_right
                  : containerVisible
                  ? styles.rotate
                  : styles.containerVisible
              }
            >
              <NavigateArrowRigth
                width={'10'}
                height={'10'}
                color={'#373737'}
              />
            </div>
          </div>
        </div>
        {containerVisible && (
          <div className={styles.selectContainer}>
            {options.map((option, index) => (
              <button
                key={index}
                className={styles.selectContainerItem}
                onClick={() => handleSelect(option)}
              >
                <span>{option.label}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

SelectRound.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  defaultSelected: PropTypes.object,
  onSelectItem: PropTypes.func,
  placeholder: PropTypes.string,
  changeBackgroundColor: PropTypes.string,
}
