// import React from 'react'
import styles from './styles.module.css'
import { Clock, Calendar as CalendarIcon } from '../../../../assets/svgs/icons'
import SchedulerCalendarMini from '../../../SchedulerCalendar/SchedulerCalendarMini'

export const CalendarStep = ({ modalData }) => {
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString)
      if (!isNaN(date)) {
        return date.toLocaleDateString('pt-BR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      } else {
        return '' // Retorna uma string vazia se a data for inválida
      }
    } catch (error) {
      return '' // Retorna uma string vazia em caso de erro
    }
  }

  function formatHour24(data) {
    data = new Date(data)

    let horas = data.getHours()
    let minutos = data.getMinutes()

    horas = horas < 10 ? '0' + horas : horas
    minutos = minutos < 10 ? '0' + minutos : minutos

    return horas + ':' + minutos
  }

  return (
    <div className={styles.step_container}>
      <div className={styles.calendar_container}>
        <SchedulerCalendarMini />
      </div>
      <div className={styles.label_container}>
        <div className={styles.data_container}>
          <CalendarIcon color={'#000'} />
          <div className={styles.text}>
            <span>Data</span>
            {/* <p>16 de Junho</p> */}
            <p>{formatDate(modalData?.publicationDate)}</p>
          </div>
        </div>
        <div className={styles.data_container}>
          <Clock color={'#000'} />
          <div className={styles.text}>
            <span>Horario</span>
            {/* <p>00:00</p> */}
            <p>{formatHour24(modalData?.publicationDate)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
