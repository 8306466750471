/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import SchedulerCalendar from '../../../components/SchedulerCalendar'
import CarouselHours from '../../../components/CarouselHours'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

import {
  Picture,
  Instagram,
  Linkedin,
  Pinterest,
  TikTok,
  Twitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
} from '../../../assets/svgs/icons'

import { useState, useEffect } from 'react'

import { ModalComponent } from '../../../components/ModalComponent'
import { date } from 'yup'
import { ImageRender } from '../../../components/ImageRender'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

function StepFinish({
  setStep,
  imagesSelectedsArrayObj,
  setImagesSelectedsArrayObj,
  imagesSelectedId,
  legendList,
  // selectedObjectItem,
}) {
  const [oppenedModalLocale, setOppenedModalLocale] = useState(false)
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false)

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false)
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')

  const [selectedValues, setSelectedValues] = useState({})

  const imageUrl = imagesSelectedsArrayObj.find(
    (item) => item.id === imagesSelectedId.current
  ).image

  const legendId = legendList[imagesSelectedId.current]

  const handleClick = (value) => {
    if (selectedValues[value]) {
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  useEffect(() => {
    // console.log(selectedObjectItem)
  }, [])

  const [valueItim, setValueItim] = useState(1)

  const handleSelectedIntem = (item) => {
    // campaignRef.current = item
    setValueItim(item.id)
  }

  function formatDate2(data) {
    data = new Date(data)

    let dia = data.getDate()
    let mes = data.getMonth() + 1 // getMonth() retorna mês de 0-11
    let ano = data.getFullYear()

    dia = dia < 10 ? '0' + dia : dia
    mes = mes < 10 ? '0' + mes : mes

    return dia + '/' + mes + '/' + ano
  }

  const handleSelectDate = () => {
    let newSelectValues = Object.keys(selectedValues).toString() || ''

    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      return
    }

    if (!newSelectValues.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')

      return
    }

    const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
      if (item.id === imagesSelectedId.current) {
        return {
          ...item,
          // date: data.formatDate,
          // hour: data.valueHour + ':' + data.valueMinute,
          // valueHour: data.valueHour,
          // valueMinute: data.valueMinute,
          // selectedDate: data.selectedDate,
          // sketch: data.sketch,
          // socialTypes: data.socialTypes,

          date: formatDate2(selectedDate),
          hour: valueHour + ':' + valueMinute,
          valueHour: valueHour,
          valueMinute: valueMinute,
          selectedDate: selectedDate,
          sketch: false,
          socialTypes: newSelectValues,

          schedule: true,
        }
      }
      return item
    })

    setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)

    setStep('stepSchedule')
  }

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ]
    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          height: '800px',
        }}
      >
        <div className={styles.card}>
          <div className={styles.showPath}>
            <Picture color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Publicar em'}</p>
          </div>

          <div className={styles.iconsField}>
            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(1)}
            >
              <Linkedin
                color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(4)}
            >
              <Instagram
                color={selectedValues[4] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <TikTok color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(2)}
            >
              <Twitter
                color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            {/* <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div> */}
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Calendar2 color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Data e Horário'}</p>
          </div>

          <div className={styles.box_calendar}>
            <div
              style={{
                flex: 1,
              }}
            >
              <SchedulerCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className={styles.container_hour_carousel}>
              <p className={styles.container_hour_carousel_title}>Horário</p>
              <CarouselHours
                items={hours}
                setValueMinute={setValueMinute}
                valueMinute={valueMinute}
                setValueHour={setValueHour}
                valueHour={valueHour}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Configurações Adicionais'}</p>
          </div>
          <div
            onClick={() => setOppenedModalLocale(true)}
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <PinMapsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>Localização</div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione uma localização
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppenedModalPersons(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <MorePersonsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Marcar pessoas
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Marque pessoas ou empresas a esta publicação
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <FacebookChatIcon color={'#610f92'} size={'24'} />

            <div>
              <div className={styles.selectedModalTextTitle}>
                Receber Mensagens
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Permitam que pessoas te enviem mensagens a partir desta
                publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalLinks(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <LinksIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Links
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione links externos a sua publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalHashtags(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <HashtagIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Hashtags
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Segmente sua publicação para os melhores públicos
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>
              {'Configurações de Privacidade'}
            </p>
          </div>

          <div
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Público</div>
              <div className={styles.selectedModalTextSubtitle}>
                Qualquer pessoa dentro ou fora do Facebook poderá ver sua
                publicação.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Restrito</div>
              <div className={styles.selectedModalTextSubtitle}>
                Escolha certas pessoas no Facebook que podem ver sua publicação.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SchedulePhotosField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#fbfbfb',
            flex: 1,
          }}
        >
          <div className={styles.container_select_preview}>
            <SelectSquare
              backgroundColor={'#EDEDED'}
              border={true}
              borderColor={'#dadada'}
              placeholder={'Selecione'}
              arrowDirection="down"
              options={[
                {
                  id: 1,
                  label: 'Previa Feed Do Facebook',
                },
                {
                  id: 2,
                  label: 'Previa Feed Do Instagram',
                },
                {
                  id: 3,
                  label: 'Previa Stories Do Facebook',
                },
                {
                  id: 4,
                  label: 'Previa Stories Do Instagram',
                },
              ]}
              fontSize={12}
              //  defaultSelected={locationsStatesOptions.find(
              //    (e) => e.value === locationsStatesOptions?.value
              //  )}
              onClickItem={handleSelectedIntem}
            />
          </div>
          <ImageRender image={imageUrl} type={valueItim} legend={legendId} />
        </div>
        <div>{/* <CarrouselComponent /> */}</div>
      </div>
    )
  }

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setStep('stepSchedule')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={styles.button_next}
              onClick={() => {
                handleSelectDate()
              }}
            >
              <p className={styles.button_title}>Finalizar</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Localização</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Marcar Pessoas</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Twitter color={'#AAAAAA'} width={24} height={24} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Quem Você gostaria de Marcar?"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Link</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira um link público e válido aqui"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Hashtags</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira as hashtags e separe as mesmas com uma vírgula (,)"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  )
}

export default StepFinish
