import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'

import { useState } from 'react'

import {
  BoxChecket,
  BoxNotChecket,
  LeftBackArrow,
} from '../../../assets/svgs/icons'

const STEPS = {
  STEP_NEW_CAMPAIGN: 'stepNewCampaign',
}

function StepNewCampaignSuccess({ setStep }) {
  const [selectedOption, setSelectedOption] = useState('')
  const handleChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const navigation = useNavigate()

  const [isBranding, setIsBranding] = useState(false)
  const [isLogo, setIsLogo] = useState(false)
  const [isCommunication, setIsCommunication] = useState(false)
  const [isEffect, setIsEffect] = useState(false)
  const [isArchetype, setIsArchetype] = useState(false)
  const [isPreferences, setIsPreferences] = useState(false)
  const [isReferences, setIsReferences] = useState(false)
  const [isParam, setIsParam] = useState(false)

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_NEW_CAMPAIGN)
    }
  }

  const handleCheckt = (param) => {
    if (param === 'branding') {
      setIsBranding(!isBranding)
    }
    if (param === 'logo') {
      setIsLogo(!isLogo)
    }
    if (param === 'comunicacao') {
      setIsCommunication(!isCommunication)
    }
    if (param === 'efeitos') {
      setIsEffect(!isEffect)
    }
    if (param === 'arquetipo') {
      setIsArchetype(!isArchetype)
    }
    if (param === 'preferencias') {
      setIsPreferences(!isPreferences)
    }
    if (param === 'referencias') {
      setIsReferences(!isReferences)
    }
  }

  const handleChecktParamGeral = () => {
    setIsParam(!isParam)
  }

  return (
    <div className={styles.container_header}>
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Campanhas</p>
        <p className={styles.title_new_posts_title}>Nova Campanha</p>
      </div>

      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.container_form_first_options_header}>
              <div
                className={styles.box_button_back}
                onClick={() => {
                  nextButton('back')
                }}
              >
                <LeftBackArrow /> Voltar
              </div>
            </div>
            <div className={styles.form_container_input}>
              <div className={styles.box_input_title}>
                <h2 className={styles.form_container_input_title}>Sucesso!</h2>
                <p className={styles.form_container_input_sub_title}>
                  Você concluiu esta etapa do procedimento. Determine agora
                  &nbsp;
                  <b>somente o que deseja editar </b> das suas parametrizações
                </p>
              </div>
              <div className={styles.container_checkbox}>
                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('branding')}
                  >
                    {isBranding ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Branding</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('logo')}
                  >
                    {isLogo ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Logos</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('comunicacao')}
                  >
                    {isCommunication ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Tom de comunicação</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('efeitos')}
                  >
                    {isEffect ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Efeitos</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('arquetipo')}
                  >
                    {isArchetype ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Arquétipo de Marca</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('preferencias')}
                  >
                    {isPreferences ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>
                    Preferencias visuais
                  </p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('referencias')}
                  >
                    {isReferences ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Referencias</p>
                </div>
              </div>
              <div className={styles.box_buttons}>
                <button className={styles.button_maintain_parameterization}>
                  Quero manter das minhas paramerizações
                </button>
                <button className={styles.button_start_parameterization}>
                  iniciar paramerização
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className={styles.button_next}>
          <button>prosseguir</button>
        </div>
      </div>
    </div>
  )
}

export default StepNewCampaignSuccess
