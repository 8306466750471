import { string, func, object, number } from 'prop-types'
import styles from './styles.module.css'
import { MagnifyingGlass } from '../../assets/svgs/icons'
export const Input = ({
  id,
  value,
  onChange,
  fontSize,
  onFocus,
  onBlur,
  placeholder,
  defaultValue,
  style,
  label,
  htmlFor,
}) => {
  return (
    <div className={styles.container} style={{ fontSize: fontSize }}>
      {label && (
        <label className={styles.label} htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <input
        style={{ ...style, fontSize: fontSize }}
        className={styles.input}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        type="text"
      />
    </div>
  )
}

Input.propTypes = {
  value: string,
  defaultValue: string,
  onChange: func,
  onFocus: func,
  onBlur: func,
  placeholder: string,
  fontSize: number,
  style: object,
  id: string,
  label: string,
  htmlFor: string,
}
