import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getUserBusinessUserById = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))
  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  return await axios
    .get(`${BASE_URL}user-account-business-users/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getUserBusinessUserById', error.response.data)
      return error
    })
}

export const updateUserById = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))
  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  return await axios
    .put(`${BASE_URL}user-account-business-users/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error updateUserById', error.response.data)
      return error
    })
}
