import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { BucketUploadService } from '../../../services'

const STEPS = {
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_LOGO: 'stepLogo',
}

function StepTypography({ setStep }) {
  const [textIA, setTextIA] = useState('')
  const [textExemple, setTextExemple] = useState('')
  const [fontsOptions, setFontsOptions] = useState([])

  const [font, setFont] = useState('')

  const handleNavigate = async (type) => {
    if (type === 'next') {
      //
      setStep(STEPS.STEP_PASS_LOGO)
    } else {
      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    }
  }

  // useEffect(async () => {
  //   // if (dataToCompare)
  //   loadFonts()
  //   const fontData = await BucketUploadService.getGoogleFontsBucketByName(
  //     'abeezee'
  //   )
  //   setFont(fontData.data)
  // }, [])

  console.log('font', font[0]?.name)

  const loadFonts = async () => {
    let googleFontsBucket = await BucketUploadService.getGoogleFontsBucket()

    setFontsOptions(
      googleFontsBucket.data.map((e) => ({ label: e.name, value: e.name }))
    )
  }

  let importFont = ''

  return (
    <div className={styles.box_step}>
      <style>
        {fontsOptions.forEach((element) => {
          importFont =
            importFont +
            `@import url('href="https://fonts.googleapis.com/css?family=${element.label}"'); `
        })}
        {console.log(importFont)}
        {importFont}
      </style>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Tipografia</p>
        <h1 style={{ marginTop: '0px' }}>Fonte de Texto</h1>

        <div className={styles.box_content_page}>
          <div className={styles.box_input_search}>
            <InputIA
              placeholder={'Digite aqui o que deseja'}
              text={textIA}
              setText={setTextIA}
              setLoading={() => {}}
              clickFunction={() => {}}
            />
          </div>

          <div className={styles.box_selects}>
            <input
              value={textExemple}
              onChange={(e) => setTextExemple(e.target.value)}
              className={styles.input_label}
              type="text"
              placeholder="Insira seu texto "
            />
            <SelectRound
              htmlFor="select"
              placeholder="Selecione"
              defaultSelected={{ label: 'Categoria', value: '' }}
              options={[
                { label: 'Teste', value: 1 },
                { label: 'Teste2', value: 2 },
              ]}
            />
          </div>

          <div className={styles.container_box_font}>
            {fontsOptions.map((font, index) => (
              <div className={styles.box_font_select} key={index}>
                {console.log('font', font)}
                <p className={styles.font_title_text}>{font.label}</p>

                <div className={styles.box_font_select_text}>
                  <h1
                    className={styles.font_select_text}
                    style={{ fontFamily: `${font.value}` }}
                  >
                    {textExemple ||
                      'Whereas disregard and contempt for human rights have resulted. Lorem Ipsum dolor it damet setinels'}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepTypography
