// import { useState } from 'react'
// import styles from './styles.module.css'
// import {
//   CircleX,
//   Cross,
//   Interrogation,
//   ArrowDown,
// } from '../../../../assets/svgs/icons'
// import Masonry from 'react-responsive-masonry'

// const ModalUploads = ({ setImageUploadArray, closeModal }) => {
//   // const [dropzoneHover, setDropzoneHover] = useState(false)

//   const [galeyimages, setGaleyimages] = useState(false)
//   const [selectedImages, setSelectedImages] = useState([])
//   const [selectedArrayImage, setSelectedArrayImage] = useState([])

//   const images = [
//     // Adicione URLs de imagens conforme necessário
//     {
//       id: 1,
//       url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
//       title: 'Comida saudavel',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 2,
//       url: 'https://i.pinimg.com/564x/17/39/0b/17390b7b5350ee00f99c43394d0e1bdc.jpg',
//       title: 'Violão',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 3,
//       url: 'https://i.pinimg.com/564x/a8/cd/72/a8cd72ee405f0fe7be15f9390f23ac4d.jpg',
//       title: 'Aplicativo de storyes',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 4,
//       url: 'https://i.pinimg.com/564x/88/37/ba/8837ba494600949bead1264bf32d4653.jpg',
//       title: 'Promoção',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 5,
//       url: 'https://i.pinimg.com/564x/c8/fc/4c/c8fc4cd20998a332b016f857feeed6ec.jpg',
//       title: 'Start Up',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 6,
//       url: 'https://i.pinimg.com/564x/7a/89/22/7a89227cb718b8ce49dc6904e18013db.jpg',
//       title: 'Milke Shake',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//     {
//       id: 7,
//       url: 'https://i.pinimg.com/564x/16/e7/9d/16e79d15cfd25e7ef5c7a69935bd4b26.jpg',
//       title: 'Escola de ensino',
//       size: '32mb',
//       origin: 'imgBank',
//     },

//     {
//       id: 8,
//       url: 'https://i.pinimg.com/564x/23/17/7e/23177ed9ca9388c3b0b51181a0f5b61f.jpg',
//       title: 'Resvista',
//       size: '32mb',
//       origin: 'imgBank',
//     },
//   ]

//   const handleSearchimage = () => {
//     setGaleyimages(!galeyimages)
//   }

//   const handleImageClick = (imageUrl) => {
//     const imageArray = images.find((image) => image.url === imageUrl.url)

//     if (imageArray) {
//       const arrayExists = selectedArrayImage.some(
//         (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
//       )

//       if (arrayExists) {
//         setSelectedArrayImage(
//           selectedArrayImage.filter(
//             (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
//           )
//         )
//       } else {
//         setSelectedArrayImage([...selectedArrayImage, imageArray])
//         // setImageArray([...selectedArrayImage, imageArray])
//       }
//     }

//     if (selectedImages.includes(imageUrl.url)) {
//       setSelectedImages(
//         selectedImages.filter((image) => image !== imageUrl.url)
//       )
//     } else {
//       setSelectedImages([...selectedImages, imageUrl.url])
//     }
//   }

//   const handleRemoveImage = (imageArray) => {
//     setSelectedArrayImage(
//       selectedArrayImage.filter(
//         (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
//       )
//     )

//     if (selectedImages.includes(imageArray.url)) {
//       setSelectedImages(
//         selectedImages.filter((image) => image !== imageArray.url)
//       )
//     } else {
//       setSelectedImages([...selectedImages, imageArray.url])
//     }
//   }

//   const addImages = async () => {
//     setImageUploadArray(selectedArrayImage)

//     // nextButton()
//     closeModal('uploads')
//   }

//   return (
//     <div className={styles.modal}>
//       <div className={styles.header_close}>
//         <div
//           className={styles.close_modal}
//           onClick={() => closeModal('uploads')}
//         >
//           <Cross width={'20px'} height={'20px'} />
//         </div>
//       </div>
//       <div className={styles.modal_title}>
//         <h2>
//           Selecione um de seus uploads <Interrogation color={'#FF8B00'} />
//         </h2>
//       </div>
//       <div className={styles.modal_sub_title}>
//         <p className={styles.modal_sub_title_text}>
//           Elementos e assets que você utilizou em outra publicações podem ser
//           usados nesta publicação também!
//         </p>
//       </div>
//       <div className={styles.container_modal}>
//         <div className={styles.toogle_company}>
//           Campanha 01 - Empresa{' '}
//           <div className={styles.expand_galery} onClick={handleSearchimage}>
//             <div
//               className={
//                 galeyimages
//                   ? styles.expand_galery_arrow_up
//                   : styles.expand_galery_arrow_down
//               }
//             >
//               <ArrowDown color={'#000'} />
//             </div>
//           </div>
//         </div>
//       </div>

//       {galeyimages && (
//         <div className={styles.container_galeyimages}>
//           <Masonry columnsCount={3} gutter="10px">
//             {images.map((imageUrl, i) => (
//               <img
//                 key={i}
//                 src={imageUrl.url}
//                 onClick={() => handleImageClick(imageUrl)}
//                 className={
//                   selectedImages.includes(imageUrl.url)
//                     ? styles.selectedImage
//                     : styles.image
//                 }
//               />
//             ))}
//           </Masonry>
//         </div>
//       )}

//       {selectedArrayImage.length !== 0 && (
//         <div className={styles.contaier_caroucel_list_selected_image}>
//           Imagens Adicionadas
//           <div className={styles.caroucel_list_selected_image}>
//             {selectedArrayImage.map((imageArray) => (
//               <div
//                 className={styles.card_lis_selected_image}
//                 key={imageArray.id}
//               >
//                 <div className={styles.container_card_lis_selected_image}>
//                   <div
//                     className={styles.delete_card_lis_selected_image}
//                     onClick={() => handleRemoveImage(imageArray)}
//                   >
//                     <div style={{ cursor: 'pointer' }}>
//                       <CircleX
//                         color={'#FF2F00'}
//                         width={'15px'}
//                         height={'15px'}
//                       />
//                     </div>
//                   </div>
//                   <img
//                     className={styles.item_selected_image}
//                     src={imageArray.url}
//                     alt={imageArray.title}
//                   />
//                 </div>
//                 <div className={styles.item_selected_image_description}>
//                   <p className={styles.item_selected_image_description_title}>
//                     {imageArray.title}
//                   </p>
//                   <p
//                     className={styles.item_selected_image_description_category}
//                   >
//                     Nome da categoria
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//       {selectedArrayImage.length !== 0 && (
//         <button
//           className={styles.button_add_image}
//           onClick={() => {
//             addImages()
//           }}
//         >
//           Adicionar Imagens
//         </button>
//       )}
//     </div>
//   )
// }

// export default ModalUploads

/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import {
  CircleX,
  Cross,
  Interrogation,
  // ArrowDown,
} from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { MyUploadsService } from '../../../../services'
import { Loading } from '../../../../components/Loading'

import {
  notifyErrorCustom,
  // notifySuccessCustom,
} from '../../../../utils/notify'

import { useClient } from '../../../../hooks/useClient'

const ModalUploads = ({ setImageUploadArray, closeModal }) => {
  const { clientData } = useClient()

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const [images, setImages] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [])

  const loadData = async () => {
    console.log('clientData?.uuid', clientData)

    const response = await MyUploadsService.getMyUploadsByPublicationIdBase64(
      clientData?.uuid || null
    )

    if (response.responseStatus === 200) {
      const images = response.data

      setImages(images)
    } else {
      notifyErrorCustom('Ocorreu um erro ao carregar as imagens')
    }

    setLoading(false)
  }

  const handleImageClick = (imageUrl) => {
    const imageArray = images.find(
      (image) => image.fileBase64 === imageUrl.fileBase64
    )

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.fileBase64)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.fileBase64)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.fileBase64])
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.url])
    }
  }

  const addImages = async () => {
    let newSelectedArrayImage = selectedArrayImage.map((image) => {
      return {
        id: image.id,
        image: 'data:image/' + image.fileFormat + ';base64,' + image.fileBase64,
      }
    })

    setImageUploadArray(newSelectedArrayImage)

    closeModal('uploads')
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />
      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('uploads')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>
          Selecione um de seus uploads <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Elementos e assets que você utilizou em outra publicações podem ser
          usados nesta publicação também!
        </p>
      </div>

      <div className={styles.container_galeyimages}>
        <Masonry columnsCount={3} gutter="10px">
          {images.map((imageUrl, i) => (
            <img
              key={i}
              src={'data:image/png;base64,' + imageUrl?.fileBase64}
              onClick={() => handleImageClick(imageUrl)}
              className={
                selectedImages.includes(imageUrl.url)
                  ? styles.selectedImage
                  : styles.image
              }
            />
          ))}
        </Masonry>
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.contaier_caroucel_list_selected_image}>
          <p>Imagens Adicionadas</p>
          <div className={styles.caroucel_list_selected_image}>
            {selectedArrayImage.map((imageArray) => (
              <div
                className={styles.card_lis_selected_image}
                key={imageArray.id}
              >
                <div className={styles.container_card_lis_selected_image}>
                  <div
                    className={styles.delete_card_lis_selected_image}
                    onClick={() => handleRemoveImage(imageArray)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CircleX
                        color={'#FF2F00'}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  </div>
                  <img
                    className={styles.item_selected_image}
                    src={'data:image/png;base64,' + imageArray.fileBase64}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            addImages()
          }}
        >
          Adicionar Imagens
        </button>
      )}
    </div>
  )
}

export default ModalUploads
