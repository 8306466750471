import { useEffect, useRef, useState } from 'react'
import { PosteiLogo } from '../../assets/svgs/icons'
import StepName from './stepName'
import styles from './styles.module.css'
import StepEmail from './stepEmail'
import StepPhone from './stepPhone'
import StepPassword from './stepPassword'
import StepImage from './stepImage'
import StepLogo from './stepLogo'
import StepBPS from './stepBPS'
import StepTypography from './stepTypography'

import StepNIcheAndObjective from './stepNicheAndObjective'
import StepPublication from './stepPublication'
import StepPublicationDownload from './stepPublicationDownload'
import Password from 'antd/es/input/Password'
import { BusinessUser, UserService } from '../../services'

import { notifyErrorCustom } from '../../utils/notify'
import StepCPF from './stepCPF'
import StepAddress from './stepAddress'
import StepColorPalette from './stepColorPalette'

const STEPS = {
  STEP_PASS_NAME: 'stepName',
  STEP_PASS_EMAIL: 'stepEmail',
  STEP_PASS_PHONE: 'stepPhone',
  STEP_PASS_PASSWORD: 'stepPassword',
  STEP_PASS_CPF: 'stepCPF',
  STEP_PASS_ADDRESS: 'stepAddress',
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_IMAGE: 'stepImage',
  STEP_PASS_LOGO: 'stepLogo',
  STEP_PASS_BPS: 'stepBPS',
  STEP_PASS_TYPOGRAPHY: 'stepTypography',
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  STEP_PASS_PUBLICATION: 'stepPublication',
  STEP_PASS_PUBLICATION_DOWNLOAD: 'stepPublicationDownload',
}

function FastPublishing() {
  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS_NAME)
  const [numberStep, setNumberStep] = useState(0)

  const [name, setName] = useState('')
  const [arrayName, setArrayName] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [corporateReason, setCorporateReason] = useState('')

  const [userData, setUserData] = useState('')

  const wordsArray = name.trim().split(/\s+/)

  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [logoSelectedArray, setLogoSelectedArray] = useState([])

  const [colors, setColors] = useState([])

  useEffect(() => {
    handleStep()
    console.log(wordsArray)
  }, [numberStep])

  const isValidEmail = (email) => {
    // Expressão regular para validar e-mail
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Testa o e-mail recebido com o regex
    return emailRegex.test(email)
  }

  const verifyEmailExist = async (email) => {
    let response = false
    const checkEmailExists = await UserService.getUserCheckEmailExists(email)
    if (checkEmailExists?.data?.exists) {
      console.log('teste1', checkEmailExists)
      response = checkEmailExists?.data?.exists
    } else {
      console.log('teste2', checkEmailExists)

      response = checkEmailExists?.data?.exists
    }

    return response
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // if (numberStep === 0 && name === '') {
      //   notifyErrorCustom('Preencha o campo com seu nome e sobrenome')
      //   return
      // } else if (wordsArray.length === 1) {
      //   notifyErrorCustom('Faltou você informar um sobrenome')
      //   return
      // }
      // if (numberStep === 1 && email === '') {
      //   notifyErrorCustom('Preencha o campo de email')
      //   return
      // } else if (numberStep === 1 && !isValidEmail(email)) {
      //   notifyErrorCustom('Esse não é um formato de email valido')
      //   return
      // } else if (numberStep === 1) {
      //   // verifyEmailExist(email)
      //   // console.log('89', verifyEmailExist(email))

      //   // if (verifyEmailExist(email)) {
      //   //   notifyErrorCustom('E-mail já cadastrado!')
      //   //   return
      //   // }

      //   const checkEmailExists = await UserService.getUserCheckEmailExists(
      //     email
      //   )
      //   if (checkEmailExists?.data?.exists) {
      //     console.log('teste1', checkEmailExists)
      //     notifyErrorCustom('E-mail já cadastrado!')
      //     return
      //     // response = checkEmailExists?.data?.exists
      //   }
      // }
      // if (numberStep === 2 && phone === '') {
      //   notifyErrorCustom('Preencha o campo de telefone')
      //   return
      // }
      // if (
      //   (numberStep === 3 && pass === '') ||
      //   (numberStep === 3 && confirmPass === '')
      // ) {
      //   notifyErrorCustom('Preencha os campos de senha e confirmação de senha')
      //   return
      // }
      // if (numberStep === 3 && pass != confirmPass) {
      //   notifyErrorCustom('A confirmação de senha não está correta')
      //   return
      // }

      // if (numberStep === 3) {
      //   let bodyUser = {
      //     name: name.trim().split(/\s+/)[0],
      //     surname: wordsArray[wordsArray.length - 1],
      //     phone: phone,
      //     email: email,
      //     password: pass,
      //   }

      //   UserService.postUser(bodyUser)
      // }

      // if (numberStep === 4 && cpf === '') {
      //   notifyErrorCustom('Preencha o campo de CPF')
      //   return
      // }

      if (numberStep >= 12) {
        return
      }
      setNumberStep(numberStep + 1)
    }

    if (type === 'back') {
      if (numberStep <= 0) {
        return
      }
      setNumberStep(numberStep - 1)
    }
  }

  const handleStep = () => {
    let newStep = ''

    switch (numberStep) {
      case 0:
        newStep = STEPS.STEP_PASS_NAME
        break
      case 1:
        newStep = STEPS.STEP_PASS_EMAIL
        break
      case 2:
        newStep = STEPS.STEP_PASS_PHONE
        break
      case 3:
        newStep = STEPS.STEP_PASS_PASSWORD
        break
      case 4:
        newStep = STEPS.STEP_PASS_CPF
        break
      case 5:
        newStep = STEPS.STEP_PASS_ADDRESS
        break
      case 6:
        newStep = STEPS.STEP_PASS_NICHE_AND_OBJECTIVE
        break
      case 7:
        newStep = STEPS.STEP_PASS_IMAGE
        break
      case 8:
        newStep = STEPS.STEP_PASS_LOGO
        break
      case 9:
        newStep = STEPS.STEP_PASS_BPS
        break
      case 10:
        newStep = STEPS.STEP_PASS_TYPOGRAPHY
        break
      case 11:
        newStep = STEPS.STEP_PASS_PUBLICATION
        break
      case 12:
        newStep = STEPS.STEP_PASS_PUBLICATION_DOWNLOAD
        break
      default:
        break
    }

    setStep(newStep)
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.box_logo}>
        <PosteiLogo />
      </div>

      {step === STEPS.STEP_PASS_NAME && (
        <StepName
          setName={setName}
          name={name}
          setArrayName={setArrayName}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_EMAIL && (
        <StepEmail setEmail={setEmail} email={email} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PHONE && (
        <StepPhone phone={phone} setPhone={setPhone} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PASSWORD && (
        <StepPassword
          setPass={setPass}
          setConfirmPass={setConfirmPass}
          pass={pass}
          confirmPass={confirmPass}
          setStep={setStep}
          wordsArray={wordsArray}
          phone={phone}
          email={email}
          setUserData={setUserData}
        />
      )}
      {step === STEPS.STEP_PASS_CPF && (
        <StepCPF
          cpf={cpf}
          setCpf={setCpf}
          setCnpj={setCnpj}
          cnpj={cnpj}
          setFantasyName={setFantasyName}
          fantasyName={fantasyName}
          setCorporateReason={setCorporateReason}
          corporateReason={corporateReason}
          setNumberStep={setNumberStep}
          numberStep={numberStep}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_ADDRESS && (
        <StepAddress
          setStep={setStep}
          cpf={cpf}
          corporateReason={corporateReason}
          userData={userData}
          fantasyName={fantasyName}
          cnpj={cnpj}
        />
      )}
      {step === STEPS.STEP_PASS_COLOR_PALETTE && (
        <StepColorPalette
          setStep={setStep}
          colors={colors}
          setColors={setColors}
        />
      )}
      {step === STEPS.STEP_PASS_IMAGE && (
        <StepImage
          setStep={setStep}
          filesSelectedArray={filesSelectedArray}
          setFilesSelectedArray={setFilesSelectedArray}
        />
      )}
      {step === STEPS.STEP_PASS_NICHE_AND_OBJECTIVE && (
        <StepNIcheAndObjective setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_LOGO && (
        <StepLogo
          setStep={setStep}
          logoSelectedArray={logoSelectedArray}
          setLogoSelectedArray={setLogoSelectedArray}
        />
      )}
      {step === STEPS.STEP_PASS_BPS && <StepBPS setStep={setStep} />}
      {step === STEPS.STEP_PASS_TYPOGRAPHY && (
        <StepTypography setStep={setStep} />
      )}

      {step === STEPS.STEP_PASS_PUBLICATION && (
        <StepPublication setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PUBLICATION_DOWNLOAD && (
        <StepPublicationDownload setStep={setStep} />
      )}

      {/* <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div> */}
    </div>
  )
}

export default FastPublishing
