import React, { useState } from 'react'
import styles from './styles.module.css'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { Button, ColorPalete, Input } from '../../../components'
import { CloundArrowUp, CloundUp, CheckBadge } from '../../../assets/svgs/icons'
import { Editor } from '../../../components/Editor'
import { motion } from 'framer-motion'
import InputIA from '../../../components/InputIA'
import InputSearch from '../../../components/InputSearch'
import { ExportFileModal } from '../../../components/ExportFileModal'

export const ProductConfig = ({ setStep }) => {
  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [renderModal, setRenderModal] = useState(false)

  const onDragOver = (event) => {
    event.preventDefault()
    setDropzoneHover(true)
  }

  const onDragLeave = () => {
    setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const handleClickSave = () => {
    setRenderModal(true)
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      <ExportFileModal
        onClose={() => setRenderModal(false)}
        isOpen={renderModal}
      />
      <div className={styles.text_button_container}>
        <div className={styles.text_title_container}>
          <label htmlFor="">Protótipo</label>
          <div className={styles.header_button_container}>
            <h3>Lista de Produto</h3>
            <Button
              onClick={() => setStep(1)}
              style={{ height: '45px' }}
              variant={'primary'}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.input_container}>
            <div className={styles.select}>
              <label className={styles.label} htmlFor="">
                Selecione o produto deste Protótipo
              </label>
              <SelectSquare
                placeholder={'selecione'}
                options={[{ label: 'option 1', value: '' }]}
              />
            </div>
            <div className={styles.select}>
              <label className={styles.label} htmlFor="">
                Agora selecione a categoria deste Protótipo:
              </label>
              <SelectSquare
                placeholder={'selecione'}
                options={[{ label: 'option 1', value: '' }]}
              />
            </div>
            <div className={styles.select}>
              <label className={styles.label} htmlFor="">
                Digite o texto do Protótipo
              </label>
              <Input placeholder={'Digite aqui o texto'} />
            </div>

            <div className={styles.modal_container_drag}>
              <div className={styles.modal_drag}>
                {/* <label htmlFor="arquivo">
                        <h3>Arrase & Solte o arquivo</h3>
                        <p>Ou selecione o arquivo no explorador</p>
                    </label> */}

                <div
                  className={styles.modal_drag_drop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  // onClick={handleShowListImages}

                  // style={{
                  //   height: '100px',
                  //   width: '100%',
                  //   border: '2px dashed black',
                  //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                  // }}
                  // className={styles.modal_drag}
                >
                  <label htmlFor="arquivo">
                    <CloundArrowUp color={'#B900FE'} />
                    <h2
                      className={
                        filesSelectedArray.length !== 0
                          ? styles.modal_drag_drop_title_short
                          : styles.modal_drag_drop_title
                      }
                    >
                      Arraste & Solte o arquivo
                    </h2>
                    <p
                      className={
                        filesSelectedArray.length !== 0
                          ? styles.modal_drag_drop_sub_title_short
                          : styles.modal_drag_drop_sub_title
                      }
                    >
                      Ou selecione o arquivo no explorador
                    </p>
                  </label>
                  <input
                    type="file"
                    name="arquivo"
                    id="arquivo"
                    onChange={(e) => onFileChange(e)}
                  ></input>
                  {/* {fileData && (
                            <img
                                style={{ width: 50, height: 50 }}
                                src={fileData}
                                alt="file-preview"
                            />
                            )} */}
                </div>
              </div>

              {/* color picker */}
            </div>
            <div className={styles.color_pallete}>
              <ColorPalete
                colors={['#00A124', '#873624', '#000000', '#3C0E58', '#9D981F']}
              />
            </div>

            <Button variant={'primary'}>Atualizar</Button>
            <Button variant={'secondary'}>Editor Avanaçado</Button>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.input_search_container}>
            <Input value={'[NOME DO PROTÓTIPO]'} />
            <Button
              variant={'primary'}
              style={{ borderRadius: '20px' }}
              onClick={handleClickSave}
            >
              Salvar <CloundUp />
            </Button>
          </div>

          <div className={styles.image_center_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
          </div>
          <Editor />
        </div>

        <div className={styles.rightContainer}>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button
              style={{
                padding: '5px 45px',
                backgroundColor: '#3CBC00',
                borderColor: '#3CBC00',
                gap: '12px',
              }}
              variant={'primary'}
            >
              <p>Selecionado</p>
              <CheckBadge color="white" />
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
          <div className={styles.image_container}>
            <img
              src="https://cdn.pixabay.com/photo/2014/11/18/11/09/paper-535983_1280.png"
              alt=""
            />
            <Button style={{ padding: '5px 45px' }} variant={'primary'}>
              Selecionar
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
