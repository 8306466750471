import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import { CheckBadge, Clock } from '../../../../assets/svgs/icons'
import { useNavigate } from 'react-router-dom'

import { UserAccountsService } from '../../../../services'

export const Parameterizations = () => {
  const [settingsCompleted, setSettingsCompleted] = useState({
    parameterizationCompleted: true,
  })

  const navigate = useNavigate()

  const handleNavigate = () => {
    if (settingsCompleted?.parameterizationCompleted) {
      navigate('/StepsPage')
    } else {
      navigate('/StepsPage', {
        state: { uncompletedSteps: settingsCompleted?.uncompletedSteps },
      })
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const { data, status } =
      await UserAccountsService.getUserAccountsParameterizationCompleted()

    if (status === 200) {
      setSettingsCompleted(data)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      {settingsCompleted?.parameterizationCompleted ? (
        <div className={styles.container_parameterizations}>
          <div className={styles.box_parameterization_content}>
            <div className={styles.parameterizations_module}>
              <div className={styles.module}>
                <h3 className={styles.module_title}>Parametrização</h3>
              </div>
            </div>
            <div className={styles.container_parameterizations_content}>
              <CheckBadge color={'#0FBE00'} size="48" />
              <p className={styles.title_for_completed_configurations}>
                Parabéns!
              </p>
              <div className={styles.description_text}>
                <p className={styles.content_text}>
                  Você concluiu a configurações de parametrização com sucesso!
                </p>
                <p className={styles.content_text}>
                  Agora nossa IA poderá criar publicações alinhadas com suas
                  preferências
                </p>
                <p className={styles.content_text}>
                  Caso deseje editar clique no botão abaixo
                </p>
              </div>

              <button
                className={styles.button_finish_parameterizations}
                onClick={() => handleNavigate()}
              >
                Editar Parametrizações
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container_parameterizations}>
          <div className={styles.box_parameterization_content}>
            <div className={styles.parameterizations_module}>
              <div className={styles.module}>
                <h3 className={styles.module_title}>Parametrização</h3>
              </div>
            </div>
            <div
              className={styles.container_parameterizations_incomplete_content}
            >
              <Clock color={'#FF8B00'} width="48" height="48" />
              <p className={styles.title_for_incomplete_configurations}>
                Aguardando Conclusão...
              </p>
              <div className={styles.description_text}>
                <p className={styles.content_text_incomplete}>
                  Nosso sistema identificou que você preencheu apenas algumas
                  etapas da sua parametrização.
                </p>
                <p className={styles.content_text_incomplete}>
                  Para receber publicações mais alinhadas com suas preferências
                  é necessário concluir a parametrização.
                </p>
                <p className={styles.content_text_incomplete}>
                  Para continuar clique no botão abaixo
                </p>
              </div>

              <button
                className={styles.button_finish_parameterizations_incomplete}
                onClick={() => handleNavigate()}
              >
                Editar Parametrizações
              </button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  )
}
