// import React from 'react'
import styles from './styles.module.css'
import {
  Clock,
  Calendar as CalendarIcon,
  CheckBadge,
  CircleX,
} from '../../../../assets/svgs/icons'
import SchedulerCalendarMini from '../../../SchedulerCalendar/SchedulerCalendarMini'
import SchedulerCalendar from '../../../SchedulerCalendar'
import { useState } from 'react'

export const PublicationStep = ({ modalData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())

  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString)
      if (!isNaN(date)) {
        return date.toLocaleDateString('pt-BR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      } else {
        return '' // Retorna uma string vazia se a data for inválida
      }
    } catch (error) {
      return '' // Retorna uma string vazia em caso de erro
    }
  }

  function formatHour24(data) {
    data = new Date(data)

    let horas = data.getHours()
    let minutos = data.getMinutes()

    horas = horas < 10 ? '0' + horas : horas
    minutos = minutos < 10 ? '0' + minutos : minutos

    return horas + ':' + minutos
  }

  const handleChangeHour = (e) => {
    const inputValue = e.target.value

    // Verifica se o valor digitado tem no máximo 2 caracteres
    if (inputValue.length <= 2) {
      // Se o primeiro caractere for maior que 2, não atualiza o valor
      if (inputValue.length === 1 && inputValue > '2') {
        return
      }

      // Se o primeiro caractere for igual a 2, o segundo não pode ser maior que 3
      if (
        inputValue.length === 2 &&
        valueHour[0] === '2' &&
        inputValue[1] > '3'
      ) {
        return
      }

      // Atualiza o valor no estado
      setValueHour(inputValue)
    }
  }

  const handleChangeMinute = (e) => {
    const inputValue = e.target.value

    // Verifica se o valor digitado tem no máximo 2 caracteres
    if (inputValue.length <= 2) {
      // Se o primeiro caractere for maior que 2, não atualiza o valor
      if (inputValue.length === 1 && inputValue > '5') {
        return
      }

      // Atualiza o valor no estado
      setValueMinute(inputValue)
    }
  }

  return (
    <div className={styles.step_container}>
      <div className={styles.calendar_container}>
        <SchedulerCalendarMini />

        {/* <SchedulerCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        /> */}
      </div>
      <div className={styles.label_container}>
        <div className={styles.data_container}>
          <div className={styles.text}>
            <p className={styles.hour_text}>Horarío</p>

            <div className={styles.box_hour_publication}>
              <input
                className={styles.hours}
                placeholder="00"
                value={valueHour}
                onChange={handleChangeHour}
                maxLength={2}
              />
              :
              <input
                className={styles.minutes}
                placeholder="00"
                value={valueMinute}
                onChange={handleChangeMinute}
                maxLength={2}
              />
            </div>
          </div>
        </div>
        <div className={styles.data_container}>
          <div className={styles.box_buttons}>
            <button className={styles.button_post}>
              Publicar Post <CheckBadge color={'#e6e6e6'} size="17" />
            </button>
            <button className={styles.button_cancel}>
              Cancelar <CircleX color={'#e6e6e6'} width={'20'} height={'20'} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
