/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'

import {
  ArrowDown,
  CloundUp,
  InterrogationCircleIcon,
  NumberOne,
  Pen2,
} from '../../../assets/svgs/icons'

import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'

import { ProductService, TargetAudienceService } from '../../../services/'

import { motion } from 'framer-motion'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
}

function StepFirstPass({
  setStep,
  // purposeOfPublication,
  // setPurposeOfPublication,
  // publicationFormat,
  // setPublicationFormat,
  // product,
  // setProduct,
  // targetAudience,
  // setTargetAudience,
  // iWantPublication,
  // setIWantPublication,
  //publicationDraft,
  purposeOfPublicationRef,
  publicationFormatRef,
  productRef,
  targetAudienceRef,
  iWantPublicationRef,

  clientData,
}) {
  const navigation = useNavigate()

  const [expand, setexpand] = useState(false)

  const [expandPostObjectValue, setExpandPostObjectValue] = useState('')
  const [purposeOfPublicationValue, setPurposeOfPublicationValue] =
    useState(false)
  const [productValue, setProductValue] = useState('')
  const [targetAudienceValue, setTargetAudienceValue] = useState('')

  setTargetAudienceValue

  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
    useState([])

  useEffect(() => {
    loadProductServiceData()
    loadtargetAudienceData()

    setPurposeOfPublicationValue(purposeOfPublicationRef?.current ?? '')
  }, [clientData])

  // useEffect(() => {
  //   // Verifique se purposeOfPublicationRef.current foi atualizado
  //   if (purposeOfPublicationRef.current !== '') {
  //     // Se foi atualizado, defina expandPostObject como true
  //     setExpandProduct(true)
  //   }
  // }, [purposeOfPublicationRef.current])

  const formatOption = [{ label: 'Quadrado', value: 1 }]
  const loadProductServiceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await ProductService.getProductByNotBusinessClientList()

      const nothingProduct = {
        label: 'Nenhum',
        value: null,
      }

      if (responseStatus === 200) {
        const options = data.map((item) => ({
          ...item,
          price: item.value,
          label: item.name,
          value: item.uuid,
        }))

        // Adicione nothingProduct como o primeiro objeto na matriz de opções
        const productTypeOptions = [nothingProduct, ...options]

        setProductTypeOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await ProductService.getProductByBusinessClientIdList(clientData?.uuid)
      if (responseStatus === 200)
        setProductTypeOptions(
          data.map((item) => ({
            ...item,
            price: item.value,
            label: item.name,
            value: item.uuid,
          }))
        )
    }
  }

  const loadtargetAudienceData = async () => {
    if (!clientData?.uuid) {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByNotClientId()

      const nothingTargetAudience = {
        label: 'Nenhum',
        value: null,
      }

      if (responseStatus === 200) {
        const options = data.map((item) => ({
          ...item,
          label: item.name,
          value: item.uuid,
        }))

        // Adicione nothingProduct como o primeiro objeto na matriz de opções
        const productTypeOptions = [nothingTargetAudience, ...options]

        setProductTargetAudienceOptions(productTypeOptions)
      }
    } else {
      const { data, responseStatus } =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )

      if (responseStatus === 200)
        setProductTargetAudienceOptions(
          data.map((item) => ({
            ...item,
            label: item.name,
            value: item.uuid,
          }))
        )
    }
  }

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }

  const nextButton = async (navigate) => {
    if (navigate === 'next') {
      setStep(STEPS.STEP_SECOND_PASS_IMAGE)
    }
  }

  const handleExpand = () => {
    setexpand(!expand)
  }

  return (
    <div className={styles.container_header}>
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Nova</p>
        <p className={styles.title_new_posts_title}>Publicação</p>
      </div>
      <div className={styles.title_new_posts_pass}>
        <div>
          <NumberOne width={'40px'} height={'40px'} />
        </div>
        <div>
          <p className={styles.label_pass}>1° Passo</p>
          <p className={styles.title_pass}>Headlines</p>
        </div>
      </div>
      <div className={styles.container_form}>
        <form className={styles.form}>
          <div className={styles.form_first_options}>
            <div className={styles.container_form_first_options}>
              <div className={styles.form_container_input}>
                <label className={styles.title_select}>
                  Agora, escolha o formato da publicação:
                </label>
                <div className={styles.box_select}>
                  <SelectSquare
                    htmlFor="select"
                    placeholder="Selecione"
                    defaultSelected={formatOption.find(
                      (e) => e.value === publicationFormatRef.current
                    )}
                    options={formatOption}
                    onClickItem={({ value }) => {
                      // publicationFormatRef.current = value
                      publicationFormatRef.current = formatOption.find(
                        (e) => e.value === value
                      )

                      setExpandPostObjectValue(value)
                    }}
                  />
                </div>
              </div>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                animate={{ opacity: 1 }}
                className={styles.form_container_input}
              >
                {expandPostObjectValue && (
                  <div className={styles.form_container_input}>
                    <label className={styles.title_select}>
                      Primeiro escolha o objetivo da publicação:
                    </label>
                    <div className={styles.box_select}>
                      <input
                        className={styles.form_input}
                        htmlFor="select"
                        placeholder="Digite"
                        value={purposeOfPublicationValue}
                        onChange={(e) => {
                          purposeOfPublicationRef.current = e.target.value
                          setPurposeOfPublicationValue(e.target.value)
                        }}
                      ></input>
                    </div>
                  </div>
                )}

                {purposeOfPublicationValue != '' && (
                  <div className={styles.form_container_input}>
                    <label className={styles.title_select}>
                      Escolha o produto:
                    </label>
                    <div className={styles.box_select}>
                      <SelectSquare
                        htmlFor="select"
                        placeholder="Selecione"
                        defaultSelected={productTypeOptions.find(
                          (e) => e.value === productRef.current
                        )}
                        options={productTypeOptions}
                        onClickItem={({ value }) => {
                          // handleChange('educationLevelId', value)

                          // productRef.current = value
                          productRef.current = productTypeOptions.find(
                            (e) => e.value === value
                          )
                          setProductValue(value)
                        }}
                      />
                    </div>
                  </div>
                )}

                {productValue != '' && (
                  <div className={styles.form_container_input}>
                    <label className={styles.title_select}>
                      Escolha o público-alvo:
                    </label>
                    <div className={styles.box_select}>
                      <SelectSquare
                        htmlFor="select"
                        placeholder="Selecione"
                        defaultSelected={productTargetAudienceOptions.find(
                          (e) => e.value === targetAudienceRef.current
                        )}
                        options={productTargetAudienceOptions}
                        onClickItem={({ value }) => {
                          // handleChange('educationLevelId', value)
                          // targetAudienceRef.current = value
                          targetAudienceRef.current =
                            productTargetAudienceOptions.find(
                              (e) => e.value === value
                            )
                          setTargetAudienceValue(value)
                        }}
                      />
                    </div>
                  </div>
                )}

                {targetAudienceValue != '' && (
                  <div className={styles.form_container_textarea}>
                    <label className={styles.title_textarea}>
                      Finalmente descreva o que você deseja para esta
                      publicação!
                    </label>
                    <div className={styles.box_textarea}>
                      <textarea
                        className={styles.textarea}
                        placeholder="Digite sua prompt..."
                        value={iWantPublicationRef.current}
                        onChange={(e) => {
                          // setIWantPublication(e.target.value)
                          iWantPublicationRef.current = e.target.value
                        }}
                      ></textarea>
                      <div className={styles.box_interrogation_svg}>
                        <InterrogationCircleIcon
                          color={'#373737'}
                          width={'20px'}
                          height={'20px'}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              cursor: 'pointer',
            }}
            onClick={handleExpand}
          >
            {expand ? (
              <p className={styles.more_options_text}> Menos Opções</p>
            ) : (
              <p className={styles.more_options_text}>Opções Adicionais</p>
            )}
            <div className={expand ? styles.box_rotate : styles.box_arrow}>
              <ArrowDown color={'#190027'} />
            </div>
          </div>

          <div
            className={styles.form_more_options}
            style={{ display: expand ? '' : 'none' }}
          >
            <div className={styles.button_new_publication}>
              <div className={styles.button_new_publication_icon}>
                <CloundUp color={'#E6E6E6'} />
              </div>
              <div className={styles.button_new_publication_info}>
                <div className={styles.button_new_publication_info_title}>
                  <h2>Nova publicação</h2>
                </div>
                <div className={styles.button_new_publication_info_description}>
                  <p>
                    Opção destinada caso você já tenha sua publicação pronta e
                    deseja realizar o agendamento
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.button_advanced_editor}>
              <div className={styles.button_advanced_editor_icon}>
                <Pen2 width={50} height={50} color={'#292929'} />
              </div>
              <div className={styles.button_advanced_editor_info}>
                <div className={styles.button_advanced_editor_info_title}>
                  <h2>Editor avançado</h2>
                </div>
                <div className={styles.button_advanced_editor_info_description}>
                  <p>
                    Crie uma nova publicação com suas próprias habilidades de
                    design
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              handleNavigate('/NewPostPage')
            }}
          >
            <p className={styles.button_title}>Cancelar e voltar</p>
          </button>
          <button
            className={styles.button_next}
            onClick={() => {
              nextButton('next')
            }}
          >
            Prosseguir
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepFirstPass
