import { useState } from 'react'
import { BoxChecket, BoxNotChecket } from '../../../assets/svgs/icons'
import InputMask from 'react-input-mask'
import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'

const STEPS = {
  STEP_PASS_PASSWORD: 'stepPassword',
  STEP_PASS_ADDRESS: 'stepAddress',
}
function StepCPF({
  setCpf,
  cpf,
  setNumberStep,
  setStep,
  setCorporateReason,
  corporateReason,
  setCnpj,
  cnpj,
  setFantasyName,
  fantasyName,
}) {
  const [exibe, setExibe] = useState(false)

  async function isValidCNPJ(value) {
    console.log('CNPJ', cnpj)
    const numericValue = value.replace(/[^\d]+/g, '')
    console.log('CNPJ', numericValue)

    if (numericValue.length === 14) {
      // Verifica se é um CNPJ
      const invalidCpns = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999',
      ]

      if (invalidCpns.includes(numericValue)) {
        console.log('CNPJ2', cnpj)
        return false
      }

      let size = numericValue.length - 2
      let numbers = numericValue.substring(0, size)
      const digits = numericValue.substring(size)
      let sum = 0
      let pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(0), 10)) {
        return false
      }

      size = size + 1
      numbers = numericValue.substring(0, size)
      sum = 0
      pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(1), 10)) {
        return false
      }
      console.log('TEste cnpj')
      return true // É um CNPJ válido
    } else {
      console.log('Fecho')
      return false
    }
  }

  async function isValidCPF(value) {
    console.log('TEste cpf', value)
    const numericValue = value.replace(/[^\d]+/g, '')

    console.log('TEste cpf', numericValue)

    if (numericValue.length === 11) {
      // Verifica se é um CPF
      const invalidCpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ]

      if (invalidCpfs.includes(numericValue)) {
        console.log('TEste cpf2')
        return false
      }

      console.log('TEste cpf3')

      let sum = 0
      let mod = 0

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(numericValue.substring(i - 1, i), 10) * (11 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        //console.log('Tste cnp3')
        mod = 0
      }

      if (mod !== parseInt(numericValue.substring(9, 10), 10)) {
        console.log('TEste cpf4')
        return false
      }

      sum = 0

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(numericValue.substring(i - 1, i), 10) * (12 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(numericValue.substring(10, 11), 10)) {
        return false
      }
      console.log('TEste cpf')
      return true // É um CPF válido
    } else {
      return false // Não é nem CPF nem CNPJ
    }
  }

  const handleNavigate = async (type) => {
    const isValidCpf = await isValidCPF(cpf)
    const isValidCnpj = await isValidCNPJ(cnpj)
    console.log(isValidCpf)
    if (type === 'next') {
      // if (isValidCpf === false) {
      //   notifyErrorCustom(
      //     'O campo de CPF não foi preenchido com um valor válido'
      //   )
      //   return false
      // }
      // if (isValidCnpj === false && cnpj != '') {
      //   notifyErrorCustom(
      //     'O campo de CNPJ não foi preenchido com um valor válido'
      //   )
      //   return false
      // }
      ~setStep(STEPS.STEP_PASS_ADDRESS)
    } else {
      setStep(STEPS.STEP_PASS_PASSWORD)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <div className={styles.box_content}>
            <p style={{ margin: '0px' }}>Cadastro</p>
            <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

            <div className={styles.box_input_name}>
              <p>Digite seu CPF</p>

              <InputMask
                className={styles.input_name}
                type="text"
                value={cpf}
                mask="999.999.999-99"
                placeholder="CPF"
                onChange={(e) => setCpf(e.target.value)}
              />
            </div>
            <div className={styles.check_cnpj}>
              <div
                className={styles.box_check_cnpj}
                onClick={() => setExibe(!exibe)}
              >
                {exibe ? (
                  <BoxChecket />
                ) : (
                  <BoxNotChecket borderColor={'#000'} />
                )}
              </div>
              <p>Este perfil é destinado para uma empresa ?</p>
            </div>
            {exibe && (
              <div className={styles.box_fantasy_name}>
                <p>CNPJ</p>
                <InputMask
                  className={styles.input_name}
                  type="text"
                  mask="99.999.999/9999-99"
                  placeholder="CNPJ"
                  value={cnpj}
                  onChange={(event) => setCnpj(event.target.value)}
                />
                <p>Razão Social</p>
                <input
                  className={styles.input_name}
                  value={corporateReason}
                  onChange={(event) => setCorporateReason(event.target.value)}
                />
                <p>Nome Fantasia</p>
                <input
                  className={styles.input_name}
                  value={fantasyName}
                  onChange={(event) => setFantasyName(event.target.value)}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepCPF
