import { Select } from '../../../Select/LightSelect'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styles from './styles.module.css'

export const CampaingStep = ({
  campaingOptions,
  productOptions,
  targetPublicOptions,
  onChangeItems,
  targetPublicOptionSelected,
  productOptionSelected,
  campaingOptionSelected


}) => {

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} className={styles.container}>
      <Select
        label={'Campanha'}
        options={campaingOptions}
        placeholder={'Selecione'}
        defaultSelected={campaingOptionSelected}
        onSelectItem={(e)=>onChangeItems(e, 'campaing')}/>
      <Select
        label={'Produto'}
        options={productOptions}
        placeholder={'Selecione'}
        defaultSelected={productOptionSelected}
        onSelectItem={(e)=>onChangeItems(e, 'product')}/>
      <Select
        label={'Publico-Alvo'}
        options={targetPublicOptions}
        placeholder={'Selecione'}
        defaultSelected={targetPublicOptionSelected}
        onSelectItem={(e)=>onChangeItems(e,'targetPublic')}/>
    </motion.div>
  )
}


CampaingStep.propTypes = {
  campaingOptions:PropTypes.array,
  productOptions:PropTypes.array,
  targetPublicOptions:PropTypes.array,
  campaingOptionSelected:PropTypes.object,
  productOptionSelected:PropTypes.object,
  targetPublicOptionSelected:PropTypes.object,
  onChangeItems:PropTypes.func
}
