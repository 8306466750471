import React from 'react'
import styles from './styles.module.css'
import {
  ArrowUturnLeft,
  Minus,
  Plus,
  RoundedArrow,
} from '../../assets/svgs/icons'

export const Editor = ({ percentage = 100 }) => {
  return (
    <div className={styles.edit_container}>
      <button>
        {' '}
        <ArrowUturnLeft size={20} color="#9900ED" />
      </button>
      <button>
        <RoundedArrow />
      </button>
      <div className={styles.divider}></div>
      <button className={styles.plus}>
        {' '}
        <Plus color="#9900ED" />{' '}
      </button>
      <div className={styles.percentage}>{percentage}%</div>
      <button>
        <Minus color="#9900ED" />
      </button>
    </div>
  )
}
