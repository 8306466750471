/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { createContext, useContext, useEffect, useMemo, useState } from 'react'
//import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const RedirectContext = createContext()

export const RedirectProvider = ({ children }) => {
  const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl', null)

  const [idParam, setIdParam] = useLocalStorage('clientId', null)
  //const navigate = useNavigate()
  // const [userData, setUserData] = useLocalStorage('redirectUrl', url)
  // localStorage.setItem('clientId', id)

  const setRedirect = (url, id) => {
    setRedirectUrl(url)
    setIdParam(id)
  }

  const contextValue = {
    redirectUrl,
    idParam,
    setRedirect,
  }

  return (
    <RedirectContext.Provider value={contextValue}>
      {children}
    </RedirectContext.Provider>
  )
}

export const useRedirect = () => {
  const context = useContext(RedirectContext)
  if (!context) {
    throw new Error('useRedirect must be used within a RedirectProvider')
  }
  return context
}
