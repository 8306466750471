import { useEffect, useState } from 'react'

import styles from './styles.module.css'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Switch } from 'antd'

import { PythonService } from '../../../../services'
import { Loading } from '../../../../components/Loading'

export const EffectsStep = ({ effects, setImageUrl, editTemplate }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [effects])

  const handleChecked = async (e, index, enabled) => {
    setLoading(true)

    const newEffects = { ...effects }
    newEffects.params[e.originalIndex].activeEffect = enabled

    const returnNewTemplate = await PythonService.postGenerateArtEditTemplate([
      effects,
    ])

    setImageUrl(
      'data:image/png;base64,' +
        returnNewTemplate?.data?.data[0]?.srcComTratativa
    )

    editTemplate(index, returnNewTemplate?.data?.data[0])

    setLoading(false)
  }

  const SwitchField = ({ e, index }) => {
    return (
      <div key={index} className={styles.effectItem}>
        <span>
          <Switch
            size="small"
            checked={e.activeEffect}
            onClick={() => {
              handleChecked(e, index, !e.activeEffect)
            }}
          />
        </span>
        {e.effect}
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container}
    >
      <Loading enable={loading} />
      <span className={styles.label}>Efeitos adicionados</span>
      <div className={styles.tagsContainerEffectsContainer}>
        <div className={styles.effectsContainer}>
          {effects.params
            .map((e, originalIndex) => ({ ...e, originalIndex }))
            .filter(
              (e) =>
                e.effect !== null &&
                e.effect !== undefined &&
                e.effect !== 'none'
            )
            .map((e, index) => (
              <div key={index} className={styles.effectItem}>
                <span style={{ color: 'black' }}>{e.effect}</span>
                <SwitchField e={e} index={index} />
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  )
}

EffectsStep.propTypes = {
  effects: PropTypes.array,
  aditionalEffects: PropTypes.array,
  onClickDeleteTag: PropTypes.func,
  onClickPlus: PropTypes.func,
}
