import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { useEffect, useRef, useState} from 'react'
import { ChevronDown } from '../../../assets/svgs/icons'
export const Select = ({
  options,
  label,
  htmlFor,
  placeholder,
  onSelectItem,
  defaultSelected
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({label:'', value:''})

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  const ref = useRef(null)


  const handleSelect = (e) => {
    setContainerVisible(false)
    setSelectedItem(e)
    onSelectItem(e)
  }

  return (
    <div ref={ref} className={styles.inputSelectContainer}>
      <label htmlFor={htmlFor}>{label}</label>
      <button className={styles.buttonSelect} onClick={()=>setContainerVisible(prev=>!prev)} type="text">
        {selectedItem?.label || defaultSelected?.label || placeholder}
        <div style={{display:'flex', transition:'rotate 50ms'}} className={containerVisible ? styles.rotate : ''}>
          <ChevronDown size='24'/>
        </div>
      </button>
      {containerVisible && (
        <div className={styles.selectContainer}>
          {options.map((option, index) => (
            <button
              key={index}
              className={styles.selectContainerItem}
              onClick={()=>handleSelect(option)}
            >
              <span>
                {option.label}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array,
  label:PropTypes.string,
  htmlFor:PropTypes.string,
  defaultSelected:PropTypes.object,
  onSelectItem:PropTypes.func,
  placeholder:PropTypes.string
};
