import styles from './styles.module.css'
import {func, object, array} from 'prop-types'
import { ImageCard } from '../../../components/ImageCard'
import { motion } from 'framer-motion'

export const ProductVizualization = ({
  product,
  imagesArray,
  onExcludeFile,
}) => {

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles.container_page}>
      <div className={styles.container_content}>
        <div className={styles.container_form}>
          <p className={styles.title_new_posts_title}>{product?.name}</p>
          <div className={styles.container_content_form}>
            <div className={styles.box_form}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Tipo de Produto
                </label>
                <p className={styles.bold}>{product?.productTypeValue}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Descrição do produto
                </label>
                <p className={styles.description_input_form}>
                  {product?.description}
                </p>
              </div>
            </div>

            <p className={styles.title_new_posts_title}>Valores</p>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Preço do produto
                </label>
                <p className={styles.bold}>{product?.value}</p>
              </div>

              <div className={styles.box_input_form}>
                <label className={styles.label_input_form}>
                  Preço Promocional
                </label>
                <p className={styles.bold}>{product?.promotionalValue}</p>
              </div>
            </div>

            <div className={styles.title_new_posts_title}>Imagens</div>

            <div className={styles.uploads_files}>
              {/* <div className={styles.modal_container_drag}>
                <div className={styles.modal_drag}>
                  <div className={styles.modal_drag_drop}>
                    <label htmlFor="arquivo">
                      <CloundArrowUp color={'#B900FE'} />
                      <h2 className={styles.modal_drag_drop_title}>
                        Arraste & Solte o arquivo
                      </h2>
                      <p className={styles.modal_drag_drop_sub_title}>
                        Ou selecione o arquivo no explorador
                      </p>
                    </label>
                    <input
                      type="file"
                      name="arquivo"
                      id="arquivo"
                      onChange={onChangeFile}
                    ></input>

                  </div>
                </div>
                <button className={styles.button_upload}>Fazer Upload</button>
              </div> */}

              <div className={styles.images_container}>
                {imagesArray?.map((e, index)=> e.status != 'deleted' ? (
                  <ImageCard 
                    key={index} 
                    image={e} 
                    onClickButton={()=>onExcludeFile(index)}>
                  </ImageCard>
                ) : null)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div >
  )
}

export default ProductVizualization

ProductVizualization.propTypes = {
  product : object,
  onClickPrevious: func,
  imagesArray:array,
  onExcludeFile:func,
  onChangeFile:func
}


