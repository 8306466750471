/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styles from './styles.module.css'

// import posteiLogoColor from '../../../assets/logos/postei-logo.svg'
// import posteiLogoTextColor from '../../../assets/logos/postei-logo-text.svg'

// import { InterrogationCircleIcon } from '../../../assets/svgs/icons'
import {
  UserIcon,
  InformationIcon,
  BrushIcon,
  PhotoIcon,
  SocialMediaIcon,
  TargetIcon,
  CarIcon,
  ChatIcon,
  ImageListIcon,
  ArchetypeIcon,
  PreferencesIcon,
} from '../../../assets/svgs/iconsSteps'

//import StepRegistrationData from '../../containers/stepRegistrationData'
import StepBusinessInformation from '../containers/stepBusinessInformation'
import StepBranding from '../containers/stepBranding'
import StepLogos from '../containers/stepLogos'

import NoticeStep from '../containers/stepNotice'

import StepVoiceTone from '../containers/stepVoiceTone'
import StepVisualPreferences from '../containers/stepVisualPreferences'
import StepEffects from '../containers/stepEffects'
import StepVisualReferences from '../containers/stepVisualReferences'
// import StepSocialMedia from '../containers/stepSocialMedia'
// import StepTargetAudience from '../containers/stepTargetAudience'
// import StepProduct from '../containers/stepProduct'
import StepBrandArchetype from '../containers/stepBrandArchetype'
// import NewCampaign from '../NewCampaignStep'
import StepCompleted from '../containers/stepCompleted'
import NewCampaignStep from '../NewCampaignStep'

// import { useLocation } from 'react-router-dom'

const STEPS = {
  //  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',

  STEP_NOTICE: 'NoticeStep',

  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
  //STEP_NEW_CAMPAIGN: 'NewCampaign',
  STEP_NEW_CAMPAIGN: 'NewCampaignStep',
  STEP_CAMPAIGN_LIST: 'CampaignList',
  STEP_COMPLETED: 'completed',
}

function StepsPage({
  setSteps,
  campaignItem,
  setCampaignItem,
  clientData,
  uncompletedSteps,
}) {
  // const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_BRANDING)
  const [iaData, setIaData] = useState(undefined)

  const [firstLoginParam, setfirstLoginParam] = useState({})

  const handleNavigate = () => {
    setSteps(STEPS.STEP_CAMPAIGN_LIST)
  }

  useEffect(() => {
    // const uncompletedSteps = location.state?.uncompletedSteps
    // setfirstLoginParam(location.state?.firstLogin)
    // if (uncompletedSteps?.length > 0) {
    //   setStep(uncompletedSteps[0])
    // }

    if (uncompletedSteps?.length > 0) {
      setStep(uncompletedSteps[0])
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [step])

  const StepRender = ({
    selectedPage,
    title,
    stage,
    Icon,
    selectedStep,
    setStep,
  }) => {
    let textColor = '#0FBE00'
    let iconColor = '#FFFF'
    let circleColor = '#0FBE00'
    let minCircleColor = '#0FBE00'

    if (selectedPage) {
      textColor = '#20180F'
      circleColor = '#FF8B00'
      iconColor = '#FFFFFF'
      minCircleColor = '#FF8B00'
    }

    return (
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        onClick={() => setStep(selectedStep)}
      >
        <div style={{ marginRight: 10, marginTop: 20 }}>
          <p
            style={{
              color: textColor,
              margin: 0,
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {title}
          </p>
          <p
            style={{
              color: '#747474',
              textAlign: 'end',
              margin: 0,
              fontWeight: 300,
              fontSize: 10,
            }}
          >
            {stage}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <div
            style={{
              background: circleColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1000,
              padding: 8,
            }}
          >
            <Icon color={iconColor} />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ background: '#424242', flex: 1, width: 0.5 }} />
          <div
            style={{
              background: minCircleColor,
              padding: 3,
              borderRadius: 100,
            }}
          />
          <div style={{ background: '#424242', flex: 1, width: 0.5 }} />
        </div>
      </div>
    )
  }

  if (step === STEPS.STEP_NOTICE)
    return <NoticeStep setStep={setStep} firstLogin={firstLoginParam} />

  return (
    <div className={styles.container_page}>
      <div className={styles.page}>
        <div className={styles.container_title_new_posts}>
          <div className={styles.title_new_posts}>
            <p className={styles.title_new_posts_label}>Campanhas</p>
            <p className={styles.title_new_posts_title}>Nova Campanha</p>
          </div>
          <div>
            <button
              className={styles.button_new_user}
              onClick={() => handleNavigate()}
            >
              {'Cancelar'}
            </button>
          </div>
        </div>

        <div>
          {step === STEPS.STEP_NEW_CAMPAIGN && (
            <NewCampaignStep
              setStep={setStep}
              state={campaignItem}
              setCampaignItem={setCampaignItem}
              clientData={clientData}
            />
          )}
        </div>
        {/* 
        <div className={styles.data_field}>
          <div>
            


            <div className={styles.divider} style={{ marginTop: 40 }} />
          </div>
        </div> */}

        {/* STEP_REGISTRATION_DATA */}

        {/* <div>
          {step === STEPS.STEP_REGISTRATION_DATA && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                <StepRender
                  title={'Dados Cadastrais'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepRegistrationData setStep={setStep} />
              </div>
            </div>
          )}
        </div> */}

        {/* STEP_BUSINESS_INFORMATION */}
        <div>
          {step === STEPS.STEP_COMPLETED && (
            <StepCompleted setSteps={setSteps} campaignItem={campaignItem} />
          )}
        </div>

        {/* <div>
          {step === STEPS.STEP_BUSINESS_INFORMATION && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepBusinessInformation
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div> */}

        {/* STEP_BRANDING */}

        <div>
          {step === STEPS.STEP_BRANDING && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepBranding
                  setStep={setStep}
                  setSteps={setSteps}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_LOGOS */}

        <div>
          {step === STEPS.STEP_LOGOS && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepLogos
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_SOCIAL_MEDIA */}

        {/* <div>
          {step === STEPS.STEP_SOCIAL_MEDIA && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                />
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                <div>
                  <div
                    style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <div
                        style={{
                          flex: 1,
                          background: '#D2D2D2',
                          height: 1,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          color: '#FFFFFF',
                          margin: 0,
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        {'Etapas Opcionais'}
                      </p>
                    </div>

                    <p
                      style={{
                        color: '#747474',
                        textAlign: 'end',
                        margin: 0,
                        fontWeight: 300,
                        fontSize: 10,
                      }}
                    >
                      {'ETAPAS NÃO OBRIGATÓRIAS'}
                    </p>
                  </div>
                </div>

                <StepRender
                  title={'Redes Sociais'}
                  stage={'ETAPA 5'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                  Icon={SocialMediaIcon}
                  selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepSocialMedia setStep={setStep} />
              </div>
            </div>
          )}
        </div> */}

        {/* STEP_TARGET_AUDIENCE */}

        {/* STEP_PRODUCT */}

        {/* STEP_VOICE_TONE */}

        <div>
          {step === STEPS.STEP_VOICE_TONE && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Tom de Voz'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VOICE_TONE}
                  Icon={ChatIcon}
                  selectedStep={STEPS.STEP_VOICE_TONE}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepVoiceTone
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_EFFECTS */}

        <div>
          {step === STEPS.STEP_EFFECTS && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Tom de Voz'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VOICE_TONE}
                  Icon={ChatIcon}
                  selectedStep={STEPS.STEP_VOICE_TONE}
                  setStep={setStep}
                />

                <StepRender
                  title={'Efeitos'}
                  stage={'ETAPA 4'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_EFFECTS}
                  Icon={ImageListIcon}
                  selectedStep={STEPS.STEP_EFFECTS}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepEffects
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_BRAND_ARCHETYPE */}

        <div>
          {step === STEPS.STEP_BRAND_ARCHETYPE && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Tom de Voz'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VOICE_TONE}
                  Icon={ChatIcon}
                  selectedStep={STEPS.STEP_VOICE_TONE}
                  setStep={setStep}
                />
                {/* 
                <StepRender
                  title={'Efeitos'}
                  stage={'ETAPA 4'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_EFFECTS}
                  Icon={ImageListIcon}
                  selectedStep={STEPS.STEP_EFFECTS}
                  setStep={setStep}
                /> */}

                <StepRender
                  title={'Marca e Arquétipo'}
                  stage={'ETAPA 5'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                  Icon={ArchetypeIcon}
                  selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepBrandArchetype
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_VISUAL_PREFERENCES */}

        <div>
          {step === STEPS.STEP_VISUAL_PREFERENCES && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                />
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Tom de Voz'}
                  stage={'ETAPA 4'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VOICE_TONE}
                  Icon={ChatIcon}
                  selectedStep={STEPS.STEP_VOICE_TONE}
                  setStep={setStep}
                />

                <StepRender
                  title={'Efeitos'}
                  stage={'ETAPA 5'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_EFFECTS}
                  Icon={ImageListIcon}
                  selectedStep={STEPS.STEP_EFFECTS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Marca e Arquétipo'}
                  stage={'ETAPA 6'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                  Icon={ArchetypeIcon}
                  selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                  setStep={setStep}
                />

                <StepRender
                  title={'Preferências visuais'}
                  stage={'ETAPA 7'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VISUAL_PREFERENCES}
                  Icon={PreferencesIcon}
                  selectedStep={STEPS.STEP_VISUAL_PREFERENCES}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepVisualPreferences
                  iaData={iaData}
                  setIaData={setIaData}
                  setStep={setStep}
                />
              </div>
            </div>
          )}
        </div>

        {/* STEP_VISUAL_REFERENCES */}

        <div>
          {step === STEPS.STEP_VISUAL_REFERENCES && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <div
                style={{
                  minWidth: 300,
                }}
              >
                {/* <StepRender
                  title={'Dados Cadastrais'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                /> */}
                {/* <StepRender
                  title={'Informações do Negócio'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                  Icon={InformationIcon}
                  selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                  setStep={setStep}
                /> */}
                <StepRender
                  title={'Branding'}
                  stage={'ETAPA 1'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRANDING}
                  Icon={BrushIcon}
                  selectedStep={STEPS.STEP_BRANDING}
                  setStep={setStep}
                />
                <StepRender
                  title={'Logos'}
                  stage={'ETAPA 2'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_LOGOS}
                  Icon={PhotoIcon}
                  selectedStep={STEPS.STEP_LOGOS}
                  setStep={setStep}
                />

                {/* <div>
                  <div
                    style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <div
                        style={{
                          flex: 1,
                          background: '#D2D2D2',
                          height: 1,
                          marginRight: 10,
                        }}
                      />
                      <p
                        style={{
                          color: '#FFFFFF',
                          margin: 0,
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        {'Etapas Opcionais'}
                      </p>
                    </div>

                    <p
                      style={{
                        color: '#747474',
                        textAlign: 'end',
                        margin: 0,
                        fontWeight: 300,
                        fontSize: 10,
                      }}
                    >
                      {'ETAPAS NÃO OBRIGATÓRIAS'}
                    </p>
                  </div>
                </div> */}

                <StepRender
                  title={'Redes Sociais'}
                  stage={'ETAPA 5'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                  Icon={SocialMediaIcon}
                  selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                  setStep={setStep}
                />

                <StepRender
                  title={'Público-Alvo'}
                  stage={'ETAPA 6'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                  Icon={TargetIcon}
                  selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                  setStep={setStep}
                />

                <StepRender
                  title={'Produtos'}
                  stage={'ETAPA 7'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_PRODUCT}
                  Icon={CarIcon}
                  selectedStep={STEPS.STEP_PRODUCT}
                  setStep={setStep}
                />

                <StepRender
                  title={'Tom de Voz'}
                  stage={'ETAPA 3'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VOICE_TONE}
                  Icon={ChatIcon}
                  selectedStep={STEPS.STEP_VOICE_TONE}
                  setStep={setStep}
                />

                <StepRender
                  title={'Efeitos'}
                  stage={'ETAPA 4'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_EFFECTS}
                  Icon={ImageListIcon}
                  selectedStep={STEPS.STEP_EFFECTS}
                  setStep={setStep}
                />

                <StepRender
                  title={'Marca e Arquétipo'}
                  stage={'ETAPA 5'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                  Icon={ArchetypeIcon}
                  selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                  setStep={setStep}
                />

                {/* <StepRender
                  title={'Preferências visuais'}
                  stage={'ETAPA 11'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VISUAL_PREFERENCES}
                  Icon={PreferencesIcon}
                  selectedStep={STEPS.STEP_VISUAL_PREFERENCES}
                  setStep={setStep}
                /> */}

                <StepRender
                  title={'Minhas referências'}
                  stage={'ETAPA 12'}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_VISUAL_REFERENCES}
                  Icon={ImageListIcon}
                  selectedStep={STEPS.STEP_VISUAL_REFERENCES}
                  setStep={setStep}
                />
              </div>

              <div style={{ marginLeft: 30, width: '100%' }}>
                <StepVisualReferences
                  setStep={setStep}
                  campaignItem={campaignItem}
                  clientData={clientData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StepsPage
