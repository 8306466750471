/* eslint-disable react/prop-types */
import { useRef, useState } from 'react'
import styles from './styles.module.css'
import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { PexelsService } from '../../../../services'
import { Loading } from '../../../../components/Loading'
import InputSearch from '../../../../components/InputSearch'

const ModalImageBank = ({ closeModal, updateImage }) => {
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])
  const [search, setSearch] = useState('')

  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const carouselRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [allImages, setAllImages] = useState([])

  const handleImageClick = (imageUrl) => {
    const imageArray = allImages.find((image) => image.id === imageUrl.id)

    if (imageArray) {
      setSelectedArrayImage([])
      setSelectedArrayImage([imageArray])
    }
    setSelectedImages([])
    setSelectedImages([imageUrl.id])
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    setSelectedImages([])
  }

  const handleLoadImages = async () => {
    const body = {
      search: search,
      limit: 10,
    }
    const response = await PexelsService.postPexelsImages(body)

    if (response.data) {
      let newResponseData = response.data.map((image, index) => {
        return {
          id: index,
          url: image.imageUri,
          base64: image.imageBase64,
        }
      })
      console.log('newResponseData', newResponseData)
      setAllImages(newResponseData)
    }

    setLoading(false)
  }

  const handleMouseDown = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    // }
  }

  const handleMouseMove = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    // }
  }

  const handleMouseUp = () => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
    // }
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />

      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('imageBank')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>

      <h2 className={styles.image_bank_text}>
        Banco de Imagens
        <Interrogation color={'#FF8B00'} width={18} height={18} />
      </h2>

      <p className={styles.image_bank_subtitle_text}>
        Escolha imagens personalizadas para o seu nicho ou produto de nossa
        coleção de imagens
      </p>

      <div className={styles.filters}>
        <div className={styles.search_images}>
          <InputSearch
            placeholder={'Pesquise...'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={() => {
              // console.log('pesquisar')
              setLoading(true)
              handleLoadImages()
            }}
            style={{
              color: '#190027',
            }}
          />
        </div>
      </div>

      <div className={styles.box_container_galery_of_image}>
        <div className={styles.container_galery_of_image}>
          <div style={{ marginBottom: 160 }}>
            <Masonry columnsCount={3} gutter="10px">
              {allImages.map((imageUrl, i) => (
                <img
                  key={i}
                  src={'data:image/png;base64,' + imageUrl.base64}
                  onClick={() => handleImageClick(imageUrl)}
                  className={
                    selectedImages.includes(imageUrl.id)
                      ? styles.selectedImage
                      : styles.image
                  }
                />
              ))}
            </Masonry>
          </div>
        </div>
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.container_list_selected_image}>
          <p>Imagem Selecionada</p>
          <div
            className={styles.content_list_selected_image}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div
              className={styles.caroucel_list_selected_image}
              ref={carouselRef}
            >
              {selectedArrayImage.map((imageArray) => (
                <div
                  className={styles.card_lis_selected_image}
                  key={imageArray.id}
                >
                  <div className={styles.container_card_lis_selected_image}>
                    <div
                      className={styles.delete_card_lis_selected_image}
                      onClick={() => handleRemoveImage(imageArray)}
                    >
                      <div style={{ cursor: 'pointer' }}>
                        <CircleX
                          color={'#FF2F00'}
                          width={'15px'}
                          height={'15px'}
                        />
                      </div>
                    </div>
                    <img
                      className={styles.item_selected_image}
                      src={'data:image/png;base64,' + imageArray.base64}
                      alt={imageArray.title}
                    />
                  </div>
                  {/* <div className={styles.item_selected_image_description}>
                    <p className={styles.item_selected_image_description_title}>
                      {imageArray.title}
                    </p>
                    <p
                      className={
                        styles.item_selected_image_description_category
                      }
                    >
                      Nome da categoria
                    </p>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
          <button
            className={styles.button_add_image}
            onClick={() => {
              // addImages()
              updateImage(selectedArrayImage)
            }}
          >
            Adicionar Imagens
          </button>
        </div>
      )}

      {/* {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            // addImages(selectedArrayImage)
            updateImage(selectedArrayImage)
          }}
        >
          Adicionar Imagens
        </button>
      )} */}
    </div>
  )
}

export default ModalImageBank
