/* eslint-disable no-unused-vars */
import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'

import ModalComponent from '../../../../components/ModalComponent'
import ModalLogoUp from './ModalLogoUp'

import { useState, createRef, useEffect } from 'react'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',

  STEP_NOTICE: 'NoticeStep',

  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

import {
  InterrogationCircleIcon,
  CloundArrowUp,
} from '../../../../assets/svgs/icons'
import { ButtonNavigateStep } from '../../ButtonNavigateStep'
import {
  BusinessService,
  CampaignService,
  LogosService,
} from '../../../../services'
import { getBusinessByClientId } from '../../../../services/business'
import { useClient } from '../../../../hooks/useClient'

const SIZES_MOCKS = [
  { size: 'Horizontal', upload: false },
  //   { size: 'Vertical', upload: false },
  //   { size: 'Quadrado', upload: false },
]

function StepLogos({ setStep, campaignItem }) {
  const [logosGroup, setLogosGroup] = useState(SIZES_MOCKS)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalVerticalOpen, setModalVerticalOpen] = useState(false)
  const [isModalSquareOpen, setModalSquareOpen] = useState(false)

  const [filesSelectedArray, setFilesSelectedArray] = useState([])

  const handleOpenModal = (size) => {
    if (size === 'Horizontal') {
      setModalOpen(true)
    }
    if (size === 'Vertical') {
      setModalVerticalOpen(true)
    }
    if (size === 'Quadrado') {
      setModalSquareOpen(true)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setModalVerticalOpen(false)
    setModalSquareOpen(false)
  }

  useEffect(() => {
    getLogos()
  }, [])

  function handleNavigate() {
    postLogos()
    setStep(STEPS.STEP_VOICE_TONE)
  }
  const { clientData } = useClient()

  const [imageHorizontalArrayGet, setImageHorizontalArrayGet] = useState([])

  const getLogos = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    const { data } = await await LogosService.getLogosByCampaign(
      campaignItem?.uuid
    )
    

    setImageHorizontalArrayGet(data)
  }

  const postLogos = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }
    //return
    let body = {}

    // Dividir a string base64 para separar o cabeçalho da imagem
    const parts = imageHorizontalArray[0]?.fileDataBase64?.split(',')
    
    const header = parts[0]
    const base64Data = parts[1]

    // Extrair o formato do arquivo e o tipo de conteúdo
    const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
    const fileContentType = matches[0]
    const fileFormat = fileContentType.split('/')[1]

    if (clientData?.uuid) {
      body = {
        businessId: businessId,
        businessClientId: clientData?.uuid,
        campaignId: campaignItem?.uuid,
        description: imageHorizontalArray[0]?.name,
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    } else {
      body = {
        businessId: businessId,
        campaignId: campaignItem?.uuid,
        description: imageHorizontalArray[0]?.name,
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    if (imageHorizontalArrayGet.length > 0) {
      
      body = {
        businessId: businessId,
        campaignId: campaignItem?.uuid,
        description: imageHorizontalArray[0]?.name,
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
        fileName: imageHorizontalArrayGet[0].fileName,
      }

      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.putLogos(imageHorizontalArrayGet[0]?.uuid, body)
    } else {
      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.postLogo(body)
    }
  }

  const UploadComponent = ({}) => {
    const [dropzoneHover, setDropzoneHover] = useState(false)

    const fileInputRef = createRef() // Cria uma referência para o input.

    const handleClick = () => {
      fileInputRef.current.click() // Aciona o input de arquivo quando a div é clicada.
    }

    function onFileChange(e) {
      const fileReader = new FileReader()

      Array.from(e.target.files).forEach((file) => {
        const reader = new FileReader()

        reader.onload = function (event) {
          let fileDataBase64 = reader.result

          let fileDataReturn = {
            fileDataBase64,
            name: file.name,
            size: file.size,
            type: file.type,
          }

          setFilesSelectedArray((current) => [...current, fileDataReturn])
        }

        reader.readAsDataURL(file)
      })
    }

    const onDragOver = (event) => {
      event.preventDefault()
      setDropzoneHover(true)
    }

    const onDragLeave = () => {
      setDropzoneHover(false)
    }

    const onDrop = (event) => {
      event.preventDefault()
      event.stopPropagation()
      setDropzoneHover(false)

      Array.from(event.dataTransfer.files).forEach((file) => {
        const reader = new FileReader()

        reader.onload = function (event) {
          let fileDataBase64 = reader.result

          let fileDataReturn = {
            fileDataBase64,
            name: file.name,
            size: file.size,
            type: file.type,
          }

          setFilesSelectedArray((current) => [...current, fileDataReturn])
        }

        reader.readAsDataURL(file)
      })
    }

    if (filesSelectedArray.length > 0) {
      return (
        <div style={{ display: 'flex' }}>
          <div>1</div>
          <div>2</div>
        </div>
      )
    }

    return (
      <div className={styles.page}>
        <div
          style={{
            display: 'flex',
            width: '100%',
          }}
          onClick={handleClick}
        >
          <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              display: 'flex',
              width: '100%',
              background: '#28003F',
              padding: 30,
              borderRadius: 12,
              cursor: 'pointer',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <CloundArrowUp color={'#B900FE'} width={48} height={48} />

            <p className={styles.subtitle_text}>Arrase & Solte o arquivo</p>
            <p className={styles.subtitle_text} style={{ fontSize: 10 }}>
              Ou selecione o arquivo no explorador
            </p>
          </div>
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            onChange={(e) => onFileChange(e)}
            style={{ display: 'none' }}
          />

          {/* <label
            htmlFor="arquivo"
            style={{
              width: '100%',
              margin: 0,
              padding: '10px 0px',
              background: '#E6E6E6',
              color: '#190027',
              borderRadius: 4,
              fontSize: 10,
              textAlign: 'center',
            }}
          >
            Adicionar
          </label> */}
        </div>
      </div>
    )
  }

  const ModalComponentDataRender = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: 200,
          minWidth: 740,
          padding: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}
        >
          <p className={styles.title_text} style={{ color: '#190027' }}>
            Realize upload da sua logomarca
          </p>
          <InterrogationCircleIcon color={'#FF8B00'} width={22} height={22} />
        </div>

        <p
          className={styles.subtitle_text}
          style={{ color: '#190027', fontSize: 12 }}
        >
          Também é possível enviar variações da logo para o formato especificado
        </p>

        <div
          style={{
            display: 'flex',
            width: '100%',
            margin: '20px 0px',
          }}
        >
          <UploadComponent />
        </div>

        <button
          style={{
            width: '100%',
            margin: 0,
            padding: '10px 60px',
            background: '#E6E6E6',
            color: '#190027',
            borderRadius: 4,
            fontSize: 12,
          }}
          onClick={handleCloseModal}
        >
          Adicionar
        </button>
      </div>
    )
  }

  const [imageHorizontalArray, setImageHorizontalArray] = useState([])
  const [imageVerticalArray, setImageVerticalArray] = useState([])
  const [imageSquareArray, setImageSquareArray] = useState([])

  return (
    <div className={styles.page}>
      <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalLogoUp
          imagesArray={imageHorizontalArray}
          setImagesArray={setImageHorizontalArray}
          closeModal={setModalOpen}
        />
      </ModalComponent>

      {/* <ModalComponent isOpen={isModalVerticalOpen} onClose={handleCloseModal}>
        <ModalLogoUp
          imagesArray={imageVerticalArray}
          setImagesArray={setImageVerticalArray}
          closeModal={setModalVerticalOpen}
        />
      </ModalComponent>

      <ModalComponent isOpen={isModalSquareOpen} onClose={handleCloseModal}>
        <ModalLogoUp
          imagesArray={imageSquareArray}
          setImagesArray={setImageSquareArray}
          closeModal={setModalSquareOpen}
        />
      </ModalComponent> */}

      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Logos</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        Nos envie as variações de logomarca para diferentes orientações
      </p>

      <div
        style={{ display: 'flex', gap: 50 }}
        className={styles.step_data_container}
      >
        {logosGroup.map((item, index) => (
          <div
            key={index}
            className={
              (item.size === 'Horizontal' &&
                imageHorizontalArray.length != 0) ||
              imageHorizontalArrayGet.length != 0 ||
              (item.size === 'Vertical' && imageVerticalArray.length) ||
              (item.size === 'Quadrado' && imageSquareArray.length)
                ? styles.step_data_item_upload
                : styles.step_data_item
            }
          >
            <div className={styles.step_data_item_header}>
              <p style={{ fontSize: 12 }}>{item.size}</p>

              {item.size === 'Quadrado' && imageSquareArray.length != 0 && (
                <p className={styles.number_variations}>
                  {' '}
                  {imageSquareArray.length} Variações
                </p>
              )}
              {item.size === 'Horizontal' &&
                imageHorizontalArray.length != 0 && (
                  <p className={styles.number_variations}>
                    {' '}
                    {imageHorizontalArray.length} Variações
                  </p>
                )}
              {item.size === 'Vertical' && imageVerticalArray.length != 0 && (
                <p className={styles.number_variations}>
                  {' '}
                  {imageVerticalArray.length} Variações
                </p>
              )}
            </div>
            <div className={styles.step_data_item_footer}>
              {!item.upload ? (
                <button
                  // style={{ color: '#000', fontSize: 12 }}
                  style={{
                    fontSize: 12,
                    background: '#190027',
                    border: '1px solid #fff',
                    color: '#fff',
                    borderRadius: 4,
                    padding: '6px 40px',
                  }}
                  onClick={() => handleOpenModal(item.size)}
                >
                  Fazer upload
                </button>
              ) : (
                <button
                  // style={{ color: '#000', fontSize: 12 }}
                  style={{
                    fontSize: 12,
                    background: '#0FBE00',
                    border: '1px solid #fff',
                    color: '#fff',
                    borderRadius: 4,
                    padding: '6px 40px',
                  }}
                  onClick={() => handleOpenModal(item.size)}
                >
                  Editar Uploads
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_BRANDING)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleNavigate()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepLogos
