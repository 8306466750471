import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'

function StepName({ setName, name, setStep }) {
  const STEPS = {
    STEP_PASS_EMAIL: 'stepEmail',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // if (name === '') {
      //   notifyErrorCustom('Preencha o campo com seu nome e sobrenome')
      //   return
      // }
      setStep(STEPS.STEP_PASS_EMAIL)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Cadastro</p>
        <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

        <div className={styles.box_input_name}>
          <p>Digite seu nome e sobrenome</p>
          <input
            className={styles.input_name}
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            type="text"
            placeholder="Nome"
          />
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepName
