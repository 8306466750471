/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'

import { useState, useEffect } from 'react'

import { useClient } from '../../../hooks/useClient'

import {
  BoxChecket,
  BoxNotChecket,
  LeftBackArrow,
} from '../../../assets/svgs/icons'

import {
  getBusinessByLogin,
  getBusinessByClientId,
  postBusiness,
} from '../../../services/business'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

import {
  BrandingService,
  VoiceToneService,
  BrandingArchetypeService,
  BusinessService,
  LogosService,
} from '../../../services/'
import {
  getLogosByClientBase64,
  getLogosById,
  getLogosUserBase64,
} from '../../../services/logosService'

const STEPS = {
  STEP_NEW_CAMPAIGN: 'NewCampaign',
  STEP_NEW_CAMPAIGN_SUCCESS: 'StepNewCampaignSuccess',
  STEP_PAGE_PARAMETERS: 'StepPageParameters',
}

function StepNewCampaignSuccess({
  setStep,
  state,
  // setCampaignItem,
  // registrationData,
  setUncompletedSteps,
  campaignItem,
}) {
  const navigate = useNavigate()

  const { clientData } = useClient()

  const [isBranding, setIsBranding] = useState(false)
  const [isLogo, setIsLogo] = useState(false)
  const [isCommunication, setIsCommunication] = useState(false)
  const [isEffect, setIsEffect] = useState(false)
  const [isArchetype, setIsArchetype] = useState(false)
  const [isPreferences, setIsPreferences] = useState(false)
  const [isReferences, setIsReferences] = useState(false)
  // const [isParam, setIsParam] = useState(false)

  // const [businessInformationData, setBusinessInformationData] = useState({})

  const [businessByLoginData, setBusinessByLoginData] = useState({})
  const [businessByClient, setBusinessByClient] = useState({})
  const [logoByClient, setLogoByClient] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    if (clientData.uuid) {
      const { data, responseStatus } = await getBusinessByClientId(
        clientData.uuid
      )

      const { data: dataLogo, responseStatus: responseLogo } =
        await getLogosByClientBase64(clientData.uuid)

      if (responseStatus === 200) {
        setBusinessByClient(data[0])
      }
      if (responseLogo === 200) {
        setLogoByClient(dataLogo[0])
      }
    } else {
      const { data: dataLogo, responseStatus: responseLogo } =
        await getLogosUserBase64()

      const { data, responseStatus } = await getBusinessByLogin()

      if (responseStatus === 200) {
        setBusinessByLoginData(data[0])
      }

      if (responseLogo === 200) {
        setLogoByClient(dataLogo[0])
      }
    }
  }

  const nextButton = async (navigation) => {
    if (navigation === 'nextNewParameters') {
      setStep(STEPS.STEP_PAGE_PARAMETERS)
    }
    if (navigation === 'back') {
      setStep(STEPS.STEP_NEW_CAMPAIGN)
    }
  }

  const handleCheckt = (param) => {
    if (param === 'branding') {
      setIsBranding(!isBranding)
    }
    if (param === 'logo') {
      setIsLogo(!isLogo)
    }
    if (param === 'comunicacao') {
      setIsCommunication(!isCommunication)
    }
    if (param === 'efeitos') {
      setIsEffect(!isEffect)
    }
    if (param === 'arquetipo') {
      setIsArchetype(!isArchetype)
    }
    if (param === 'preferencias') {
      setIsPreferences(!isPreferences)
    }
    if (param === 'referencias') {
      setIsReferences(!isReferences)
    }
  }

  const postLogos = async () => {
    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }
    //return
    let body = {}

    // Dividir a string base64 para separar o cabeçalho da imagem
    // const parts = imageHorizontalArray[0]?.fileDataBase64?.split(',')

    // const header = parts[0]
    // const base64Data = parts[1]

    // // Extrair o formato do arquivo e o tipo de conteúdo
    // const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
    // const fileContentType = matches[0]
    // const fileFormat = fileContentType.split('/')[1]

    if (clientData?.uuid) {
      body = {
        businessId: businessId,
        businessClientId: clientData?.uuid,
        campaignId: campaignItem?.uuid,
        description: logoByClient?.description,
        fileFormat: logoByClient?.fileFormat,
        fileContentType: logoByClient?.fileContentType,
        fileBase64: logoByClient?.fileBase64,
      }
    } else {
      body = {
        businessId: businessId,
        campaignId: campaignItem?.uuid,
        description: logoByClient?.description,
        fileFormat: logoByClient?.fileFormat,
        fileContentType: logoByClient?.fileContentType,
        fileBase64: logoByClient?.fileBase64,
      }
    }

    // if (imageHorizontalArrayGet.length > 0) {
    //   body = {
    //     businessId: businessId,
    //     campaignId: campaignItem?.uuid,
    //     description: imageHorizontalArray[0]?.name,
    //     fileFormat: fileFormat,
    //     fileContentType: fileContentType,
    //     fileBase64: base64Data,
    //     fileName: imageHorizontalArrayGet[0].fileName,
    //   }

    //   const { data: dataLogo, responseStatus: responseStatusLogo } =
    //     await LogosService.putLogos(imageHorizontalArrayGet[0]?.uuid, body)
    // } else {
    // }
    // const { data: dataLogo, responseStatus: responseStatusLogo } =
    await LogosService.postLogo(body)
  }

  const wantToKeepParameters = async (toKeepParametersSelected) => {
    let newState = await state
    let bodyNewBusiness

    if (clientData?.uuid) {
      bodyNewBusiness = {
        businessNicheId: businessByClient.businessNicheId,
        campaignId: newState?.uuid,
        subNiche: businessByClient.subNiche,
        description: businessByClient.description,
        businessClientId: clientData.uuid,
      }
    } else {
      bodyNewBusiness = {
        businessNicheId: businessByLoginData.businessNicheId,
        campaignId: newState?.uuid,
        subNiche: businessByLoginData.subNiche,
        description: businessByLoginData.description,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
      }
    }

    // if (clientData?.uuid) {
    //   bodyNewBusiness = {
    //     ...bodyNewBusiness,
    //     businessClientId: clientData.uuid,
    //   }
    // }

    const postBusinessResponse = await postBusiness(bodyNewBusiness)

    if (postBusinessResponse?.responseStatus === 200) {
      //----------------------
      let getBrandingByLoginDataResponse
      // await BrandingService.getAllBrandingByBusiness() getAllBrandingByBusinessId
      if (clientData.uuid) {
        getBrandingByLoginDataResponse =
          await BrandingService.getAllBrandingByBusinessId(
            businessByClient.uuid
          )
      } else {
        getBrandingByLoginDataResponse =
          await BrandingService.getAllBrandingByBusiness()
      }
      // await BrandingService.getAllBrandingByBusinessId()

      const bodyNewBranding = {
        businessId: postBusinessResponse.data.uuid,
        primaryColor: getBrandingByLoginDataResponse.data[0].primaryColor,
        secondaryColor: getBrandingByLoginDataResponse.data[0].secondaryColor,
        tertiaryColor: getBrandingByLoginDataResponse.data[0].tertiaryColor,
        complementaryColor1:
          getBrandingByLoginDataResponse.data[0].complementaryColor1,
        complementaryColor2:
          getBrandingByLoginDataResponse.data[0].complementaryColor2,
        fontTitleId: getBrandingByLoginDataResponse.data[0].fontTitleId,
        fontTitleByUploaded:
          getBrandingByLoginDataResponse.data[0].fontTitleByUploaded,
        fontSubtitleId: getBrandingByLoginDataResponse.data[0].fontSubtitleId,
        fontSubtitleByUploaded:
          getBrandingByLoginDataResponse.data[0].fontSubtitleByUploaded,
        fontTextId: getBrandingByLoginDataResponse.data[0].fontTextId,
        fontTextByUploaded:
          getBrandingByLoginDataResponse.data[0].fontTextByUploaded,
      }

      const postBrandingResponse =
        await BrandingService.postBrandingByBusinessId(bodyNewBranding)

      //----------------------
      // const getVoiceToneByLogin =
      //   await VoiceToneService.getAllVoiceToneByBusiness()

      let getVoiceToneByLogin
      if (clientData.uuid) {
        getVoiceToneByLogin = await VoiceToneService.getVoiceToneByBusiness(
          businessByClient.uuid
        )
      } else {
        getVoiceToneByLogin = await VoiceToneService.getAllVoiceToneByBusiness()
      }

      const bodyNewVoiceTone = {
        businessId: postBusinessResponse.data.uuid,
        tone: getVoiceToneByLogin.data[0].tone,
        description: getVoiceToneByLogin.data[0].description,
      }

      const postVoiceToneResponse = await VoiceToneService.postVoiceToneByLogin(
        bodyNewVoiceTone
      )

      //----------------------
      // const getArchetypeByLogin =
      //   await BrandingArchetypeService.getBrandingArchetypeByBusiness()

      let getArchetypeByLogin
      if (clientData.uuid) {
        getArchetypeByLogin =
          await BrandingArchetypeService.getBrandingArchetypeByBusinessId(
            businessByClient.uuid
          )
      } else {
        getArchetypeByLogin =
          await BrandingArchetypeService.getBrandingArchetypeByBusiness()
      }

      const bodyNewArchetype = {
        businessId: postBusinessResponse.data.uuid,
        bpsClassicOrModern: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsClassicOrModern
          : getArchetypeByLogin.data.bpsClassicOrModern,
        bpsFemaleOrMale: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsFemaleOrMale
          : getArchetypeByLogin?.data.bpsFemaleOrMale,
        bpsFriendlyOrDistant: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsFriendlyOrDistant
          : getArchetypeByLogin?.data.bpsFriendlyOrDistant,
        bpsNaturalOrIndustrial: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsNaturalOrIndustrial
          : getArchetypeByLogin?.data.bpsNaturalOrIndustrial,
        bpsPlayfulOrSerious: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsPlayfulOrSerious
          : getArchetypeByLogin?.data.bpsPlayfulOrSerious,
        bpsPopularOrElite: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsPopularOrElite
          : getArchetypeByLogin?.data.bpsPopularOrElite,
        bpsRusticOrRefined: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsRusticOrRefined
          : getArchetypeByLogin?.data.bpsRusticOrRefined,
        bpsSimpleOrComplex: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsSimpleOrComplex
          : getArchetypeByLogin?.data.bpsSimpleOrComplex,
        bpsStrippedOrSophisticated: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsStrippedOrSophisticated
          : getArchetypeByLogin?.data.bpsStrippedOrSophisticated,
        bpsSubtleOrExtravagant: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.bpsSubtleOrExtravagant
          : getArchetypeByLogin?.data.bpsSubtleOrExtravagant,
        brandCommunicationStyle: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.brandCommunicationStyle
          : getArchetypeByLogin?.data.brandCommunicationStyle,
        brandCompetitors: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.brandCompetitors
          : getArchetypeByLogin?.data.brandCompetitors,
        brandHistoryAndPurpose: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.brandHistoryAndPurpose
          : getArchetypeByLogin?.data.brandHistoryAndPurpose,
        brandValuesAndMission: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.brandValuesAndMission
          : getArchetypeByLogin?.data.brandValuesAndMission,
        layerMax: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.layerMax
          : getArchetypeByLogin?.data.layerMax,
        layerMin: getArchetypeByLogin?.data[0]
          ? getArchetypeByLogin?.data[0]?.layerMin
          : getArchetypeByLogin?.data.layerMin,
      }

      postLogos()

      const postArchetypeResponse =
        await BrandingArchetypeService.postArchetypeByLogin(bodyNewArchetype)

      // return
      if (toKeepParametersSelected) {
        notifySuccessCustom('Parametrizações importadas com sucesso')
        navigate('/NewPublicationPageCampaign', {
          state: {
            campaign: true,
            campaignId: bodyNewBusiness.campaignId,
          },
        })
      } else {
        isBranding && setUncompletedSteps((prev) => [...prev, 'stepBranding'])
        isLogo && setUncompletedSteps((prev) => [...prev, 'stepLogos'])
        isCommunication &&
          setUncompletedSteps((prev) => [...prev, 'stepVoiceTone'])
        isEffect && setUncompletedSteps((prev) => [...prev, 'stepEffects'])
        isArchetype &&
          setUncompletedSteps((prev) => [...prev, 'StepBrandArchetype'])
        isPreferences &&
          setUncompletedSteps((prev) => [...prev, 'stepVisualPreferences'])
        isReferences &&
          setUncompletedSteps((prev) => [...prev, 'stepVisualReferences'])

        nextButton('nextNewParameters')
      }
    } else {
      notifyErrorCustom('Erro ao importar parametrizações')
    }
  }

  return (
    <div className={styles.container_header}>
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Campanhas</p>
        <p className={styles.title_new_posts_title}>Nova Campanha</p>
      </div>

      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.container_form_first_options_header}>
              <div
                className={styles.box_button_back}
                onClick={() => {
                  nextButton('back')
                }}
              >
                <LeftBackArrow /> Voltar
              </div>
            </div>
            <div className={styles.form_container_input}>
              <div className={styles.box_input_title}>
                <h2 className={styles.form_container_input_title}>Sucesso!</h2>
                <p className={styles.form_container_input_sub_title}>
                  Você concluiu esta etapa do procedimento. Determine agora
                  &nbsp;
                  <b>somente o que deseja editar </b> das suas parametrizações
                </p>
              </div>
              <div className={styles.container_checkbox}>
                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('branding')}
                  >
                    {isBranding ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Branding</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('logo')}
                  >
                    {isLogo ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Logos</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('comunicacao')}
                  >
                    {isCommunication ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Tom de comunicação</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('efeitos')}
                  >
                    {isEffect ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Efeitos</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('arquetipo')}
                  >
                    {isArchetype ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Arquétipo de Marca</p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('preferencias')}
                  >
                    {isPreferences ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>
                    Preferências visuais
                  </p>
                </div>

                <div className={styles.box_checkbox}>
                  <div
                    className={styles.check_svg}
                    onClick={() => handleCheckt('referencias')}
                  >
                    {isReferences ? (
                      <BoxChecket width={20} height={20} color={'#000'} />
                    ) : (
                      <BoxNotChecket
                        width={20}
                        height={20}
                        borderColor={'#000'}
                      />
                    )}
                  </div>
                  <p className={styles.BoxChecket_label}>Minhas Referências</p>
                </div>
              </div>
              <div className={styles.box_buttons}>
                {isBranding === false &&
                  isLogo === false &&
                  isCommunication === false &&
                  isEffect === false &&
                  isArchetype === false &&
                  isPreferences === false &&
                  isReferences === false && (
                    <div
                      onClick={() => wantToKeepParameters(true)}
                      className={styles.button_maintain_parameterization}
                      style={{ cursor: 'pointer' }}
                    >
                      Quero manter das minhas paramerizações
                    </div>
                  )}

                <div
                  className={styles.button_start_parameterization}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    wantToKeepParameters(false)
                  }}
                >
                  iniciar paramerização
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StepNewCampaignSuccess
