/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

const mocks = {
  firstLogin: true,
}

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('userData', null)
  const [token, setToken] = useLocalStorage('token', null)
  const [clientData, setClientData] = useLocalStorage('clientData', null)

  const navigate = useNavigate()

  const login = async (data) => {
    if (data?.data) {
      setUserData(data.data)
      setToken(data.data.token)
    } else {
      setUserData(data)
      setToken(data.token)
    }

    if (mocks.firstLogin) {
      navigate('/WelcomePage', { replace: true })
      // navigate('/WelcomePage', { replace: true })
    } else {
      navigate('/HomePage', { replace: true })
    }
  }

  const logout = () => {
    setUserData(null)
    setToken(null)
    setClientData(null)
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      userData,
      login,
      logout,
    }),
    [userData]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
