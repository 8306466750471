import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import {
  CalendarIcon,
  Clock,
  Clipboard,
  Trash,
  NavigateArrowLeft,
  NavigateArrowRigth2,
} from '../../assets/svgs/icons'
import { BusinessService, PublicationService } from '../../services'
import { useClient } from '../../hooks/useClient'
import { getBusinessByClientId } from '../../services/business'

export const CalendarPublication = ({
  setSelectObjectCalendar,
  dataPublication,
  isOpen,
  setIsOpen,
  setContentPublication,
  setLoading,
  loadData,
}) => {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const [currentDate, setCurrentDate] = useState(new Date()) // Get the current date
  const [selectedDates] = useState([]) // Store selected dates
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

  const today = new Date()

  function hadleShowPubication(item, date) {
    setSelectObjectCalendar(item)
    handleDateClick(date)
  }

  const togglePopup = (data, date) => {
    setIsOpen(!isOpen)
    setContentPublication(data)
    handleDateClick(date)
  }

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + '...'
    }
    return title
  }

  const columnsRef = useRef([])

  useEffect(() => {
    const columns = columnsRef.current

    const handleDragStart = (e) => {
      e.target.classList.add('dragging')
    }

    const handleDragEnd = (e) => {
      e.target.classList.remove('dragging')
    }

    const handleDragOver = (e) => {
      e.preventDefault()
      const dragging = document.querySelector('.dragging')
      const applyAfter = getNewPosition(e.currentTarget, e.clientY)

      if (applyAfter) {
        applyAfter.insertAdjacentElement('afterend', dragging)
      } else {
        e.currentTarget.prepend(dragging)
      }
    }
    let dayDrop

    // const handleDrop = (e, day) => {
    //   // Adicionando o parâmetro 'day' para representar a data do campo onde a div foi solta
    //   e.preventDefault()

    //   dayDrop = day
    //   console.log('Arrastou para cá:', dayDrop, currentMonth, currentYear) // Imprimindo a data no console
    // }

    const getNewPosition = (column, posY) => {
      const cards = column.querySelectorAll('.item:not(.dragging)')
      let result

      for (let refer_card of cards) {
        const box = refer_card.getBoundingClientRect()
        const boxCenterY = box.y + box.height / 2

        if (posY >= boxCenterY) result = refer_card
      }

      return result
    }

    columns.forEach((column, index) => {
      column.addEventListener('dragover', handleDragOver)
      column.addEventListener('dragstart', handleDragStart)
      column.addEventListener('dragend', handleDragEnd)
      // column.addEventListener('drop', (e) => handleDrop(e, index)) // Passando o índice + 1 como a data do dia
    })

    // return () => {
    //   columns.forEach((column) => {
    //     column.removeEventListener('dragover', handleDragOver)
    //     column.removeEventListener('dragstart', handleDragStart)
    //     column.removeEventListener('dragend', handleDragEnd)
    //     //column.removeEventListener('drop', handleDrop)
    //   })
    // }
  }, [])

  const [selectedDragAndDrop, setSelectedDragAndDrop] = useState('')

  const { clientData } = useClient()

  const handleDragStart = (e, date, item) => {
    const objetoComoString = JSON.stringify({ item, date })
    e.dataTransfer.setData('text', objetoComoString)

    setSelectedDragAndDrop(date)
    if (new Date(date) < new Date()) {
      e.preventDefault() // Impede o início do arrasto
      return
    }
    e.target.classList.add('dragging')
  }

  const handleDrop = async (e, date, item, callBack) => {
    e.preventDefault()

    const objectString = e.dataTransfer.getData('text')
    const data = JSON.parse(objectString)

    // console.log('146-2', data)

    // Verifica se a data é menor que a data atual
    if (new Date(date) < new Date()) {
      console.log(
        'Não é possível arrastar para um dia que já passou.',
        new Date(date)
      )
      return
    }

    item = [data?.item]

    // console.log('linha 153', callBack())
    // console.log('Arrastou para cá', date)

    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
    }

    // console.log('146', item)

    const lastIndex = item.length - 1

    const publicationBody = {
      headline: item[lastIndex].description,
      legend: item[lastIndex].copy,
      publicationImageUrl: item[lastIndex].images[0].url,
      publicationImageUrlName: item[lastIndex].images[0].id,
      sketch: false,
      scheduleDate: new Date(date),
      publicationDate: date,
      status: 'Agendado',
      isCanceled: false,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
    }

    const publicationResponse = await PublicationService.putPublication(
      item[lastIndex]?.id,
      businessId,
      item[lastIndex].socialTypes,
      publicationBody
    )
  }

  const renderDays = () => {
    const days = []

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day)
      const isPastDate = date < today
      const isCurrentDate = date.getTime() === today.getTime()
      const isSelected = selectedDates.some(
        (selectedDate) => selectedDate.getTime() === date.getTime()
      )

      let contentReturn = findDate(date.getMonth() + 1, day, date.getFullYear())

      const getData = () => {
        return findDate(date.getMonth() + 1, day, date.getFullYear())
      }

      days.push(
        <div
          className={`${styles.day_box} ${
            isCurrentDate
              ? styles.current_day
              : date.getTime() === currentDate.getTime()
              ? styles.selected
              : isPastDate
              ? styles.disabled
              : ''
          }`}
          key={date.getTime()}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day}>{day}</p>
            </div>
            <div
              className={styles.day_box_schedules}
              id={day}
              data-day={day}
              ref={(el) => (columnsRef.current[day] = el)}
              // onDrop={(e) => {
              //   const droppedOnDay = e.currentTarget.getAttribute('data-day')
              //   // console.log('253', droppedOnDay)
              // }}
              // onDragOver={(e) => {
              //   e.preventDefault()
              // }}
            >
              {contentReturn &&
              //contentReturn?.content[0]?.status != 'Sugestão de Publicação' &&
              contentReturn.content &&
              contentReturn.content.length > 0 &&
              contentReturn.content.length < 3
                ? contentReturn.content.map((item, key) => (
                    <div
                      key={key}
                      className={styles.schedule_of_the_day}
                      //draggable={true}
                      //draggable={!isPastDate} // Define draggable como true ou false com base na data
                      //onDragStart={(e) => handleDragStart(e, date, item)}
                      style={{
                        backgroundColor:
                          item.status === 'Sugestão de Publicação'
                            ? '#190027'
                            : item.status === 'Publicado'
                            ? '#3CBC00'
                            : '#ff8b00',
                      }}
                      onClick={() => hadleShowPubication(item, date)}
                    >
                      {item.status === 'Publicado' ? (
                        <Clipboard width={'15px'} height={'15px'} />
                      ) : item.status === 'Cancelado' ? (
                        <Trash width={'15px'} height={'15px'} />
                      ) : (
                        <Clock width={'15px'} height={'15px'} />
                      )}

                      <p
                        style={{
                          display: 'flex',
                          gap: 5,
                          cursor: 'pointer',
                          fontSize: 10,
                          margin: 0,
                          color:
                            item?.status === 'Sugestão de Publicação'
                              ? '#a1a0a0'
                              : '#fff',
                        }}
                      >
                        {truncateTitle(item.title, 8)}
                      </p>
                    </div>
                  ))
                : contentReturn &&
                  contentReturn.content &&
                  contentReturn.content.length >= 3 && (
                    <div
                      className={styles.schedule_of_the_day}
                      style={{
                        cursor: 'pointer',
                        background: isSelected ? '#FF8B00' : '#fc036b',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => togglePopup(contentReturn.content, date)}
                    >
                      <div className={styles.box_schedule_of_the_day}>
                        <CalendarIcon width={'12px'} height={'12px'} />
                        <p className={styles.title_schedule_of_the_day}>
                          Ver mais
                        </p>
                      </div>
                      <p className={styles.title_schedule_of_the_day_plus}>
                        +3
                      </p>
                    </div>
                  )}
            </div>
          </div>
        </div>
      )
    }

    return days
  }

  const renderDaysLastMonth = () => {
    const daysLast = Array.from({
      length: new Date(currentYear, currentMonth, 1).getDay(),
    })
      .map((_, index) => {
        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
        const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate()
        const dayOfMonth = lastDayOfMonth - index

        const isSelected = selectedDates.some(
          (selectedDate) => selectedDate.getTime() === date.getTime()
        )

        const date = new Date(lastYear, lastMonth, dayOfMonth)
        let contentReturn = findDate(lastMonth + 1, dayOfMonth, lastYear)

        return (
          <div
            key={`empty-${index}`}
            className={`${styles.empty_day} ${styles.disabled}`}
          >
            <div className={styles.container_day_box_date}>
              <div className={styles.day_box_date}>
                <p className={styles.day_box_date_day}>{dayOfMonth}</p>
              </div>
            </div>
            {contentReturn?.content &&
            //contentReturn?.content[0]?.status != 'Sugestão de Publicação' &&
            contentReturn.content.length > 0 &&
            contentReturn.content.length < 3
              ? contentReturn.content.map((item, key) => (
                  <div
                    key={key}
                    className={styles.schedule_of_the_day}
                    style={{
                      backgroundColor:
                        item.status === 'Sugestão de Publicação'
                          ? '#190027'
                          : '#ff8b00',
                      opacity: '30%',
                    }}
                    onClick={() => hadleShowPubication(item, date)}
                  >
                    <Clock width={'15px'} height={'15px'} />
                    <p
                      style={{
                        display: 'flex',
                        gap: 5,
                        cursor: 'pointer',
                        fontSize: 10,
                        margin: 0,
                        color:
                          item?.status === 'Sugestão de Publicação'
                            ? '#a1a0a0'
                            : '#fff',
                      }}
                    >
                      {truncateTitle(item.title, 8)}
                    </p>
                  </div>
                ))
              : contentReturn?.content?.length >= 3 && (
                  <div
                    className={styles.schedule_of_the_day}
                    style={{
                      cursor: 'pointer',
                      background: isSelected ? '#FF8B00' : '#3CBC001A',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => togglePopup(contentReturn.content, date)}
                  >
                    <div className={styles.box_schedule_of_the_day}>
                      <CalendarIcon width={'12px'} height={'12px'} />
                      <p className={styles.title_schedule_of_the_day}>
                        Ver mais
                      </p>
                    </div>
                    <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                  </div>
                )}
          </div>
        )
      })
      .reverse()

    return daysLast
  }

  const renderDaysNextMonth = () => {
    const daysNext = Array.from({
      length: 6 - new Date(currentYear, currentMonth, daysInMonth).getDay(),
    }).map((_, index) => {
      const dayOfMonth = index + 1
      const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
      const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear
      const date = new Date(nextYear, nextMonth, dayOfMonth)
      let contentReturn = findDate(
        nextMonth + 1, // Adicione 1 ao mês atual para obter o próximo mês
        dayOfMonth,
        nextYear
      )

      return (
        <div
          key={`empty-${daysInMonth + index}`}
          className={`${styles.empty_day} ${styles.disabled}`}
        >
          <div className={styles.container_day_box_date}>
            <div className={styles.day_box_date}>
              <p className={styles.day_box_date_day}>{dayOfMonth}</p>
            </div>
          </div>

          {
            //contentReturn?.content[0]?.status != 'Sugestão de Publicação' &&
            contentReturn?.content?.length > 0 &&
            contentReturn?.content?.length < 3
              ? contentReturn.content.map((item, key) => (
                  <div
                    key={key}
                    className={styles.schedule_of_the_day}
                    style={{
                      backgroundColor:
                        item.status === 'Sugestão de Publicação'
                          ? '#190027'
                          : '#ff8b00',
                      opacity: '30%',
                    }}
                    onClick={() => hadleShowPubication(item, date)}
                  >
                    <Clock width={'15px'} height={'15px'} />
                    <p
                      // className={styles.title_schedule_of_the_day}
                      key={item.id}
                      // style={{
                      //   color:
                      //     item.status === 'Publicado'
                      //       ? isSelected
                      //         ? '#FFFFFF'
                      //         : '#3CBC00'
                      //       : isSelected
                      //       ? '#FFFFFF'
                      //       : '#FF8B00',
                      // }}
                      style={{
                        display: 'flex',
                        gap: 5,
                        cursor: 'pointer',
                        fontSize: 10,
                        margin: 0,
                        color:
                          item.status === 'Sugestão de Publicação'
                            ? '#a1a0a0'
                            : '#fff',
                      }}
                    >
                      {/* {item.status === 'Publicado' ? (
                              <Clipboard
                                color={'#3CBC00'}
                                className={styles.title_schedule_of_the_day}
                              />
                            ) : item.status === 'Agendado' ? (
                              <Clock
                                color={
                                  date.getTime() === currentDate.getTime()
                                    ? '#ffffff'
                                    : '#FF8B00'
                                }
                                width={'12px'}
                                height={'12px'}
                              />
                            ) : (
                              <Trash color={'#FF2F00'} />
                            )} */}
                      {truncateTitle(item.title, 8)}
                    </p>
                  </div>
                ))
              : contentReturn?.content?.length >= 3 && (
                  <div
                    className={styles.schedule_of_the_day}
                    style={{
                      cursor: 'pointer',
                      background: isSelected ? '#FF8B00' : '#3CBC001A',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => togglePopup(contentReturn.content, date)}
                  >
                    <div className={styles.box_schedule_of_the_day}>
                      <CalendarIcon width={'12px'} height={'12px'} />
                      <p className={styles.title_schedule_of_the_day}>
                        Ver mais
                      </p>
                    </div>
                    <p className={styles.title_schedule_of_the_day_plus}>+3</p>
                  </div>
                )
          }
        </div>
      )
    })

    return daysNext
  }

  function handleNextMonth() {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)

      setLoading(true)
      loadData(0, currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)

      setLoading(true)
      loadData(currentMonth + 1, currentYear)
    }
  }
  // console.log('445', currentMonth + 1)

  function handlePreviusMonth() {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)

      setLoading(true)
      loadData(11, currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)

      setLoading(true)
      loadData(currentMonth - 1, currentYear)
    }
  }

  function handleDateClick(date) {
    if (date >= today) {
      setCurrentDate(date)
    }
  }

  const findDate = (mes, dia, ano) => {
    let date = mes + '-' + dia + '-' + ano
    let verifyDate = dataPublication.find((dateData) => dateData?.date === date)

    if (!verifyDate) {
      return []
    }

    return verifyDate
  }

  const HeaderCalendar = () => {
    return (
      <>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>D</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>T</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>Q</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>Q</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
        <div className={styles.day_name}>
          <p className={styles.day_name_title}>S</p>
        </div>
      </>
    )
  }

  return (
    <div className={styles.calendar_wrapper}>
      <div className={styles.calendar_header}>
        <div>
          <h3 className={styles.calendar_header_name}>
            {monthNames[currentMonth] + ' ' + currentYear}
          </h3>
        </div>
        <div className={styles.calendar_button_navigate}>
          <div
            onClick={handlePreviusMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowLeft />
          </div>
          <div
            onClick={handleNextMonth}
            style={{ color: '#000', cursor: 'pointer' }}
          >
            <NavigateArrowRigth2 />
          </div>
        </div>
      </div>
      <div className={styles.calendar}>
        {HeaderCalendar()}

        {renderDaysLastMonth()}
        {renderDays()}
        {renderDaysNextMonth()}

        {/* {Array.from({
          length: 6 - new Date(currentYear, currentMonth, daysInMonth).getDay(),
        }).map((_, index) => {
          const dayOfMonth = index + 1
          return (
            <div
              key={`empty-${daysInMonth + index}`}
              className={`${styles.empty_day} ${styles.disabled}`}
            >
              <div className={styles.day_box_date}>
                <p className={styles.day_box_date_day}>{dayOfMonth}</p>
              </div>
            </div>
          )
        })} */}
      </div>
    </div>
  )
}
