import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { useState } from 'react'
import { Accept, Pen2 } from '../../../assets/svgs/icons'

function StepPublication() {
  const [expand, setExpand] = useState(false)
  const handleExpand = () => {
    setExpand(!expand)
  }

  return (
    <div className={styles.box_page}>
      <p style={{ margin: '0px' }}>Publicação</p>
      <h1 style={{ marginTop: '0px' }}>Escolha uma publicação</h1>

      <div className={styles.container_publication}>
        <div className={styles.carousel_publication}>
          <div className={styles.publication} onClick={handleExpand}>
            <div className={styles.box_publication}>
              <img
                className={styles.image_publication}
                src="https://i.pinimg.com/736x/71/c4/10/71c41077fec8adf8ccba69c545fb255f.jpg"
                alt="teste"
              />
            </div>
            {expand && (
              <div className={styles.info_publication}>
                <div>
                  <div className={styles.box_module}>
                    <p className={styles.description_module}>Headline</p>

                    <span className={styles.headline}>headline prompt</span>
                  </div>

                  <div>
                    <p className={styles.description_module}>
                      Legenda Da publicação
                    </p>
                  </div>
                </div>

                <div className={styles.box_actions}>
                  <div className={styles.action}>
                    <Pen2 color="#B900FE" />
                  </div>
                  <div className={styles.action_save}>
                    <Accept color="#0FBE00" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.publication}>
            <div className={styles.box_publication}>
              <img
                className={styles.image_publication}
                src="https://i.pinimg.com/736x/6f/2f/fc/6f2ffcfe02c5f5ec930fa9ef3ee4faf8.jpg"
                alt="teste"
              />
            </div>
          </div>
          <div className={styles.publication}>
            <div className={styles.box_publication}>
              <img
                className={styles.image_publication}
                src="https://i.pinimg.com/736x/eb/47/15/eb47155221d82ec21d9d871398a8cd19.jpg"
                alt="teste"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepPublication
