/* eslint-disable react/prop-types */
import styles from './styles.module.css'

import { useState, useEffect, useRef } from 'react'
import { Loading } from '../../../components/Loading'
import SimpleEditionModal from '../../../components/SimpleEditionModal'

import {
  PythonService,
  BrandingService,
  BusinessService,
  ProductService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services/'

import { NumberOne, NumberTwo, CircleX } from '../../../assets/svgs/icons'

import { motion } from 'framer-motion'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function StepThirdPass({
  setStep,
  selectedArrayImage,
  setSelectedArrayImage,

  selectedArrayImageObject,
  setSelectedArrayImageObject,

  imageLayers,
  setImageLayers,

  headlineText,
  setHeadlineText,
  imageGaleryArray,
  setHeadlineList,

  setLegendList,

  selectedCampaignOption,
  imageAIArray,
  imageUploadArray,
  imageProductsArray,
  iaData,
  setIaData,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const [headlineSubtitle, setHeadlineSubtitle] = useState('')

  const [loading, setLoading] = useState(false)

  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  useEffect(() => {
    if (iaData.length === 0) {
      loadIaData()
    }
  }, [])

  function hexToRgb(hex) {
    // Remove o símbolo '#' do início, se presente
    hex = hex.replace(/^#/, '')

    // Verifica se o valor hexadecimal é válido
    if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
      // Se o valor é uma representação de 3 dígitos, expanda para 6 dígitos
      if (hex.length === 3) {
        hex = hex
          .split('')
          .map(function (char) {
            return char + char
          })
          .join('')
      }

      // Divide o valor em pares de dígitos (R, G, B)
      const r = parseInt(hex.slice(0, 2), 16)
      const g = parseInt(hex.slice(2, 4), 16)
      const b = parseInt(hex.slice(4, 6), 16)

      return `(${r},${g},${b})`
    } else {
      // Retorna uma mensagem de erro para valores inválidos
      return `(${0},${0},${0})`
    }
  }

  const loadInfosForIaData = async () => {
    let productInfos = ''
    let targetAudiencesInfos = ''
    console.log('100', selectedCampaignOption)
    const businessInfos = await BusinessService.getBusinessByCampaign(
      selectedCampaignOption.value
    )

    if (selectedCampaignOption.productId) {
      productInfos = await ProductService.getProductById(
        selectedCampaignOption.productId
      )
    }
    const brandingInfos = await BrandingService.getBrandingByBusinessId(
      businessInfos?.data[0]?.uuid
    )

    const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      businessInfos?.data[0]?.uuid
    )

    if (selectedCampaignOption?.targetAudienceId) {
      targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudienceById(
          selectedCampaignOption?.targetAudienceId
        )
    }

    const obj = {
      productInfos: selectedCampaignOption.productId ? productInfos.data : '',
      brandingInfos: brandingInfos?.data[0],
      voiceToneInfos: voiceToneInfos?.data[0],
      businessInfos: businessInfos?.data[0],
      targetAudiencesInfos: selectedCampaignOption?.targetAudienceId
        ? targetAudiencesInfos?.data[0]
        : '',
    }

    return obj
  }

  const loadIaData = async () => {
    setLoading(true)
    const loadInfosForDataReturn = await loadInfosForIaData()
    console.log('LoadInfos',loadInfosForDataReturn)
    console.log('Campanhas',selectedCampaignOption)

    const headlineListLocal = []
    const legendListLocal = []
    const totalMainImages =
      imageGaleryArray?.length +
      imageAIArray.length +
      imageUploadArray.length +
      imageProductsArray?.length
    const numImage = 2
    const numHeadline = numImage * totalMainImages

    let bodyHeadline = {
      publications_number: numHeadline,
      niche: loadInfosForDataReturn?.businessInfos.BusinessNiche?.name,
      subniche: loadInfosForDataReturn.businessInfos.subNiche,
      publication_purpose: 'divulgar',
      description_business: loadInfosForDataReturn.businessInfos.description,
      voice_tone: loadInfosForDataReturn.voiceToneInfos.tone,
      social_class: 'Classe A',
      gender: loadInfosForDataReturn.targetAudiencesInfos
        ? loadInfosForDataReturn.targetAudiencesInfos?.genderValue
        : '',
      profession: loadInfosForDataReturn.targetAudiencesInfos
        ? loadInfosForDataReturn.targetAudiencesInfos.profession
        : '',
      name_product: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.name
        : '',
      product_type: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.productTypeValue
        : '',
      product_description: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.description
        : '',
      value: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.value
        : '',
      promotional_value: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.promotionalValue
        : '',
    }

    const headline = await PythonService.postHeadline(bodyHeadline)

    await headline?.data?.data.forEach((element) => {
      headlineListLocal.push(element.headline)
      legendListLocal.push(element.legenda)
    })
    console.log('headline',headline)
    setHeadlineText(headline?.data.data[0].titulo)

    await setHeadlineList(headlineListLocal)
    await setLegendList(legendListLocal)

    let bodyGenerateArt = {
      art_width: 1080,
      art_height: 1080,
      text: [
        // ['KITS DE ID. VISUAL', 'FONTE PRINCIPAL, FONTE SECUNDÁRIA E CORES'],
        [headline?.data?.data[0]?.headline],
      ],
      categoria: [
        // ['titulo', 'subtitulo']
        ['titulo'],
      ],
      publications_number: 1,
      niche: loadInfosForDataReturn?.businessInfos.BusinessNiche?.name,
      subniche: '', //!Remover posteriormente
      publication_purpose: 'divulgar', //?Sempre assim
      description_business: loadInfosForDataReturn.businessInfos.description,
      voice_tone: loadInfosForDataReturn.voiceToneInfos.tone,
      social_class: 'Classe B',
      gender: loadInfosForDataReturn.targetAudiencesInfos
        ? loadInfosForDataReturn.targetAudiencesInfos?.genderValue
        : '', //!Lembrar de adicionar a opção 'Ambos'
      profession: loadInfosForDataReturn.targetAudiencesInfos
        ? loadInfosForDataReturn.targetAudiencesInfos.profession
        : '',
      name_product: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.name
        : '',
      product_type: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.productTypeValue
        : '',
      product_description: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.description
        : '',
      value: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.value
        : '',
      promotional_value: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos.promotionalValue
        : '',
      produto: loadInfosForDataReturn.productInfos
        ? loadInfosForDataReturn.productInfos?.name
        : '',
      color_palette_1: hexToRgb(
        loadInfosForDataReturn.brandingInfos?.primaryColor
      ),
      color_palette_2: hexToRgb(
        loadInfosForDataReturn.brandingInfos?.secondaryColor
      ),

      complexidade: -3,
      masculino: 5,
      extravagante: -5,
      moderno: 5,
      distante: 0,
      serio: 0,
      industrial: 0,
      elitizado: 0,
      sofisticado: 0,
      refinado: 0,

      macro_effects: false,
      num_imgs: [1],

      has_logo: false, //!Quando não haver logo passar como false e não enviar o logo_base64
      // logo_base64: '',

      template: false,
    }

    if (imageGaleryArray?.length !== 0 && imageGaleryArray) {
      imageGaleryArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.fileDataBase64.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.fileDataBase64.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageAIArray.length !== 0 && imageAIArray) {
      imageAIArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.image.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.image.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageUploadArray.length !== 0 && imageUploadArray) {
      imageUploadArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.image.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.image.split(',')[1]],
            ],
          }
        }
      })
    }

    if (imageProductsArray.length !== 0 && imageProductsArray) {
      imageProductsArray.forEach((image2) => {
        if (!bodyGenerateArt?.img_base64) {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [[image2.fileDataBase64.split(',')[1]]],
          }
        } else {
          bodyGenerateArt = {
            ...bodyGenerateArt,
            img_base64: [
              ...bodyGenerateArt.img_base64,
              [image2.fileDataBase64.split(',')[1]],
            ],
          }
        }
      })
    }

    const generateArtReturn = await PythonService.postGenerateArt(
      bodyGenerateArt
    )

    console.log('generateArtReturn', generateArtReturn)

    if (generateArtReturn?.data?.status === 200) {
      setIaData(generateArtReturn?.data?.data)
    }

    setLoading(false)
  }

  const handleOpenModal = (url, imageLayers) => {
    setImageUrl(url)
    setImageLayers(imageLayers)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_SECOND_PASS_IMAGE)
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter((arr) => arr !== imageArray)
    )

    setSelectedArrayImageObject(
      selectedArrayImageObject.filter((arr) => arr?.mergedImage !== imageArray)
    )
  }

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading} />

      <SimpleEditionModal
        isOpen={isModalOpen}
        setNextStep={(newImageUrl, tempImageLayers) => {
          setSelectedArrayImage((current) => [...current, newImageUrl])
          setSelectedArrayImageObject((current) => [
            ...current,
            tempImageLayers,
          ])
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        imageLayers={imageLayers}
        setImageLayers={setImageLayers}
        onClose={handleCloseModal}
        title={headlineText}
        subtitle={headlineSubtitle}
        onChangeColorPalleteSubtittle={(e) =>
          setHeadlineSubtitle(e.target.value)
        }
        onChangeColorPalleteTittle={(e) => setHeadlineText(e.target.value)}
        isCampaign={true}
      />

      <div>
        {selectedArrayImage.length !== 0 && (
          <div>
            <p style={{ margin: '0px', marginBottom: 10, fontSize: 22 }}>
              Publicações selecionadas
            </p>
            <div
              className={styles.content_list_selected_image}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <div
                className={styles.caroucel_list_selected_image}
                ref={carouselRef}
              >
                {selectedArrayImage?.map((imageArray, index) => (
                  <div className={styles.card_lis_selected_image} key={index}>
                    <div className={styles.container_card_lis_selected_image}>
                      <div
                        className={styles.delete_card_lis_selected_image}
                        onClick={() => handleRemoveImage(imageArray)}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          <CircleX
                            color={'#FF2F00'}
                            width={'20px'}
                            height={'20px'}
                          />
                        </div>
                      </div>
                      <img
                        className={styles.item_selected_image}
                        src={imageArray}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Múltiplas</p>
        <p className={styles.title_new_posts_title}>Publicações</p>
      </div>

      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>1° Passo</p>
            <p className={styles.first_pass_subtitle}>Configurações</p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>2° Passo</p>
            <p className={styles.first_pass_subtitle}>Publicações</p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        <div className={styles.container_examples}>
          <div className={styles.container_examples_image}>
            {iaData?.map((item, index) => (
              <div
                className={styles.box_example_image}
                key={index}
                onClick={() => {
                  handleOpenModal(
                    'data:image/png;base64,' + item?.srcComTratativa,
                    item
                  )
                }}
              >
                <img
                  src={'data:image/png;base64,' + item?.srcComTratativa}
                  alt=""
                  className={styles.example_image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>Voltar</p>
        </button>

        {selectedArrayImage.length !== 0 && (
          <button
            className={styles.button_next}
            onClick={() => {
              nextButton('next')
            }}
          >
            <p className={styles.button_title}>Prosseguir</p>
          </button>
        )}
      </div>
    </motion.div>
  )
}

export default StepThirdPass
