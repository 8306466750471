import {
  Magic,
  Picture,
  Pen2,
  CheckBadge,
  Engine,
} from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import Proptypes from 'prop-types'
import { motion } from 'framer-motion'
import { useState } from 'react'

export const SideBar = ({ onClickStep, onClickDone }) => {
  const [step, setStep] = useState(1)
  const handleChangeStep = (step) => {
    setStep(step)
    onClickStep(step)
  }

  return (
    <div className={styles.sideMenu}>
      <div className={styles.topMenuButton}>
        <motion.button
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          animate={{
            backgroundColor:
              step === 1 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)',
          }}
          className={` ${styles.button} ${
            step == 1 ? styles.selected : styles.default
          }`}
          onClick={() => handleChangeStep(1)}
        >
          <Pen2 width={36} height={36} />
        </motion.button>
        <motion.button
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          animate={{
            backgroundColor:
              step === 2 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)',
          }}
          className={` ${styles.button} ${
            step == 2 ? styles.selected : styles.default
          }`}
          onClick={() => handleChangeStep(2)}
        >
          <Picture width={30} height={30} />
        </motion.button>
        <motion.button
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          animate={{
            backgroundColor:
              step === 3 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)',
          }}
          className={` ${styles.button} ${
            step == 3 ? styles.selected : styles.default
          }`}
          onClick={() => handleChangeStep(3)}
        >
          <Magic size={30} />
        </motion.button>
        <motion.button
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          animate={{
            backgroundColor:
              step === 4 ? 'rgba(25, 0, 39, 1)' : 'rgba(0, 0, 0, 0)',
          }}
          className={` ${styles.button} ${
            step == 4 ? styles.selected : styles.default
          }`}
          onClick={() => handleChangeStep(4)}
        >
          <Engine size={30} />
        </motion.button>
      </div>
      <div className={styles.bottomMenuButton}>
        <button onClick={onClickDone} className={styles.button}>
          <CheckBadge size={26.39} />
        </button>
      </div>
    </div>
  )
}

SideBar.propTypes = {
  step: Proptypes.number,
  onClickStep: Proptypes.func,
  onClickDone: Proptypes.func,
}
