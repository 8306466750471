import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getTwitterV1Callback = async (
  businessId,
  oauthToken,
  oauthVerifier
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}auth/twitter/v1/callback/${businessId}/${oauthToken}/${oauthVerifier}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getTwitterV1Callback', error.response.data)
      return error
    })
}

export const getTwitterCallback = async (businessId, code) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}auth/twitter/callback/${businessId}/${code}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getTwitterCallback', error.response.data)
      return error
    })
}

export const getTwitterByBusiness = async (businessId) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}twitter/v1/${businessId}`, config)
    .then((response) => {
      return {
        dataT: response.data,
        responseStatusT: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getTwitterByBusiness', error.response.data)
      return error
    })
}
