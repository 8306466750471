import React, {useState} from 'react'
import styles from './styles.module.css'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { CloundArrowUp } from '../../../assets/svgs/icons'
import { Button, Input } from '../../../components'
import { animate, animations, motion } from 'framer-motion'

export const ConfigStep = ({setStep}) => {

  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [animationStep, setAnimationStep] = useState(0)

  const onDragOver = (event) => {
    event.preventDefault()
    setDropzoneHover(true)
  }

  const onDragLeave = () => {
    setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  return (
    <motion.div style={{display:'flex', flexDirection:'column', flex:1, paddingRight:'30px'}} initial={{opacity:0}} animate={{opacity:1}}>
      <div className={styles.text_title_container}>
        <label htmlFor="">Protótipos</label>
        <div className={styles.header_button_container}>
          <h3>Lista de Protótipos</h3>
          <Button onClick={()=>setStep(1)} style={{height:"45px"}} variant={"primary"}>Cancelar</Button>
        </div>
     </div>
        <div className={styles.page}>
          <div className={styles.input_container}>
            <div className={styles.title_container} > 
              <p>Configuração do protótipo</p>
            </div>
              <div className={styles.label_input_container}>
                <label className={styles.label} htmlFor="">Selecione o produto deste Protótipo</label>
                  <SelectSquare
                  label={''}
                  onClickItem={()=>setAnimationStep(1)}
                  arrowDirection='down'
                  defaultSelected={{label:'opção 1', valeu:''}}  
                  options={[{label:'opção 1', value:''}]}
                  placeholder={'selecione'}/>
              </div>
              {animationStep > 0 && (
                <motion.div initial={{opacity:0, maxHeight:0}} animate={{opacity:1, maxHeight:83}} className={styles.label_input_container}>
                  <label className={styles.label}  htmlFor="">Agora selecione a categoria deste Protótipo:</label>
                        <SelectSquare
                        arrowDirection='down'
                        label={''}
                        onClickItem={()=>setAnimationStep(2)}
                        defaultSelected={{label:'opção 1', valeu:''}}  
                        options={[{label:'opção 1', value:''}]}
                        placeholder={'selecione'}/>
                </motion.div>
              )}

              {animationStep > 1 && (
                <motion.div  initial={{opacity:0, maxHeight:0}} animate={{opacity:1, maxHeight:500}} className={styles.label_input_container} onClick={()=>setAnimationStep(3)}>
                  <label className={styles.label}>Digite o texto do Protótipo</label>
                  <Input placeholder={'Digite aqui o texto'} />
                </motion.div>
              )}
              {animationStep > 2 && (
              <motion.div initial={{opacity:0, maxHeight:0}} animate={{opacity:1, maxHeight:500}}>
                <label className={styles.label} htmlFor="">Faça o upload da sua imagem</label>
                <div className={styles.modal_container_drag}>
                  <div className={styles.modal_drag}>
                    <div
                      className={styles.modal_drag_drop}
                      onDragOver={onDragOver}
                      onDragLeave={onDragLeave}
                      onDrop={onDrop}
                    >
                      <label htmlFor="arquivo">
                        <CloundArrowUp color={'#B900FE'} />
                        <div className={styles.upload_text_container}>
                        <h2
                          className={
                            filesSelectedArray.length !== 0
                              ? styles.modal_drag_drop_title_short
                              : styles.modal_drag_drop_title
                          }
                        >
                          Arraste & Solte o arquivo
                        </h2>
                        <p
                          className={
                            filesSelectedArray.length !== 0
                              ? styles.modal_drag_drop_sub_title_short
                              : styles.modal_drag_drop_sub_title
                          }
                        >
                          Ou selecione o arquivo no explorador
                        </p>
                        </div>
                        
                      </label>
                      <input
                        type="file"
                        name="arquivo"
                        id="arquivo"
                        onChange={(e) => onFileChange(e)}
                      ></input>
                    </div>
                  </div>
                </div>
              </motion.div>
              )}
                  {animationStep > 2 && (
                    <motion.div initial={{opacity:0, maxHeight:0}} animate={{opacity:1, maxHeight:45}} className={styles.button_container}>
                          <Button variant={"primary"}>Avançar</Button>
                    </motion.div>
                    )}
    </div>
</div>
    </motion.div>
  )
}
