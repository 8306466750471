/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react'

import styles from './styles.module.css'

import { isObjEqual } from '../../../../utils/verifyObject'

import { InterrogationCircleIcon } from '../../../../assets/svgs/icons'

import { BrandingArchetypeService, BusinessService } from '../../../../services'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { ButtonNavigateStep } from '../../ButtonNavigateStep'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
  STEP_COMPLETED: 'completed',
}

function StepBrandArchetype({ setStep, state, setCampaignItem, campaignItem }) {
  const [sliderLayers] = useState([0, 20])
  const [brandingArchetypeData, setBrandingArchetypeData] = useState({})
  const [brandingArchetypeDataReference, setBrandingArchetypeDataReference] =
    useState({})

  const businessId = useRef(undefined)

  const handleChange = (k, v) => {
    setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  }

  useEffect(() => {
    loadExistentBrandingArchetype()
  }, [])

  const loadExistentBrandingArchetype = async () => {
    let businessByCampaignReturn = await BusinessService.getBusinessByCampaign(
      campaignItem?.uuid
    )

    businessByCampaignReturn = businessByCampaignReturn?.data[0]

    businessId.current = businessByCampaignReturn?.uuid

    const { data, responseStatus } =
      await BrandingArchetypeService.getBrandingArchetypeByBusinessId(
        businessByCampaignReturn?.uuid
      )
    if (responseStatus === 200 && data) {
      setBrandingArchetypeData(data)
      setBrandingArchetypeDataReference(data)
    }
  }

  const handleSubmit = async () => {
    const body = {
      ...brandingArchetypeData,
    }

    if (
      body?.uuid &&
      !isObjEqual(brandingArchetypeDataReference, brandingArchetypeData)
    ) {
      const { uuid, ...rest } = body
      Object.keys(rest).map((e) => {
        if (
          e === 'updatedAt' ||
          e === 'createdAt' ||
          e === 'businessId' ||
          e === 'deletedAt'
        )
          delete rest[e]
      })

      rest.layerMax = 0
      rest.layerMin = 0
      await BrandingArchetypeService.putBrandingArchetype(uuid, rest)
    } else if (body && !body.uuid) {
      let bpsFemaleOrMale = body?.bpsFemaleOrMale || 0
      let bpsSimpleOrComplex = body?.bpsSimpleOrComplex || 0
      let bpsSubtleOrExtravagant = body?.bpsSubtleOrExtravagant || 0
      let bpsClassicOrModern = body?.bpsClassicOrModern || 0
      let bpsFriendlyOrDistant = body?.bpsFriendlyOrDistant || 0
      let bpsPlayfulOrSerious = body?.bpsPlayfulOrSerious || 0
      let bpsNaturalOrIndustrial = body?.bpsNaturalOrIndustrial || 0
      let bpsPopularOrElite = body?.bpsPopularOrElite || 0
      let bpsStrippedOrSophisticated = body?.bpsStrippedOrSophisticated || 0
      let bpsRusticOrRefined = body?.bpsRusticOrRefined || 0

      await BrandingArchetypeService.postArchetypeByLogin({
        ...body,

        bpsFemaleOrMale,
        bpsSimpleOrComplex,
        bpsSubtleOrExtravagant,
        bpsClassicOrModern,
        bpsFriendlyOrDistant,
        bpsPlayfulOrSerious,
        bpsNaturalOrIndustrial,
        bpsPopularOrElite,
        bpsStrippedOrSophisticated,
        bpsRusticOrRefined,

        layerMin: 0,
        layerMax: 0,
        businessId: businessId.current,
      })
    }

    setStep(STEPS.STEP_COMPLETED)
    //setStep(STEPS.STEP_VISUAL_REFERENCES)
  }

  const SliderComponent = ({ value, onChange }) => {
    return (
      <div>
        <Slider
          value={value}
          onChange={onChange}
          min={-5}
          max={5}
          trackStyle={{ backgroundColor: '#28003F', height: 6 }}
          railStyle={{ backgroundColor: '#28003F', height: 6 }}
          handleStyle={{
            width: 15,
            height: 15,
            backgroundColor: '#B900FE',
            border: '2.5px solid #FFFF',
            boxShadow: 'none',
          }}
        />
      </div>
    )
  }

  const SliderValueComponent = ({
    leftText,
    rightText,
    sliderValue,
    setSliderValue,
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 20, width: 180 }}>
          <p
            style={{
              margin: 0,
              fontSize: 10,
              textAlign: 'end',
              color: '#20180F',
            }}
          >
            {leftText}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
          }}
        >
          {SliderComponent({
            value: sliderValue,
            onChange: setSliderValue,
          })}
        </div>

        <div style={{ marginLeft: 20, width: 180 }}>
          <p style={{ margin: 0, fontSize: 10, color: '#20180F' }}>
            {rightText}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Arquétipo de Marca</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>
      <p className={styles.subtitle_text} style={{ fontSize: 12 }}>
        Arquétipos são padrões ou modelos universais que representam
        características e valores humanos básicos.
      </p>
      <p
        className={styles.subtitle_text}
        style={{ marginTop: 20, marginBottom: 8 }}
      >
        História e propósito da marca
      </p>
      <textarea
        id="brandHistoryAndPurpose"
        style={{ height: 80 }}
        className={styles.input}
        placeholder="Breve descrição da origem da marca e sua razão de existir."
        value={brandingArchetypeData?.brandHistoryAndPurpose}
        onChange={({ target: { id, value } }) => handleChange(id, value)}
      ></textarea>

      <p
        className={styles.subtitle_text}
        style={{ marginTop: 20, marginBottom: 8 }}
      >
        Estilo de comunicação da marca
      </p>
      <textarea
        id="brandCommunicationStyle"
        style={{ height: 80 }}
        className={styles.input}
        placeholder="Explique como a marca se comunica com seu público, incluindo o tom de voz, as palavras e frases usadas em anúncios, redes sociais e materiais de marketing."
        value={brandingArchetypeData?.brandCommunicationStyle}
        onChange={({ target: { id, value } }) => handleChange(id, value)}
      ></textarea>
      <p
        className={styles.subtitle_text}
        style={{ marginTop: 20, marginBottom: 8 }}
      >
        Principais concorrentes da marca
      </p>
      <textarea
        id="brandCompetitors"
        style={{ height: 80 }}
        className={styles.input}
        placeholder="Outras marcas que oferecem produtos ou serviços similares aos da sua marca."
        value={brandingArchetypeData?.brandCompetitors}
        onChange={({ target: { id, value } }) => handleChange(id, value)}
      ></textarea>

      <p
        className={styles.subtitle_text}
        style={{ marginTop: 20, marginBottom: 8 }}
      >
        Valores e missão da marca
      </p>
      <textarea
        id="brandValuesAndMission"
        style={{ height: 80 }}
        className={styles.input}
        placeholder="Os princípios e crenças fundamentais da marca e seu objetivo principal."
        value={brandingArchetypeData?.brandValuesAndMission}
        onChange={({ target: { id, value } }) => handleChange(id, value)}
      ></textarea>

      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          marginTop: 20,
        }}
      >
        <p className={styles.title_text} style={{ fontSize: 16 }}>
          BPS (Brand Personality Slider)
        </p>
        <InterrogationCircleIcon color={'#fff'} width={20} height={20} />
      </div> */}
{/* 
      <p
        className={styles.subtitle_text}
        style={{ textAlign: 'center', padding: '0px 80px', fontSize: 12 }}
      >
        BPS é uma ferramenta gráfica que ajuda a definir a personalidade de uma
        marca de maneira simples e visualmente compreensível, permitindo uma
        comunicação consistente e eficaz com o público-alvo.
      </p> */}

      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 330,
            gap: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <p className={styles.number_parameter}>5</p>
            <p className={styles.number_parameter}>4</p>
            <p className={styles.number_parameter}>5</p>
            <p className={styles.number_parameter}>2</p>
            <p className={styles.number_parameter}>1</p>

            <p className={styles.number_parameter}>0</p>

            <p className={styles.number_parameter}>1</p>
            <p className={styles.number_parameter}>2</p>
            <p className={styles.number_parameter}>3</p>
            <p className={styles.number_parameter}>4</p>
            <p className={styles.number_parameter}>5</p>
          </div>

          {SliderValueComponent({
            leftText: 'Feminino',
            rightText: 'Masculino',
            sliderValue: brandingArchetypeData?.bpsFemaleOrMale || 0,

            setSliderValue: (value) => handleChange('bpsFemaleOrMale', value),
          })}

          {SliderValueComponent({
            leftText: 'Simples',
            rightText: 'Complexo',
            sliderValue: brandingArchetypeData?.bpsSimpleOrComplex || 0,

            setSliderValue: (value) =>
              handleChange('bpsSimpleOrComplex', value),
          })}

          {SliderValueComponent({
            leftText: 'Sutil',
            rightText: 'Extravagante',
            sliderValue: brandingArchetypeData?.bpsSubtleOrExtravagant || 0,
            setSliderValue: (value) =>
              handleChange('bpsSubtleOrExtravagant', value),
          })}

          {SliderValueComponent({
            leftText: 'Clássico',
            rightText: 'Moderno',
            sliderValue: brandingArchetypeData?.bpsClassicOrModern || 0,
            setSliderValue: (value) =>
              handleChange('bpsClassicOrModern', value),
          })}

          {SliderValueComponent({
            leftText: 'Amigável',
            rightText: 'Distante',
            sliderValue: brandingArchetypeData?.bpsFriendlyOrDistant || 0,
            setSliderValue: (value) =>
              handleChange('bpsFriendlyOrDistant', value),
          })}

          {SliderValueComponent({
            leftText: 'Brincalhão',
            rightText: 'Sério',
            sliderValue: brandingArchetypeData?.bpsPlayfulOrSerious || 0,
            setSliderValue: (value) =>
              handleChange('bpsPlayfulOrSerious', value),
          })}

          {SliderValueComponent({
            leftText: 'Natural',
            rightText: 'Industrial',
            sliderValue: brandingArchetypeData?.bpsNaturalOrIndustrial || 0,
            setSliderValue: (value) =>
              handleChange('bpsNaturalOrIndustrial', value),
          })}

          {SliderValueComponent({
            leftText: 'Popular',
            rightText: 'Elitizado',
            sliderValue: brandingArchetypeData?.bpsPopularOrElite || 0,
            setSliderValue: (value) => handleChange('bpsPopularOrElite', value),
          })}

          {SliderValueComponent({
            leftText: 'Despojado',
            rightText: 'Sofisticado',
            sliderValue: brandingArchetypeData?.bpsStrippedOrSophisticated || 0,
            setSliderValue: (value) =>
              handleChange('bpsStrippedOrSophisticated', value),
          })}

          {SliderValueComponent({
            leftText: 'Rústico',
            rightText: 'Refinado',
            sliderValue: brandingArchetypeData?.bpsRusticOrRefined || 0,
            setSliderValue: (value) =>
              handleChange('bpsRusticOrRefined', value),
          })}
        </div>
      </div> */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          marginTop: 40,
          flex: 1,
        }}
      >
        {/* <p className={styles.title_text} style={{ fontSize: 16 }}>
          Camadas
        </p> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            flex: 1,
            // background: 'blue',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {/* <p className={styles.subtitle_text} style={{ fontSize: 12 }}>
              As camadas desempenham um papel fundamental na definição do estilo
              e da quantidade de elementos visuais em um post. Os posts com
              poucas camadas possuem um estilo mais "minimalista", enquanto
              aqueles com várias camadas apresentam uma maior complexidade.
            </p> */}
            <div style={{ width: 260, marginTop: 30 }}>
              {/* <Slider
                range
                allowCross={false}
                value={sliderLayers}
                onChange={(e) => console.log('teste 435', e)}
                defaultValue={[0, 0]}
                min={1}
                max={32}
                trackStyle={{ backgroundColor: '#B900FE', height: 6 }}
                railStyle={{ backgroundColor: '#E6E6E6', height: 6 }}
                handleStyle={{
                  width: 15,
                  height: 15,
                  backgroundColor: '#B900FE',
                  border: '2.5px solid #FFFF',
                  boxShadow: 'none',
                }}
              />
              <div style={{ display: 'flex', marginTop: 20, gap: 10 }}>
                <input
                  className={styles.inputLayer}
                  value={brandingArchetypeData?.layerMin}
                  //     setSliderValue: (value) =>
                  // handleChange('bpsNaturalOrIndustrial', value)
                  onChange={(e) => console.log('teste 435', e)}
                />
                <p style={{ fontSize: 16, fontWeight: 500, color: '#8b8b8b' }}>
                  -
                </p>
                <input
                  className={styles.inputLayer}
                  value={brandingArchetypeData?.layerMax}
                  onChange={(value) => handleChange('layerMax', value)}
                />
              </div> */}
            </div>
          </div>

          {/* <div style={{ width: 240 }}>
            <img
              style={{ width: '100%', borderRadius: 20 }}
              src={
                'https://i.pinimg.com/564x/57/fc/eb/57fcebc8defbd9e1f49e9c4b09b26682.jpg'
              }
              alt="image"
            />
          </div> */}
        </div>
      </div>

      <div />

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_VOICE_TONE)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleSubmit()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepBrandArchetype
