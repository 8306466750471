import { useState } from 'react'
import styles from './styles.module.css'
import {
  CloundArrowUp,
  Interrogation,
  Stars,
  Trash,
} from '../../../assets/svgs/icons'
import { LogosService, PythonService } from '../../../services'

const STEPS = {
  STEP_PASS_TYPOGRAPHY: 'stepTypography',
  STEP_PASS_IMAGE: 'stepImage',
}

function StepLogo({ setStep, logoSelectedArray, setLogoSelectedArray }) {
  const handleRemoveBackground = async (index, base64) => {
    const { data } = await PythonService.postRemoveBackground({ image: base64 })
    const noBgImage = data.data.images

    logoSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
    logoSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal

    setLogoSelectedArray([...logoSelectedArray])
  }

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setLogoSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setLogoSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleRemoveFile = (index) => {
    const updatedFilesArray = logoSelectedArray.filter((_, i) => i !== index)

    setLogoSelectedArray(updatedFilesArray)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      setStep(STEPS.STEP_PASS_IMAGE)
    } else {
      setStep(STEPS.STEP_PASS_TYPOGRAPHY)
    }
  }

  const postLogos = async () => {
    let businessId

    // if (clientData?.uuid) {
    //   const { data } = await getBusinessByClientId(clientData?.uuid)
    //   businessId = data[0]?.uuid
    // } else {
    //   const { data } = await BusinessService.getBusinessByLogin()
    //   businessId = data[0]?.uuid
    // }

    let body = {}

    // Dividir a string base64 para separar o cabeçalho da imagem
    const parts = logoSelectedArray[0]?.fileDataBase64?.split(',')

    const header = parts[0]
    const base64Data = parts[1]

    // Extrair o formato do arquivo e o tipo de conteúdo
    const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
    const fileContentType = matches[0]
    const fileFormat = fileContentType.split('/')[1]
    // if (clientData?.uuid) {
    //   body = {
    //     businessId: businessId,
    //     description: logoSelectedArray[0]?.name,
    //     fileFormat: fileFormat,
    //     fileContentType: fileContentType,
    //     fileBase64: base64Data,
    //     //businessClientId: clientData?.uuid,
    //   }
    // } else {
    body = {
      businessId: businessId,
      description: logoSelectedArray[0]?.name,
      fileFormat: fileFormat,
      fileContentType: fileContentType,
      fileBase64: base64Data,
    }
    //}

    await LogosService.postLogo(body)
  }

  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Logotipo</p>
        <h1 style={{ marginTop: '0px' }}>Adicione seu Logotipo</h1>

        <div className={styles.container_modal}>
          <div className={styles.content_up_image}>
            <div className={styles.modal_container_drag}>
              <div className={styles.modal_drag}>
                {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
              </label> */}

                <div
                  className={styles.modal_drag_drop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  // onClick={handleShowListImages}

                  // style={{
                  //   height: '100px',
                  //   width: '100%',
                  //   border: '2px dashed black',
                  //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                  // }}
                  // className={styles.modal_drag}
                >
                  <label htmlFor="arquivo">
                    <CloundArrowUp color={'#B900FE'} />
                    <h2
                      className={
                        logoSelectedArray.length !== 0
                          ? styles.modal_drag_drop_title_short
                          : styles.modal_drag_drop_title
                      }
                    >
                      Arraste & Solte o arquivo
                    </h2>
                    <p
                      className={
                        logoSelectedArray.length !== 0
                          ? styles.modal_drag_drop_sub_title_short
                          : styles.modal_drag_drop_sub_title
                      }
                    >
                      Ou selecione o arquivo no explorador
                    </p>
                  </label>
                  <input
                    type="file"
                    name="arquivo"
                    id="arquivo"
                    onChange={(e) => onFileChange(e)}
                  ></input>
                  {/* {fileData && (
                      <img
                        style={{ width: 50, height: 50 }}
                        src={fileData}
                        alt="file-preview"
                      />
                    )} */}
                </div>
              </div>
            </div>

            {logoSelectedArray.length !== 0 && (
              <div className={styles.content_list_image}>
                <div className={styles.container_box_list_image}>
                  {logoSelectedArray?.map((image, index) => (
                    <div
                      key={index}
                      className={styles.container_list_image}
                      // style={{ border: '1px solid red' }}
                    >
                      <div className={styles.item_list_image}>
                        <img
                          src={image.fileDataBase64}
                          alt="file-preview"
                          style={{ width: 40, height: 40 }}
                        />
                        <div className={styles.box_data_info}>
                          <p className={styles.box_data_info_title}>
                            {image.name}
                          </p>
                          <p className={styles.box_data_info_size}>
                            Tamanho &nbsp;<b> {formatBytes(image.size)} </b>
                          </p>
                        </div>
                      </div>
                      <div className={styles.item_list_image_buttons}>
                        <button
                          className={styles.button_cancel}
                          onClick={() => handleRemoveFile(index)}
                        >
                          <p className={styles.button_cancel_title}>Excluir</p>
                          <Trash />
                        </button>
                        <button
                          className={styles.button_remove_background}
                          onClick={() =>
                            handleRemoveBackground(index, image.fileDataBase64)
                          }
                        >
                          <p className={styles.button_remove_background_title}>
                            Remover Fundo
                          </p>{' '}
                          <Stars />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.modal_button_save}>
              <button className={styles.button_add}>
                <label className={styles.button_add_label} htmlFor="arquivo">
                  Adicionar Imagem
                </label>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepLogo
