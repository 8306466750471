import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './styles.module.css'

import posteiLogoColor from '../../assets/logos/postei-logo-color.png'
import posteiLogoTextColor from '../../assets/logos/postei-logo-text-color.png'
import { useEffect } from 'react'
import { LinkedinService } from '../../services'
import { useRedirect } from '../../hooks/useRedirectPAge'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useClient } from '../../hooks/useClient'

function LinkedinRedirection() {
  // let { id, teste } = useParams()

  const navigation = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryBusinessId = queryParams.get('businessId')
  const queryCode = queryParams.get('code')

  const { setClientData } = useClient()

  // const body = {}
  const { redirectUrl, idParam, setRedirect } = useRedirect()
  // const idParam = localStorage.getItem('clientId')

  useEffect(() => {
    linkedinCallback()
  }, [])

  const setClient = (idParam) => {
    setClientData(idParam)

    setRedirect(null, null)
  }

  const linkedinCallback = async () => {
    const { responseStatus } = await LinkedinService.getLinkedinCallback(
      queryBusinessId,
      queryCode
    )

    if (responseStatus === 200 || responseStatus === 204) {
      setClient(idParam)

      const timeoutId = setTimeout(() => {
        navigation(redirectUrl, {
          state: {
            redirect: true,
            //uncompletedSteps: 'stepSocialMedia',
          },
        })
      }, 5000)

      // Limpa o timeout ao desmontar o componente
      return () => clearTimeout(timeoutId)
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        {/* <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} />
        <img src={posteiLogoText} alt="posteiLogoText" style={{ height: 34 }} /> */}
        <img src={posteiLogoColor} alt="posteiLogo" style={{ height: 34 }} />
        <img
          src={posteiLogoTextColor}
          alt="posteiLogoText"
          style={{ height: 34 }}
        />
      </div>

      <div className={styles.divider} />
      <div className={styles.container}>
        <h1>Login efetuado com sucesso!</h1>
      </div>
    </div>
  )
}

export default LinkedinRedirection
