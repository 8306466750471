import { useState } from 'react'
import styles from './styles.module.css'
import { Eye, Interrogation } from '../../../assets/svgs/icons'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { UserService } from '../../../services'

function StepPassword({
  setPass,
  setConfirmPass,
  pass,
  confirmPass,
  setStep,
  wordsArray,
  phone,
  email,
  setUserData,
}) {
  const [showPass, setShowPass] = useState(false)
  const [showPassConfirm, setShowPassConfirm] = useState(false)
  const handlePass = (type) => {
    if (type === 'pass') {
      setShowPass(!showPass)
    } else {
      setShowPassConfirm(!showPassConfirm)
    }
  }

  const STEPS = {
    STEP_PASS_PHONE: 'stepPhone',
    STEP_PASS_CPF: 'stepCPF',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      // if (pass === '' || confirmPass === '') {
      //   notifyErrorCustom('Preencha os campos de senha e confirmação de senha')
      //   return
      // }
      // if (pass.length < 9 || confirmPass.length < 9) {
      //   notifyErrorCustom(
      //     'Senha muito curta, deve ter pelo menos 9 caracteres.'
      //   )
      //   return
      // }
      // if (pass != confirmPass) {
      //   notifyErrorCustom('A confirmação de senha não está correta')
      //   return
      // }

      // let bodyUser = {
      //   name: wordsArray[0],
      //   surname: wordsArray[wordsArray.length - 1],
      //   phone: phone,
      //   email: email,
      //   password: pass,
      // }

      // const UserReturn = await UserService.postUser(bodyUser)

      // console.log('userRet', UserReturn)

      // if (
      //   UserReturn?.responseStatus != 200 &&
      //   UserReturn?.responseStatus != 204
      // ) {
      //   notifyErrorCustom('Erro ao cadastrar usuario.')
      //   return
      // } else {
      //   notifySuccessCustom('Usuario foi criado com sucesso')
      //   setUserData(UserReturn?.data)
      // }

      setStep(STEPS.STEP_PASS_CPF)
    } else {
      setStep(STEPS.STEP_PASS_PHONE)
    }
  }

  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Cadastro</p>
        <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

        <div className={styles.box_input_password}>
          <p>Agora crie uma senha forte e segura</p>
          <div className={styles.container_input_password}>
            <div
              className={styles.custom_input}
              type="text"
              placeholder="Senha"
            >
              <input
                className={styles.input_password}
                type={showPass ? 'text' : 'password'}
                placeholder="Senha"
                value={pass}
                onChange={(e) => setPass(e.currentTarget.value)}
              />
              <div
                className={styles.icon_input}
                onClick={() => handlePass('pass')}
              >
                <Eye />
              </div>
            </div>

            <div
              className={styles.custom_input}
              type="text"
              placeholder="Senha"
            >
              <input
                className={styles.input_password}
                type={showPassConfirm ? 'text' : 'password'}
                placeholder="Confirmar senha"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.currentTarget.value)}
              />
              <div
                className={styles.icon_input}
                onClick={() => handlePass('passConfirm')}
              >
                <Eye />
              </div>
            </div>
          </div>

          <div className={styles.box_information}>
            <Interrogation color={'#FF8B00'} width={20} height={20} />
            <p>Dica: Use combinações com letras, símbolos e números</p>
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepPassword
