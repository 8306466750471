import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'

import StepFirstPass from './StepfirstPass'
import StepImageBank from './StepSecondPass/StepImageBank'
import StepSecondPass from './StepSecondPass'
import StepThirdPass from './StepThirdPass'
import StepFourtPass from './StepFourthPass'
import StepFifthPass from './StepFifthPass'
import StepNewCampaign from './StepNewCampaign'
import StepNewCampaignSuccess from './StepNewCampaignSuccess'

const STEPS = {
  STEP_FIRST_PASS_HEADLINES: 'stepHeadlines',
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
  STEP_FIFTH_PASS_SCHEDULE: 'stepSchedule',
  STEP_NEW_CAMPAIGN: 'stepNewCampaign',
  STEP_NEW_CAMPAIGN_SUCCESS: 'stepNewCampaignSuccess',
}

function NewCampaignForm() {
  const { clientData } = useClient()
  const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS_HEADLINES)

  const [selectedCampaignOption, setSelectedCampaignOption] =
    useState(undefined)

  const [iaData, setIaData] = useState([])

  const [imageGaleryArray, setImageGaleryArray] = useState([])
  const [imageArray, setImageArray] = useState([])
  const [imageLayers, setImageLayers] = useState([])

  const [publicationArray, setPublicationArray] = useState([])
  const [imageAIArray, setImageAIArray] = useState([])
  const [imageUploadArray, setImageUploadArray] = useState([])
  const [imageProductsArray, setImageProductsArray] = useState([])

  const campaignRef = useRef(undefined)

  const [selectedImagesLogo, setSelectedImagesLogo] = useState([])

  const [selectedArrayImage, setSelectedArrayImage] = useState([])
  const [selectedArrayImageObject, setSelectedArrayImageObject] = useState([])

  const [headlineText, setHeadlineText] = useState('')
  const [headlineList, setHeadlineList] = useState([])

  const [legendList, setLegendList] = useState([])

  const [legend, setLegend] = useState(false)

  const [uuidLocation, setUuidLocation] = useState('')
  const [campaignIDParam, setCampaignIDParam] = useState('')

  useEffect(() => {
    if (location?.state?.campaignItem) {
      setUuidLocation(location.state.campaignItem.uuid)
    }

    if (location?.state?.campaign) {
      setStep(STEPS.STEP_SECOND_PASS_IMAGE)
    } else {
      setStep(STEPS.STEP_FIRST_PASS_HEADLINES)
    }

    if (location?.state?.campaignId) {
      setCampaignIDParam(location?.state?.campaignId)
    }

    setSelectedCampaignOption(undefined)
  }, [clientData])

  return (
    <>
      {step === STEPS.STEP_FIRST_PASS_HEADLINES && (
        <StepFirstPass
          setStep={setStep}
          selectedCampaignOption={selectedCampaignOption}
          setSelectedCampaignOption={setSelectedCampaignOption}
          clientData={clientData}
          campaignRef={campaignRef}
          uuidLocation={uuidLocation}
          campaignIDParam={campaignIDParam}
        />
      )}

      {step === STEPS.STEP_NEW_CAMPAIGN && (
        <StepNewCampaign
          setStep={setStep}
          imageArray={imageArray}
          setImageArray={setImageArray}
          imageGaleryArray={imageGaleryArray}
          setImageGaleryArray={setImageGaleryArray}
          imageAIArray={imageAIArray}
          setImageAIArray={setImageAIArray}
          imageUploadArray={imageUploadArray}
          setImageUploadArray={setImageUploadArray}
        />
      )}

      {step === STEPS.STEP_NEW_CAMPAIGN_SUCCESS && (
        <StepNewCampaignSuccess setStep={setStep} />
      )}

      {step === STEPS.STEP_SECOND_PASS_IMAGE && (
        <StepSecondPass
          setStep={setStep}
          imageArray={imageArray}
          setImageArray={setImageArray}
          imageGaleryArray={imageGaleryArray}
          setImageGaleryArray={setImageGaleryArray}
          imageAIArray={imageAIArray}
          setImageAIArray={setImageAIArray}
          imageUploadArray={imageUploadArray}
          setImageUploadArray={setImageUploadArray}
          imageProductsArray={imageProductsArray}
          setImageProductsArray={setImageProductsArray}
          selectedCampaignOption={selectedCampaignOption}
        />
      )}

      {step === STEPS.STEP_IMAGE_BANK && (
        <StepImageBank setStep={setStep} setImageArray={setImageArray} />
      )}

      {step === STEPS.STEP_THIRD_PASS_PUBLICATION && (
        <StepThirdPass
          setStep={setStep}
          selectedArrayImage={selectedArrayImage}
          setSelectedArrayImage={setSelectedArrayImage}
          selectedArrayImageObject={selectedArrayImageObject}
          setSelectedArrayImageObject={setSelectedArrayImageObject}
          imageLayers={imageLayers}
          setImageLayers={setImageLayers}
          headlineText={headlineText}
          setHeadlineText={setHeadlineText}
          setHeadlineList={setHeadlineList}
          setLegendList={setLegendList}
          legendList={legendList}
          headlineList={headlineList}
          imageGaleryArray={imageGaleryArray}
          selectedCampaignOption={selectedCampaignOption}
          imageAIArray={imageAIArray}
          imageUploadArray={imageUploadArray}
          imageProductsArray={imageProductsArray}
          iaData={iaData}
          setIaData={setIaData}
        />
      )}

      {step === STEPS.STEP_FOURTH_PASS_SUBTITLE && (
        <StepFourtPass
          setStep={setStep}
          publicationArray={publicationArray}
          setPublicationArray={setPublicationArray}
          selectedArrayImage={selectedArrayImage}
          headlineList={headlineList}
          headlineText={headlineText}
          legend={legend}
          campaignRef={campaignRef}
          setLegend={setLegend}
          setLegendList={setLegendList}
          legendList={legendList}
        />
      )}

      {step === STEPS.STEP_FIFTH_PASS_SCHEDULE && (
        <StepFifthPass
          setStep={setStep}
          setPublicationArray={setPublicationArray}
          selectedArrayImage={selectedArrayImage}
          selectedArrayImageObject={selectedArrayImageObject}
          selectedCampaignOption={selectedCampaignOption}
          headlineText={headlineText}
          legend={legend}
          setLegendList={setLegendList}
          legendList={legendList}
          headlineList={headlineList}
          selectedImagesLogo={selectedImagesLogo}
          setSelectedImagesLogo={setSelectedImagesLogo}
        />
      )}
    </>
  )
}

export default NewCampaignForm
