import { Pen2, PencilSquare, RoundedArrow } from '../../../../assets/svgs/icons'
import { Input, IconButton, ColorPalete, Button } from '../../..'
import styles from './styles.module.css'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

export const ColorPalletStep = ({title, subtitle, colors}) => {
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} className={styles.form}>
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <label>Titulo</label>
          <PencilSquare size='24' color={'rgba(255, 139, 0, 1)'}/>
        </div>
        <input type="text" name="title" value={title} />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <label>SubTitulo</label>
          <PencilSquare size='24' color={'rgba(255, 139, 0, 1)'}/>
        </div>
        <input type="text" name="title" value={subtitle}  />
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.colorPaleteContainer}>
          <label>Paleta de Cores</label>
          <ColorPalete onClose={(e)=>console.log(e)} colors={colors}/>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.alterationContainer}>
          <Input defaultValue={'Digite aqui sua alteração'} placeholder='digite aqui sua alteração'/>
          <IconButton icon={<RoundedArrow size='25'/>}/>
        </div>
        <div className={styles.advencedContainer}>
          <Button style={{width:'100%'}}><div className={styles.buttonContent}><Pen2 size={21}/> Editor Avançado </div></Button>
        </div>
      </div>
    </motion.div>
  )
}

ColorPalletStep.propTypes = {
  title: PropTypes.string,
  subtitle:PropTypes.string,
  colors:PropTypes.array,
  onClose:PropTypes.func
}


