/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import {
  NavigateArrowRigth,
  ChevronDown,
  BoxNotChecket,
  BoxChecket,
} from '../../../assets/svgs/icons'

export const MultiplySelectSquare = ({
  backgroundColor = '#e0e0e0',
  variante = 'purple' || 'gray',
  border,
  borderColor,
  options,
  label,
  htmlFor,
  placeholder,
  defaultSelected = [],
  arrowDirection = 'right',
  fontSize,
  onClickItem,
  round,
  color = '#0f172a',
  arrowColor = '#0f172a',
  padding = '10px 15px 10px 15px',
  radius = '10px',
  minWidth = '0px',
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItems, setSelectedItems] = useState(defaultSelected)

  const ref = useRef(null)

  const handleSelect = (option) => {
    //const isAlreadySelected = selectedItems.some(item => item.value === option.value);
    const isAlreadySelected = selectedItems.some(
      (item) => item.value === option.value
    )

    let updatedItems

    if (isAlreadySelected) {
      updatedItems = selectedItems.filter((item) => item.value !== option.value)
    } else {
      updatedItems = [...selectedItems, option]
    }

    setSelectedItems(updatedItems)

    onClickItem(updatedItems)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  useEffect(() => {
    setSelectedItems(defaultSelected)
  }, [])

  return (
    <div ref={ref} className={styles.Container_select}>
      <div className={styles.inputSelectContainer}>
        <label htmlFor={htmlFor}>{label}</label>
        <div
          style={{
            backgroundColor,
            border: border ? '1px solid ' : 'none',
            borderColor: borderColor ? borderColor : 'none',
            padding: padding,
            borderRadius: radius,
            minWidth: minWidth,
          }}
          className={round ? styles.buttonSelectRound : styles.buttonSelect}
          onClick={() => setContainerVisible((prev) => !prev)}
        >
          <p style={{ fontSize, color }} className={styles.placeholder_select}>
            {selectedItems.length > 0
              ? selectedItems.map((item) => item.label).join(', ')
              : placeholder}
          </p>
          <div className={styles.box_svg}>
            <div
              className={`${styles[arrowDirection]} ${
                containerVisible ? styles.rotate : styles.containerVisible
              }`}
            >
              {arrowDirection === 'right' && (
                <NavigateArrowRigth size="16" color={arrowColor} />
              )}
              {arrowDirection === 'down' && (
                <ChevronDown size="16" color={arrowColor} />
              )}
            </div>
          </div>
        </div>
        {containerVisible && (
          <div className={styles.selectContainer}>
            {options.map((option, index) => {
              const isSelected = selectedItems.some(
                (item) => item.value === option.value
              )
              return (
                <div
                  key={index}
                  className={
                    variante === 'purple'
                      ? styles.selectContainerItemPurple
                      : styles.selectContainerItemGrey
                  }
                  style={{ backgroundColor }}
                  onClick={() => handleSelect(option)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isSelected ? (
                      <BoxChecket borderColor={'#000'} />
                    ) : (
                      <BoxNotChecket borderColor={'#000'} />
                    )}
                  </div>
                  <span
                    className={
                      variante === 'purple'
                        ? styles.labelPurple
                        : styles.labelGray
                    }
                  >
                    {option.label}
                  </span>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
