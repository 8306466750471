import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import InputMask from 'react-input-mask'
import { isObjEqual } from '../../utils/verifyObject'
import { useNavigate } from 'react-router-dom'
import { InterrogationCircleIcon } from '../../assets/svgs/icons'
import PropTypes from 'prop-types'
import { UserService } from '../../services'
import { notifyErrorCustom } from '../../utils/notify'
import * as yup from 'yup'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepRegistrationData({ setStep }) {
  const navigate = useNavigate()
  const [registrationData, setRegistrationData] = useState({})
  const [registrationToCompare, setRegistrationToCompare] = useState({})

  const registrationDataScheema = yup.object().shape({
    name: yup.string().required('preencha o campo nome'),
    surname: yup.string().required('preencha o campo sobrenome'),
    email: yup
      .string()
      .email('preencha o campo email corretamente')
      .required('preencha o campo email'),
    phone: yup.string().required('preencha o campo telefone'),
  })

  const validateFields = async () => {
    const isValid = await registrationDataScheema
      .validate(registrationData)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })

    return isValid
  }

  useEffect(() => {
    ;(async () => {
      const { responseStatus, data } = await UserService.getUserByLogin()
      if (responseStatus === 200) {
        setRegistrationData(data)
        setRegistrationToCompare(data)
      }
    })()
  }, [])

  const nextButton = async () => {
    const isValid = await validateFields()

    if (!isValid) return

    if (!isObjEqual(registrationData, registrationToCompare)) {
      let newRegistrationData = registrationData

      delete newRegistrationData.uuid
      delete newRegistrationData.createdAt
      delete newRegistrationData.updatedAt
      delete newRegistrationData.deletedAt

      await UserService.updateUserById(registrationData)
    }
    setStep(STEPS.STEP_BUSINESS_INFORMATION)
  }

  const handleRegistrationDataChange = (id, value) => {
    setRegistrationData((prevState) => ({ ...prevState, [id]: value }))
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Dados cadastrais</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        Estas informações são valiosas para nossa AI aprender mais sobre você
      </p>

      <div className={styles.step_data_container}>
        <div className={styles.input_field}>
          <input
            className={styles.input}
            type="text"
            placeholder="Nome"
            id="name"
            value={registrationData.name}
            onChange={({ target: { id, value } }) =>
              handleRegistrationDataChange(id, value)
            }
          />

          <input
            className={styles.input}
            type="text"
            placeholder="Sobrenome"
            value={registrationData.surname}
            id="surname"
            onChange={({ target: { id, value } }) =>
              handleRegistrationDataChange(id, value)
            }
          />

          <input
            className={styles.input}
            type="text"
            placeholder="E-mail"
            id="email"
            value={registrationData.email}
            onChange={({ target: { id, value } }) =>
              handleRegistrationDataChange(id, value)
            }
          />

          <InputMask
            className={styles.input}
            type="text"
            mask="(99) 99999-9999"
            placeholder="Telefone"
            id="phone"
            value={registrationData.phone}
            onChange={({ target: { id, value } }) =>
              handleRegistrationDataChange(id, value)
            }
          />
        </div>
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep onClick={() => navigate(-1)} variant={true}>
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => nextButton()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepRegistrationData

StepRegistrationData.propTypes = {
  setStep: PropTypes.func,
  onClickNext: PropTypes.func,
}
