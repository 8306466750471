import style from './styles.module.css'

const ProgressBar = ({ percent }) => {
  return (
    <div className={style.container_ProgressBar}>
      <div className={style.total_bar} />
      <div className={style.progress_bar} style={{ width: `${percent}%` }} />
    </div>
  )
}

export default ProgressBar
