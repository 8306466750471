import { Navigate, useOutlet, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const PublicLayout = () => {
  const { userData } = useAuth()

  const outlet = useOutlet()
  const location = useLocation()

  if (location.pathname === '/') {
    return <Navigate to="/SignIn" />
  }

  if (userData) {
    return <Navigate to="/WelcomePage" replace />
  }

  return <div>{outlet}</div>
}
