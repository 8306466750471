import styles from './styles.module.css'
import { CloseIcon } from '../../assets/svgs/icons'
import { SideBar } from './Sidebar'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

import { ImageStep } from './steps/ImageStep'
import { CalendarStep } from './steps/CalendarStep'
import { SocialMediaStep } from './steps/SocialMediaStep'
import { TextsStep } from './steps/TextsStep'
import { PublicationStep } from './steps/PublicationStep'

export const HistoryModal = ({ isOpen, onClose, modalData, scheduling }) => {
  const [step, setStep] = useState(scheduling ? 2 : 1)
  const [title, setTitle] = useState('')

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      divRef.current.style.left = '10000px'
      divRef.current.style.transition = 'ease-in-out left 0.3s'
      setTimeout(() => {
        onClose()
        setStep(scheduling ? 2 : 1)
      }, 310)
    }
  }

  const images = [
    {
      url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
      alt: 'alt_image',
    },
    {
      url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
      alt: 'alt_image',
    },
    {
      url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
      alt: 'alt_image',
    },
    {
      url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
      alt: 'alt_image',
    },
    {
      url: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png',
      alt: 'alt_image',
    },
  ]

  const divRef = useRef(null)

  useEffect(() => {
    const titles = {
      1: 'imagens',
      2: 'Data da Publicação',
      3: 'Redes Sociais Vinculadas',
      4: 'Textos',
      5: 'Publicar Novamente',
    }
    setTitle(titles[step] || 'Título Padrão')
  }, [step])

  return !isOpen ? null : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.modal_overlay}
      onClick={handleClose}
    >
      <motion.div
        ref={divRef}
        initial={{ left: 10000 }}
        animate={{ left: 0 }}
        transition={{ type: 'spring', duration: 0.5, mass: 0.4 }}
        className={styles.modal_content}
      >
        <div className={styles.sidebar_container}>
          <SideBar onClickStep={(e) => setStep(e)} />
        </div>
        <div className={styles.modal_container}>
          <div className={styles.title}>
            <p>{title}</p>
            <div
              className={styles.box_}
              style={{ cursor: 'pointer' }}
              onClick={handleClose}
            >
              <CloseIcon color="#0F172A" size="20" />
            </div>
          </div>
          <div>
            {step === 1 && <ImageStep images={images} />}
            {step === 2 && <CalendarStep modalData={modalData} />}
            {step === 3 && <SocialMediaStep />}
            {step === 4 && <TextsStep modalData={modalData} />}
            {step === 5 && <PublicationStep />}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}
