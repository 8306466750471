/* eslint-disable react/prop-types */
/* eslint-disable react-refresh/only-export-components */
import { toast } from 'react-toastify'
import { Cross } from '../../assets/svgs/icons'

const CustomToastComponent = ({ title, message, closeToast }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
    <p style={{ fontSize: 16, fontWeight: 700, margin: 0 }}>{title}</p>
    <p
      style={{
        fontSize: 12,
        fontWeight: 400,
        flex: 1,
        margin: 0,
        // textAlign: 'center',
      }}
    >
      {message}
    </p>

    <div onClick={closeToast} style={{}}>
      <Cross color={'#fff'} width={14} height={14} />
    </div>
  </div>
)

export const notifyErrorCustom = (message, title = 'Erro!') => {
  toast(<CustomToastComponent title={title} message={message} />, {
    style: {
      backgroundColor: '#FF2F00',
      color: '#fff',
      borderRadius: 10,
    },
    closeButton: false,
    autoClose: 8000,
  })
}

export const notifySuccessCustom = (message) => {
  toast(<CustomToastComponent title={'Sucesso!'} message={message} />, {
    style: {
      backgroundColor: '#3CBC00',
      color: '#fff',
      borderRadius: 10,
    },
    closeButton: false,
  })
}

export const notifyWarningCustom = (message, title = 'Aviso!') => {
  toast(<CustomToastComponent title={title} message={message} />, {
    style: {
      backgroundColor: '#f2f486',
      color: '#000000',
      borderRadius: 10,
    },
    closeButton: false,
  })
}
