import { CloseIcon, Plus } from '../../../../assets/svgs/icons'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

export const EffectsStep = ({
  effects,
  aditionalEffects,
  onClickDeleteTag,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container}
    >
      <span className={styles.label}>Efeitos Adicionais</span>
      <div className={styles.tagsContainerEffectsContainer}>
        <div className={styles.tagsContainer}>
          {effects.map((e, index) => (
            <div
              key={index}
              className={styles.tag}
              onClick={() => onClickDeleteTag(index)}
            >
              {e} <CloseIcon />
            </div>
          ))}
        </div>
        <div className={styles.effectsContainer}>
          <span>Mais Efeitos</span>
          {aditionalEffects.map((e, index) => (
            <div key={index} className={styles.effectItem}>
              <Plus />
              {e}
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  )
}

EffectsStep.propTypes = {
  effects: PropTypes.array,
  aditionalEffects: PropTypes.array,
  onClickDeleteTag: PropTypes.func,
}
