/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import {
  CheckBadge,
  Clock,
  CreditCard,
  Cross,
  PencilUploadIcon,
} from '../../assets/svgs/icons'
import styles from './styles.module.css'

import companyIcon from '../../assets/imgs/enterprise.png'
import Stepper from '../../components/Stepper'
import ProgressBar from '../../components/ProgressBar'
import { SelectRound } from '../../components/Select/SelectRound'
import { useNavigate } from 'react-router-dom'
import {
  PagarMeService,
  TimelineActionsService,
  UserAccountsService,
  UserService,
  PlanService,
  SubscriptionService,
} from '../../services/'

import Masonry from 'react-responsive-masonry'

import { SettingsService } from '../../services'
import { useClient } from '../../hooks/useClient'
import ModalComponent from '../../components/ModalComponent'
import ModalCancelPlan from './ModalCancelPlan'

function Settings() {
  const [companyData, setCompanyData] = useState({
    companyName: '',
    companyEmail: '',
    companyPlan: '',
    companyValue: '',
    companyPeriod: '',
  })
  const [featureData, setFeatureData] = useState({
    // postQuantity: 3,
    // postQuantityLimit: 30,
    // userQuantity: 0,
    // userQuantityLimit: 1,
    // schedullingQuantity: 14,
    // schedullingQuantityLimit": 120,
    // clientQuantity: 1,
    // clientQuantityLimit: 2,
    // integrationQuantity: 1,
    // integrationQuantityLimit: 2
  })

  const [userImageData, setUserImageData] = useState([])

  const [nameCredCard, setNameCredCard] = useState('')

  const [validateCredCard, setValidateCredCard] = useState('')
  const [cvvCredCard, setCvvCredCard] = useState('')
  const [timelineActionData, setTimelineActionData] = useState([])

  const [percentStep, setPercentStep] = useState(0)

  const [completedStepParametrization, setCompletedStepParametrization] =
    useState(false)
  const [completedStepAddClients, setCompletedStepAddClients] = useState(false)
  const [completedStepAddTeamMembers, setCompletedStepAddTeamMembers] =
    useState(false)
  const [completedStepCreateCampaign, setCompletedStepCreateCampaign] =
    useState(false)
  const [completedStepAddNewPost, setCompletedStepAddNewPost] = useState(false)

  const { clientData } = useClient()

  const [userAcount, setUserAccount] = useState()

  const [isModalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    loadData()
    loadCardData()
  }, [])

  const loadCardData = async () => {
    if (clientData?.uuid) {
      // console.log(clientData)
      // const { data: dataAccount, responseStatus: responseStatusAccount } =
      //   await UserAccountsService.getUserAccountsByLogin()
      // const dataCard = await PagarMeService.postCard(clientData?.uuid)
    } else {
      const dataCard = await PagarMeService.getCardByUser()

      if (dataCard.data) {
        setNumberCredCard(dataCard?.data[0]?.number)
        setNumberCredCardValue(dataCard?.data[0]?.number)
        setNameCredCard(dataCard?.data[0]?.cardName)
        setValidateCredCard(dataCard?.data[0]?.expirationDate)
      }
    }
  }

  const loadData = async () => {
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())
    const settingsResponse =
      await SettingsService.getSettingsByUserAccountParameters()

    const settingsByUserData = await SettingsService.getSettingsByUser()

    if (settingsResponse.responseStatus === 200) {
      // console.log('settingsResponse', settingsResponse.data)

      if (settingsResponse?.data?.parameterization) {
        setCompletedStepParametrization(true)
        setPercentStep((prev) => prev + 1)
      }

      if (settingsResponse?.data?.clientes) {
        setCompletedStepAddClients(true)
        setPercentStep((prev) => prev + 1)
      }

      if (settingsResponse?.data?.members) {
        setCompletedStepAddTeamMembers(true)
        setPercentStep((prev) => prev + 1)
      }

      if (settingsResponse?.data?.campaigns) {
        setCompletedStepCreateCampaign(true)
        setPercentStep((prev) => prev + 1)
      }

      if (settingsResponse?.data?.publications) {
        setCompletedStepAddNewPost(true)
        setPercentStep((prev) => prev + 1)
      }
    }
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (userSubscription.data.length > 0) {
      const planValue = parseFloat(userSubscription.data[0].Plan.price)
      const expirationDate = new Date(userSubscription.data[0].expiration)
      expirationDate.setHours(expirationDate.getHours() + 3)
      const formattedPrice = planValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      const userSubscriptionFeatures =
        await SubscriptionService.getSubscriptionFeatures()

      const formattedDate = `${expirationDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${(expirationDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${expirationDate.getFullYear()}`

      if (settingsByUserData.responseStatus === 200) {
        let companyName = settingsByUserData.data?.userAccount?.fantasyName
          ? settingsByUserData.data?.userAccount?.fantasyName
          : settingsByUserData.data.name
        if (settingsByUserData.data?.userAccount?.corporateName) {
          companyName = settingsByUserData.data?.userAccount?.corporateName
        } else if (settingsByUserData.data?.name) {
          companyName =
            settingsByUserData.data?.name +
            ' ' +
            settingsByUserData.data?.surname
        }
        // const initPlan = formatDate(planData.data[0].planStartedIn)
        // const endPlan = formatDate(planData.data[0].planEndIn)

        // let period = planData.data[0].planTypeId == 0 ? 'Ilimitado' : `${initPlan} até ${endPlan}`;

        setCompanyData({
          companyName: companyName,
          companyEmail: settingsByUserData.data?.email,
          companyPlan: userSubscription
            ? userSubscription.data[0].Plan.description
            : '',
          companyValue: formattedPrice ?? '',
          companyPeriod:
            userSubscription.data[0].Plan.tier == 1
              ? '--'
              : formattedDate ?? '',
        })

        setFeatureData({
          postQuantity: userSubscriptionFeatures.data.postQuantity,
          postQuantityLimit:
            userSubscriptionFeatures.data.postQuantityLimit ?? '♾️',
          userQuantity: userSubscriptionFeatures.data.userQuantity,
          userQuantityLimit: userSubscriptionFeatures.data.userQuantityLimit,
          schedullingQuantity:
            userSubscriptionFeatures.data.schedullingQuantity,
          schedullingQuantityLimit:
            userSubscriptionFeatures.data.schedullingQuantityLimit ?? '♾️',
          clientQuantity: userSubscriptionFeatures.data.clientQuantity,
          clientQuantityLimit:
            userSubscriptionFeatures.data.clientQuantityLimit ?? '♾️',
          integrationQuantity:
            userSubscriptionFeatures.data.integrationQuantity,
          integrationQuantityLimit:
            userSubscriptionFeatures.data.integrationQuantityLimit,
        })
      }
    } else {
      setCompanyData({
        companyName: settingsByUserData.data?.userAccount?.fantasyName
          ? settingsByUserData.data?.userAccount?.fantasyName
          : settingsByUserData.data.name,
        companyEmail: settingsByUserData.data?.email,
        companyPlan: 'Plano Free',
        companyValue: 'R$ 00,00',
        companyPeriod: '--',
      })
      const userSubscriptionFeatures =
        await SubscriptionService.getSubscriptionFeatures()

      setFeatureData({
        postQuantity: userSubscriptionFeatures.data.postQuantity,
        postQuantityLimit: 8,
        userQuantity: userSubscriptionFeatures.data.userQuantity,
        userQuantityLimit: 1,
        schedullingQuantity: userSubscriptionFeatures.data.schedullingQuantity,
        schedullingQuantityLimit: 60,
        clientQuantity: userSubscriptionFeatures.data.clientQuantity,
        clientQuantityLimit: 2,
        integrationQuantity: userSubscriptionFeatures.data.integrationQuantity,
        integrationQuantityLimit: 2,
      })
    }
  }
  useEffect(() => {
    loadTimeline()
  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = addLeadingZero(date.getDate())
    const month = addLeadingZero(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = addLeadingZero(date.getHours())
    const minutes = addLeadingZero(date.getMinutes())
    const secconds = addLeadingZero(date.getSeconds())
    return `${day}/${month}/${year} ${hours}:${minutes}:${secconds}`
  }
  const addLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const loadTimeline = async () => {
    try {
      const { data, responseStatus } =
        await TimelineActionsService.getTimelineActionAllData()

      setTimelineActionData(
        data?.map((item) => ({
          userName: item.UserAccount.user.name,
          clientName: item.BusinessClient?.companyName,
          timelineAction: item.timelineActionsValue,
          date: formatDate(item.createdAt),
          guestName: item.UserAccountBusinessUsers
            ? item.UserAccountBusinessUsers.name
            : null,
        }))
      )
    } catch (error) {
      console.error('Erro ao carregar timeline', error)
    }
  }

  const onFileChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileDataBase64,
          isBase64: true,
          status: 'create',
          fileName: file.name,
          fileFormat: file.type,
          fileContentType: 'image/' + file.type,
        }

        setUserImageData(() => [fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const navigate = useNavigate()

  const navigationPage = (page) => {
    if (page === 'TimeLinePage') navigate('/TimeLinePage')
    if (page === 'PlanPage') navigate('/PlansPage')
  }

  //const [creditCardNumber, setCreditCardNumber] = React.useState('')
  const [numberCredCard, setNumberCredCard] = useState('')
  const [numberCredCardValue, setNumberCredCardValue] = useState('')

  const formatCreditCardNumber = (inputValue) => {
    if (inputValue.length > 19) {
      return
    }

    setNumberCredCardValue(inputValue)

    // Remove qualquer caractere que não seja número
    const numericValue = inputValue.replace(/\D/g, '')

    // Divide o número em grupos de 4 dígitos
    const formattedValue = numericValue.replace(/(\d{4})/g, '$1 ')

    // Atualiza o estado com o valor formatado
    setNumberCredCard(formattedValue.trim())
  }

  const formatValidateNumber = (inputValue) => {
    // Remove qualquer caractere que não seja número

    if (inputValue.length === 7) {
      setValidateCredCard(inputValue)
    } else {
      const numericValue = inputValue.replace(/\D/g, '')

      // Limita a 6 dígitos
      const limitedValue = numericValue.slice(0, 6)
      let formattedValue

      if (limitedValue.length >= 2) {
        // Verifica se os dois primeiros números são válidos (entre 1 e 12)
        const firstTwoDigits = parseInt(limitedValue.slice(0, 2), 10)
        if (firstTwoDigits < 1 || firstTwoDigits > 12) {
          // Trunca para dois dígitos válidos
          formattedValue = limitedValue.slice(0, 2)
        }
      }
      // Adiciona a máscara "00/0000"
      if (inputValue.length >= 6) {
        formattedValue = limitedValue
          .replace(/^(\d{2})/, '$1') // Adiciona a barra após os dois primeiros dígitos
          .replace(/(\d{2})(\d{0,4})/, '$1/$2') // Adiciona a barra após os próximos quatro dígitos
      }
      // Atualiza o estado com o valor formatado
      setValidateCredCard(formattedValue)
    }
  }

  const formatCcvNumber = (inputValue) => {
    if (inputValue.length > 3) {
      return
    }
    const numericValue = inputValue.replace(/\D/g, '')

    setCvvCredCard(numericValue)
  }

  const handleInputChange = (e, type) => {
    if (type === 'credCard') {
      formatCreditCardNumber(e.target.value)
    }
    if (type === 'validate') {
      formatValidateNumber(e.target.value)
    }
    if (type === 'cvv') {
      formatCcvNumber(e.target.value)
    }
  }

  const renderTimeline = () => {
    return timelineActionData.map((item, index) =>
      item.guestName ? (
        <div key={index} className={styles.data}>
          <div>
            <div className={styles.circle} />
          </div>
          <p className={`${styles.data_label}`}>
            O usuário convidado{' '}
            <span className={styles.boldText}>{item.guestName} </span>
            {item.timelineAction} {item.clientName ? 'para o cliente ' : ' '}
            <span className={styles.boldText}>{item.clientName}</span>
          </p>
          <p className={styles.data_content}>{item.date}</p>
        </div>
      ) : (
        <div key={index} className={styles.data}>
          <div>
            <div className={styles.circle} />
          </div>
          <p className={`${styles.data_label}`}>
            O usuário <span className={styles.boldText}>{item.userName} </span>
            {item.timelineAction} {item.clientName ? 'para o cliente ' : ' '}
            <span className={styles.boldText}>{item.clientName}</span>
          </p>
          <p className={styles.data_content}>{item.date}</p>
        </div>
      )
    )
  }

  const handleSavePaymentData = async () => {
    const data = await PagarMeService.getCardByUser()

    const body = {
      uuid: data?.data[0]?.uuid,
      userAccountId: userAcount?.data?.uuid,
      cardToken: `${cvvCredCard}`,
      cardName: `${nameCredCard}/${userAcount?.data?.cnpj}`,
      flag: 'VISA',
      number: `${numberCredCardValue}`,
      expirationDate: validateCredCard,
      type: 'CREDIT',
    }

    if (data.data.length > 0) {
      await PagarMeService.postCard(body)
    } else {
      const { uuid, ...rest } = body

      await PagarMeService.postCard(rest)
    }
  }

  const formatDateLog = (inputDate) => {
    const date = new Date(inputDate)
    const day = date.getDate()
    const monthIndex = date.getMonth()

    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ]

    const month = months[monthIndex]

    return `${day} de ${month}`
  }

  const handleCloseModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(false)
    }
  }
  const handleOpenModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(true)
    }
  }

  const teste = {
    height: '100px',
    width: '100%',
    'overflow-y': 'scroll',
  }

  return (
    <div className={styles.page}>
      <div className={styles.title_my_data}>
        <p className={styles.title_my_data_label}>Configurações </p>
        <p className={styles.title_my_data_title}>Configurações do Sistema</p>
      </div>
      <div className={styles.container_box_image}>
        <ModalComponent
          isOpen={isModalOpen}
          onClose={() => handleCloseModal('galeria')}
          className={teste}
        >
          <ModalCancelPlan
            // setImageAIArray={setImageAIArray}
            onClose={() => handleCloseModal('galeria')}
            handleCloseModal={handleCloseModal}
          />
        </ModalComponent>
      </div>

      <div className={styles.container_system_Settings}>
        <Masonry columnsCount={2} gutter="10px">
          <div className={styles.box_company_card}>
            <div className={styles.box_company}>
              <div className={styles.box_image_user_upload}>
                <img
                  className={styles.image_user_upload}
                  src={
                    userImageData[0] !== undefined
                      ? userImageData[0]?.fileUrl
                      : companyIcon
                  }
                  alt=""
                />
                <div className={styles.button_user_upload}>
                  <label htmlFor="arquivo">
                    <PencilUploadIcon
                      color={'#FF8B00'}
                      colorPen={''}
                      width={30}
                      height={30}
                    />
                  </label>
                  <input
                    type="file"
                    name="arquivo"
                    id="arquivo"
                    onChange={(e) => onFileChange(e)}
                  ></input>
                </div>
              </div>
              <div className={styles.box_title_conpany}>
                <p className={styles.title_card}>{companyData.companyName}</p>
                <p className={styles.email_company}>@nomedaempresa</p>
              </div>
            </div>
            <div>
              <div className={styles.data}>
                <p className={styles.data_label}>E-mail</p>
                <p className={styles.data_content}>
                  {companyData.companyEmail}
                </p>
              </div>

              <div className={styles.data}>
                <p className={styles.data_label}>Nome do usuário</p>
                <p className={styles.data_content}>{companyData.companyName}</p>
              </div>

              <div className={styles.data}>
                <p className={styles.data_label}>Plano</p>
                <p className={styles.data_content}>{companyData.companyPlan}</p>
              </div>

              <div className={styles.data}>
                <p className={styles.data_label}>Valor do plano</p>
                <p className={styles.data_content}>
                  {companyData.companyValue}
                </p>
              </div>

              <div className={styles.data}>
                <p className={styles.data_label}>Periodo</p>
                <p className={styles.data_content}>
                  {companyData.companyPeriod}
                </p>
              </div>
            </div>
            <div className={styles.box_button}>
              <button
                className={styles.button_change_payment_data}
                onClick={() => navigationPage('PlanPage')}
              >
                Upgrade Plano
              </button>
              <button
                className={styles.button_cancel_payment_data}
                onClick={() => handleOpenModal('galeria')}
              >
                Cancelar Plano
              </button>
            </div>
          </div>

          {/* Timeline */}

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p className={styles.title_card}>Timeline</p>
            </div>
            <div>
              {}

              {renderTimeline()}
            </div>

            <button
              className={styles.button_settings}
              onClick={() => navigationPage('TimeLinePage')}
            >
              Ver Mais
            </button>
          </div>

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p
                className={styles.title_card}
              >{`Configurações (${percentStep}/5)`}</p>

              <ProgressBar percent={percentStep * 20} />
            </div>

            <Stepper
              size={'50px'}
              completedStepParametrization={completedStepParametrization}
              completedStepAddClients={completedStepAddClients}
              completedStepAddTeamMembers={completedStepAddTeamMembers}
              completedStepCreateCampaign={completedStepCreateCampaign}
              completedStepAddNewPost={completedStepAddNewPost}
            />
          </div>

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p className={styles.title_card}>Features</p>
            </div>

            <div className={styles.container_features}>
              <div className={styles.box_features}>
                <div className={styles.features}>
                  <p className={styles.current_number}>
                    {featureData.userQuantity}
                  </p>
                  <p className={styles.total_number}>
                    /{featureData.userQuantityLimit}
                  </p>
                </div>
                <div className={styles.features_title}>Usuários</div>
              </div>

              <div className={styles.box_features}>
                <div className={styles.features}>
                  <p className={styles.current_number}>
                    {featureData.clientQuantity}
                  </p>
                  <p className={styles.total_number}>
                    /{featureData.clientQuantityLimit}
                  </p>
                </div>
                <div className={styles.features_title}>Clientes</div>
              </div>

              <div className={styles.box_features}>
                <div className={styles.features}>
                  <p className={styles.current_number}>
                    {featureData.postQuantity}
                  </p>
                  <p className={styles.total_number}>
                    /{featureData.postQuantityLimit}
                  </p>
                </div>
                <div className={styles.features_title}>Publicações</div>
              </div>

              <div className={styles.box_features}>
                <div className={styles.features}>
                  <p className={styles.current_number}>
                    {featureData.schedullingQuantity}
                  </p>
                  <p className={styles.total_number}>
                    /{featureData.schedullingQuantityLimit}
                  </p>
                </div>
                <div className={styles.features_title}>Agendamentos</div>
              </div>

              <div className={styles.box_features}>
                <div className={styles.features}>
                  <p className={styles.current_number}>
                    {featureData.integrationQuantity}
                  </p>
                  <p className={styles.total_number}>
                    /{featureData.integrationQuantityLimit}
                  </p>
                </div>
                <div className={styles.features_title}>Integrações</div>
              </div>
            </div>
          </div>

          {/* Dados De Pagamento */}

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p className={styles.title_card}>Dados de Pagamento</p>

              <div className={styles.box_select}>
                <SelectRound
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={{
                    label: 'Comercial',
                    value: '',
                  }}
                  options={[
                    { label: 'Teste', value: 1 },
                    { label: 'Teste2', value: 2 },
                  ]}
                />
              </div>
            </div>

            <div className={styles.container_data_payment}>
              <div>
                <div className={styles.container_cred_card}>
                  <div className={styles.content_cred_card}>
                    <div>
                      <p className={styles.name_cred_card}>{nameCredCard}</p>
                    </div>
                    <div className={styles.content_separate_cred_card}>
                      <p className={styles.number_cred_card}>
                        {numberCredCard}
                      </p>

                      <p className={styles.validate_cred_card}>
                        {validateCredCard}
                      </p>
                    </div>
                  </div>
                  <CreditCard />
                </div>
              </div>

              <div className={styles.container_input}>
                <div className={styles.box_input}>
                  <label className={styles.input_label}> Número</label>
                  <input
                    className={styles.input_payment}
                    placeholder="**** **** **** 4578"
                    value={numberCredCard}
                    onChange={(e) => handleInputChange(e, 'credCard')}
                  />
                </div>

                <div className={styles.box_input}>
                  <label className={styles.input_label}>
                    Nome do Proprietário
                  </label>
                  <input
                    className={styles.input_payment}
                    placeholder="Nome do Usuário"
                    value={nameCredCard}
                    onChange={(e) => setNameCredCard(e.target.value)}
                  />
                </div>
                <div className={styles.box_input_separate}>
                  <div className={styles.box_input}>
                    <label className={styles.input_label}>
                      Data de Vencimento
                    </label>

                    <input
                      className={styles.input_payment}
                      placeholder="00/0000"
                      value={validateCredCard}
                      onChange={(e) => handleInputChange(e, 'validate')}
                    />
                  </div>
                  <div className={styles.box_input}>
                    <label className={styles.input_label}> CVV</label>
                    <input
                      className={styles.input_payment}
                      placeholder="***"
                      value={cvvCredCard}
                      onChange={(e) => handleInputChange(e, 'cvv')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                className={styles.button_save_payment_data}
                onClick={() => handleSavePaymentData()}
              >
                Salvar Dados de Pagamento
              </button>
            </div>
          </div>

          {/* Pagamentos */}

          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p className={styles.title_card}>Pagamentos</p>
            </div>

            <div className={styles.content_payment}>
              {userAcount?.data?.PaymentHistory?.map((pagamento) => (
                <div className={styles.container_payment} key={pagamento.uuid}>
                  <div className={styles.box_icon_status}>
                    {pagamento.status === 'NOT_PAID' ? (
                      <Cross width={'10px'} height={'10px'} color={'red'} />
                    ) : pagamento.status === 'PENDING' ? (
                      <Clock width={'16px'} height={'16px'} color={'#FF8B00'} />
                    ) : (
                      <CheckBadge size={'15px'} />
                    )}
                  </div>

                  <div className={styles.box_status}>
                    <div>
                      <p
                        className={
                          pagamento.status === 'NOT_PAID'
                            ? styles.status_not_approve
                            : pagamento.status === 'PENDING'
                            ? styles.status_pendant
                            : styles.approve
                        }
                      >
                        {pagamento.status}
                      </p>
                    </div>
                    <div className={styles.box_payment_log_description}>
                      <p
                        className={
                          pagamento.status === 'NOT_PAID'
                            ? styles.date_payment_not_approve
                            : styles.date_payment
                        }
                      >
                        {pagamento.status === 'NOT_PAID'
                          ? 'Pagamento recusado'
                          : formatDateLog(pagamento.startDate) +
                            ' - ' +
                            formatDateLog(pagamento.expirationDate)}
                      </p>

                      {pagamento.status === 'NOT_PAID' && (
                        <div className={styles.stamp}>
                          <p className={styles.stamp_title}>Pagamento</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Masonry>
      </div>
    </div>
  )
}

export default Settings
