import React, { useState } from 'react'
import { SelectPrototypeStep } from './SelectPrototypeStep'
import { ConfigStep } from './ConfigStep'
import { ProductConfig } from './ProductConfig'

export const PrototypePage = () => {
  const [step, setStep] = useState(1)

  return (
    <>
      {step == 1 && <SelectPrototypeStep setStep={setStep} />}
      {step == 2 && <ConfigStep setStep={setStep} />}
      {step == 3 && <ProductConfig setStep={setStep} />}
    </>
  )
}
