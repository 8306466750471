import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Button, Input } from '../../../../components'
//import { UploadDrag } from '../../../../components/UploadDrag'
import { CloundArrowUp, Trash } from '../../../../assets/svgs/icons'
import { ImageCard } from '../../../../components/ImageCard'
import { motion } from 'framer-motion'
import ConfirmParametrizationModal from '../../../../components/ConfirmParametrizationModal'
import { BusinessClient, BusinessService } from '../../../../services'
import * as yup from 'yup'
import {
  notifyErrorCustom,
  notifySuccessCustom,
} from '../../../../utils/notify'

import InputMask from 'react-input-mask'

import { Loading } from '../../../../components/Loading'
import { useNavigate } from 'react-router-dom'

import { useClient } from '../../../../hooks/useClient'

import logoPostei from '../../../../assets/logos/postei-logo-color.png'
export const CreateClientStep = ({ setStep, client, setClient }) => {
  const { clientData, setClientData, allClientData, setAllClientData } =
    useClient()

  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [imagesToRender, setImagesToRender] = useState([])

  const [loading, setLoading] = useState(false)

  function onlyNumbers(index) {
    return index.replace(/[^0-9]/g, '')
  }

  const formatarValor = (valor) => {
    const apenasDigitos = valor.replace(/\D/g, '')

    if (apenasDigitos.length <= 11) {
      return apenasDigitos.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      )
    } else {
      return apenasDigitos.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
    }
  }

  const handleChange = (k, v) => {
    if (k === 'cnpj') {
      const novoValor = formatarValor(v)

      if (v.length <= 18) {
        setClient((prevState) => ({ ...prevState, [k]: novoValor }))
      }
    } else {
      setClient((prevState) => ({ ...prevState, [k]: v }))
    }
  }

  const registrationDataScheema = yup.object().shape({
    companyName: yup.string().required('preencha o campo nome'),
    cnpj: yup.string().required('preencha o campo CNPJ'),
    email: yup
      .string()
      .email('preencha o campo email corretamente')
      .required('preencha o campo email'),
    phone: yup.string().required('preencha o campo telefone'),
    responsibleName: yup
      .string()
      .required('preencha o campo nome do responsavel'),
  })

  const validateFields = async () => {
    const isValid = await registrationDataScheema
      .validate(client)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })

    return isValid
  }
  async function isEmailValid(email) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return regex.test(email)
  }

  const hadleNavigate = () => {
    navigate('/StepsPage')
  }

  const handleSave = async ({ parameterized }) => {
    const guestId = JSON.parse(
      window.localStorage.getItem('userData')
    ).guestUserId
    const isValid = await validateFields(client)
    var imageName = undefined
    var photoUrl = undefined

    if (!isValid) return

    const isValidEmail = await isEmailValid(client.email)

    if (!isValidEmail) {
      notifyErrorCustom(
        'O campo de Email não foi preenchido com um Email válido'
      )
      return false
    }

    const cnpjWithoutMask = client.cnpj.replace(/\D/g, '')

    const isValidCpf = await isValidCPForCNPJ(cnpjWithoutMask)

    if (!isValidCpf) {
      notifyErrorCustom(
        'O campo de CPF/CNPJ não foi preenchido com um CPF/CNPJ válido'
      )
      return false
    }
    const newCpf = onlyNumbers(cnpjWithoutMask)

    if (client?.uuid) {
      if (
        imagesToRender[0]?.isBase64 &&
        imagesToRender[0]?.status != 'deleted'
      ) {
        if (client?.businessClientPhotoName) {
          const { responseImageStatus } =
            await BusinessClient.deleteBusinessImageUser(
              client?.businessClientPhotoName
            )
        }

        if (imagesToRender.length > 0) {
          const uploadImageBody = mountImageBodyUpload(
            imagesToRender[0]?.fileUrl,
            imagesToRender[0]?.fileName,
            imagesToRender[0]?.fileFormat,
            imagesToRender[0].fileContentType
          )

          setLoading(true)

          setLoading(true)

          const { dataImage, responseStatusImage } =
            await BusinessClient.postBusinessUserImage(uploadImageBody)

          setLoading(false)

          const newCnpj = onlyNumbers(client.cnpj)

          const { uuid, ...rest } = client

          delete rest.userAccountId
          delete rest.createdAt
          delete rest.updatedAt
          delete rest.deletedAt
          delete rest.businessId
          delete rest.isParameterized
          rest.fileUrl = ''
          rest.businessClientPhotoName = dataImage?.fileName
            ? dataImage?.fileName
            : ''
          rest.BusinessClientPhotoUrl = dataImage?.fileUrl
            ? dataImage?.fileUrl
            : ''
          rest.cnpj = newCnpj

          if (guestId) {
            rest.guestId = guestId
          } else {
            delete rest.guestId
          }

          const { responseStatus } = await BusinessClient.putBusinessClient(
            uuid,
            rest
          )
          if (responseStatus === 200 || responseStatus === 204) {
            notifySuccessCustom('Item alterado com sucesso', '')
          } else {
            notifyErrorCustom('Não foi possível alterar os dados do usuario!')
          }

          //  return

          if (!parameterized) {
            setImagesToRender([])
            setClient({})
            setStep(0)
            window.location.reload()
          } else {
            try {
              setClientData(client)
              navigate('/StepsPage')
            } catch (error) {
              console.error('Erro ao carregar clientes', error)
            }
          }
        }
      } else if (
        client.businessClientPhotoName &&
        imagesToRender[0]?.status === 'deleted'
      ) {
        if (client.businessClientPhotoName) {
          const { responseImageStatus } =
            await BusinessClient.deleteBusinessImageUser(
              client.businessClientPhotoName
            )

          const { uuid, ...rest } = client

          delete rest.userAccountId
          delete rest.createdAt
          delete rest.updatedAt
          delete rest.deletedAt
          delete rest.businessId
          delete rest.isParameterized
          rest.fileUrl = ''
          rest.businessClientPhotoName = ''
          rest.BusinessClientPhotoUrl = ''

          if (guestId) {
            rest.guestId = guestId
          } else {
            delete rest.guestId
          }

          const { responseStatus } = await BusinessClient.putBusinessClient(
            uuid,
            rest
          )
          if (responseStatus === 200 || responseStatus === 204) {
            notifySuccessCustom('Item alterado com sucesso', '')
          } else {
            notifyErrorCustom('Não foi possível alterar os dados do usuario!')
          }
          setImagesToRender([])
          setClient({})
          setStep(0)
        }
      } else {
        const { uuid, ...rest } = client

        delete rest.userAccountId
        delete rest.createdAt
        delete rest.updatedAt
        delete rest.deletedAt
        delete rest.businessId
        delete rest.isParameterized
        rest.fileUrl = ''
        if (!rest.businessClientPhotoName) {
          rest.businessClientPhotoName = ''
        }
        if (!rest.BusinessClientPhotoUrl) {
          rest.BusinessClientPhotoUrl = ''
        }
        if (guestId) {
          rest.guestId = guestId
        } else {
          delete rest.guestId
        }

        // rest.businessClientPhotoName = ''
        // rest.BusinessClientPhotoUrl = ''
        const { responseStatus } = await BusinessClient.putBusinessClient(
          uuid,
          rest
        )
        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom('Item alterado com sucesso', '')
        } else {
          notifyErrorCustom('Não foi possível alterar os dados do usuario!222')
        }

        if (parameterized) {
          try {
            setClientData(client)

            navigate('/StepsPage')
          } catch (error) {
            console.error('Erro ao carregar clientes', error)
          }
        } else {
          setImagesToRender([])
          setClient({})
          setStep(0)
        }
      }
    } else {
      if (imagesToRender.length > 0) {
        const uploadImageBody = mountImageBodyUpload(
          imagesToRender[0]?.fileUrl,
          imagesToRender[0]?.fileName,
          imagesToRender[0]?.fileFormat,
          imagesToRender[0].fileContentType
        )

        setLoading(true)

        const { dataImage, responseStatusImage } =
          await BusinessClient.postBusinessUserImage(uploadImageBody)

        setLoading(false)

        imageName = dataImage.fileName
        photoUrl = dataImage?.fileUrl
      }

      const newCnpj = onlyNumbers(client.cnpj)

      setLoading(true)

      let clientBody
      if (guestId) {
        clientBody = {
          ...client,
          cnpj: newCnpj,
          businessClientPhotoName: imageName,
          BusinessClientPhotoUrl: photoUrl,
          guestId: guestId,
        }
      } else {
        clientBody = {
          ...client,
          cnpj: newCnpj,
          businessClientPhotoName: imageName,
          BusinessClientPhotoUrl: photoUrl,
        }
      }

      const { data, responseStatus } = await BusinessClient.postBusinessClient(
        clientBody
      )

      setLoading(false)

      if (responseStatus === 200 || responseStatus === 204) {
        notifySuccessCustom('Cliente criado com sucesso', '')
        navigate('/Clients')
      } else {
        notifyErrorCustom('Não foi possível criar o cliente!')
      }

      if (parameterized) {
        try {
          const businessAccount = await BusinessService.getBusinessByLogin()

          const { data: data2, responseStatus: responseStatus2 } =
            await BusinessClient.getAllBusinessClient()

          const myAccount = {
            uuid: undefined,
            businessId: businessAccount?.data[0]?.uuid,
            companyName: 'Minha conta',
            BusinessClientPhotoUrl: logoPostei,
          }

          if (responseStatus2 === 200) {
            setAllClientData([myAccount, ...data2])
          }

          setClientData(data)

          navigate('/StepsPage')
        } catch (error) {
          console.error('Erro ao carregar clientes', error)
        }
      } else {
        setImagesToRender([])
        setClient({})
        setStep(0)
      }
    }
  }

  const hadleOpenModal = async () => {
    const isValid = await validateFields(client)

    if (!isValid) return

    const isValidEmail = await isEmailValid(client.email)

    if (!isValidEmail) {
      notifyErrorCustom(
        'O campo de Email não foi preenchido com um Email válido'
      )
      return false
    }

    const cnpjWithoutMask = client.cnpj.replace(/\D/g, '')

    const isValidCpf = await isValidCPForCNPJ(cnpjWithoutMask)

    if (!isValidCpf) {
      notifyErrorCustom(
        'O campo de CPF/CNPJ não foi preenchido com um CPF/CNPJ válido'
      )
      return false
    }

    setIsModalOpen(true)
  }

  const mountImageBodyUpload = (base64Str, name, type) => {
    const formats = ['jpg', 'png', 'jpeg', 'webp']
    const stringReference = 'base64,'
    const index = base64Str.indexOf(stringReference) + stringReference.length
    let fileFormat = ''
    const fileBase64 = base64Str.substr(index)
    formats.map((format) => {
      if (base64Str.includes(format)) fileFormat = format
    })

    return {
      fileName: name,
      fileFormat,
      fileContentType: type,
      fileBase64,
    }
  }

  const onFileChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileDataBase64,
          isBase64: true,
          status: 'create',
          fileName: file.name,
          fileFormat: file.type,
          fileContentType: 'image/' + file.type,
        }

        setImagesToRender([])
        setImagesToRender((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const onExcludeImage = (index) => {
    if (imagesToRender[index]) {
      imagesToRender[index].status = 'deleted'
      setImagesToRender([...imagesToRender])
    } else if (index === 0) {
      let fileDataReturn = {
        fileUrl: '',
        isBase64: true,
        status: 'deleted',
        fileName: '',
        fileFormat: '',
        fileContentType: '',
      }

      setImagesToRender((current) => [...current, fileDataReturn])
    }
  }

  const handleCancel = () => {
    setClient({})
    setStep(0)
  }
  async function isValidCPForCNPJ(value) {
    const numericValue = value.replace(/[^\d]+/g, '')

    if (value.length === 11) {
      // Verifica se é um CPF
      const invalidCpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ]

      if (invalidCpfs.includes(value)) {
        return false
      }

      let sum = 0
      let mod = 0

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(value.substring(i - 1, i), 10) * (11 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(value.substring(9, 10), 10)) {
        return false
      }

      sum = 0

      for (let i = 1; i <= 10; i++) {
        sum += parseInt(value.substring(i - 1, i), 10) * (12 - i)
      }

      mod = (sum * 10) % 11

      if (mod === 10 || mod === 11) {
        mod = 0
      }

      if (mod !== parseInt(value.substring(10, 11), 10)) {
        return false
      }

      return true // É um CPF válido
    } else if (numericValue.length === 14) {
      // Verifica se é um CNPJ
      const invalidCpns = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999',
      ]

      if (invalidCpns.includes(numericValue)) {
        return false
      }

      let size = numericValue.length - 2
      let numbers = numericValue.substring(0, size)
      const digits = numericValue.substring(size)
      let sum = 0
      let pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(0), 10)) {
        return false
      }

      size = size + 1
      numbers = numericValue.substring(0, size)
      sum = 0
      pos = size - 7

      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--
        if (pos < 2) pos = 9
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
      if (result !== parseInt(digits.charAt(1), 10)) {
        return false
      }

      return true // É um CNPJ válido
    }

    return false // Não é nem CPF nem CNPJ
  }

  const newEdit = client.uuid ? 'Editar Cliente' : 'Novo Cliente'

  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ duration: 1 }}
        animate={{ opacity: 1 }}
        className={styles.page}
      >
        <ConfirmParametrizationModal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          saveClient={handleSave}
          onClickStartButton={() => hadleNavigate()}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label className={styles.label}>Clientes</label>
          <div className={styles.container_header}>
            <h3>Lista de Clientes</h3>
            <Button onClick={handleCancel} variant="primary">
              Cancelar
            </Button>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.container_header}>
            <h3>{newEdit}</h3>
          </div>
          <div className={styles.inputs_container}>
            <div className={styles.left}>
              {/* <UploadDrag />
            <Button variant="tertiary">Salvar foto de Perfil</Button> */}
              <div className={styles.box_container_drag}>
                <div className={styles.modal_container_drag}>
                  <div className={styles.modal_drag}>
                    <div className={styles.modal_drag_drop}>
                      <label htmlFor="arquivo">
                        <CloundArrowUp color={'#B900FE'} />
                        <h2 className={styles.modal_drag_drop_title}>
                          Arraste & Solte o arquivo
                        </h2>
                        <p className={styles.modal_drag_drop_sub_title}>
                          Ou selecione o arquivo no explorador
                        </p>
                      </label>
                      <input
                        type="file"
                        name="arquivo"
                        id="arquivo"
                        onChange={(e) => onFileChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className={styles.images_container}>
                    {imagesToRender?.map(
                      (e, index) =>
                        e.status !== 'deleted' && (
                          <ImageCard
                            key={index}
                            image={e}
                            onClickButton={() => onExcludeImage(index)}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '13px',
                              }}
                            >
                              <p
                                style={{
                                  color: '##190027',
                                  fontSize: '12px',
                                  margin: 0,
                                }}
                              >
                                Excluir
                              </p>
                              <Trash color={'#000000'} />
                            </div>
                          </ImageCard>
                        )
                    )}

                    {client?.BusinessClientPhotoUrl &&
                      imagesToRender.length <= 0 && (
                        <ImageCard
                          // image={`data:image/jpeg;base64,${client?.BusinessClientPhotoUrl}`}
                          image={`${client?.BusinessClientPhotoUrl}`}
                          onClickButton={() => onExcludeImage(0)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '13px',
                            }}
                          >
                            <p
                              style={{
                                color: '##190027',
                                fontSize: '12px',
                                margin: 0,
                              }}
                            >
                              Excluir
                            </p>
                            <Trash color={'#000000'} />
                          </div>
                        </ImageCard>
                      )}
                  </div>
                  {/* <button className={styles.button_save_data}>
                    Salvar Foto de Perfil
                  </button> */}
                </div>
              </div>
            </div>

            <div className={styles.right}>
              <Input
                id={'companyName'}
                label={'Nome da Empresa'}
                placeholder={'Digite...'}
                value={client?.companyName}
                //   onChange={(event) => setCompanyName(event.target.value)}
                onChange={({ target: { id, value } }) =>
                  handleChange(id, value)
                }
              />
              <div className={styles.box_input}>
                <label className={styles.label_input}>CPF/CNPJ</label>
                {/* <InputMask
                id={'cnpj'}
                className={styles.input}
                label={'CPF/CNPJ'}
                mask={
                  client?.cnpj && client.cnpj.length < 14
                    ? '999.999.999-99'
                    : '99.999.999/9999-99'
                }
                maskChar=""
                placeholder={'Digite...'}
                value={client?.cnpj}
                onChange={({ target: { id, value } }) =>
                  handleChange(id, value)
                }
                onKeyDown={({ target: { id, value } }) =>
                  handleChange(id, value)
                }
              /> */}

                <InputMask
                  id={'cnpj'}
                  className={styles.input}
                  label={'CPF/CNPJ'}
                  // mask={
                  //   client?.cnpj && client.cnpj.length < 14
                  //     ? '999.999.999-99'
                  //     : '99.999.999/9999-99'
                  // }
                  // mask="99.999.999/9999-99"
                  placeholder={'Digite...'}
                  value={client?.cnpj}
                  onChange={({ target: { id, value } }) =>
                    handleChange(id, value)
                  }
                  // onKeyDown={({ target: { id, value } }) =>
                  //   handleChange(id, value)
                  // }
                />
              </div>
              <Input
                id={'email'}
                className={styles.input}
                label={'E-mail'}
                placeholder={'Digite...'}
                value={client?.email}
                onChange={({ target: { id, value } }) =>
                  handleChange(id, value)
                }
              />

              <div className={styles.box_input}>
                <label className={styles.label_input}>Telefone</label>
                <InputMask
                  id={'phone'}
                  className={styles.input}
                  type="text"
                  mask={'(99) 99999-9999'}
                  placeholder="Telefone"
                  value={client?.phone}
                  onChange={({ target: { id, value } }) =>
                    handleChange(id, value)
                  }
                />
              </div>
              <Input
                id={'responsibleName'}
                label={'Nome do Responsável'}
                placeholder={'Digite...'}
                value={client?.responsibleName}
                onChange={({ target: { id, value } }) =>
                  handleChange(id, value)
                }
              />
            </div>
          </div>
          <div className={styles.button_container}>
            <Button
              onClick={() => {
                hadleOpenModal()
                //handleSave()
              }}
              variant={'primary'}
            >
              Salvar
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  )
}
