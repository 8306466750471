import React, { useState, useRef } from 'react'
import styles from './styles.module.css'
import { CloundArrowUp, Trash } from '../../assets/svgs/icons'
import { motion } from 'framer-motion'

export const MultipleFontUploadDrag = ({ variant, onFileChange, filesSelectedArray }) => {



  return (
    <div className={styles.modal}>
      <div
        className={
          variant === 'column'
            ? styles.container_modal_column
            : styles.container_modal
        }
      >
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <div className={styles.modal_drag}>
              {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
              </label> */}

              <div
                className={styles.modal_drag_drop}

                // onClick={handleShowListImages}

              // style={{
              //   height: '100px',
              //   width: '100%',
              //   border: '2px dashed black',
              //   backgroundColor: dropzoneHover ? 'blue' : 'red',
              // }}
              // className={styles.modal_drag}
              >
                <label htmlFor="arquivo">
                  <CloundArrowUp color={'#B900FE'} />
                  <h2
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_title_short
                        : styles.modal_drag_drop_title
                    }
                  >
                    Arraste & Solte o arquivo
                  </h2>
                  <p
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_sub_title_short
                        : styles.modal_drag_drop_sub_title
                    }
                  >
                    Ou selecione o arquivo no explorador
                  </p>
                </label>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={(e) => onFileChange(e)}
                ></input>
                {/* {fileData && (
                      <img
                        style={{ width: 50, height: 50 }}
                        src={fileData}
                        alt="file-preview"
                      />
                    )} */}
              </div>
            </div>
          </div>
          <div className={styles.modal_button_save}>
            {/* <button className={styles.button_add}>
              <label className={styles.button_add_label} htmlFor="arquivo">
                {textButton}
              </label>
            </button> */}
          </div>
        </div>

      </div>
    </div>
  )
}
