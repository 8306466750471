// import styles from './styles.module.css'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'

// export const DemoPage = () => {
//   const [videoUrl, setVideoUrl] = useState('')

//   // Handler para quando o usuário selecionar um arquivo
//   const handleFileChange = (event) => {
//     const file = event.target.files[0]
//     if (file) {
//       const url = URL.createObjectURL(file)
//       setVideoUrl(url)
//     }
//   }

//   return (
//     <div>
//       <div
//         style={{ flex: 1, background: '#d0d0d0', padding: 20, marginRight: 20 }}
//       >
//         <input type="file" accept="video/*" onChange={handleFileChange} />
//         {videoUrl && (
//           <ReactPlayer url={videoUrl} controls width="100%" height="100%" />
//         )}
//       </div>
//     </div>
//   )
// }

export const DemoPage = () => {
  const [videoUrl, setVideoUrl] = useState('')
  const [posterUrl, setPosterUrl] = useState('')

  // Handler para quando o usuário selecionar um arquivo de vídeo
  const handleVideoChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setVideoUrl(url)
    }
  }

  // Handler para quando o usuário selecionar um arquivo de imagem
  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPosterUrl(url)
    }
  }

  return (
    <div>
      <div
        style={{ flex: 1, background: '#d0d0d0', padding: 20, marginRight: 20 }}
      >
        <div>
          <label htmlFor="videoInput">Selecione um vídeo:</label>
          <input
            id="videoInput"
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
          />
        </div>
        <div>
          <label htmlFor="posterInput">Selecione uma capa:</label>
          <input
            id="posterInput"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div
          style={{
            marginTop: 20,
          }}
        >
          {videoUrl && (
            <ReactPlayer
              url={videoUrl}
              controls
              // width="100%"
              // height="100%"
              width={500}
              height={500}
              light={posterUrl}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DemoPage
