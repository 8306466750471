export const isObjEqual = (Aobj, Bobj) => {
  if (JSON.stringify(Aobj) !== JSON.stringify(Bobj)) {
    return false
  }

  return true
}

export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}
