import React, { useState, useEffect, useRef } from 'react'
import { ListTable } from '../../components/ListTable'
import { Row } from '../../components/ListTable/Row'
import { TableItem } from '../../components/ListTable/TableItem'
// import { Checkbox } from 'antd'
import {
  EditPencil,
  // Eye,
  // Picture,
  // Reload,
  Trash,
} from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { HistoryModal, SimpleEditionModal } from '../../components'

import {
  BrandingService,
  PublicationService,
  // TargetAudienceService,
} from '../../services'
import { Loading } from '../../components/Loading'
import { useNavigate } from 'react-router-dom'
import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'

import { useClient } from '../../hooks/useClient'

export const DraftPage = () => {
  const { clientData } = useClient()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSimplesEditModalOpen, setIsSimplesEditModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [simplesEditModalData, setSimplesEditModalData] = useState({})

  //const [fontToRender, setFontToRender] = useState('')

  // const [headlineSubtitle, setHeadlineSubtitle] = useState('')

  // const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
  //   useState([])

  // const [productTypeOptions, setProductTypeOptions] = useState([])

  const [fontToRender, setFontToRender] = useState('')

  const [publications, setPublications] = useState([])

  //  const [headlineText, setHeadlineText] = useState('')

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const headers = [
    // 'Código',
    'Publicação',
    'Campanha',
    '  Data de Criação',
    '  Data da Publicação',
    '  Ações',
  ]

  useEffect(() => {
    setLoading(true)
    loadData()
    // loadtargetAudienceData()
    // loadProductServiceData()
    loadFontImages()
  }, [clientData])

  const loadData = async () => {
    if (clientData?.uuid === undefined) {
      const { data, responseStatus } =
        await PublicationService.getAllPublicationByLoginSketchNotBusinessClient()

      if (responseStatus === 200) {
        setPublications(data)
      }

      setLoading(false)

      return
    }

    const { data, responseStatus } =
      await PublicationService.getAllPublicationByLoginSketchByBusinessClientId(
        clientData?.uuid
      )

    if (responseStatus === 200) {
      setPublications(data)
    }

    setLoading(false)
  }

  const loadFontImages = async () => {
    const { data } = await BrandingService.getAllBrandingByBusiness()

    const fontData = data.at(0).fontsBase64.fontTitleBase64
    const fontName = fontData.name.replace(/\..+$/, '')
    const dynamicFont = new FontFace(
      fontName,
      `url(data:font/woff2;base64,${fontData.data})`
    )

    await dynamicFont.load().then((font) => document.fonts.add(font))
    setFontToRender(fontName)
  }

  // const loadtargetAudienceData = async () => {
  //   const { data, responseStatus } =
  //     await TargetAudienceService.getTargetAudiences()
  //   if (responseStatus === 200)
  //     setProductTargetAudienceOptions(
  //       data?.map((item) => ({
  //         label: item.profession,
  //         value: item.uuid,
  //       }))
  //     )
  // }

  // const loadProductServiceData = async () => {
  //   const { data, responseStatus } = await ProductService.getProduct()
  //   if (responseStatus === 200)
  //     setProductTypeOptions(
  //       data.map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       }))
  //     )
  // }

  function formatDate(data) {
    data = new Date(data)

    let dia = data.getDate()
    let mes = data.getMonth() + 1 // getMonth() retorna mês de 0-11
    let ano = data.getFullYear()

    dia = dia < 10 ? '0' + dia : dia
    mes = mes < 10 ? '0' + mes : mes

    return dia + '/' + mes + '/' + ano
  }

  const handleOpenModal = (data) => {
    setModalData(data)
    setIsModalOpen(true)
  }
  const handleOpenSimpleEditionModal = (data) => {
    setSimplesEditModalData(data)
    setIsSimplesEditModalOpen(true)
  }

  const handleDeleteDraft = async (id) => {
    setLoading(true)

    const publicationResponse = await PublicationService.deletePublication(id)

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom('Rascunho removido com sucesso!')
    } else {
      notifyErrorCustom('Erro ao remover rascunho!')
    }
    setLoading(false)
    loadData()
  }

  const handleNavigate = () => {
    // setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    navigate('/NewPostFormPage', {
      state: { draft: true, dataPublication: simplesEditModalData },
    })
  }
  const handleCloseSimpleEditionModal = () => {
    setIsSimplesEditModalOpen(false)
  }
  return (
    <>
      <Loading enable={loading} />

      <HistoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalData={modalData}
        setModalData={setModalData}
      />

      <div className={styles.header_page}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>Rascunhos</p>
          <p className={styles.title_new_posts_title}>Rascunhos Salvos</p>
        </div>
        {/* <div>
          <button
            className={styles.button_new_user}
            // onClick={() => handleExpandForm()}
          >
            Novo Rascunho
          </button>
        </div> */}
      </div>

      <ListTable headers={headers} disabledButton={true}>
        {/* <Row>
          <TableItem>
            <div className={`${styles.table_item_gap} ${styles.code}`}>
              <p>#000001</p>
            </div>
          </TableItem>
          <TableItem>
            <div className={`${styles.table_item_gap} ${styles.secondColumn}`}>
              <div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png"
                  alt=""
                />
              </div>
              <p>Sua moto precisa de cuidados?...</p>
            </div>
          </TableItem>
          <TableItem>Cliente 01</TableItem>
          <TableItem>Campanha de Teste</TableItem>
          <TableItem>
            <span className={styles.bold}>00/00/0000</span>
          </TableItem>
          <TableItem>
            <span className={styles.bold}>25/05/2023</span>
          </TableItem>
          <TableItem style={{ maxWidth: 170 }}>
            <div className={styles.last_column}>
              <div className={styles.icons_container}>
                <div onClick={handleOpenModal} className={styles.icon}>
                  <Eye />
                </div>
                <div className={styles.icon}>
                  <Reload />
                </div>
              </div>
            </div>
          </TableItem>
        </Row> */}

        {publications.map((publication, index) => (
          <Row key={index}>
            <TableItem style={{}}>
              <div
                className={`${styles.table_item_gap} ${styles.secondColumn}`}
              >
                <div>
                  <img src={publication?.publicationImageUrl} alt="" />
                </div>

                <p className={styles.textLineRender}>{publication?.headline}</p>
              </div>
            </TableItem>
            {/* <TableItem></TableItem> */}

            <TableItem></TableItem>
            <TableItem>
              {/* <span className={styles.bold}>00/00/0000</span> */}
              <span className={styles.bold}>
                {formatDate(publication?.createdAt)}
              </span>
            </TableItem>
            <TableItem>
              {/* <span className={styles.bold}>25/05/2023</span> */}
              <span className={styles.bold}>
                {formatDate(publication?.publicationDate)}
              </span>
            </TableItem>
            <TableItem style={{ maxWidth: 170 }}>
              <div className={styles.last_column}>
                <div className={styles.icons_container}>
                  <div
                    // onClick={}
                    className={styles.icon}
                    onClick={() => handleOpenSimpleEditionModal(publication)}
                  >
                    <EditPencil color={'#000000'} />
                  </div>

                  <div
                    className={styles.icon}
                    onClick={() => handleDeleteDraft(publication?.uuid)}
                  >
                    <Trash color={'#000000'} />
                  </div>
                  {/* <div
                    onClick={() => handleOpenModal(publication)}
                    className={styles.icon}
                  >
                    <Picture color={'#000000'} />
                  </div> */}
                </div>
              </div>
            </TableItem>
          </Row>
        ))}

        <SimpleEditionModal
          isOpen={isSimplesEditModalOpen}
          setNextStep={() => handleNavigate(simplesEditModalData)}
          imageUrl={simplesEditModalData?.publicationImageUrl}
          onClose={handleCloseSimpleEditionModal}
          fontName={fontToRender}
          title={simplesEditModalData?.headline}
          subtitle={simplesEditModalData?.legend}
          isCampaign={simplesEditModalData.campaignId === null ? false : true}

          // publicTargetOptions={productTargetAudienceOptions}
          // productOptions={productTypeOptions}
          //onChangeColorPalleteSubtittle={(e) =>
          //  setHeadlineSubtitle(e.target.value)
          //}
          // onChangeColorPalleteTittle={(e) => setHeadlineText(e.target.value)}
        />
      </ListTable>
    </>
  )
}
