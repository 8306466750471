/* eslint-disable react/prop-types */
import { Button } from '../Button'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
const MODAL_TYPES = {
  DEFAULT: 'default',
}

export const ConfirmParametrizationModal = ({
  isOpen,
  onClose,
  type,
  // onClickStartButton,
  saveClient,
}) => {
  if (!isOpen) return null

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const Modal_Default = () => {
    return (
      <div className={styles.modal_overlay} onClick={handleClose}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.modal_content}
        >
          <div className={styles.content_container}>
            <h3>ATENÇÃO!</h3>
            <p>
              Para que este cliente esteja{' '}
              <span className={styles.bold}>ativo</span> para uso em publicações
              é necessário que você faça a{' '}
              <span className={styles.bold}>parametrização de marca.</span>{' '}
            </p>
            <p>
              A parametrização de campanha é um mecanismo que usamos para que
              você não tenha que fazer isto novamente a cada publicação
            </p>

            <div className={styles.button_container}>
              <Button
                onClick={() => saveClient({ parameterized: true })}
                style={{ padding: '13px 30px' }}
                variant={'primary'}
              >
                Iniciar Parametrização
              </Button>

              <Button
                onClick={() => saveClient({ parameterized: false })}
                style={{
                  color: '#FF2F00',
                  borderColor: '#FF2F00',
                  borderWidth: 1,
                  padding: '5px 16px 5px 16px',
                }}
              >
                Não quero fazer isso agora
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    )
  }

  if (!type || type === MODAL_TYPES.DEFAULT) return <Modal_Default />
}

export default ConfirmParametrizationModal
