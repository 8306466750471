import PropTypes from 'prop-types'
import styles from './styles.module.css'
export const IconButton = ({icon, onClick}) => {
	return (
		<button onClick={onClick} className={styles.iconButton}>{icon}</button>
	)
}

IconButton.propTypes = {
	icon:PropTypes.element,
	onClick:PropTypes.func
}


