import { Plus } from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import PropTypes from 'prop-types'

export const ColorBox = ({color='blue', onClickBox}) => {

  return (
    <div className={styles.colorBox} style={{backgroundColor:color}}>
      <button onClick={onClickBox}>
        <Plus size='20'/>
      </button>
    </div>
  )
}

ColorBox.propTypes = {
  color:PropTypes.string,
  onClickBox:PropTypes.func
}


