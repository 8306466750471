import { useState, useEffect } from 'react'
import Masonry from 'react-responsive-masonry'
import styles from './styles.module.css'

import { InterrogationCircleIcon } from '../../../../assets/svgs/icons'
// import { UseAiImages } from '../../hooks/useAiImages'
import {
  PythonService,
  ProductService,
  BrandingService,
  BusinessService,
  VoiceToneService,
  TargetAudienceService,
} from '../../../../services'
import { Loading } from '../../../../components/Loading'
import { ButtonNavigateStep } from '../../../../components/ButtonNavigateStep'
import { func, array } from 'prop-types'
// import { getAiImagesService } from '../../services/postsServices/getAiImagesService'

// import { Icon, Icon2 } from '../../../src/assets/svgs/icons'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepVisualPreferences({ setStep, setIaData, iaData }) {
  const [selectedImages, setSelectedImages] = useState([])

  const [loading, setLoading] = useState(false)

  function hexToRgb(hex) {
    // Remove o símbolo '#' do início, se presente
    hex = hex.replace(/^#/, '')

    // Verifica se o valor hexadecimal é válido
    if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
      // Se o valor é uma representação de 3 dígitos, expanda para 6 dígitos
      if (hex.length === 3) {
        hex = hex
          .split('')
          .map(function (char) {
            return char + char
          })
          .join('')
      }

      // Divide o valor em pares de dígitos (R, G, B)
      const r = parseInt(hex.slice(0, 2), 16)
      const g = parseInt(hex.slice(2, 4), 16)
      const b = parseInt(hex.slice(4, 6), 16)

      return `(${r},${g},${b})`
    } else {
      // Retorna uma mensagem de erro para valores inválidos
      return `(${0},${0},${0})`
    }
  }

  const handleImageClick = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages?.filter((image) => image !== imageUrl))
    } else {
      setSelectedImages([...selectedImages, imageUrl])
    }
  }

  // useEffect(() => {
  //   if (!iaData) {
  //     setLoading(true)
  //     getIaImages()
  //   }
  // }, [iaData])

  const getIaImages = async () => {
    const productInfos = await ProductService.getProduct()
    const brandingInfos = await BrandingService.getAllBranding()
    const voiceToneInfos = await VoiceToneService.getAllVoiceTone()
    const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudience()

    let bodyHeadline = {
      publications_number: 4,
      niche: businessInfos.data.BusinessNiche.name,
      subniche: businessInfos.data.subNiche,
      publication_purpose: 'divulgar',
      description_business: businessInfos.data.description,
      voice_tone: voiceToneInfos.data[0].tone,
      social_class: 'Classe A',
      gender: targetAudiencesInfos.data[0]?.genderValue,
      profession: targetAudiencesInfos?.data[0]?.profession,
      name_product: productInfos.data[0].name,
      product_type: productInfos.data[0].productTypeValue,
      product_description: productInfos.data[0].description,
      value: productInfos.data[0].value,
      promotional_value: productInfos.data[0].promotionalValue,
    }

    const headline = await PythonService.postHeadline(bodyHeadline)

    const headline1 = headline?.data?.data

    let body = {
      headline: headline1?.headline_1,
      produto: productInfos?.data[0]?.name,
      cor_primaria: hexToRgb(brandingInfos?.data[0]?.primaryColor),
      cor_secundaria: hexToRgb(brandingInfos?.data[0]?.secondaryColor),
      cor_terciaria: hexToRgb(brandingInfos?.data[0]?.tertiaryColor),
      complexidade: 3,
      masculino: 5,
      extravagante: 5,
      moderno: -4,
      distante: 3,
      serio: 4,
      industrial: -5,
      elitizado: -3,
      sofisticado: 3,
      refinado: -4,
    }

    let generateImagesReturn = await PythonService.postGenerateImages(body)

    setIaData(generateImagesReturn?.data?.data)

    setLoading(false)
  }

  // useEffect(() => {
  //   if (iaData?.length > 0) {
  //     setLoading(false)
  //   } else {
  //     setLoading(true)
  //   }
  // }, [iaData])

  return (
    <>
      <Loading enable={loading} />
      <div className={styles.page}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
          <p className={styles.title_text}>Informe suas preferências visuais</p>
          <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
        </div>

        <p className={styles.subtitle_text}>
          Escolha pelo menos 5 itens. Quanto mais escolher, mais assertiva serão
          as artes.
        </p>

        <div className={styles.padding10} style={{ marginTop: 20 }}>
          <Masonry columnsCount={3} gutter="10px">
            {iaData?.map((item, index) => (
              <img
                key={index}
                src={'data:image/png;base64,' + item.imageId}
                onClick={() => handleImageClick(item.imageId)}
                className={
                  selectedImages.includes(item.imageId)
                    ? styles.selectedImage
                    : styles.image
                }
              />
            ))}
          </Masonry>
        </div>

        <div className={styles.buttons_field}>
          <ButtonNavigateStep
            onClick={() => setStep(STEPS.STEP_BRAND_ARCHETYPE)}
            variant={true}
          >
            Voltar
          </ButtonNavigateStep>

          <ButtonNavigateStep
            onClick={() => setStep(STEPS.STEP_VISUAL_REFERENCES)}
            variant={'next'}
          >
            Prosseguir
          </ButtonNavigateStep>
        </div>
      </div>
    </>
  )
}

export default StepVisualPreferences

StepVisualPreferences.propTypes = {
  setStep: func,
  setIaData: func,
  iaData: array,
}
