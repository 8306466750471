import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getAllBrandingArchetype = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-archetype-and-brands`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllBrandingArchetype', error.response.data)
      return error
    })
}

export const getBrandingArchetypeByBusiness = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-archetype-and-brands-by-business`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllBrandingArchetype', error.response.data)
      return error
    })
}

export const getBrandingArchetypeByBusinessId = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}business-archetype-by-business/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getBrandingArchetypeByBusinessId', error.response.data)
      return error
    })
}

export const postBrandingArchetype = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}business-archetype-and-brands`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postBrandingArchetype', error.response.data)
      return error
    })
}

export const putBrandingArchetype = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}business-archetype-and-brands/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error putBrandingArchetype', error.response.data)
      return error
    })
}
export const getProduct = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}products`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getProduct', error.response.data)
      return error
    })
}

export const postArchetypeByLogin = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}business-archetype-by-business-id`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postArchetypeByLogin', error.response.data)
      return error
    })
}
