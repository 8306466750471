import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

const getLegendsService = async (body) => {
  const { data } = await axios.post(`${BASE_URL}legends/`, body)

  return data
}

export { getLegendsService }
