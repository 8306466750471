import styles from './styles.module.css'

import Lottie from 'lottie-react'
import loading from '../../assets/lottie/loading.json'

export const Loading = ({ enable }) => {
  if (!enable) return null

  return (
    <div className={styles.content}>
      <div className={styles.content_overlay}>
        <Lottie animationData={loading} style={{ width: 200 }} />
      </div>
    </div>
  )
}

export default Loading
