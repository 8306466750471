/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useState, useEffect } from 'react'
import { CircleX, MiniSquares, NumberOne } from '../../../assets/svgs/icons'
import PropTypes from 'prop-types'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { Button } from '../../../components/Button'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { CampaignService } from '../../../services'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_NEW_CAMPAIGN: 'stepNewCampaign',
}

function StepFirstPass({
  setStep,
  selectedCampaignOption,
  setSelectedCampaignOption,
  clientData,
  campaignRef,
  uuidLocation,
  campaignIDParam,
}) {
  const navigation = useNavigate()

  const [campaignOptions, setCampaignOptions] = useState([])
  // const [selectedCampaignOption, setSelectedCampaignOption] =
  //   useState(undefined)

  useEffect(() => {
    loadData()
  }, [clientData])

  const loadData = async () => {
    if (clientData.uuid) {
      const { data: campaignData, responseStatus } =
        await CampaignService.getCampaignByClient(clientData.uuid)

      let newCampaignData = campaignData
        .filter((item) => item.isParameterized == true)
        .map((item) => ({
          label: item.name,
          value: item.uuid,
          name: item.name,
          objective: item.objective,
          productId: item.productId,
          targetAudienceId: item.targetAudienceId,
          userAccountId: item.userAccountId,
        }))

      if (campaignIDParam !== '') {
        newCampaignData.map((item) => {
          if (campaignIDParam === item.value) {
            setSelectedCampaignOption(item)
          }
        })
      }

      if (uuidLocation !== '') {
        newCampaignData.map((item) => {
          if (item.value === uuidLocation) {
            campaignRef.current = item
            setSelectedCampaignOption(item)
          }
        })
        nextButton(STEPS.STEP_SECOND_PASS_IMAGE)
      }

      if (responseStatus === 200) setCampaignOptions(newCampaignData)
    } else {
      const { data: campaignData, responseStatus } =
        await CampaignService.getCampaignByNotClient()

      let newCampaignData = campaignData
        .filter((item) => item.isParameterized == true)
        .map((item) => ({
          label: item.name,
          value: item.uuid,
          name: item.name,
          objective: item.objective,
          productId: item.productId,
          targetAudienceId: item.targetAudienceId,
          userAccountId: item.userAccountId,
        }))

      if (campaignIDParam !== '') {
        newCampaignData.map((item) => {
          if (campaignIDParam === item.value) {
            setSelectedCampaignOption(item)
          }
        })
      }

      if (uuidLocation !== '') {
        newCampaignData.map((item) => {
          if (item.value === uuidLocation) {
            campaignRef.current = item
            setSelectedCampaignOption(item)
          }
        })
        nextButton(STEPS.STEP_SECOND_PASS_IMAGE)
      }

      if (responseStatus === 200) setCampaignOptions(newCampaignData)
    }
  }

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }

  const nextButton = async (step) => {
    setStep(step)
  }

  const handleSelectedIntem = (item) => {
    campaignRef.current = item
    setSelectedCampaignOption(item)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      className={styles.new_capaing_container}
    >
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Nova</p>
        <p className={styles.title_new_posts_title}>Publicação</p>
      </div>

      <div className={styles.title_steps}>
        <div>
          <NumberOne width={'40px'} height={'40px'} />
        </div>
        <div>
          <p style={{ color: '#898989', fontSize: '10px', margin: '0px' }}>
            1° Passo
          </p>
          <p
            style={{
              color: '#3E3E3E',
              fontSize: '16px',
              margin: '0px',
              fontWeight: 500,
            }}
          >
            Seleção Campanha
          </p>
        </div>
      </div>
      <div className={styles.container_form}>
        <form className={styles.form_campaign}>
          <div className={styles.container_form_first_options}>
            <div className={styles.form_container_input}>
              <label>Selecione uma campanha para continuar</label>
              <div style={{ width: '100%' }}>
                <SelectSquare
                  arrowDirection="down"
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={() => {
                    if (selectedCampaignOption) {
                      return {
                        label: selectedCampaignOption.label,
                        value: selectedCampaignOption.value,
                      }
                    }

                    return { label: 'Selecione', value: 1 }
                  }}
                  options={campaignOptions}
                  onClickItem={handleSelectedIntem}
                />
              </div>
            </div>

            {selectedCampaignOption && (
              <div className={styles.item_campaing}>
                <div className={styles.item_campaing_data}>
                  <div className={styles.item_campaing_svg}>
                    <MiniSquares />
                  </div>
                  <div className={styles.item_campaing_descrption}>
                    <p className={styles.item_campaing_label}>
                      Nome da campanha
                    </p>
                    <p className={styles.item_campang_itle}>
                      {selectedCampaignOption.label}
                    </p>
                  </div>
                </div>
                <div
                  className={styles.item_campaing_remove}
                  onClick={() => setSelectedCampaignOption(undefined)}
                >
                  <CircleX color={'#000'} />
                </div>
              </div>
            )}

            {!selectedCampaignOption && (
              <div className={styles.call_to_action_container}>
                <div className={styles.new_campaing}>
                  <p className={styles.new_campaing_label}>
                    Não tem uma campanha?
                  </p>
                  <p className={styles.new_campaing_text}>
                    Crie uma nova campanha agora mesmo!
                  </p>
                </div>
                <div className={styles.box_button_create_campaign}>
                  <Button
                    onClick={() => {
                      navigation('/Campaign', {
                        state: { step: 'newCampaign' },
                      })
                    }}
                    variant={'secondary'}
                  >
                    Criar Nova Campanha
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.button_next}>
        <Button
          onClick={() => {
            handleNavigate('/NewPostPage')
          }}
          variant={'secondary'}
        >
          Cancelar e Voltar
        </Button>

        {selectedCampaignOption && (
          <Button
            onClick={() => nextButton(STEPS.STEP_SECOND_PASS_IMAGE)}
            variant={'primary'}
          >
            Prosseguir
          </Button>
        )}
      </div>
    </motion.div>
  )
}

export default StepFirstPass

StepFirstPass.propTypes = {
  setStep: PropTypes.func,
}
