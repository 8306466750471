import { useEffect, useState } from 'react'
import { BoxChecket, BoxNotChecket } from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import { StatesService, UserAccountsService } from '../../../services'

const STEPS = {
  STEP_PASS_CPF: 'stepCPF',
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
}

function StepAddress({
  setStep,
  cpf,
  cnpj,
  fantasyName,
  corporateReason,
  userData,
}) {
  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [stateOption, setStateOption] = useState('')
  const [stateData, setStateData] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let statesReturn = await StatesService.getStates()

    setStateData(statesReturn.data)
  }

  function onlyNumbers(input) {
    return input.replace(/[^0-9]/g, '')
  }

  const handleChangeState = (event) => {
    setStateOption(event.target.value)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      //setStep(STEPS.STEP_PASS_ADDRESS)

      // let bodyUserAccounts = {
      //   userId: userData?.uuid,
      //   userAccountTypeId: 0,
      //   cpf: cpf,
      //   cnpj: onlyNumbers(cnpj),
      //   fantasyName: fantasyName,
      //   corporateName: corporateReason,
      //   cep: onlyNumbers(cep),
      //   logradouro: street,
      //   number: onlyNumbers(number),
      //   complement: complement,
      //   //state: formCompany ? +stateOption : 100,
      //   state: Number(stateOption),
      //   city: city,
      // }

      // let userAccountsRegister = await UserAccountsService.postUserAccounts(
      //   bodyUserAccounts
      // )

      // console.log('userAccountsRegister', userAccountsRegister)

      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    } else {
      setStep(STEPS.STEP_PASS_CPF)
    }
  }
  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <div className={styles.box_content}>
          <p style={{ margin: '0px' }}>Cadastro</p>
          <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

          <div className={styles.box_input_name}>
            <p>CEP</p>
            <input
              className={styles.input_name}
              value={cep}
              onChange={(e) => setCep(e.currentTarget.value)}
              type="text"
              placeholder="CEP"
            />

            <p>Rua</p>
            <input
              className={styles.input_name}
              value={street}
              onChange={(e) => setStreet(e.currentTarget.value)}
              type="text"
              placeholder="Rua"
            />
            <p>Numero</p>
            <input
              className={styles.input_name}
              value={number}
              onChange={(e) => setNumber(e.currentTarget.value)}
              type="text"
              placeholder="Numero"
            />
            <p>Complemento</p>
            <input
              className={styles.input_name}
              value={complement}
              onChange={(e) => setComplement(e.currentTarget.value)}
              type="text"
              placeholder="Complemento"
            />
            <p>Estado</p>
            <select
              className={styles.input_name}
              onChange={handleChangeState}
              value={stateOption}
            >
              <option value="">Estado</option>
              {stateData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <p>Cidade</p>
            <input
              className={styles.input_name}
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
              type="text"
              placeholder="Cidade"
            />
          </div>
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepAddress
