import { useState } from 'react'
import styles from './styles.module.css'
import InputMask from 'react-input-mask'

function StepPhone({ phone, setPhone, setStep }) {
  const STEPS = {
    STEP_PASS_EMAIL: 'stepEmail',
    STEP_PASS_PASSWORD: 'stepPassword',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      setStep(STEPS.STEP_PASS_PASSWORD)
    } else {
      setStep(STEPS.STEP_PASS_EMAIL)
    }
  }

  const formatPhone = (value) => {
    console.log('value', value)

    // Remove caracteres não numéricos do valor
    const cleanedValue = value.replace(/\D/g, '')

    // Aplica a máscara de telefone (xx) xxxxx-xxxx
    let formattedValue = ''
    if (cleanedValue.length >= 2) {
      formattedValue += `(${cleanedValue.substring(0, 2)}`
    }
    if (cleanedValue.length > 2) {
      formattedValue += `) ${cleanedValue.substring(2, 7)}`
    }
    if (cleanedValue.length > 7) {
      formattedValue += `-${cleanedValue.substring(7, 11)}`
    }

    console.log('entrou', formattedValue)
    return formattedValue
  }

  const handlePhoneChange = (event) => {
    console.log(event.target.value)
    const inputValue = event.target.value
    const formattedPhone = formatPhone(inputValue)
    setPhone(formattedPhone)
  }

  return (
    <div className={styles.box_step}>
      <div className={styles.box_page}>
        <p style={{ margin: '0px' }}>Cadastro</p>
        <h1 style={{ marginTop: '0px' }}>Criar conta</h1>

        <div className={styles.box_input_phone}>
          <p>Insira seu melhor telefone (preferência WhatsApp)</p>
          {/* <input
          className={styles.input_phone}
          type="text"
          placeholder="Telefone"
          value={phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
        /> */}
          <InputMask
            className={styles.input_phone}
            type="text"
            mask="(99) 99999-9999"
            placeholder="(00) 00000-0000"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.box_button}>
        <button
          className={styles.back_button}
          onClick={() => handleNavigate('back')}
        >
          Voltar
        </button>
        <button
          className={styles.next_button}
          onClick={() => handleNavigate('next')}
        >
          Avançar
        </button>
      </div>
    </div>
  )
}

export default StepPhone
